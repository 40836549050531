import { useMediaQuery } from 'react-responsive';

import { useTheme } from 'styled-components';
import {
  ALCOHOL_STUDIES_ABOUT_US_URL,
  ALCOHOL_CATALOGUE_URL
} from '../../../constants/navigationRoutes';
import * as T from '../../common/Typography';
import Button from './../../common/Button';
import * as S from './style';

import MakingEveryContactCountImg from '../../../assets/MakingEveryContactCount.svg';
import NorthEastNorthCumbriaImg from '../../../assets/NorthEastNorthCumbria-NHS.svg';
import NorthEastPartnershipImg from '../../../assets/NorthEastPartnership.png';
import NHSEngland from '../../../assets/NHS-England.jpg';

const CalloutSection = () => {
  const { breakpoints } = useTheme();

  const isDesktop = useMediaQuery({
    query: breakpoints.laptop
  });

  return (
    <S.CallOut>
      <S.GrayCard>
        <T.H2W mb="4" mbT="2">
          About the programme
        </T.H2W>
        <S.FindMoreSubtitleWrapper>
          <T.P
            mb="5"
            mbT="3"
            smallMedium={!!isDesktop}
            small={!isDesktop}
            weight={'400'}
          >
            Learn more about the North East & North Cumbria Programme for
            Alcohol Studies training programme here
          </T.P>
          <Button
            to={ALCOHOL_STUDIES_ABOUT_US_URL}
            type={isDesktop ? 'primary' : 'small'}
            label="Find out more"
            width={isDesktop ? '270px' : '213px'}
          />
        </S.FindMoreSubtitleWrapper>
      </S.GrayCard>
      <S.CallOutRow2>
        <S.BlueCard>
          <T.H2W color="white" mb="4" mbT="2">
            Get started with training
          </T.H2W>
          <S.SubtitleWrapper>
            <T.P
              color="white"
              mb="4"
              mbT="3"
              smallMedium={!!isDesktop}
              small={!isDesktop}
              weight={'400'}
            >
              To view training modules browse the catalogue. Modules are
              accessible to all relevant health, social care and third sector
              staff in North East & North Cumbria region. Please note sign in is
              required
            </T.P>
          </S.SubtitleWrapper>

          <Button
            to={ALCOHOL_CATALOGUE_URL}
            type={isDesktop ? 'secondary' : 'small'}
            label="View the training catalogue"
            width={isDesktop ? '270px' : '213px'}
          />
        </S.BlueCard>
        <S.BlackCard>
          <T.H2W color="white" mb="4" mbT="2">
            The programme covers a wide range of online training materials and
            access to live training opportunities
          </T.H2W>
          <T.P
            color="white"
            mb="5"
            mbT="4"
            smallMedium={!!isDesktop}
            small={!isDesktop}
            weight={'400'}
          >
            It was created in collaboration with
          </T.P>

          <S.collaborateCol>
            <S.collaborateRow>
              <S.collaborateDiv small>
                <S.Img
                  src={NorthEastNorthCumbriaImg}
                  alt="NorthEastNorthCumbriaImg"
                  maxWidth="146px"
                />
              </S.collaborateDiv>
              <S.collaborateDiv small>
                <S.Img
                  src={NHSEngland}
                  alt="NHS England Logo"
                  maxWidth="92px"
                />
              </S.collaborateDiv>
            </S.collaborateRow>
            <S.collaborateRow>
              <S.collaborateDiv small>
                <S.ImgNoHeight
                  src={NorthEastPartnershipImg}
                  alt="NorthEastPartnershipImg"
                />
              </S.collaborateDiv>
              <S.collaborateDiv small>
                <S.Img
                  src={MakingEveryContactCountImg}
                  alt="MakingEveryContactCountImg"
                  maxWidth="92px"
                />
              </S.collaborateDiv>
            </S.collaborateRow>
          </S.collaborateCol>
        </S.BlackCard>
      </S.CallOutRow2>
    </S.CallOut>
  );
};

export default CalloutSection;
