const organisations = [
  {
    groupLabel: 'Housing organisation',
    options: [
      {
        label: 'Karbon Homes',
        value: 'Housing organisation - Karbon Homes'
      },
      {
        label: 'Home Group',
        value: 'Housing organisation - Home Group'
      },
      {
        label: 'Anchor Trust',
        value: 'Housing organisation - Anchor Trust'
      },
      {
        label: 'Accent Group',
        value: 'Housing organisation - Accent Group'
      },
      {
        label: 'Byker Community Trus',
        value: 'Housing organisation - Byker Community Trus'
      },
      {
        label: 'Bernicia',
        value: 'Housing organisation - Bernicia'
      },
      {
        label: 'Johnnie Johnson Housing',
        value: 'Housing organisation - Johnnie Johnson Housing'
      },
      {
        label: 'Hanover Housing Association',
        value: 'Housing organisation - Hanover Housing Association'
      },
      {
        label: 'Housing 21',
        value: 'Housing organisation - Housing 21'
      },
      {
        label: 'Isos Housing',
        value: 'Housing organisation - Isos Housing'
      },
      {
        label: 'Places for People',
        value: 'Housing organisation - Places for People'
      },
      {
        label: 'Tyne Housing',
        value: 'Housing organisation - Tyne Housing'
      },
      {
        label: 'Gentoo',
        value: 'Housing organisation - Gentoo'
      },
      {
        label: 'Other, please specify',
        value: 'Housing organisation - Other, please specify'
      }
    ]
  },
  {
    groupLabel: 'Statutory organisation',
    options: [
      {
        label: 'Please specify',
        value: 'Statutory organisation - Please specify'
      }
    ]
  },

  {
    groupLabel: 'Local Authority',
    options: [
      {
        label: 'Darlington',
        value: 'Local Authority -  Darlington'
      },

      {
        label: 'Durham',
        value: 'Local Authority - Durham'
      },
      {
        label: 'Gateshead',
        value: 'Local Authority - Gateshead'
      },
      {
        label: 'Hartlepool',
        value: 'Local Authority - Hartlepool'
      },
      {
        label: 'Middlesbrough',
        value: 'Local Authority - Middlesbrough'
      },
      {
        label: 'Newcastle',
        value: 'Local Authority - Newcastle'
      },
      {
        label: 'North Cumbria',
        value: 'Local Authority - North Cumbria'
      },
      {
        label: 'North Tyneside',
        value: 'Local Authority - North Tyneside'
      },
      {
        label: 'Northumberland',
        value: 'Local Authority - Northumberland'
      },
      {
        label: 'Redcar and Cleveland',
        value: 'Local Authority - Redcar and Cleveland'
      },
      {
        label: 'South Tyneside',
        value: 'Local Authority - South Tyneside'
      },
      {
        label: 'Sunderland',
        value: 'Local Authority - Sunderland'
      },
      {
        label: 'Stockton-on-Tees',
        value: 'Local Authority - Stockton-on-Tees'
      },
      {
        label: 'Other, please specify',
        value: 'Local Authority - Other, please specify'
      }
    ]
  },

  {
    groupLabel: 'Health Services',
    options: [
      {
        label: 'Please specify',
        value: 'Health Services - Please specify'
      }
    ]
  },
  {
    groupLabel: 'Social Care Services',
    options: [
      {
        label: 'Please specify',
        value: 'Social Care Services - Please specify'
      }
    ]
  },
  {
    groupLabel: 'Education',
    options: [
      {
        label: 'Northumbria University',
        value: 'Education - Northumbria University'
      },

      {
        label: 'Newcastle University',
        value: 'Education - Newcastle University'
      },
      {
        label: 'Teesside University',
        value: 'Education - Teesside University'
      },
      {
        label: 'Durham University',
        value: 'Education - Durham University'
      },
      {
        label: 'University of Sunderland',
        value: 'Education - University of Sunderland'
      },
      {
        label: 'University of Cumbria',
        value: 'Education - University of Cumbria'
      },
      {
        label: 'Tyne Metropolitan College',
        value: 'Education - Tyne Metropolitan College'
      },
      {
        label: 'Gateshead College',
        value: 'Education - Gateshead College'
      },
      {
        label: 'New College Durham',
        value: 'Education - New College Durham'
      },
      {
        label: 'East Durham College',
        value: 'Education - East Durham College'
      },
      {
        label: 'Stockton Riverside College',
        value: 'Education - Stockton Riverside College'
      },
      {
        label: 'Stockton Sixth Form College',
        value: 'Education - Stockton Sixth Form College'
      },
      {
        label: 'Darlington College',
        value: 'Education - Darlington College'
      },
      {
        label: 'Hartlepool College of Further Education',
        value: 'Education - Hartlepool College of Further Education'
      },
      {
        label: 'Hartlepool Sixth Form College',
        value: 'Education - Hartlepool Sixth Form College'
      },
      {
        label: 'South Tyneside College',
        value: 'Education - South Tyneside College'
      },
      {
        label: 'Sunderland College',
        value: 'Education - Sunderland College'
      },
      {
        label: 'Northumberland College',
        value: 'Education - Northumberland College'
      },
      {
        label: 'Middlesbrough College',
        value: 'Education - Middlesbrough College'
      },
      {
        label: 'Bishop Auckland College',
        value: 'Education - Bishop Auckland College'
      },
      {
        label: 'Redcar and Cleveland College',
        value: 'Education - Redcar and Cleveland College'
      },
      {
        label: 'Derwentside College',
        value: 'Education - Derwentside College'
      },
      {
        label: 'Queen Elizabeth Sixth Form',
        value: 'Education - Queen Elizabeth Sixth Form'
      },
      {
        label: 'Lakes College',
        value: 'Education - Lakes College'
      },
      {
        label: 'Carlisle College',
        value: 'Education - Carlisle College'
      },

      {
        label: 'Other, please specify',
        value: 'Education - Other, please specify'
      }
    ]
  },
  {
    groupLabel: 'Sports and Leisure, non-Council',
    options: [
      {
        label: 'RISE',
        value: 'Sports and Leisure, non-Council - RISE'
      },

      {
        label: 'County Durham Sport',
        value: 'Sports and Leisure, non-Council - County Durham Sport'
      },

      {
        label: 'Tees Valley Sport',
        value: 'Sports and Leisure, non-Council - Tees Valley Sport'
      },
      {
        label: 'Active Cumbria',
        value: 'Sports and Leisure, non-Council - Active Cumbria'
      },
      {
        label: 'Newcastle United Foundation',
        value: 'Sports and Leisure, non-Council - Newcastle United Foundation'
      },
      {
        label: 'Newcastle Rugby Foundation',
        value: 'Sports and Leisure, non-Council - Newcastle Rugby Foundation'
      },
      {
        label: 'Newcastle Eagles Community Foundation',
        value:
          'Sports and Leisure, non-Council - Newcastle Eagles Community Foundation'
      },
      {
        label: 'Foundation of Light',
        value: 'Sports and Leisure, non-Council - Foundation of Light'
      },
      {
        label: 'Middlesbrough FC Foundation',
        value: 'Sports and Leisure, non-Council - Middlesbrough FC Foundation'
      },
      {
        label: 'Gateshead FC Foundation',
        value: 'Sports and Leisure, non-Council - Gateshead FC Foundation'
      },
      {
        label: 'Hartlepool United Community Sports Foundation',
        value:
          'Sports and Leisure, non-Council - Hartlepool United Community Sports Foundation'
      },
      {
        label: 'Carlisle United Community Sports Trust',
        value:
          'Sports and Leisure, non-Council - Carlisle United Community Sports Trust'
      },
      {
        label: 'Berwick Rangers FC Community Foundation',
        value:
          'Sports and Leisure, non-Council - Berwick Rangers FC Community Foundation'
      },
      {
        label: 'StreetGames',
        value: 'Sports and Leisure, non-Council - StreetGames'
      },
      {
        label: 'Other, please specify',
        value: 'Sports and Leisure, non-Council - Other, please specify'
      }
    ]
  },

  {
    groupLabel: 'Drug and Alcohol Services',
    options: [
      {
        label: ' Newcastle Treatment and Recovery (NTaR)',
        value:
          'Drug and Alcohol Services -  Newcastle Treatment and Recovery (NTaR)'
      },

      {
        label: 'County Durham Drug and Alcohol Recovery Service',
        value:
          'Drug and Alcohol Services - County Durham Drug and Alcohol Recovery Service'
      },
      {
        label: 'PROPS North East',
        value: 'Drug and Alcohol Services - PROPS North East'
      },
      {
        label: 'The Matrix Service',
        value: 'Drug and Alcohol Services - The Matrix Service'
      },
      {
        label: 'Platform Gateshead',
        value: 'Drug and Alcohol Services - Platform Gateshead'
      },
      {
        label: 'Wear Recovery',
        value: 'Drug and Alcohol Services - Wear Recovery'
      },
      {
        label: 'NECA',
        value: 'Drug and Alcohol Services - NECA'
      },
      {
        label: 'Other, please specify',
        value: 'Drug and Alcohol Services - Other, please specify'
      }
    ]
  },
  {
    groupLabel: 'Third Sector Organisations',
    options: [
      {
        label: 'North Tyneside VODA',
        value: 'Third Sector Organisations - North Tyneside VODA'
      },

      {
        label: 'VONNE',
        value: 'Third Sector Organisations - VONNE'
      },
      {
        label: 'NEYOUTH',
        value: 'Third Sector Organisations - NEYOUTH'
      },
      {
        label: 'Community Action Northumberland',
        value: 'Third Sector Organisations - Community Action Northumberland'
      },
      {
        label: 'Community Voluntary Action Blyth Valley',
        value:
          'Third Sector Organisations - Community Voluntary Action Blyth Valley'
      },
      {
        label: 'Durham Community Action',
        value: 'Third Sector Organisations - Durham Community Action'
      },
      {
        label: 'East Durham Trust',
        value: 'Third Sector Organisations - East Durham Trust'
      },
      {
        label: 'Hartlepower CIC',
        value: 'Third Sector Organisations - Hartlepower CIC'
      },
      {
        label: 'Catalyst Stockon-on-Tees',
        value: 'Third Sector Organisations - Catalyst Stockon-on-Tees'
      },
      {
        label: 'Middlesbrough Voluntary Development Agency',
        value:
          'Third Sector Organisations - Middlesbrough Voluntary Development Agency'
      },
      {
        label: 'Redcar & Cleveland Voluntary Development Agency',
        value:
          'Third Sector Organisations - Redcar & Cleveland Voluntary Development Agency'
      },
      {
        label: 'Tees Valley Rural Action',
        value: 'Third Sector Organisations - Tees Valley Rural Action'
      },
      {
        label: 'Connected Voice',
        value: 'Third Sector Organisations - Connected Voice'
      },
      {
        label: 'Inspire South Tyneside',
        value: 'Third Sector Organisations - Inspire South Tyneside'
      },
      {
        label: 'Voluntary Community Action Sunderland',
        value:
          'Third Sector Organisations - Voluntary Community Action Sunderland'
      },
      {
        label: 'Children North East',
        value: 'Local Authority - Children North East'
      },
      {
        label: 'Other, please specify',
        value: 'Third Sector Organisations - Other, please specify'
      }
    ]
  },

  {
    groupLabel: 'Community Group',
    options: [
      {
        label: 'Please specify',
        value: 'Community Group - please specify'
      }
    ]
  },

  {
    groupLabel: 'Government Sponsored Body',
    options: [
      {
        label: 'Please specify',
        value: 'Government Sponsored Body - Please specify'
      }
    ]
  },
  {
    groupLabel: 'Private Sector',
    options: [
      {
        label: 'Please specify',
        value: 'Private Sector - Other, please specify'
      }
    ]
  },
  {
    groupLabel: 'Emergency Services',
    options: [
      {
        label: 'Tyne and Wear Fire and Rescue Service',
        value: 'Emergency Services - Tyne and Wear Fire and Rescue Service'
      },
      {
        label: 'Cumbria Fire and Rescue Service',
        value: 'Emergency Services - Cumbria Fire and Rescue Service'
      },
      {
        label: 'Cleveland Fire',
        value: 'Emergency Services - Cleveland Fire'
      },
      {
        label: 'County Durham and Darlington Fire and Rescue Service',
        value:
          'Emergency Services - County Durham and Darlington Fire and Rescue Service'
      },
      {
        label: 'Northumberland Fire and Rescue Service',
        value: 'Emergency Services - Northumberland Fire and Rescue Service'
      },
      {
        label: 'Northumbria Police',
        value: 'Emergency Services - Northumbria Police'
      },
      {
        label: 'Cleveland Police',
        value: 'Emergency Services - Cleveland Police'
      },
      {
        label: 'Durham Police',
        value: 'Emergency Services - Durham Police'
      },
      {
        label: 'Cumbria Police',
        value: 'Emergency Services - Cumbria Police'
      }
    ]
  },
  {
    groupLabel: 'Foundation Trusts',
    options: [
      {
        label: 'Northumbria Healthcare NHS Trust',
        value: 'Foundation Trusts - Northumbria Healthcare NHS Trust'
      },
      {
        label: 'North Cumbria Integrated Care NHS Trust',
        value: 'Foundation Trusts - North Cumbria Integrated Care NHS Trust'
      },
      {
        label: 'North Tees and Hartlepool NHS Trust',
        value: 'Foundation Trusts - North Tees and Hartlepool NHS Trust'
      },
      {
        label: 'South Tees NHS Trust',
        value: 'Foundation Trusts - South Tees NHS Trust'
      },
      {
        label: 'Tees, Esk and Wear Valleys NHS Trust',
        value: 'Foundation Trusts - Tees, Esk and Wear Valleys NHS Trust'
      },
      {
        label: 'County Durham and Darlington NHS Trust',
        value: 'Foundation Trusts - County Durham and Darlington NHS Trust'
      },
      {
        label: 'Cumbria, Northumberland, Tyne and Wear NHS Trust',
        value:
          'Foundation Trusts - Cumbria, Northumberland, Tyne and Wear NHS Trust'
      },
      {
        label: 'Gateshead NHS Trust',
        value: 'Foundation Trusts - Gateshead NHS Trust'
      },
      {
        label: 'Newcastle Hospitals NHS Trust',
        value: 'Foundation Trusts - Newcastle Hospitals NHS Trust'
      },
      {
        label: 'South Tyneside and Sunderland NHS Trust',
        value: 'Foundation Trusts - South Tyneside and Sunderland NHS Trust'
      },
      {
        label: 'North East Ambulance Service (NEAS)',
        value: 'Foundation Trusts - North East Ambulance Service (NEAS)'
      },
      {
        label: 'Other, please specify',
        value: 'Third Sector Organisations - Other, please specify'
      }
    ]
  },

  {
    groupLabel: 'None of the above, please specify',
    options: [
      {
        label: 'None of the above, please specify',
        value: 'None of the above, please specify'
      }
    ]
  }
];

export default organisations;
