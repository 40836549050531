/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';

// COMMON COMPONENTS

import Layout from '../../Layouts';
import * as T from '../../common/Typography';
import { Row, Col } from '../../common/Grid';
import Icon from '../../common/Icon';
import { externalLinks } from '../../../constants';
// STYLING
import { IconWrapper } from './TrainerAdvice.Style';

class TrainerAdvice extends Component {
  render() {
    return (
      <Layout>
        <Row mb="4">
          <Col w={[4, 8, 8]} display="block">
            <T.H1 mb="6" width="100%">
              Trainer Advice
            </T.H1>

            <T.P weight={700} mr="1" color="black">
              Resources:
            </T.P>
          </Col>
        </Row>
        <Row mb="8">
          <Col w={[4, 8, 8]}>
            <T.P mr="1">
              If you ever need access to trainining resources, tools or advice,
              simply go to{' '}
              <T.Link
                external
                color="secondary"
                underline={false}
                to={externalLinks.NHS_FUTURE_LEARNS}
              >
                NHS Future Learns
              </T.Link>
              . This will provide you all the latest information you need to
              help with training.
            </T.P>
          </Col>
        </Row>

        <Row mb="4">
          <Col w={[4, 8, 8]} display="block">
            <T.P weight={700} mr="1" color="black">
              Using this tool
            </T.P>
          </Col>
        </Row>

        <Row mb="6">
          <Col w={[4, 8, 8]}>
            <T.P mr="1">
              On this platform you will be able to set up training sessions,
              invite participants, collect feedback and see your results. To
              make the most of it and use the tool properly, watch this video
              that will explain how to use this tool.
            </T.P>
          </Col>
        </Row>
        <Row mb="8">
          <Col w={[4, 8, 8]}>
            <T.Link
              external
              underline={false}
              to={externalLinks.EXPLAIN_TOOL_VIDEO}
            >
              <IconWrapper>
                <Icon icon="open" mr="4" /> View video
              </IconWrapper>
            </T.Link>
          </Col>
        </Row>

        <Row mb="4">
          <Col w={[4, 8, 8]} display="block">
            <T.P weight={700} mr="1" color="black">
              Contact
            </T.P>
          </Col>
        </Row>

        <Row mb="6">
          <Col w={[4, 8, 8]}>
            <T.P mr="1" color="darkGray">
              Need to talk to someone? Contact us on{' '}
              <T.Link
                href={`mailto:${externalLinks.MECC_CONTACT_EMAIL}`}
                external
                target="_blank"
                rel="noopener noreferrer"
                color="primary"
                underline={false}
              >
                {externalLinks.MECC_CONTACT_EMAIL}
              </T.Link>
            </T.P>
          </Col>
        </Row>
      </Layout>
    );
  }
}

export default TrainerAdvice;
