import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';

import { POVERTY_CATALOGUE_URL } from '../../../constants/navigationRoutes';
import Layout from '../../Layouts';

import { Col, Row } from '../../common/Grid';
import IconCard from '../../common/IconCard';
import MaxWidthContainer from '../../common/MaxWidthContainer';
import * as T from '../../common/Typography';
import * as S from './style';

import CNEHeader from '../../common/CNEHeader';
import CalloutSection from './CalloutSection';

const PovertyProofing = () => {
  const { breakpoints } = useTheme();

  const isDesktop = useMediaQuery({
    query: breakpoints.laptop
  });
  return (
    <Layout layout="website">
      <CNEHeader
        title=" North East and North Cumbria, Programme for Poverty Proofing©
            Studies"
      />
      <MaxWidthContainer>
        <S.HeadWrapper>
          <T.P
            medium={!!isDesktop}
            small={!isDesktop}
            ta="center"
            color="gray"
            mt="4"
            weight="700"
          >
            A comprehensive training programme to equip the workforce with
            skills, capacity and knowledge towards improving access and
            experience of healthcare for individuals and families experiencing
            poverty.
          </T.P>
        </S.HeadWrapper>
        <Row mt="7" mtT="27" mb="7" mbT="6" inner>
          <Col w={[4, 12, 4]} mbT="5">
            <IconCard
              bgColor="mainPrimary"
              icon="calculator"
              to={`${POVERTY_CATALOGUE_URL}?div=${1}`}
            >
              <T.H4W mt="4">Poverty Awareness</T.H4W>
              <T.P mt="2" mb="2" color="gray" small={!isDesktop}>
                Learn about the evidence based drivers of poverty, how this
                impacts health inequalities and how you can make a difference in
                your day-to-day work.
              </T.P>
              <T.Link
                color="gray"
                to={`${POVERTY_CATALOGUE_URL}?div=${1}`}
                small={!isDesktop}
              >
                Learn more
              </T.Link>
            </IconCard>
          </Col>
          <Col w={[4, 12, 4]} mbT="5">
            <IconCard
              bgColor="mainSecondary"
              icon="conversation"
              to={`${POVERTY_CATALOGUE_URL}?div=${2}`}
            >
              <T.H4W mt="4">Poverty Identification & Support</T.H4W>
              <T.P mt="2" mb="2" color="gray" small={!isDesktop}>
                Learn how to have effective conversations about poverty and
                respond appropriately when it is a factor in patient care.
              </T.P>
              <T.Link
                color="gray"
                to={`${POVERTY_CATALOGUE_URL}?div=${2}`}
                small={!isDesktop}
              >
                Learn more
              </T.Link>
            </IconCard>
          </Col>
          <Col w={[4, 12, 4]}>
            <IconCard
              bgColor="darkGray"
              icon="handshake"
              to={`${POVERTY_CATALOGUE_URL}?div=${3}`}
            >
              <T.H4W mt="4">Poverty Proofing© Interventions</T.H4W>
              <T.P mt="2" mb="2" color="gray" small={!isDesktop}>
                Learn about interventions and promising practice from across the
                region to help remove barriers to inclusive access and improve
                patient experience.
              </T.P>
              <T.Link
                color="gray"
                to={`${POVERTY_CATALOGUE_URL}?div=${3}`}
                small={!isDesktop}
              >
                Learn more
              </T.Link>
            </IconCard>
          </Col>
        </Row>
        <CalloutSection />
      </MaxWidthContainer>
    </Layout>
  );
};

export default PovertyProofing;
