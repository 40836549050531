import styled from 'styled-components';

export const Wrapper = styled.div`
  font-family: 'museo-sans', sans-serif;
  margin: 0 auto;
`;

export const EmailInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 0.5rem;
`;

export const InfoTitle = styled.p`
  font-size: 1rem;
  font-weight: 400;
  color: black;
`;

export const SessionInfoTitle = styled.span`
  margin-right: 2px;
`;

export const List = styled.ul`
  padding-left: 1.5rem;
  color: ${({ theme }) => theme.colors.darkGray};
  & > * {
    margin-left: 0.5rem;
    font-size: 16px;
  }
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.colors.primary} !important;

  :hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;
