import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div``;

export const DetailsContent = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  width: 100%;
  box-shadow: ${({ theme }) => theme.shadows.primary};
  margin-bottom: 1rem;
  padding: 2rem 1rem;
  flex-direction: column;
`;

export const Detail = styled.h2`
  font-size: 1rem;
  margin-bottom: 0rem;
  font-weight: 300;
`;

export const BoldSpan = styled.span`
  font-weight: 500;
  cursor: ${pointer => pointer && 'pointer'};
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const DeteteAccountBtn = styled.div`
  margin: 0 auto;
  font-weight: 300;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.gray};
  padding: 0.5rem 0;
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.colors.red};
  }
`;

export const StyledLink = styled(Link)`
  margin: 0 auto;
  font-weight: 300;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.gray};
  padding: 0.5rem 0;
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.colors.red};
  }
`;
