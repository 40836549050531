import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Row } from '../Grid';

export const Wrapper = styled(Row)`
  font-family: 'museo-sans', sans-serif;

  #participantsEmails {
    .ant-select-selection.ant-select-selection--single {
      height: 5rem;
    }
    .ant-select-selection__rendered {
      height: 100%;
    }
  }

  #selectGroup .ant-checkbox-wrapper {
    width: 100%;
  }
`;

export const StyledLink = styled(Link)`
  font-size: 16px;
  line-height: 19px;
  color: #2f80ed;
  display: inline-block;
`;

export const SelecetWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`;

export const IconsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  min-width: 90px;
  margin-top: ${({ theme }) => theme.spacings[2]};
`;

export const AddEmailsButton = styled.button`
  width: 100%;
  padding: 0.75rem;
  background: none;
  border: none;
  cursor: pointer;
  font-family: 'museo-sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
`;

export const BackLink = styled.button`
  border: none;
  background: none;
  color: ${({ theme }) => theme.colors.gray};
  font-weight: 500;
  cursor: pointer;
  outline: none;

  :focus,
  :hover {
    text-decoration: underline;
  }
`;

export const HiddenButton = styled.button`
  border: none;
  background: none:
  cursor: pointer;
  outline: none;
`;
