import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { courseLabelMap } from '../../../constants';
import { courseOptions } from '../../../constants/index';
import * as R from '../../../constants/navigationRoutes';
import Layout from '../../Layouts';
import { Col, Row } from '../../common/Grid';
import { Dropdown } from '../../common/Inputs';
import * as T from '../../common/Typography';
import useSearchParams from './../../../hooks/useSearchParams';

const ChooseCourse = ({ role, coursesCanDeliver, match, history }) => {
  const [course, setCourse] = useState(() => {
    return role === 'trainer' && coursesCanDeliver.length === 1
      ? coursesCanDeliver[0]
      : '';
  });
  const searchParams = useSearchParams();

  const { trainerName, target } = searchParams.params;
  useEffect(() => {
    if (course) {
      searchParams.set('course', course);

      history.push({
        pathname: R.TRAINER_RESULTS_URL,
        search: searchParams.toString()
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course]);
  const onChange = _course => {
    setCourse(_course);
    searchParams.set('course', _course);

    history.push({
      pathname: R.TRAINER_RESULTS_URL,
      search: searchParams.toString()
    });
  };
  const _trainerName =
    trainerName && trainerName[0].toUpperCase() + trainerName.slice(1);

  const titles = {
    all: 'All results',
    me: 'Your results',
    myGroup: "My group's results",
    localLeadGroup: `${_trainerName} group's results`,
    trainer: `${_trainerName}'s results`
  };

  const title = titles[target];
  return (
    <Layout>
      <>
        <T.H1 mb={7}>{title}</T.H1>
        {!course && (
          <>
            <Row mb={4}>
              <Col w={[4, 8, 4]}>
                <T.P weight={700}>
                  Please select the course you would like to view
                </T.P>
              </Col>
            </Row>
            <Row mb={4}>
              <Col w={[4, 8, 4]}>
                <Dropdown
                  selected={course}
                  placeholder="Select..."
                  required
                  options={
                    role === 'trainer'
                      ? coursesCanDeliver.map(c => ({
                          label: courseLabelMap[c],
                          value: c
                        }))
                      : courseOptions
                  }
                  name="course"
                  handleChange={value => onChange(value)}
                />
              </Col>
            </Row>
          </>
        )}
      </>
    </Layout>
  );
};

const mapStateToProps = state => {
  const coursesCanDeliver = state?.auth?.coursesCanDeliver || [];

  return {
    coursesCanDeliver
  };
};

export default connect(mapStateToProps, {})(ChooseCourse);
