import ChildrenNorthEast from '../../../assets/childrennortheast_logo.png';
import WhiteChildrenNorthEast from '../../../assets/childrennortheast_logo_white.png';
import * as T from '../../common/Typography';
import * as S from './style';

const CNEHeader = ({ dark, footer, title, description }) => {
  return (
    <S.Container dark={dark}>
      <S.Wrapper footer={footer}>
        <S.LogosSide>
          <S.LogoImage
            src={dark ? WhiteChildrenNorthEast : ChildrenNorthEast}
            alt="ChildrenNorthEast"
          />
        </S.LogosSide>
        <S.ContentSide>
          <T.H1 color={dark ? 'white' : 'mainPrimary'} weight="700" mb="4">
            {title}
          </T.H1>
          {description && (
            <T.P weight="700" smallMedium color="gray">
              {description}
            </T.P>
          )}
        </S.ContentSide>
      </S.Wrapper>
    </S.Container>
  );
};

export default CNEHeader;
