import _userRoles from './userTypes';
import _organisations from './organisations';
import _errMsgs from './errors';

export const errMsgs = _errMsgs;
export const userRoles = _userRoles;
export const organisations = _organisations;
export const readableSurveysNamePairs = {
  'pre-course': 'Pre-course',
  post: 'Post',
  '3-months-follow-up': '3 months follow up',
  '6-months-follow-up': '6 months follow up',
  'pre-train-the-trainer': 'pre Train the Trainer',
  'post-train-the-trainer': 'post Train the Trainer'
};

export const surveysHaveBehavQuestions = [
  'pre-course',
  '3-months-follow-up',
  '6-months-follow-up'
];

/**
 * an object with { "session-type" : "Session Name"}
 */
export const readableSessionNamePairs = {
  '1': 'Session 1',
  '2': 'Session 2',
  '3': 'Session 3',
  'special-2-days': '2-day Intensive',
  'train-trainers-s1': 'Train Trainers Session 1',
  'train-trainers-s2': 'Train Trainers Session 2',
  'train-trainers-event': 'Train Trainers Day Event'
};

export const localAuthoritiesBasic = [
  'Darlington',
  'Durham',
  'Gateshead',
  'Hartlepool',
  'Middlesbrough',
  'Newcastle',
  'North Cumbria',
  'North Tyneside',
  'Northumberland',
  'Redcar and Cleveland',
  'South Tyneside',
  'Sunderland',
  'Stockton-on-Tees'
];

export const localAuthoritiesWithOtherOption = [
  'Darlington',
  'Durham',
  'Gateshead',
  'Hartlepool',
  'Middlesbrough',
  'Newcastle',
  'North Cumbria',
  'North Tyneside',
  'Northumberland',
  'Redcar and Cleveland',
  'South Tyneside',
  'Sunderland',
  'Stockton-on-Tees',
  'Other, please specify'
];

export const modules = {
  MODULE_1: '1',
  MODULE_2: '2',
  MODULE_3: '3',
  TRAIN_THE_TRAINER: 'Train the Trainer'
};
export const courses = {
  C5: 'C5',
  MECC: 'MECC',
  ALCOHOL_STUDIES: 'Alcohol Studies',
  POVERTY_PROOFING: 'POVERTY_PROOFING',
  TOBACCO_DEPENDENCY: 'TOBACCO_DEPENDENCY'
};

export const courseLabelMap = {
  [courses.C5]: 'Connect 5',
  [courses.MECC]: 'MECC',
  [courses.ALCOHOL_STUDIES]: 'Alcohol Studies',
  [courses.POVERTY_PROOFING]: 'Poverty Proofing',
  [courses.TOBACCO_DEPENDENCY]: 'Tobacco Dependency'
};

export const courseOptions = Object.entries(courseLabelMap).map(
  ([key, value]) => ({
    label: value,
    value: key
  })
);

export const customCourses = [
  courses.ALCOHOL_STUDIES,
  courses.POVERTY_PROOFING,
  courses.TOBACCO_DEPENDENCY
];

export const surveyTypesNew = {
  PRE_COURSE: 'pre-course',
  POST: 'post',
  PRE_TRAIN_THE_TRAINER: 'pre-train-the-trainer',
  POST_TRAIN_THE_TRAINER: 'post-train-the-trainer',
  FOLLOW_UP_3: '3-months-follow-up',
  FOLLOW_UP_6: '6-months-follow-up',
  ALCOHOL_DEMOGRAPHICS: 'alcohol-demographics',
  POVERTY_DEMOGRAPHICS: 'poverty-demographics',
  SELF_ASSESSMENT: 'self-assessment'
};

export const externalLinks = {
  NHS_FUTURE_LEARNS:
    'https://future.nhs.uk/system/login?nextURL=%2Fconnect%2Eti%2FNEConnect5MentalWellbeing%2Fgrouphome',
  EXPLAIN_TOOL_VIDEO:
    'https://yallacooperative.notion.site/North-East-and-North-Cumbria-ICS-Training-Platform-Tutorials-f87f448dc6144b90a581d3d000b36860',
  MECC_CONTACT_EMAIL: 'MECCRegional@northumbria-healthcare.nhs.uk',
  PRIVACY_POLICY:
    'https://docs.google.com/document/d/1Pi3jFzs3eH4K7yNnkYqONrz_qqsun0e6/edit?usp=sharing&ouid=117859953410061859799&rtpof=true&sd=true',
  TERMS_OF_USE:
    'https://docs.google.com/document/d/13Ghvo0u0KZS-GIwBOb9r0snIiV6NGAAU/edit?usp=sharing&ouid=117859953410061859799&rtpof=true&sd=true',
  HELP_EMAIL: 'MECCRegional@northumbria-healthcare.nhs.uk'
};

export const upgradeTttSteps = {
  signupAsTtt: 'signupAsTtt',
  watchTttVideo: 'watchTttVideo'
};
