import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import setMargin from '../../../helpers/setMargin';
import { Typography } from 'antd';
const { Title, Paragraph } = Typography;

const weights = {
  900: '900 !important',
  700: '700 !important',
  600: '600 !important',
  500: '500 !important',
  400: '400 !important'
};

const text = {
  caps: 'uppercase',
  capitalize: 'capitalize'
};

const commonStyle = ({ theme, color, transform, ta, d, ...props }) => `
  font-style: normal !important;
  letter-spacing: 0px !important;
  color: ${theme.colors[color] || color || theme.colors.darkGray} !important;
  text-transform: ${transform ? text[transform] : 'initial'} !important;
  text-align: ${ta || 'left'} !important;
  display: ${d || 'block'} !important;
  ${d === 'block' ? 'width: 100% !important;' : ''}
`;

const Head1 = styled(Title)`
  ${setMargin};
  ${commonStyle};
  font-size: 32px !important;
  line-height: 120% !important;
  font-weight: ${({ weight }) => weights[weight] || weights[900]};
`;
export const H1 = props => <Head1 {...props} level={1} />;

const Head2 = styled(Title)`
  ${setMargin};
  ${commonStyle};
  font-size: 24px !important;
  line-height: 32px !important;
  font-weight: ${({ weight }) => weights[weight] || weights[500]};
`;
export const H2 = props => <Head2 {...props} level={2} />;

const Head3 = styled(Title)`
  ${setMargin};
  ${commonStyle};
  font-size: 20px !important;
  line-height: 32px !important;
  font-weight: ${({ weight }) => weights[weight] || weights[500]};
  font-family: 'Frutiger65', 'museo-sans', sans-serif;
`;
export const H3 = props => <Head3 {...props} level={3} />;

const Head4 = styled(Title)`
  ${setMargin};
  ${commonStyle};
  font-size: 18px !important;
  line-height: 150% !important;
  font-weight: ${({ weight }) => weights[weight] || weights[500]};
`;
export const H4 = props => <Head4 {...props} level={4} />;

const Head5 = styled(Title)`
  ${setMargin};
  ${commonStyle};
  font-size: 14px !important;
  line-height: 150% !important;
  font-weight: ${({ weight }) => weights[weight] || weights[500]};
`;
export const H5 = props => <Head5 {...props} level={4} />;

const Head1W = styled(Title)`
  ${setMargin};
  ${commonStyle};
  font-size: 20px !important;
  line-height: 140% !important;
  font-weight: ${({ weight }) => weights[weight] || weights[600]};
  letter-spacing: -0.01em;
  @media ${({ theme }) => theme.breakpoints.laptop} {
    font-size: 44px !important;
    line-height: 120% !important;
  }
`;
export const H1W = props => <Head1W {...props} level={1} />;

const Head2W = styled(Title)`
  ${setMargin};
  ${commonStyle};
  font-size: 20px !important;
  line-height: 140% !important;
  font-weight: ${({ weight }) => weights[weight] || weights[600]};
  letter-spacing: -0.01em;
  @media ${({ theme }) => theme.breakpoints.laptop} {
    font-size: 32px !important;
    line-height: 120% !important;
  }
`;

export const H2W = props => <Head2W {...props} level={2} />;

const Head3W = styled(Title)`
  ${setMargin};
  ${commonStyle};
  font-size: 24px !important;
  line-height: 130% !important;
  font-weight: ${({ weight }) => weights[weight] || weights[600]};
  letter-spacing: -0.01em;
`;
export const H3W = props => <Head3W {...props} level={3} />;

const Head4W = styled(Title)`
  ${setMargin};
  ${commonStyle};

  font-size: 18px !important;
  line-height: 130% !important;
  font-weight: ${({ weight }) => weights[weight] || weights[600]};
  letter-spacing: -0.01em;
  @media ${({ theme }) => theme.breakpoints.laptop} {
    font-size: 20px !important;
    line-height: 140% !important;
  }
`;
export const H4W = props => <Head4W {...props} level={4} />;

const Head5W = styled(Title)`
  ${setMargin};
  ${commonStyle};
  font-size: 18px !important;
  line-height: 130% !important;
  font-weight: ${({ weight }) => weights[weight] || weights[600]};
  letter-spacing: -0.01em;
`;
export const H5W = props => <Head5W {...props} level={4} />;

export const P = styled(Paragraph)`
  ${setMargin};
  ${commonStyle};
  font-size: ${({ small, large, medium, smallMedium }) => {
    if (large) {
      return '48px !important';
    } else if (medium) {
      return '24px !important';
    } else if (smallMedium) {
      return '20px !important';
    } else if (small) {
      return '14px !important';
    } else {
      return '16px !important';
    }
  }};
  line-height: 160% !important;
  font-weight: ${({ weight }) => (weight ? weights[weight] : weights[500])};
  font-family: 'Frutiger55Roman', 'museo-sans', sans-serif;
  pre {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
  }
`;

const commonLinkStyle = ({
  theme,
  color,
  underline,
  weight,
  block,
  small,
  large,
  ...props
}) => `
  font-style: normal !important;
  font-size:${small ? '14px' : large ? '20px' : '16px'} !important;
  line-height: 160% !important;
  font-weight: ${weights[weight] || weights[700]};
  text-decoration: ${underline ? 'underline' : 'none'} !important;
  padding-bottom: 0px;
  display:${block ? 'block' : 'inline-block'};
  width: fit-content;

`;

const StyledLink = styled(RouterLink)`
  ${setMargin};
  ${commonStyle};
  ${commonLinkStyle};
`;
const ExternalLink = styled.a`
  ${setMargin};
  ${commonStyle};
  ${commonLinkStyle};
`;

export const Link = ({
  to,
  external = false,
  underline = true,
  block = true,
  d = 'inline',
  small,
  ...props
}) => {
  return external ? (
    <ExternalLink
      target="_blank"
      href={to}
      to={to}
      underline={underline}
      block={block}
      d={d}
      small={small}
      {...props}
    />
  ) : (
    <RouterLink
      to={to}
      component={() => (
        <StyledLink
          to={to}
          underline={underline}
          block={block}
          d={d}
          small={small}
          {...props}
        />
      )}
    />
  );
};
