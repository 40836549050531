const modulesCategories = {
  ALCOHOL_AWARENESS: 'ALCOHOL_AWARENESS',
  ALCOHOL_IDENTIFICATION: 'ALCOHOL_IDENTIFICATION',
  MANAGEMENT_OF_ALCOHOL: 'MANAGEMENT_OF_ALCOHOL',
  POVERTY_AWARENESS: 'POVERTY_AWARENESS',
  POVERTY_IDENTIFICATION_AND_SUPPORT: 'POVERTY_IDENTIFICATION_AND_SUPPORT',
  POVERTY_PROOFING_INTERVENTIONS: 'POVERTY_PROOFING_INTERVENTIONS',
  TOBACCO_AWARENESS: 'TOBACCO_AWARENESS',
  TOBACCO_IDENTIFICATION: 'TOBACCO_IDENTIFICATION',
  TOBACCO_TREATMENT: 'TOBACCO_TREATMENT',
  TOBACCO_CONTROL: 'TOBACCO_CONTROL'
};

export default modulesCategories;
