import Layout from '../../Layouts';

import ChildrenNorthEast from '../../../assets/childrennortheast_logo.png';
import Connect5 from '../../../assets/Connect_5_logo_colour.png';
import MECC from '../../../assets/MakingEveryContactCount.svg';
import Tobacco from '../../../assets/NHS 01060-23 Illustration Awareness White On Blue.png';
import { Col, Row } from '../../common/Grid';
import IconCard from '../../common/IconCard';
import MaxWidthContainer from '../../common/MaxWidthContainer';
import * as T from '../../common/Typography';
import * as S from './style';

import {
  ALCOHOL_STUDIES,
  POVERTY_PROOFING_URL,
  TOBACCO_URL,
  CONNECT5_URL,
  MECC_URL
} from '../../../constants/navigationRoutes';

const Programmes = () => {
  return (
    <Layout layout="website">
      <MaxWidthContainer>
        <Row mt="7" mtT="4" mb="8">
          <S.HeadWrapper>
            <T.H1 ta="center">
              North East & North Cumbria Integrated Care System
            </T.H1>
            <T.H2 color="gray" ta="center" mt="5" weight="400">
              Comprehensive training programme to equip our workforce with
              relevant skills across the prevention workstream.
            </T.H2>
          </S.HeadWrapper>
        </Row>
        <Row mt="7" mtT="4" mb="8" jc="center">
          <Col w={[4, 12, 4]}>
            <IconCard
              bgColor="offWhite"
              Img={() => <S.Img src={Connect5} w="90%" alt="Connect 5 Logo" />}
              to={CONNECT5_URL}
            >
              <T.H3 smallMedium mt="4">
                Connect 5
              </T.H3>
              <T.P small color="gray">
                A workforce training programme that has been created to upskill
                non-mental health specialists to better understand and feel
                confident to have everyday conversations about mental health and
                wellbeing in their everyday encounters.
              </T.P>
              <T.Link small color="gray" to={CONNECT5_URL}>
                Learn more
              </T.Link>
            </IconCard>
          </Col>
          <Col w={[4, 12, 4]} mtT="5">
            <IconCard
              bgColor="offWhite"
              Img={() => <S.Img src={MECC} alt="MECC Logo" />}
              to={MECC_URL}
            >
              <T.H3 smallMedium mt="4">
                MECC
              </T.H3>
              <T.P small color="gray">
                A workforce training programme that can support the public,
                private, voluntary and community sectors to share health
                messages and signpost services with individuals across the North
                East and North Cumbria.
              </T.P>
              <T.Link small color="gray" to={MECC_URL}>
                Learn more
              </T.Link>
            </IconCard>
          </Col>
          <Col w={[4, 12, 4]} mtT="5">
            <IconCard
              bgColor="offWhite"
              Img={() => (
                <S.Img src={ChildrenNorthEast} alt="Children NorthEast Logo" />
              )}
              to={POVERTY_PROOFING_URL}
            >
              <T.H3 smallMedium mt="4">
                Poverty Proofing©
              </T.H3>
              <T.P small color="gray">
                A comprehensive training programme to equip the workforce with
                skills, capacity and knowledge towards improving access and
                experience of healthcare for individuals and families
                experiencing poverty.
              </T.P>
              <T.Link small color="gray" to={POVERTY_PROOFING_URL}>
                Learn more
              </T.Link>
            </IconCard>
          </Col>
        </Row>
        <Row mt="7" mtT="4" mb="8" jc="center">
          <Col w={[4, 12, 4]}>
            <IconCard bgColor="mainPrimary" icon="glass" to={ALCOHOL_STUDIES}>
              <T.H3 smallMedium mt="4">
                Programme for Alcohol Studies
              </T.H3>
              <T.P small color="gray">
                A comprehensive training programme to equip our workforce with
                the skills to prevent and manage alcohol related harm.
              </T.P>
              <T.Link small color="gray" to={ALCOHOL_STUDIES}>
                Learn more
              </T.Link>
            </IconCard>
          </Col>

          <Col w={[4, 12, 4]} mtT="5">
            <IconCard
              bgColor="primaryMain"
              Img={() => (
                <S.Img
                  src={Tobacco}
                  w="100%"
                  h="auto%"
                  alt="Tobacco Dependency Logo"
                />
              )}
              to={TOBACCO_URL}
            >
              <T.H3 smallMedium mt="4">
                Programme for Tobacco Dependency
              </T.H3>
              <T.P small color="gray">
                A comprehensive training programme to equip the North East and
                North Cumbria workforce with the skills to identify and treat
                tobacco dependency and support wider tobacco control.
              </T.P>
              <T.Link small color="gray" to={TOBACCO_URL}>
                Learn more
              </T.Link>
            </IconCard>
          </Col>
        </Row>
      </MaxWidthContainer>
    </Layout>
  );
};

export default Programmes;
