import styled from 'styled-components';
export const HeadWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  @media (max-width: 918px) {
    margin-top: 12px;
  }
`;

export const Img = styled.img`
  width: ${({ w }) => w || '192px'};
  height: ${({ h }) => h || 'auto'};
  max-width: ${({ maxWidth }) => maxWidth || 'auto'};
`;
