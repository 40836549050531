import { useReducer, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ParticipantSignUp from './ParticipantSignUp';
import * as R from '../../../constants/navigationRoutes';

import * as T from '../../common/Typography';
import { Row, Col } from '../../common/Grid';

import { signUpTrainer } from '../../../actions/authAction';
import { useHistory } from 'react-router-dom';

import Layout from '../../Layouts';
import Button from '../../common/Button';
import Modal from '../../common/modal';

import { BasicInput, Dropdown, Checkbox } from '../../common/Inputs';

import { Wrapper, ContentWrapper, Form } from './SignUp.style';
import IEMessage from '../../common/IEMessage';
import validate from '../../../validation/schemas/signup';
import {
  userRoles,
  localAuthoritiesBasic as localAuthoritiesOptions,
  organisations as organisationsOptions
} from '../../../constants';

const stateReducer = (state, newState) => {
  return { ...state, ...newState };
};

const initialState = {
  name: '',
  email: '',
  password: '',
  localAuthorities: '',
  acceptLocalLeadAccess: '',
  isManager: false,
  organisation: { value: '', category: '' },
  organisationOther: '',
  submitAttempt: false,
  validationErrors: {},
  showOrgOther: false,
  jobRole: '',
  team: '',
  department: ''
};

const cleanEmail = email => email.toLowerCase().trim();

const SignUp = ({
  isAuthenticated,
  loading,
  signUpTrainer: signUpTrainerActionCreator,
  httpError
}) => {
  const [state, setState] = useReducer(stateReducer, initialState);
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    name,
    email,
    password,
    isManager,
    organisation,
    localAuthorities,
    acceptLocalLeadAccess,
    submitAttempt,
    validationErrors,
    organisationOther,
    showOrgOther,
    jobRole,
    team,
    department
  } = state;

  useEffect(() => {
    if (isAuthenticated) {
      return history.push('/login', { signupAsMeccTrainer: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateForm = () => {
    try {
      validate({
        role: isManager ? userRoles.localLead : userRoles.trainer,
        email: cleanEmail(email),
        password,
        name,
        organisation,
        localAuthorities,
        showOrgOther,
        organisationOther,
        jobRole,
        team,
        department,
        allowWeakPassword: isModalOpen
      });

      setState({ validationErrors: {} });

      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({ validationErrors: error.inner });
      }
      return false;
    }
  };

  useEffect(() => {
    if (submitAttempt) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    name,
    email,
    password,
    organisation,
    localAuthorities,
    jobRole,
    team,
    department
  ]);

  const handleSubmit = (e, providedParticipantPassword) => {
    e.preventDefault();
    setState({ submitAttempt: true });

    const isValid = validateForm();
    if (isValid) {
      handleSignup(providedParticipantPassword);
    }
  };

  const handleSignup = async providedParticipantPassword => {
    setState({ loading: true });

    const { error } = await signUpTrainerActionCreator({
      name,
      email: cleanEmail(email),
      password,
      role: isManager ? userRoles.localLead : userRoles.trainer,
      organisation: {
        ...organisation,
        value: showOrgOther ? organisationOther : organisation.value
      },
      localAuthorities,
      jobRole,
      team,
      department,
      providedParticipantPassword
    });
    if (error) {
      if (
        error?.response?.status === 409 &&
        error?.response?.data?.emailForParticipant
      ) {
        setIsModalOpen(true);
      }
    }

    return history.push(R.DASHBOARD_URL);
  };

  useEffect(() => {
    setState({ showOrgOther: organisation.value.includes('specify') });
  }, [organisation.value]);

  return (
    <Layout>
      <T.H1 mb="7">Create a trainer account</T.H1>
      <Wrapper className="sign-up">
        <IEMessage />
        <Row>
          <Col w={[4, 12, 8]}>
            <ContentWrapper>
              <T.P weight={400} mb="6">
                <T.P weight={600} d="inline">
                  Important Notice:
                </T.P>{' '}
                Only Connect 5 or MECC staff members (trainers, local leads
                etc.) are supposed to sign up here. If you are participating in
                the course, you can create an account when you register for a
                session and complete your pre-course evaluation.
              </T.P>
              <Form onSubmit={handleSubmit} className="signup-form">
                <BasicInput
                  value={name}
                  handleChange={value => setState({ name: value })}
                  mb={4}
                  label="Name"
                  placeholder="Type your name here"
                  required
                  error={validationErrors.name}
                />
                <BasicInput
                  value={email}
                  handleChange={value => setState({ email: value })}
                  label="Email"
                  placeholder="Type your email here"
                  mb={4}
                  required
                  error={
                    validationErrors.email ||
                    (httpError?.response?.status === 409 &&
                      httpError?.response?.data?.error)
                  }
                />

                <Dropdown
                  selected={organisation.value}
                  m={{ mb: 4 }}
                  label="Where do you work"
                  placeholder="Select"
                  required
                  groupedOptions
                  options={organisationsOptions}
                  handleChange={(value, option, b) =>
                    setState({
                      organisation: {
                        value,
                        category: option?.key?.split('_')[0]
                      }
                    })
                  }
                />
                <BasicInput
                  value={jobRole}
                  handleChange={value => setState({ jobRole: value })}
                  mb={4}
                  label="Job role"
                  placeholder="Type your job role here"
                  required
                  error={validationErrors.jobRole}
                />
                <BasicInput
                  value={team}
                  handleChange={value => setState({ team: value })}
                  mb={4}
                  label="team"
                  placeholder="Type your team here"
                  required
                  error={validationErrors.team}
                />
                <BasicInput
                  value={department}
                  handleChange={value => setState({ department: value })}
                  mb={4}
                  label="Business Unit / Department"
                  placeholder="Type your Business Unit / Department  here"
                  required
                  error={validationErrors.department}
                />
                {showOrgOther && (
                  <BasicInput
                    value={organisationOther}
                    autoFocus
                    handleChange={value =>
                      setState({ organisationOther: value })
                    }
                    placeholder="Type organisation here..."
                    mb={4}
                    error={
                      validationErrors.organisation ||
                      validationErrors.organisationOther
                    }
                  />
                )}

                <BasicInput
                  value={password}
                  label="Create password"
                  required
                  placeholder="Password"
                  mb={4}
                  type="password"
                  handleChange={value => setState({ password: value })}
                  error={validationErrors.password}
                />

                <Dropdown
                  m={{ mb: 4 }}
                  label="In which local authorities do you expect to deliver the training?"
                  required
                  placeholder="Select as many as you like or add new"
                  options={localAuthoritiesOptions.map(i => ({
                    label: i,
                    value: i
                  }))}
                  addNew
                  multi
                  selected={localAuthorities}
                  handleChange={values =>
                    setState({ localAuthorities: values })
                  }
                  error={validationErrors.localAuthorities}
                />

                <Checkbox
                  checked={isManager}
                  handleChange={value => setState({ isManager: value })}
                  mb={6}
                  mt={6}
                  label="I'm managing groups of trainers"
                  helper={{
                    title: '',
                    text: `In case you are responsible for helping manage other trainers (e.g. organising their sessions) please select this option.`
                  }}
                />

                <Checkbox
                  checked={acceptLocalLeadAccess}
                  handleChange={value =>
                    setState({ acceptLocalLeadAccess: value })
                  }
                  mb={3}
                  label="By signing up I confirm that my local lead can access
                    individual profile data such as name, email and
                    organisation as well as session results collected via the
                    app after each session which I was assigned to as trainer"
                />
                <Row>
                  <Col w={[4, 6, 6]} mt={4}>
                    {httpError?.response?.data?.error && !isModalOpen && (
                      <T.P color="error">
                        {' '}
                        {httpError?.response?.data?.error}
                      </T.P>
                    )}
                    <Button
                      onClick={handleSubmit}
                      type="primary"
                      label="Sign Up"
                      loading={loading}
                      disabled={!acceptLocalLeadAccess}
                    />
                  </Col>
                </Row>

                <T.P mt={4}>
                  Already have an account?{' '}
                  <T.Link
                    to={{
                      pathname: '/login',
                      state: { signupAsMeccTrainer: true }
                    }}
                  >
                    Log in
                  </T.Link>
                </T.P>
              </Form>
            </ContentWrapper>
          </Col>
        </Row>
      </Wrapper>
      <Modal
        type="confirm"
        title="This email is already registered as a participant"
        visible={isModalOpen}
        setModalOpen={() => setIsModalOpen(false)}
        parentFunc={e => {
          handleSubmit(new Event('submit'), true);
        }}
        closeOnOK={false}
        okLabel="Submit"
        loading={loading}
      >
        <Row mt="4" mb="4">
          <Col w={[4, 12, 12]}>
            <T.P color="gray">
              You can use the same email address to sign up as a trainer. Please
              enter the password you used to sign up as a participant.
            </T.P>
          </Col>
          <Col w={[4, 12, 12]}>
            <BasicInput
              value={password}
              label={`Enter the password you used to sign up as a participant`}
              required
              placeholder="Password"
              mt={6}
              type="password"
              handleChange={value => setState({ password: value })}
              error={!password && "Password can't be empty"}
            />
            {httpError?.response?.data?.error &&
              !httpError?.response?.data?.emailForParticipant && (
                <T.P color="error" mt={4} mb="-25px">
                  {' '}
                  {httpError?.response?.data?.error}
                </T.P>
              )}
          </Col>
        </Row>
      </Modal>
    </Layout>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  httpError: state.auth.error,
  loading: state.loading.signupLoading
});

export { ParticipantSignUp };

export default connect(mapStateToProps, {
  signUpTrainer
})(SignUp);
