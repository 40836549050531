import React, { Component } from 'react';
import moment from 'moment';
import {
  SessionTopDetailsWrapper,
  StatHeaderRow
} from './SessionDetails.Style';
import { concatModules } from '../../../helpers';

import { Row, Col } from '../../common/Grid';
import * as T from '../../common/Typography';
import SessionTopDetailsNonLiveSession from './SessionTopDetailsNonLiveSession';
import { courseLabelMap } from '../../../constants';

class SessionTopDetails extends Component {
  render() {
    const { sessionDetails } = this.props;
    const {
      date,
      endDate,
      course,
      trainers,
      startTime,
      endTime,
      address,
      capacity,
      modules,
      customModules,
      remote,
      meetingPlatform,
      meetingLink,
      extraInfo,
      defaultSessionForCustomModule
    } = sessionDetails;
    if (!sessionDetails) {
      return <div>loading</div>;
    }

    if (defaultSessionForCustomModule) {
      return (
        <SessionTopDetailsNonLiveSession sessionDetails={sessionDetails} />
      );
    }

    let fullAddress = '';

    if (address) {
      const { postcode, addressLine1, addressLine2, town } = address;
      if (postcode || addressLine1 || addressLine2 || town) {
        fullAddress = [addressLine1, addressLine2, town, postcode]
          .filter(item => !!item)
          .join(', ');
      }
    }

    return (
      <SessionTopDetailsWrapper>
        <StatHeaderRow mb="4">
          <Col w={[1.3, 4, 4]}>
            <T.P weight={700}>Date</T.P>
          </Col>
          <Col w={[1.3, 4, 4]}>
            <T.P weight={700}>Module(s)</T.P>
          </Col>
          <Col w={[1.3, 4, 4]}>
            <T.P weight={700}>Capacity</T.P>
          </Col>
        </StatHeaderRow>
        <Row mb="7">
          <Col w={[1.3, 4, 4]}>
            <T.P>{moment(date).format('DD/MM/YYYY')}</T.P>
          </Col>
          <Col w={[1.3, 4, 4]}>
            <T.P>{concatModules({ customModules, modules })}</T.P>
          </Col>
          <Col w={[1.3, 4, 4]}>
            <T.P>{capacity}</T.P>
          </Col>
        </Row>
        <Row mb="4">
          <Col w={[2, 4, 3]}>
            <T.P weight={700}>Course:</T.P>
          </Col>
          <Col w={[2, 8, 9]}>
            <T.P transform="capitalize">{courseLabelMap[course] || 'N/A'}</T.P>
          </Col>
        </Row>
        {!!trainers?.length && (
          <Row mb="4">
            <Col w={[2, 4, 3]}>
              <T.P weight={700}>Trainer(s):</T.P>
            </Col>
            <Col w={[2, 8, 9]}>
              <T.P transform="capitalize">
                {trainers?.[0]?.name}{' '}
                {trainers?.[1] && `& ${trainers?.[1]?.name}`}
              </T.P>
            </Col>
          </Row>
        )}
        <Row mb="4">
          <Col w={[2, 4, 3]}>
            <T.P weight={700}>Location:</T.P>
          </Col>
          <Col w={[2, 8, 9]}>
            <T.P transform="capitalize">
              {remote ? 'Online' : fullAddress || 'TBC'}
            </T.P>
          </Col>
        </Row>
        {remote && (
          <Row mb="4">
            <Col w={[2, 4, 3]}>
              <T.P weight={700}>Meeting platform:</T.P>
            </Col>
            <Col w={[2, 8, 9]}>
              <T.P transform="capitalize">{meetingPlatform || 'N/A'}</T.P>
            </Col>
          </Row>
        )}
        {remote && (
          <Row mb="4">
            <Col w={[2, 4, 3]}>
              <T.P weight={700}>Meeting link:</T.P>
            </Col>
            <Col w={[2, 8, 9]}>
              <a
                href={meetingLink}
                target="_blank"
                rel="noreferrer"
                style={{
                  wordBreak: 'break-all'
                }}
              >
                <T.P style={{ maxWidth: '100%' }}>{meetingLink || 'N/A'}</T.P>
              </a>
            </Col>
          </Row>
        )}
        {extraInfo && (
          <Row mb="4">
            <Col w={[2, 4, 3]}>
              <T.P weight={700}>Extra info:</T.P>
            </Col>
            <Col w={[2, 8, 9]}>
              <T.P style={{ maxWidth: '100%', wordBreak: 'break-word' }}>
                {extraInfo}
              </T.P>
            </Col>
          </Row>
        )}
        <Row mb="4">
          <Col w={[2, 4, 3]}>
            <T.P weight={700}>Times:</T.P>
          </Col>
          <Col w={[2, 8, 9]}>
            <T.P>
              {startTime || 'TBC'} {endTime && `to ${endTime}`}
            </T.P>
          </Col>
        </Row>
        {endDate && (
          <Row mb="7">
            <Col w={[2, 4, 3]}>
              <T.P weight={700}>End Date:</T.P>
            </Col>
            <Col w={[2, 8, 9]}>
              <T.P>
                <T.P>{moment(endDate).format('DD/MM/YYYY')}</T.P>
              </T.P>
            </Col>
          </Row>
        )}
      </SessionTopDetailsWrapper>
    );
  }
}

export default SessionTopDetails;
