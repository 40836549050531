import React, { useReducer, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import { logout, selectRole } from '../../../actions/authAction';
import useSearchParams from './../../../hooks/useSearchParams';
import logo from '../../../assets/NorthEastNorthCumbria-NHS-White.svg';

import {
  DASHBOARD_URL,
  ADD_SESSION_URL,
  TRAINERS_URL,
  MY_ACCOUNT_URL,
  PROGRAMMES,
  MODULES_URL,
  MY_SESSIONS_URL,
  ALL_SESSIONS_URL,
  PARTICIPANT_DASHBOARD,
  CHOOSE_RESULTS_FOR_SESSIONS,
  CHOOSE_RESULTS_FOR_MATERIALS,
  TRAINER_ADVICE_URL,
  CHOOSE_RESULTS_FOR_COURSE,
  DECIDE_ROLE
} from '../../../constants/navigationRoutes';

import USER_TYPES from '../../../constants/userTypes';

import {
  MenuDiv,
  MenuWrapper,
  MenuItem,
  MainDiv,
  MenuIcon,
  CloseIconWrapper,
  GroupMenuTitle,
  Logo
} from './Menu.style';

import Icon from './../Icon';
const stateReducer = (state, newState) => {
  return { ...state, ...newState };
};
const Menu = ({
  role,
  logout: logoutAction,
  dark,
  toggleShow,
  setToggleShow,
  isDesktop,
  multipleUsers,
  selectRole
}) => {
  const initialState = {
    activeSub: null,
    listOpen: false
  };
  const [state, setState] = useReducer(stateReducer, initialState);
  const { activeSub, listOpen } = state;
  const history = useHistory();
  const searchParams = useSearchParams();

  useEffect(() => {
    return () => {
      const wrapper = document.getElementById('wrapper');
      if (wrapper?.style?.marginLeft) {
        wrapper.style.marginLeft = '0';
      }
    };
  }, []);

  const handleSubClick = target => {
    setState({
      listOpen: !listOpen,
      activeSub: target
    });
  };

  const resetRole = async () => {
    await selectRole(null);
    history.push(DECIDE_ROLE);
  };

  return (
    <MenuDiv>
      {toggleShow && (
        <MenuWrapper dark={dark}>
          {!isDesktop && (
            <CloseIconWrapper onClick={() => setToggleShow(false)}>
              <Icon icon="cross" width="24" height="24" color="white" />
            </CloseIconWrapper>
          )}
          <Logo src={logo} alt="logo" />
          <MainDiv>
            {/* trainer */}
            {role === USER_TYPES.trainer && (
              <>
                <MenuItem
                  to={DASHBOARD_URL}
                  onClick={() => setToggleShow(false)}
                >
                  Home
                </MenuItem>
                <MenuItem
                  to={ADD_SESSION_URL}
                  onClick={() => setToggleShow(false)}
                >
                  Create New Session
                </MenuItem>
                <MenuItem
                  to={MY_SESSIONS_URL}
                  onClick={() => setToggleShow(false)}
                >
                  Sessions
                </MenuItem>
                <MenuItem
                  to={{
                    pathname: CHOOSE_RESULTS_FOR_COURSE,
                    search: searchParams.toStringWithReplace({
                      target: 'me'
                    })
                  }}
                  onClick={() => setToggleShow(false)}
                >
                  Evaluation results
                </MenuItem>
                <MenuItem
                  to={MY_ACCOUNT_URL}
                  onClick={() => setToggleShow(false)}
                >
                  Account details
                </MenuItem>
                <MenuItem
                  to={TRAINER_ADVICE_URL}
                  onClick={() => setToggleShow(false)}
                >
                  Trainer Advice
                </MenuItem>
                <MenuItem to={PROGRAMMES} onClick={() => setToggleShow(false)}>
                  Programmes
                </MenuItem>
              </>
            )}

            {/* Admin */}
            {role === USER_TYPES.admin && (
              <>
                <MenuItem
                  to={DASHBOARD_URL}
                  onClick={() => setToggleShow(false)}
                >
                  Home
                </MenuItem>
                <MenuItem
                  to={{
                    pathname: CHOOSE_RESULTS_FOR_COURSE,
                    search: searchParams.toStringWithReplace({
                      target: 'all'
                    })
                  }}
                  onClick={() => setToggleShow(false)}
                >
                  All Results
                </MenuItem>
                <MenuItem
                  to={ALL_SESSIONS_URL}
                  onClick={() => setToggleShow(false)}
                >
                  All Sessions
                </MenuItem>
                <MenuItem
                  to={ADD_SESSION_URL}
                  onClick={() => setToggleShow(false)}
                >
                  Create New Session
                </MenuItem>
                <MenuItem
                  to={TRAINERS_URL}
                  onClick={() => setToggleShow(false)}
                >
                  Trainers & Local Leads
                </MenuItem>

                <MenuItem to={MODULES_URL} onClick={() => setToggleShow(false)}>
                  Modules
                </MenuItem>
                <MenuItem to={PROGRAMMES} onClick={() => setToggleShow(false)}>
                  Programmes
                </MenuItem>
              </>
            )}

            {/* LOCAL LEAD */}
            {role === USER_TYPES.localLead && (
              <>
                <MenuItem
                  to={DASHBOARD_URL}
                  onClick={() => setToggleShow(false)}
                >
                  Home
                </MenuItem>

                <MenuItem
                  as="div"
                  onClick={() => handleSubClick('results')}
                  block
                >
                  <GroupMenuTitle>
                    <MenuIcon
                      icon="chevron"
                      width="25px"
                      height="25px"
                      direction={
                        listOpen && activeSub === 'results' ? 'down' : 'right'
                      }
                    />
                    Evaluation results
                  </GroupMenuTitle>
                  {listOpen && activeSub === 'results' && (
                    <>
                      <MenuItem
                        to={{
                          pathname: CHOOSE_RESULTS_FOR_COURSE,
                          search: searchParams.toStringWithReplace({
                            target: 'me'
                          })
                        }}
                        block
                        sub
                        onClick={() => {
                          setToggleShow(false);
                        }}
                      >
                        Your Results
                      </MenuItem>
                      <MenuItem
                        to={{
                          pathname: CHOOSE_RESULTS_FOR_COURSE,
                          search: searchParams.toStringWithReplace({
                            target: 'myGroup'
                          })
                        }}
                        block
                        sub
                        onClick={() => setToggleShow(false)}
                      >
                        Your Group Results
                      </MenuItem>
                    </>
                  )}
                </MenuItem>

                <MenuItem
                  as="div"
                  onClick={() => handleSubClick('sessions')}
                  block
                >
                  <GroupMenuTitle>
                    <MenuIcon
                      icon="chevron"
                      width="25px"
                      height="25px"
                      direction={
                        listOpen && activeSub === 'sessions' ? 'down' : 'right'
                      }
                    />
                    Sessions
                  </GroupMenuTitle>

                  {listOpen && activeSub === 'sessions' && (
                    <>
                      <MenuItem
                        to={MY_SESSIONS_URL}
                        block
                        sub
                        onClick={() => setToggleShow(false)}
                      >
                        Your Sessions
                      </MenuItem>
                      <MenuItem
                        to="/group-sessions"
                        block
                        sub
                        onClick={() => setToggleShow(false)}
                      >
                        Your Group Sessions
                      </MenuItem>
                    </>
                  )}
                </MenuItem>
                <MenuItem
                  to={ADD_SESSION_URL}
                  onClick={() => setToggleShow(false)}
                >
                  Create New Session
                </MenuItem>
                <MenuItem
                  to={MY_ACCOUNT_URL}
                  onClick={() => setToggleShow(false)}
                >
                  Account details
                </MenuItem>
                <MenuItem
                  to={TRAINERS_URL}
                  onClick={() => setToggleShow(false)}
                >
                  Your Trainers
                </MenuItem>
                <MenuItem to={PROGRAMMES} onClick={() => setToggleShow(false)}>
                  Programmes
                </MenuItem>
              </>
            )}
            {/* USER */}
            {role === USER_TYPES.participant && (
              <>
                <MenuItem
                  to={PARTICIPANT_DASHBOARD}
                  onClick={() => setToggleShow(false)}
                >
                  Home
                </MenuItem>
                <MenuItem
                  to={CHOOSE_RESULTS_FOR_SESSIONS}
                  onClick={() => setToggleShow(false)}
                >
                  My course
                </MenuItem>
                <MenuItem
                  to={CHOOSE_RESULTS_FOR_MATERIALS}
                  onClick={() => setToggleShow(false)}
                >
                  Materials
                </MenuItem>
                <MenuItem
                  to={MY_ACCOUNT_URL}
                  onClick={() => setToggleShow(false)}
                >
                  My Account
                </MenuItem>

                <MenuItem to={PROGRAMMES} onClick={() => setToggleShow(false)}>
                  Programmes
                </MenuItem>
              </>
            )}
            {role && (
              <>
                {multipleUsers && (
                  <MenuItem onClick={() => resetRole()}>
                    Switch Account
                  </MenuItem>
                )}
                <MenuItem
                  as="div"
                  onClick={() => logoutAction(history)}
                  lastItem
                >
                  Log out
                </MenuItem>
              </>
            )}
            {!role && (
              <>
                <MenuItem
                  to="/"
                  dark={dark}
                  onClick={() => setToggleShow(false)}
                >
                  Home
                </MenuItem>
                <MenuItem to={PROGRAMMES} onClick={() => setToggleShow(false)}>
                  Programmes
                </MenuItem>
              </>
            )}
          </MainDiv>
        </MenuWrapper>
      )}
    </MenuDiv>
  );
};

const mapStateToProps = state => ({
  role: state.auth.role,
  PIN: state.auth.PIN,
  loaded: state.auth.loaded,
  isDeskTop: state.checkBrowserWidth.isDeskTop,
  userRoles: state.auth.userRoles,
  multipleUsers: state.auth.multipleUsers
});

export default connect(mapStateToProps, {
  logout,
  selectRole
})(withRouter(Menu));
