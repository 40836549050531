import styled from 'styled-components';

export const Wrapper = styled.div``;

export const ActionsWrapper = styled.div`
  display: flex;
`;

export const ActionButton = styled.button`
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  display: flex;
`;
