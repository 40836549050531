import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Layout from '../../Layouts';
import * as T from '../../common/Typography';
import * as I from '../../common/Inputs';
import Button from '../../common/Button';
import * as R from '../../../constants/navigationRoutes';
import { surveyTypesNew } from '../../../constants';
import * as S from './style';

import {
  fetchCustomModule,
  completeModule
} from '../../../actions/customModulesActions';
const ParticipantAssessment = ({
  loading,
  module,
  fetchModuleAction,
  completeModule
}) => {
  const [selectedAnswers, setSelectedAnswers] = useState([]);

  const handleAnswerChange = (questionIndex, answer) => {
    setSelectedAnswers(prevAnswers => {
      const newAnswers = [...prevAnswers];
      newAnswers[questionIndex] = answer;
      return newAnswers;
    });
  };

  const handleSubmit = async () => {
    const score = module?.questions.reduce((acc, q, i) => {
      return (
        acc +
        q.options.reduce((acc2, opt, optIndex) => {
          if (
            selectedAnswers[i] !== undefined &&
            selectedAnswers[i] === opt.text &&
            opt.isCorrect
          ) {
            return acc2 + 1;
          }
          return acc2;
        }, 0)
      );
    }, 0);

    if ((score / module.questions.length) * 100 >= module.assessmentPassMark) {
      await completeModule({
        moduleId: module?._id,
        data: {
          sessionId: module.sessions?.[0]?._id
        }
      });

      if (module?.showPostCourseEvaluation) {
        return history.push(
          R.SURVEY_QUESTIONS_URL.replace(':id', module?.sessions[0]?.shortId)
            .replace(':type', surveyTypesNew.POST)
            .replace(':step', 0)
            .replace(':success', true)
        );
      } else {
        return history.push({
          pathname: R.MODULE_STEPS_URL.replace(':id', id)
        });
      }
    }

    return history.push({
      pathname: R.MODULE_STEPS_URL.replace(':id', id),
      state: {
        assessmentUnsuccessful: true
      }
    });
  };

  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    fetchModuleAction({ id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout withGraph>
      <T.H1 weight="700">Assessment</T.H1>
      <T.H2 color="gray" weight="600" mt="4">
        {module.title}
      </T.H2>
      <S.PWrapper>
        <T.P color="gray" mt="6" mb="4" weight="400">
          To finish this module and receive your certification, you must first
          complete the short assessment below.
        </T.P>
      </S.PWrapper>
      <S.Form>
        {module?.questions?.map((question, index) => (
          <I.Dropdown
            m={{
              mb: '5'
            }}
            assessmentOptions={true}
            label={question.question}
            addNew={false}
            search={false}
            options={question.options}
            selected={selectedAnswers[index]}
            handleChange={event => {
              handleAnswerChange(index, event);
            }}
            placeholder="Select"
          />
        ))}
        <Button
          type="primary"
          label="Submit"
          width="200px"
          onClick={handleSubmit}
          loading={loading}
        />
      </S.Form>
    </Layout>
  );
};

const mapStateToProps = state => ({
  archivedModules: state.modules.modules,
  module: state.modules.module,
  loading: state.loading.fetchModuleLoading
});

export default connect(mapStateToProps, {
  fetchModuleAction: fetchCustomModule,
  completeModule
})(ParticipantAssessment);
