/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import useSearchParams from './../../../hooks/useSearchParams';

//  ANTD COMPONENTS

//  COMMON COMPOENTS
import Layout from '../../Layouts';
import Collapse from '../../common/Collapse';
import ExportButton from '../../common/ExportButton';
import { Col, Row } from '../../common/Grid';
import SessionList from '../../common/List/SessionList';
import Toggle from '../../common/Toggle';
import * as T from '../../common/Typography';

// ACTIONS
import { fetchUserResults as fetchUserResultsAction } from '../../../actions/users';

import {
  fetchLocalLeadSessions,
  fetchTrainerSessions
} from '../../../actions/groupSessionsAction';

import { isCustomCourse } from '../../../helpers';
import { courseLabelMap } from './../../../constants';
import panels from './panels';

class UserResults extends Component {
  state = {
    resultsFor: null,
    resultForRole: null,
    toggle: 'left',
    showFilter: false,
    filters: {},
    hiddenFields: []
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    const { path: oldPath } = prevProps.match;
    const { path } = this.props.match;
    if (path !== oldPath) {
      this.getData();
    }
  }

  isFilterActive = () => {
    const { showFilter } = this.state;
    this.setState({ showFilter: !showFilter });
  };

  getData = () => {
    const { loggedInUserId, history, searchParams } = this.props;
    const { userId, course, trainerName, target } = searchParams.params;
    const { state } = history.location;

    // if a user has been passed on then store as the user
    const user = state && state.trainer;
    const viewdUserName =
      user && user.name && user.name[0].toUpperCase() + user.name.slice(1);

    let resultsFor;
    let resultForRole;
    let headerTitle;
    let filters = {};
    let hiddenFields = [];

    switch (target) {
      case 'me':
        resultsFor = loggedInUserId;
        resultForRole = 'trainer';
        headerTitle = 'Your ';
        filters = { trainer: [resultsFor] };
        hiddenFields = ['localLead', 'trainer'];
        this.props.fetchTrainerSessions({
          id: resultsFor,
          history: this.props.history
        });
        this.props.fetchUserResults(
          resultsFor,
          filters,
          course,
          this.props.history
        );

        break;
      case 'trainer':
        resultsFor = userId;
        resultForRole = 'trainer';
        headerTitle = trainerName
          ? `${trainerName &&
              trainerName[0].toUpperCase() + trainerName.slice(1)} - `
          : `${viewdUserName} - `;
        hiddenFields = ['trainer', 'localLead'];
        filters = { trainer: [resultsFor] };
        this.props.fetchTrainerSessions({
          id: resultsFor,
          history: this.props.history
        });
        this.props.fetchUserResults(
          resultsFor,
          filters,
          course,
          this.props.history
        );
        break;
      case 'all':
        resultsFor = loggedInUserId;
        resultForRole = 'admin';
        headerTitle = 'All - ';
        // no filters & no hidden fields
        this.props.fetchUserResults(
          resultsFor,
          filters,
          course,
          this.props.history
        );
        break;
      case 'myGroup':
        resultsFor = loggedInUserId;
        resultForRole = 'localLead';
        headerTitle = "Your Group's ";
        filters = { localLead: [loggedInUserId] };
        hiddenFields = ['localLead'];

        this.props.fetchLocalLeadSessions({
          id: resultsFor,
          history: this.props.history
        });
        this.props.fetchUserResults(
          resultsFor,
          filters,
          course,
          this.props.history
        );
        break;
      case 'localLeadGroup':
        resultsFor = userId;
        resultForRole = 'localLead';
        headerTitle = trainerName
          ? `${trainerName &&
              trainerName[0].toUpperCase() + trainerName.slice(1)} - Group `
          : `${viewdUserName} - Group `;
        filters = { localLead: [userId] };
        hiddenFields = ['localLead'];

        this.props.fetchLocalLeadSessions({
          id: resultsFor,
          history: this.props.history
        });
        this.props.fetchUserResults(
          resultsFor,
          filters,
          course,
          this.props.history
        );
        break;

      default:
        history.push('/unauthorized');
        break;
    }

    this.setState({
      resultsFor,
      resultForRole,
      headerTitle,
      filters,
      hiddenFields
    });
  };

  clickToggle = () => {
    const { toggle } = this.state;
    if (toggle === 'left') this.setState({ toggle: 'right' });
    else this.setState({ toggle: 'left' });
  };

  handleFilteredData = filters => {
    const { resultsFor } = this.state;
    const { match } = this.props;
    const { course } = match.params;
    this.props.fetchUserResults(
      resultsFor,
      filters,
      course,
      this.props.history
    );
  };

  render() {
    const {
      results,
      role,
      sessions,
      loggedInUserId,
      searchParams
    } = this.props;
    const { course, target } = searchParams.params;
    const {
      toggle,
      resultsFor,
      resultForRole,
      headerTitle,
      filters,
      hiddenFields
    } = this.state;
    // if a user has been passed on then store as the user
    const courseName = courseLabelMap[course];

    const filteredSessions = sessions.filter(s => s.course === course);

    const panelsArr = Object.values(panels).filter(p =>
      isCustomCourse(course) ? p.text !== 'Trainer feedback' : true
    );

    const showExportParticipants =
      isCustomCourse(course) && target === 'all' && role === 'admin';
    return (
      <Layout>
        <>
          <T.H1 mb={7}>
            {toggle === 'left'
              ? `${headerTitle} ${courseName} Results`
              : `${headerTitle} ${courseName} Sessions`}
          </T.H1>
          {loggedInUserId !== resultsFor && (
            <>
              <T.P mb={7}>
                Data collected since registering on {results.registrationDate}
              </T.P>
              <Toggle
                leftText="Results"
                rightText="Sessions"
                selected={toggle}
                onClick={this.clickToggle}
                mb={6}
              />
            </>
          )}
          {toggle === 'left' && (
            <Collapse
              items={panelsArr.map(panel => ({
                title: panel.text,
                infoContent: panel.infoContent,
                content: panel.render({
                  results,
                  resultsFor,
                  resultForRole,
                  role,
                  hiddenFields,
                  filters,
                  handleFilteredData: this.handleFilteredData,
                  defaultFilters: filters,
                  course
                })
              }))}
            />
          )}
          {toggle === 'right' && (
            <>
              <SessionList dataList={filteredSessions} />
            </>
          )}
          {showExportParticipants && (
            <Row inner mt={7}>
              <Col w={[4, 6, 4]}>
                <ExportButton
                  filters={{ ...filters, course }}
                  type="primary"
                  height="50px"
                  text="Export Participants"
                  exportType="participants"
                />
              </Col>
            </Row>
          )}
          <Row inner mt={4}>
            <Col w={[4, 6, 4]}>
              <ExportButton
                filters={{ ...filters, course }}
                type={showExportParticipants ? 'secondary' : 'primary'}
                height="50px"
                text="Export Responses"
                exportType="responses"
              />
            </Col>
          </Row>
          {target === 'all' && role === 'admin' && (
            <>
              <Row inner mt={4}>
                <Col w={[4, 6, 4]}>
                  <ExportButton
                    filters={{ ...filters, course }}
                    type={showExportParticipants ? 'tertiary' : 'secondary'}
                    height="50px"
                    text="Export Sessions"
                    exportType="sessions"
                  />
                </Col>
              </Row>
              <Row inner mt={4}>
                <Col w={[4, 6, 4]}>
                  <ExportButton
                    filters={{ ...filters, course }}
                    type={showExportParticipants ? 'quartenary' : 'tertiary'}
                    height="50px"
                    text="Export Trainers"
                    exportType="trainers"
                  />
                </Col>
              </Row>
            </>
          )}
        </>
      </Layout>
    );
  }
}

const UserResultsWrapper = props => {
  const searchParams = useSearchParams();
  return <UserResults searchParams={searchParams} {...props} />;
};

const mapStateToProps = state => ({
  results: state.results,
  loggedInUserId: state.auth.userId,
  sessions: state.sessions.sessions,
  sessionsNum: state.sessions.sessionsCount
});

export default connect(mapStateToProps, {
  fetchUserResults: fetchUserResultsAction,
  fetchTrainerSessions,
  fetchLocalLeadSessions
})(UserResultsWrapper);
