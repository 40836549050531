import {
  FETCH_MODULES,
  FETCH_MODULE,
  FETCH_MODULES_FAIL,
  FETCH_MODULE_FAIL,
  CREATE_MODULE_SUCCESS,
  CREATE_MODULE_FAIL,
  COMPLETE_MODULE_FAIL
} from '../constants/actionTypes';

const initState = {
  modules: [],
  module: {},
  httpError: null
};

const customModules = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_MODULES:
      return { ...state, modules: payload, loaded: true };
    case FETCH_MODULE:
      return { ...state, module: payload, loaded: true };
    case CREATE_MODULE_SUCCESS:
      return { ...state, ...payload, httpError: null };

    case CREATE_MODULE_FAIL:
    case FETCH_MODULES_FAIL:
    case COMPLETE_MODULE_FAIL:
    case FETCH_MODULE_FAIL:
      return {
        ...state,
        httpError: payload
      };
    default:
      return state;
  }
};

export default customModules;
