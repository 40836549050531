/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';

import { withTheme } from 'styled-components';
import { Col, Row } from '../Grid';
import * as T from '../Typography';
import useSearchParams from './../../../hooks/useSearchParams';
// COMMON COMPONENTS
import { CHOOSE_RESULTS_FOR_COURSE } from '../../../constants/navigationRoutes';
import Button from '../Button';
import Modal from '../modal';
// STYLING
import { checkAuth } from '../../../actions/authAction';
import { courseOptions } from '../../../constants';
import { Dropdown } from '../Inputs';
import { ModalStyle, StyledLink, Wrapper } from './List.style';

class TrainerList extends Component {
  state = {
    modalOpen: false,
    selectedTrainer: null,
    trainerCourses: null,
    trainerCoursesModalOpen: false,
    trainerCoursesUpdateModalOpen: false
  };

  toggleModal = dataItem => {
    const { modalOpen } = this.state;
    this.setState({ modalOpen: !modalOpen, selectedTrainer: dataItem });
  };

  toggleTrainerCoursesModal = (dataItem, trainerCourses) => {
    const { trainerCoursesModalOpen } = this.state;
    this.setState({
      trainerCoursesModalOpen: !trainerCoursesModalOpen,
      selectedTrainer: dataItem,
      trainerCourses
    });
  };

  handleTrainerCoursesUpdate = async () => {
    const { selectedTrainer: trainer, trainerCourses: courses } = this.state;

    await axios.patch(
      `/api/users/admin/update-trainer-courses`,
      {
        userId: trainer._id,
        courses
      },
      {
        headers: this.headers
      }
    );
    await this.props.refetchData();

    // This is to refetch current user info (in case it's a local lead)
    this.props.checkAuth();

    this.setState({
      trainerCoursesUpdateModalOpen: true,
      selectedTrainer: null,
      trainerCourses: null
    });
  };

  render() {
    const {
      dataList,
      role,
      deleteUser,
      userId,
      searchParams,
      theme
    } = this.props;
    const { modalOpen, selectedTrainer } = this.state;

    const { colors, spacings } = theme;

    const localAuthoritiesAsString = localAuthorities => {
      // turn array of authorities into a string
      if (!localAuthorities || localAuthorities.length === 0) return 'N/A';
      return localAuthorities.join(', ');
    };

    const modalContent = selectedTrainer && (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Row>
          <Col w={[4, 12, 12]}>
            <T.P weight={700} color="darkGray" mb="2" transform="capitalize">
              {selectedTrainer.name}'s info
            </T.P>
          </Col>
        </Row>
        <Row
          style={{
            borderTop: `1px solid ${colors.midPrimary}`,
            padding: `${spacings[3]} 0`
          }}
          ai="center"
        >
          <Col w={[1, 4, 4]}>
            <T.P color="gray">Organisation:</T.P>
          </Col>
          <Col w={[3, 8, 8]}>
            <T.P color="gray" transform="capitalize">
              {selectedTrainer.organisation?.value || 'N/A'}
            </T.P>
          </Col>
        </Row>
        <Row
          style={{
            borderTop: `1px solid ${colors.midPrimary}`,
            padding: `${spacings[3]} 0`
          }}
          ai="center"
        >
          <Col w={[1, 4, 4]}>
            <T.P color="gray">Local authorities:</T.P>
          </Col>
          <Col w={[3, 8, 8]}>
            <T.P color="gray" transform="capitalize">
              {localAuthoritiesAsString(selectedTrainer.localAuthorities)}
            </T.P>
          </Col>
        </Row>
        {/* render local lead if trainer  */}
        {selectedTrainer.role === 'trainer' && (
          <Row
            style={{
              borderTop: `1px solid ${colors.midPrimary}`,
              padding: `${spacings[3]} 0`
            }}
            ai="center"
          >
            <Col w={[1, 4, 4]}>
              <T.P color="gray">Local lead:</T.P>
            </Col>
            <Col w={[3, 8, 8]}>
              <T.P color="gray" transform="capitalize">
                {selectedTrainer.localLeadName || 'N/A'}
              </T.P>
            </Col>
          </Row>
        )}
        {/* render number of trainers if local lead */}
        {selectedTrainer.role === 'localLead' && role === 'admin' && (
          <Row
            style={{
              borderTop: `1px solid ${colors.midPrimary}`,
              padding: `${spacings[3]} 0`
            }}
            ai="center"
          >
            <Col w={[1, 4, 4]}>
              <T.P color="gray">No of trainers:</T.P>
            </Col>
            <Col w={[3, 8, 8]}>
              <T.P color="gray" transform="capitalize">
                {selectedTrainer.trainerCount || 'N/A'}
              </T.P>
            </Col>
          </Row>
        )}
        <Row
          style={{
            borderTop: `1px solid ${colors.midPrimary}`,
            padding: `${spacings[3]} 0`
          }}
          ai="center"
        >
          <Col w={[1, 4, 4]}>
            <T.P color="gray">Email:</T.P>
          </Col>
          <Col w={[3, 8, 8]}>
            <a href={`mailto:${selectedTrainer.email}`}>
              <T.P color="gray">{selectedTrainer.email || 'N/A'}</T.P>
            </a>
          </Col>
        </Row>

        <Row
          style={{
            borderTop: `1px solid ${colors.midPrimary}`,
            padding: `${spacings[3]} 0`
          }}
          ai="center"
        >
          <Col w={[1, 4, 4]}>
            <T.P color="gray">Job role:</T.P>
          </Col>
          <Col w={[3, 8, 8]}>
            <T.P color="gray">{selectedTrainer.jobRole || 'N/A'}</T.P>
          </Col>
        </Row>

        <Row
          style={{
            borderTop: `1px solid ${colors.midPrimary}`,
            padding: `${spacings[3]} 0`
          }}
          ai="center"
        >
          <Col w={[1, 4, 4]}>
            <T.P color="gray">Team:</T.P>
          </Col>
          <Col w={[3, 8, 8]}>
            <T.P color="gray">{selectedTrainer.team || 'N/A'}</T.P>
          </Col>
        </Row>

        <Row
          style={{
            borderTop: `1px solid ${colors.midPrimary}`,
            padding: `${spacings[3]} 0`
          }}
          ai="center"
          mb="5"
        >
          <Col w={[1, 4, 4]}>
            <T.P color="gray">Department:</T.P>
          </Col>
          <Col w={[3, 8, 8]}>
            <T.P color="gray">{selectedTrainer.department || 'N/A'}</T.P>
          </Col>
        </Row>
        {selectedTrainer && selectedTrainer.role === 'localLead' ? (
          <>
            <Link
              to={{
                pathname: CHOOSE_RESULTS_FOR_COURSE,
                search: searchParams.toStringWithReplace({
                  target:
                    selectedTrainer._id === userId
                      ? 'myGroup'
                      : 'localLeadGroup',
                  userId: selectedTrainer._id,
                  trainerName: selectedTrainer.name
                })
              }}
            >
              <Button
                label={`View ${selectedTrainer.name}'s Group Results`}
                type="primary"
                mb="3"
              />
            </Link>

            <Link
              to={{
                pathname: CHOOSE_RESULTS_FOR_COURSE,
                search: searchParams.toStringWithReplace({
                  target: selectedTrainer._id === userId ? 'me' : 'trainer',
                  userId: selectedTrainer._id,
                  trainerName: selectedTrainer.name
                })
              }}
            >
              <Button
                label={`View ${selectedTrainer.name}'s Results`}
                type="secondary"
              />
            </Link>
          </>
        ) : (
          <Link
            to={{
              pathname: CHOOSE_RESULTS_FOR_COURSE,
              search: searchParams.toStringWithReplace({
                target: 'trainer',
                userId: selectedTrainer._id,
                trainerName: selectedTrainer.name
              })
            }}
          >
            <Button
              label={`View ${selectedTrainer.name}'s Results`}
              type="primary"
            />
          </Link>
        )}
      </div>
    );

    return (
      <Wrapper>
        <Row
          style={{
            borderBottom:
              dataList?.length > 0 ? 'none' : `1px solid ${colors.midPrimary}`
          }}
        >
          <Col w={[1, 3, 3]}>
            <T.P weight={700} color="darkGray" mb="2">
              Name
            </T.P>
          </Col>
          <Col w={[1, 6, 6]}>
            <T.P weight={700} color="darkGray" mb="2">
              Programme(s)
            </T.P>
          </Col>
          <Col w={[1, 3, 3]}>
            <T.P weight={700} color="darkGray" mb="2">
              Details
            </T.P>
          </Col>
        </Row>
        {dataList?.length > 0 ? (
          dataList
            // TODO: this should not return null in the response / check backend query/dummy data
            .filter(Boolean)
            .sort(({ _id }) => (_id === userId ? -1 : 1))
            .map(dataItem => (
              <Row
                key={dataItem._id}
                style={{
                  borderTop: `1px solid ${colors.midPrimary}`,
                  padding: `${spacings[3]} 0`
                }}
                ai="center"
              >
                <Col w={[1, 3, 3]}>
                  <T.P color="gray" transform="capitalize">
                    {dataItem.name} {dataItem._id === userId && '(Me)'}
                  </T.P>
                </Col>
                <Col w={[1, 6, 6]}>
                  <Dropdown
                    selected={dataItem.coursesCanDeliver || []}
                    placeholder="Select..."
                    multi
                    options={courseOptions}
                    name="courses"
                    handleChange={val => {
                      this.toggleTrainerCoursesModal(dataItem, val);
                    }}
                  />
                </Col>
                <Col w={[1, 3, 3]}>
                  <StyledLink
                    width="80px"
                    onClick={() => this.toggleModal(dataItem)}
                    underline
                    bold
                  >
                    View
                  </StyledLink>
                </Col>

                {role !== 'admin' && (
                  <Col w={[1, 3, 3]}>
                    <Button
                      type="secondary"
                      onClick={() => deleteUser(dataItem._id)}
                      disabled={dataItem._id === userId}
                      label="Delete"
                      width="80px"
                    />
                  </Col>
                )}
              </Row>
            ))
        ) : (
          <Row jc="center" mt="5">
            <Col w={[4, 12, 12]} jc="center">
              <T.P weight="700" color="gray" ta="center">
                No trainers found
              </T.P>
            </Col>
          </Row>
        )}
        <Modal
          visible={modalOpen}
          setModalOpen={this.toggleModal}
          extraModalStyle={ModalStyle}
        >
          {modalContent}
        </Modal>
        <Modal
          visible={this.state.trainerCoursesModalOpen}
          setModalOpen={this.toggleTrainerCoursesModal}
          parentFunc={this.handleTrainerCoursesUpdate}
          closeOnOK
          type="confirm"
        >
          You are about to change the programmes this trainer is authorised to
          deliver and set up sessions for.
        </Modal>
        <Modal
          visible={this.state.trainerCoursesUpdateModalOpen}
          setModalOpen={val => {
            this.setState({
              trainerCoursesUpdateModalOpen: val
            });
          }}
          type="success"
        >
          The programmes this trainer is authorised to deliver have been
          updated.
        </Modal>
      </Wrapper>
    );
  }
}

const TrainerListWrapper = props => {
  const searchParams = useSearchParams();
  return <TrainerList searchParams={searchParams} {...props} />;
};
const mapStateToProps = state => {
  return {
    userId: state.auth.userId,
    isMobile: state.checkBrowserWidth.isMobile
  };
};

export default connect(mapStateToProps, {
  checkAuth
})(withTheme(TrainerListWrapper));
