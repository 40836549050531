import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Spin, Icon as AntIcon } from 'antd';

import { StyledButton } from './style';
import Icon from '../Icon';

const antIcon = (
  <AntIcon
    type="loading"
    style={{ fontSize: 24, color: '#fff', marginLeft: '5px' }}
    spin
  />
);

const FilterButton = ({ label, icon, color, onClick, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    if (typeof onClick === 'function') {
      onClick();
    }
    setIsOpen(oldValue => !oldValue);
  };

  return (
    <StyledButton
      aria-label={label}
      color={color}
      isOpen={isOpen}
      onClick={handleClick}
      {...props}
    >
      {label}
      {icon && (
        <Icon
          icon={icon}
          color={color}
          width={icon === 'cross' ? '16px' : '24px'}
          ml="4"
        />
      )}
    </StyledButton>
  );
};

const Button = ({ label, loading, onClick, to, ...props }) => {
  const history = useHistory();

  if (props.type === 'filter') {
    return (
      <FilterButton
        label={label}
        onClick={onClick}
        loading={loading}
        {...props}
      />
    );
  }
  if (to) {
    return (
      <StyledButton
        aria-label={label}
        loading={loading}
        onClick={() => history.push(to)}
        {...props}
      >
        {label}
        {loading && <Spin indicator={antIcon} />}
      </StyledButton>
    );
  }

  return (
    <StyledButton
      aria-label={label}
      loading={loading}
      onClick={onClick}
      {...props}
    >
      {label}
      {loading && <Spin indicator={antIcon} />}
    </StyledButton>
  );
};

export default Button;
