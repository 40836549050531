import {
  FETCH_PARTICIPANT_RESPONSES_SUCCESS,
  FETCH_PARTICIPANT_RESPONSES_FAIL
} from '../constants/actionTypes';

const initState = {
  participantResponses: [],
  loaded: false
};

const fetchParticipantResponses = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_PARTICIPANT_RESPONSES_SUCCESS:
      return { ...state, participantResponses: payload, loaded: true };
    case FETCH_PARTICIPANT_RESPONSES_FAIL:
      return { ...state, loaded: true };
    default:
      return state;
  }
};

export default fetchParticipantResponses;
