import React from 'react';
import { connect } from 'react-redux';
import useCsvDownload from '../../../hooks/useCsvDownload';
import { CSVLink } from 'react-csv';

import Button from '../Button';

import { exportDataAction } from '../../../actions/exportDataAction';

const generateFileName = ({ exportType, target = '' }) => {
  let result = exportType;
  if (target) {
    const targetFormatted = target.replace(/([A-Z])/g, ' $1');
    const targetResults =
      targetFormatted.charAt(0).toUpperCase() + targetFormatted.slice(1);
    result = `${exportType} - ${targetResults}`;
  }
  result += ` - ${new Date().toISOString()}.csv`;

  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

const ExportButton = ({
  exportData,
  filters,
  target,
  exportType = 'responses',
  type,
  text,
  ...props
}) => {
  const fileName = generateFileName({ exportType, target });
  const [data, handleClick, buttonRef] = useCsvDownload({
    exportType,
    filters,
    target
  });

  return (
    <>
      <CSVLink
        data={data.csvData}
        asyncOnClick
        id="csvLink"
        style={{ display: 'none' }}
        ref={buttonRef}
        headers={data.csvHeaders}
        filename={fileName}
      />

      <Button
        label={text || 'Export CSV'}
        onClick={handleClick}
        style={{ display: 'block' }}
        {...props}
        type={type || 'primary'}
        loading={data.loading}
        disabled={data.loading || props?.disabled}
      />
    </>
  );
};

const mapStateToProps = state => ({
  csvData: state.exportData.data,
  userId: state.auth.userId
});

export default connect(mapStateToProps, { exportData: exportDataAction })(
  ExportButton
);
