import styled from 'styled-components';

export const Form = styled.form``;

export const InputLabel = styled.label``;

export const Label = styled.label`
  width: 100%;
  font-size: 15px;
  margin-left: 0.5rem;
  color: ${({ theme }) => theme.colors.darkGray};
`;
