import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { fetchCustomModules } from '../../../actions/customModulesActions';
import modulesCategories from '../../../constants/dataTypes';
import useSearchParams from '../../../hooks/useSearchParams';

import { MODULE_URL } from '../../../constants/navigationRoutes';
import Layout from '../../Layouts';

import Card from '../../common/Card';
import CatHeader from '../../common/CatHeader';
import { Col, Row } from '../../common/Grid';
import MaxWidthContainer from '../../common/MaxWidthContainer';
import * as T from '../../common/Typography';
import WebsiteHeader from '../../common/WebsiteHeader';

const Catalogue = ({ fetchModulesAction, modules, loading }) => {
  const searchParams = useSearchParams();
  const scrolled = useRef(false);

  const categoryA = modules.filter(
    item => item.category === modulesCategories.ALCOHOL_AWARENESS
  );
  const categoryB = modules.filter(
    item => item.category === modulesCategories.ALCOHOL_IDENTIFICATION
  );
  const categoryC = modules.filter(
    item => item.category === modulesCategories.MANAGEMENT_OF_ALCOHOL
  );

  const categoryDiv = searchParams?.params?.div;
  useEffect(() => {
    // scroll to category div
    if (categoryDiv && !scrolled.current) {
      const el = document.getElementById(`div-${categoryDiv}`);
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
        scrolled.current = true;
      }
    }
  }, [categoryDiv, modules]);

  useEffect(() => {
    fetchModulesAction({ forPublic: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout layout="website">
      <WebsiteHeader
        title="North East & North Cumbria Programme for Alcohol Studies"
        subtitle="Training catalogue"
      />
      <MaxWidthContainer>
        {categoryA?.length > 0 && (
          <>
            <CatHeader
              mt="7"
              mtT="5"
              bgColor="mainPrimary"
              textColor="white"
              title="Alcohol Awareness"
              subtitle="Learn about the evidence based drivers of alcohol related harm, how this impacts health inequalities and how you can make a difference in your day-to-day work"
              id={`div-1`}
            />
            <Row mt="5" mtT="3" inner ai="stretch">
              {categoryA.map(item => (
                <Col w={[4, 12, 4]} key={item._id} mb="3">
                  <Card bgColor="lightPrimary">
                    <T.H4W>{item.title}</T.H4W>
                    <T.P mt="2" mb="2" color="gray">
                      {item.description}
                    </T.P>
                    <T.Link
                      color="gray"
                      to={MODULE_URL.replace(':id', item._id)}
                    >
                      Read more
                    </T.Link>
                  </Card>
                </Col>
              ))}
            </Row>
          </>
        )}
        {categoryB?.length > 0 && (
          <>
            <CatHeader
              mt="7"
              mtT="3"
              bgColor="mainSecondary"
              textColor="darkGray"
              title="Alcohol Identification and Brief Advice"
              subtitle="Learn how to have effective conversations about alcohol and respond appropriately when alcohol-related harm is identified"
              id={`div-2`}
            />
            <Row mt="5" mtT="3" inner ai="stretch">
              {categoryB.map(item => (
                <Col w={[4, 12, 4]} key={item._id} mb="3">
                  <Card bgColor="lightPrimary">
                    <T.H4W>{item.title}</T.H4W>
                    <T.P mt="2" mb="2" color="gray">
                      {item.description}
                    </T.P>
                    <T.Link
                      color="gray"
                      to={MODULE_URL.replace(':id', item._id)}
                    >
                      Read more
                    </T.Link>
                  </Card>
                </Col>
              ))}
            </Row>
          </>
        )}
        {categoryC?.length > 0 && (
          <>
            <CatHeader
              mt="7"
              mtT="3"
              bgColor="darkGray"
              textColor="white"
              title="Management of alcohol harm and dependence"
              subtitle="Learn about interventions to manage alcohol use disorder and supporting the journey through to recovery"
              id={`div-3`}
            />
            <Row mt="5" mtT="3" inner ai="stretch">
              {categoryC.map(item => (
                <Col w={[4, 12, 4]} key={item._id} mb="3">
                  <Card bgColor="lightPrimary">
                    <T.H4W>{item.title}</T.H4W>
                    <T.P mt="2" mb="2" color="gray">
                      {item.description}
                    </T.P>
                    <T.Link
                      color="gray"
                      to={MODULE_URL.replace(':id', item._id)}
                    >
                      Read more
                    </T.Link>
                  </Card>
                </Col>
              ))}
            </Row>
          </>
        )}
      </MaxWidthContainer>
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    httpError: state.error,
    modules: state.modules.modules,
    loading: state.loading.fetchModulesLoading
  };
};

export default connect(mapStateToProps, {
  fetchModulesAction: fetchCustomModules
})(Catalogue);
