import { courses } from '../../../constants';
import modulesCategories from '../../../constants/dataTypes';

export const categoryOptions = [
  {
    label: 'Alcohol Awareness',
    value: modulesCategories.ALCOHOL_AWARENESS,
    course: courses.ALCOHOL_STUDIES
  },
  {
    label: 'Alcohol Identification and Brief Advice',
    value: modulesCategories.ALCOHOL_IDENTIFICATION,
    course: courses.ALCOHOL_STUDIES
  },
  {
    label: 'Management of alcohol harm and dependence',
    value: modulesCategories.MANAGEMENT_OF_ALCOHOL,
    course: courses.ALCOHOL_STUDIES
  },
  {
    label: 'Poverty Awareness',
    value: modulesCategories.POVERTY_AWARENESS,
    course: courses.POVERTY_PROOFING
  },
  {
    label: 'Poverty Identification & Support',
    value: modulesCategories.POVERTY_IDENTIFICATION_AND_SUPPORT,
    course: courses.POVERTY_PROOFING
  },
  {
    label: 'Poverty Proofing© Interventions',
    value: modulesCategories.POVERTY_PROOFING_INTERVENTIONS,
    course: courses.POVERTY_PROOFING
  },
  {
    label: 'Tobacco Awareness',
    value: modulesCategories.TOBACCO_AWARENESS,
    course: courses.TOBACCO_DEPENDENCY
  },
  {
    label:
      'Identification of Tobacco Dependence (Smoking) and Delivery of Brief Advice',
    value: modulesCategories.TOBACCO_IDENTIFICATION,
    course: courses.TOBACCO_DEPENDENCY
  },
  {
    label: 'Treatment of Tobacco Dependence (Smoking Cessation)',
    value: modulesCategories.TOBACCO_TREATMENT,
    course: courses.TOBACCO_DEPENDENCY
  },
  {
    label: 'Tobacco Control',
    value: modulesCategories.TOBACCO_CONTROL,
    course: courses.TOBACCO_DEPENDENCY
  }
];

export const yesNoOptions = [
  {
    label: 'Yes',
    value: 'true'
  },
  {
    label: 'No',
    value: 'false'
  }
];
