import styled from 'styled-components';

export const IconWrapper = styled.div`
  display: flex;
  margin-left: 22px;
  align-items: center;
`;

export const Error = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.error};
  font-size: 0.9rem;
`;
