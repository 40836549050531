import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import checkIfSessionIsFinished from '../../../helpers/checkIfSessionIsFinished';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';

import Layout from '../../Layouts';
import { Row, Col } from '../../common/Grid';

import MaxWidthContainer from '../../common/MaxWidthContainer';
import * as T from '../../common/Typography';
import * as S from './style';

import CalloutSection from './CalloutSection';
import PublicSessionList from '../../common/List/PublicSessionList';

import { fetchALLSessions } from '../../../actions/groupSessionsAction';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

const Connect5AndMECC = ({ sessions, fetchALLSessions, isMobile }) => {
  const [availableSessions, setAvailableSessions] = useState([]);
  const url = window.location.href;
  // get the bit after the domain in the url
  const urlParams = url.split('/').slice(3);

  let courseToFilter = urlParams?.[0];
  if (courseToFilter === 'connect5') {
    courseToFilter = 'C5';
  } else if (courseToFilter === 'mecc') {
    courseToFilter = 'MECC';
  }

  const { breakpoints } = useTheme();

  const isDesktop = useMediaQuery({
    query: breakpoints.laptop
  });

  const history = useHistory();

  useEffect(() => {
    fetchALLSessions({ type: 'upcoming', history, course: courseToFilter });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseToFilter]);

  useEffect(() => {
    if (sessions?.length > 0) {
      const spaces = sessions
        .filter(
          session =>
            session.capacity > session.participantsEmails.length &&
            !checkIfSessionIsFinished(session)
        )
        .sort((a, b) => {
          // sort by date
          return moment(a.date).isSameOrAfter(moment(b.date)) ? 1 : -1;
        });
      setAvailableSessions(spaces);
    }
  }, [sessions]);

  return (
    <Layout layout="website">
      <MaxWidthContainer>
        <S.HeadWrapper>
          <T.H1W ta="center" color="darkGray" mb={4} mt={6} mtT={'27'}>
            {courseToFilter === 'C5' && 'Connect 5'}
            {courseToFilter === 'MECC' && 'Making Every Contact Count'}
          </T.H1W>
          <T.P medium={!!isDesktop} small={!isDesktop} ta="center" color="gray">
            {courseToFilter === 'C5' &&
              `Connect 5 is a workforce training programme that has been created to upskill non-mental health specialists to better understand and feel confident to have everyday conversations about mental health and wellbeing in their everyday encounters.`}

            {courseToFilter === 'MECC' &&
              `MECC is a workforce training programme that can support the public, private, voluntary and community sectors to share health messages and signpost services with individuals across the North East and North Cumbria. It is underpinned by behaviour change to utilise the daily interactions we have to encourage positive changes to improve health outcomes and reduce inequalities.
              `}
          </T.P>
        </S.HeadWrapper>
        <CalloutSection
          isMECC={courseToFilter === 'MECC'}
          isC5={courseToFilter === 'C5'}
        />
        <Row mb={5} mt={8}>
          <Col w={[4, 8, 8]} direction="column" ai="flex-start">
            <T.H1 mb={4}>Upcoming training sessions</T.H1>
            {courseToFilter === 'C5' && (
              <T.P>
                Remember that for Connect 5 you must complete the modules in
                order, so please do not attend a session teaching module 2 or 3
                before you’ve done module 1.
              </T.P>
            )}
          </Col>
        </Row>
        <Row>
          {availableSessions?.length > 0 ? (
            <PublicSessionList
              dataList={availableSessions}
              linkText={!isMobile && 'View & register'}
            />
          ) : (
            <Col w={[4, 8, 8]}>
              <T.P weight={700}>
                No sessions currently available. Check back soon.
              </T.P>
            </Col>
          )}
        </Row>
      </MaxWidthContainer>
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    sessions: state.sessions.sessions,
    isMobile: state.checkBrowserWidth.isMobile
  };
};

const mapActionsToProps = {
  fetchALLSessions
};

export default connect(mapStateToProps, mapActionsToProps)(Connect5AndMECC);
