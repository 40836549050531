import styled from 'styled-components';

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.mainPrimary};
  padding: ${({ theme }) => `${theme.spacings[6]} ${theme.spacings[0]}`};
  min-height: 276px;
  display: flex;
  align-items: center;
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;

  height: fit-content;

  border-radius: 4px;
  padding: ${({ theme, footer }) =>
    footer ? '0px 8.3vw' : `${theme.spacings[0]} ${theme.spacings[5]}`};
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  flex-direction: row;

  @media (max-width: 930px) {
    height: auto;
    flex-direction: column;
  }
`;

export const LogosSide = styled.div`
  max-width: 345px;
  // margin-top: ${({ theme, footer }) => (footer ? 0 : theme.spacings[5])};
  @media (max-width: 930px) {
    // margin-top: ${({ theme }) => theme.spacings[5]};
  }
`;

export const LogoImage = styled.img`
  // width: 300px;
  height: 100%;
`;

export const ContentSide = styled.div`
  padding: ${({ theme, footer }) =>
    footer ? `${theme.spacings[0]} ${theme.spacings[4]}` : 0};
`;

export const ContentHading = styled.div``;
