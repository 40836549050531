import styled from 'styled-components';
import setMargins from '../../../helpers/setMargin';

export const MultiInputFieldWrapper = styled.div`
  ${setMargins};
  width: 100%;
`;

export const TextWithIcon = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  gap: 6px;
  margin-top: ${({ theme }) => theme.spacings[4]};
`;

export const Form = styled.form`
  background: ${({ theme }) => theme.colors.lightPrimary};
  max-width: 498px;
  padding: ${({ theme }) => theme.spacings[6]};
  border-radius: 12px;
`;

export const GrayLine = styled.div`
  ${setMargins}
  border-top : 1px solid #EBEEF0;
`;

export const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
