import React from 'react';
import moment from 'moment';
import {
  convertAddressObjToString,
  concatModules
} from './../../../../helpers';

import { Divider } from 'antd';

import {
  Text,
  EmailWrapper,
  EmailText,
  BoldSpan
} from '../SessionDetails.Style';

const EmailTemplate = ({ sessionDetails, activeEmailTemplate }) => {
  const fullAddress = convertAddressObjToString(sessionDetails.address);
  const modulesAsString = concatModules({ modules: sessionDetails.modules });

  return (
    <div>
      <EmailWrapper>
        <div>
          <Text>
            Date:{' '}
            {activeEmailTemplate
              ? moment(activeEmailTemplate.sendDate).format('DD-MM-YYYY')
              : 'N/A'}
          </Text>
        </div>
        <div>
          <Text>Message:</Text>
        </div>
        <EmailText>Dear course participants,</EmailText>
        <EmailText>
          This is a friendly reminder of your upcoming training session:
        </EmailText>
        <ul style={{ paddingLeft: '2rem', marginTop: '1rem' }}>
          <li>
            <EmailText>
              <BoldSpan>Session Date:</BoldSpan>{' '}
              {(activeEmailTemplate.sessionDate &&
                moment(activeEmailTemplate.sessionDate).format(
                  'DD MMM YYYY'
                )) ||
                'N/A'}
            </EmailText>
          </li>
          <li>
            <EmailText>
              <BoldSpan>Module(s):</BoldSpan> {modulesAsString}
            </EmailText>
          </li>
          {sessionDetails.remote ? (
            <>
              <li>Location: Online</li>
              <li>
                Meeting link:{' '}
                {sessionDetails.meetingLink ? (
                  <a href={sessionDetails.meetingLink}>
                    {sessionDetails.meetingLink}
                  </a>
                ) : (
                  'TBC'
                )}
              </li>
              <li>
                Meeting platform: {sessionDetails.meetingPlatform || 'TBC'}
              </li>
            </>
          ) : (
            <li> Location: {fullAddress || 'TBC'}</li>
          )}
          <li>
            <EmailText>
              <BoldSpan>time: From</BoldSpan>{' '}
              {activeEmailTemplate.startTime || 'N/A'} <BoldSpan>To</BoldSpan>{' '}
              {activeEmailTemplate.endTime || 'N/A'}
            </EmailText>
          </li>
          <li>
            <EmailText>
              <BoldSpan>Trainer(s):</BoldSpan>{' '}
              {activeEmailTemplate.trainers || 'N/A'}
            </EmailText>
          </li>
          <EmailText>
            <BoldSpan>Session End Date:</BoldSpan>{' '}
            {(activeEmailTemplate.endDate &&
              moment(activeEmailTemplate.endDate).format('DD MMM YYYY')) ||
              'N/A'}
          </EmailText>
        </ul>

        <EmailText>
          To track your own progress and to ensure that our trainings are
          effective we rely on course participants to fill out surveys after
          each session. All the data is anonymised. After answering surveys you
          can immediately see your own progress and access certificates via the
          app. You will receive a link to the post-session evaluation from your
          trainers.
        </EmailText>
        {activeEmailTemplate.preSurveyLink && (
          <>
            <br />
            <EmailText>
              <b>
                Before starting the training session please follow this link and
                fill out the{' '}
                <a
                  href={activeEmailTemplate.preSurveyLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  pre-course evaluation
                </a>
                .
              </b>
            </EmailText>
          </>
        )}

        <br />

        {activeEmailTemplate.extraInformation && (
          <EmailText as="pre">{activeEmailTemplate.extraInformation}</EmailText>
        )}

        <br />
        <EmailText>Sincerely,</EmailText>
        <EmailText>Your ICS Training team</EmailText>

        <Divider />
        <Text>
          <BoldSpan>Sent To:</BoldSpan>
        </Text>
        {activeEmailTemplate.recipients &&
          activeEmailTemplate.recipients.map(email => (
            <EmailText key={email}>{email}</EmailText>
          ))}
      </EmailWrapper>
    </div>
  );
};

export default EmailTemplate;
