import React from 'react';

import Question from './Question';

import BehaviouralHelper from './BehaviouralHelper';
import {
  QuestionGroup,
  QuestionText,
  QuestionWrapper,
  SectionCategory,
  SubGroup,
  TextField,
  Warning
} from './Questions.style';

const Questions = ({ questions, setQuestion }) => {
  return questions.map((q, i) => {
    const {
      _id: questionId,
      text: questionText,
      helperText,
      group,
      answered,
      followUpQuestion,
      answer
    } = q;

    const currentQuestionIndex = questions.findIndex(
      question => question._id.toString() === questionId.toString()
    );
    const onBlur = answer => {
      if (
        (typeof answer === 'string' &&
          answer?.toLowerCase()?.includes('please specify')) ||
        followUpQuestion?.ifAnswerIsOneOf.includes(answer) ||
        !answer
      ) {
        return;
      }
      const nextQuestion = questions.find((question, i) => {
        return i > currentQuestionIndex && !question.answered;
      });

      if (nextQuestion) {
        const nextQuestionId = nextQuestion._id;
        const nextQuestionElement = document.getElementById(
          `question-wrapper-${nextQuestionId}`
        );
        // used the timeout because the next question was not scrolling into view if the question was date picker
        setTimeout(() => {
          nextQuestionElement?.scrollIntoView({
            behavior: 'smooth'
          });
        }, 0);
      }
    };

    const inputType = q.questionType.desc;

    const subGroup = q.subGroup && q.subGroup.text ? q.subGroup.text : null;

    const handleOther = e => {
      const prefix = answer.split(' - ')[0];
      const otherAnswer = `${prefix}: ${e.target.value}`;

      setQuestion({
        _id: questionId,
        otherAnswer: otherAnswer,
        answered: !!otherAnswer
      });
    };

    const lastRequiredQuestionIndex = questions
      .slice(0, i)
      .findLastIndex(question => question.isRequired);

    const isLastRequiredQuestionAnswered = !!questions[
      lastRequiredQuestionIndex
    ]?.answered;

    const isChildQuestion = !!q.metadata?.parentQuestion;
    const disabled =
      !isChildQuestion &&
      currentQuestionIndex !== 0 &&
      !isLastRequiredQuestionAnswered;

    return (
      <QuestionGroup key={questionId}>
        {i === 0 && <SectionCategory>{group?.text}</SectionCategory>}
        {i === 0 && group.text === 'Behavioural Insights' && (
          <BehaviouralHelper showText />
        )}
        <QuestionWrapper disabled={disabled}>
          <TextField id={`question-wrapper-${questionId}`}>
            <header>
              {subGroup && <SubGroup>{subGroup}</SubGroup>}
              <QuestionText id={questionId}>{questionText}</QuestionText>
              {group.text === 'Behavioural Insights' && <BehaviouralHelper />}
              <p className="helpertext">{helperText}</p>
            </header>
            <Question
              question={q}
              inputType={inputType}
              setQuestion={setQuestion}
              onBlur={onBlur}
              disabled={disabled}
            />

            <div className="other-div">
              {/* Load "Other" div */}
              {followUpQuestion?.ifAnswerIsOneOf.includes(answer) && (
                <TextField>
                  <p>{followUpQuestion.text}</p>
                  <input
                    id="other"
                    name={questionId}
                    onChange={handleOther}
                    onBlur={e => onBlur(e.target.value)}
                    autoFocus
                  />
                </TextField>
              )}
              {typeof answer === 'string' &&
              answer?.toLowerCase()?.includes('please specify') ? (
                <TextField>
                  <p>Please specify:</p>
                  <input
                    id="other"
                    name={questionId}
                    type="text"
                    onChange={handleOther}
                    onBlur={e => onBlur(e.target.value)}
                    autoFocus
                  />
                </TextField>
              ) : (
                ''
              )}
            </div>
            <Warning>
              {q.isRequired && !answered && '* this question must be answered'}
            </Warning>
          </TextField>
        </QuestionWrapper>
      </QuestionGroup>
    );
  });
};

export default Questions;
