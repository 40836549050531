import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { message } from 'antd';

import Spin from 'antd/lib/spin';
import Layout from '../../Layouts';

import { fetchStatsData } from '../../../actions/users';
import InfoPopUp from '../../common/InfoPopup';
import useSearchParams from './../../../hooks/useSearchParams';

// STYLING
import {
  Wrapper,
  WrapperRow,
  TopSection,
  StatsWrapper,
  StatItem,
  SpinWrapper
} from './Dashboard.style';

//  COMMON COMPONENTS
import * as T from '../../common/Typography';

// ROUTES
import {
  MY_SESSIONS_URL,
  MY_GROUP_SESSIONS_URL,
  TRAINERS_URL,
  ALL_SESSIONS_URL,
  CHOOSE_RESULTS_FOR_COURSE
} from '../../../constants/navigationRoutes';

const Dashboard = ({
  role,
  fetchStatsData: fetchStatsDataActionCreator,
  userName,
  stats,
  userId
}) => {
  const searchParams = useSearchParams();

  useEffect(() => {
    fetchStatsDataActionCreator(role);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (stats.responseRate > 100) {
      message.warning(
        <>
          <h3 style={{ fontSize: '1rem' }}>
            You have surveys with a response rate over 100%.
          </h3>
          <p>
            This is because you have had more responses than confirmed
            attendees. To fix this please update your sessions to have the
            correct number of people who attended
          </p>
        </>,
        3
      );
    }
  }, [stats.responseRate]);

  const captalizesName =
    userName && userName[0].toUpperCase() + userName.substr(1);

  let sessionURL;

  switch (role) {
    case 'trainer':
      sessionURL = MY_SESSIONS_URL;
      searchParams.append({
        target: 'me'
      });

      break;

    case 'localLead':
      sessionURL = MY_GROUP_SESSIONS_URL;
      searchParams.append({
        target: 'myGroup'
      });

      break;

    case 'admin':
      sessionURL = ALL_SESSIONS_URL;
      searchParams.append({
        target: 'all'
      });
      break;

    default:
      break;
  }

  return (
    <Layout>
      <Wrapper>
        {!stats.loaded ? (
          <SpinWrapper>
            <Spin size="large" />
          </SpinWrapper>
        ) : (
          <>
            <TopSection>
              <T.H1 mb={4}>Welcome back {captalizesName}</T.H1>
              <WrapperRow>
                <T.P weight={700} mr="1">
                  Role:
                </T.P>
                <T.P>{role === 'localLead' ? 'Local Lead' : role}</T.P>
              </WrapperRow>
            </TopSection>
            <T.P weight={700} mb={4}>
              Your Total Reach
            </T.P>
            <StatsWrapper>
              <StatItem to={sessionURL}>
                <T.P weight={700}>Sessions</T.P>
                <T.H1 color="primary">{stats.sessionCount}</T.H1>
              </StatItem>
              <StatItem
                to={{
                  pathname: CHOOSE_RESULTS_FOR_COURSE,
                  search: searchParams.toString()
                }}
              >
                <T.P weight={700}>
                  Participants{' '}
                  <InfoPopUp
                    text={
                      'this is total times people have participated in a session'
                    }
                    h="16px"
                    w="16px"
                    color={'primary'}
                  />
                </T.P>
                <T.H1 color="primary">{stats.participantCount} </T.H1>
              </StatItem>
              <StatItem
                to={{
                  pathname: CHOOSE_RESULTS_FOR_COURSE,
                  search: searchParams.toString()
                }}
              >
                <T.P weight={700}>Responses</T.P>
                <T.H1 color="primary">{stats.responseCount}</T.H1>
              </StatItem>
              {role === 'trainer' ? (
                <StatItem
                  to={{
                    pathname: CHOOSE_RESULTS_FOR_COURSE,
                    search: searchParams.toString()
                  }}
                >
                  <T.P weight={700}>Response Rate</T.P>
                  <T.H1 color="primary">{stats.responseRate || '0'}%</T.H1>
                </StatItem>
              ) : (
                <StatItem to={TRAINERS_URL}>
                  <T.P weight={700}>Trainers</T.P>
                  <T.H1 color="primary">{stats.trainerCount}</T.H1>
                </StatItem>
              )}
            </StatsWrapper>
          </>
        )}
      </Wrapper>
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    userName: state.auth.name,
    stats: state.stats,
    userId: state.auth.userId,
    role: state.auth.role
  };
};

export default connect(mapStateToProps, { fetchStatsData })(Dashboard);
