import CopyLinkComponent from '../../../common/CopyLink';
import { Row, Col } from '../../../common/Grid';
import * as T from '../../../common/Typography';

import { MODULE_URL } from '../../../../constants/navigationRoutes';

import { SessionTopDetailsWrapper } from '../SessionDetails.Style';
const InviteAndPromote = props => {
  const { sessionDetails } = props;

  return (
    <SessionTopDetailsWrapper>
      <Row mb="4" mt="4">
        <Col w={[4, 8, 10]}>
          <T.P>
            Here you can invite people to your session. By copying the
            registration link and sharing it manually.
          </T.P>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 8, 8]}>
          <T.P weight={700} mt="4" mb="2">
            Registration Link
          </T.P>
        </Col>
      </Row>
      <CopyLinkComponent
        link={`${
          process.env.NODE_ENV === 'production' ? 'https://' : 'http://'
        }${window.location.host}${MODULE_URL.replace(
          ':id',
          sessionDetails?.customModules?.[0]?._id
        )}`}
      />
    </SessionTopDetailsWrapper>
  );
};
export default InviteAndPromote;
