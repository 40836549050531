import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  margin-bottom: ${({ mb }) => mb || '0'};
`;

export const Disclaimer = styled.div`
  p {
    font-weight: 300;
  }
`;

export const SpinWrapper = styled.div`
  height: 100vh;
  width: 100vw;
`;
export const SpinDiv = styled.div`
  height: 300px;
  width: 300px;
`;
export const ButtonDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding: 1.5rem;

  /* button {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.blackSecondary};
    border: ${({ theme }) => theme.borders.button};
  }

  button: hover {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.primary};
  } */
`;

export const SkipButtonsDiv = styled(ButtonDiv)`
  button {
    background-color: ${({ submit, theme }) => submit && theme.colors.primary};
  }
  margin-bottom: 60px;
`;

export const ButtonLink = styled(Link)``;

export const SectionSubHeadline = styled.h2`
  font-weight: 350;
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.black};
`;

export const Paragraph = styled.p`
${({ strong }) => (strong ? 'font-weight: 600;' : '300')}
color: ${({ theme }) => theme.colors.black};
margin-bottom: 0;
`;

export const SessionDetails = styled.div`
  width: 100%;
  height: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-top: 1rem;
  padding-left: 2rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  background: ${({ theme }) => theme.colors.lightPrimary};
`;

export const DetailsDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
`;
