import { fields, createSchema, validate as _validate } from '..';

import { userRoles } from '../../constants';

const {
  email,
  password,
  name,
  arrayOfString,
  organisationOther,
  requiredText
} = fields;

const validate = data => {
  const trainerSchema = createSchema({
    email,
    name,
    password: data.allowWeakPassword ? requiredText : password,
    localAuthorities: arrayOfString,
    organisationOther,
    jobRole: requiredText,
    team: requiredText,
    department: requiredText
  });

  const participantSchema = createSchema({
    email,
    password,
    name
  });

  if (data.role === userRoles.trainer || data.role === userRoles.localLead) {
    return _validate(trainerSchema, data);
  } else {
    return _validate(participantSchema, data);
  }
};

export default validate;
