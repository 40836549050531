/* eslint-disable react/destructuring-assignment */
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

// COMMON COMPONENTS
import Layout from '../../Layouts';
import * as T from '../../common/Typography';
import { Row, Col } from '../../common/Grid';
import Button from '../../common/Button';
import Icon from '../../common/Icon';
import { Checkbox } from '../../common/Inputs';
import { externalLinks } from '../../../constants';
import * as S from './HowItWorks.style';
import * as Actions from '../../../actions/authAction';
import { upgradeTttSteps, errMsgs } from '../../../constants';

const HowItWorks = ({ signUpTttTrainer, loading, httpError }) => {
  const [confirmWatchingVideo, setConfirmWatchingVideo] = useState(false);
  const history = useHistory();

  const handleNext = () => {
    signUpTttTrainer({
      history,
      step: upgradeTttSteps.watchTttVideo
    });
  };

  return (
    <Layout>
      <Row mb="4">
        <Col w={[4, 8, 8]} display="block">
          <T.H1 mb="6" width="100%">
            How this tool works
          </T.H1>
        </Col>
      </Row>
      <Row mb="5">
        <Col w={[4, 8, 8]}>
          <T.P mr="1">
            On this platform you will be able to set up training sessions,
            invite participants, collect feedback and see your results. To make
            the most of it and use the tool properly, we require all trainers to
            watch a video that will explain how to use this tool.
          </T.P>
        </Col>
      </Row>

      <Row mb="8">
        <Col w={[4, 8, 8]}>
          <T.Link
            external
            underline={false}
            to={externalLinks.EXPLAIN_TOOL_VIDEO}
          >
            <S.IconWrapper>
              <Icon icon="open" mr="4" /> View video
            </S.IconWrapper>
          </T.Link>
        </Col>
      </Row>

      <Row mt={6}>
        <Col w={[4, 8, 8]}>
          <Checkbox
            checked={confirmWatchingVideo}
            handleChange={value => setConfirmWatchingVideo(value)}
            label="I confirm I have watched the video"
          />
        </Col>
      </Row>
      <Row mt={7}>
        <Col w={[4, 4, 4]}>
          <Button
            onClick={handleNext}
            type="primary"
            label="Next"
            disabled={!confirmWatchingVideo}
          />
        </Col>
      </Row>
      <Row>
        <Col w={[4, 12, 12]}>
          {httpError && (
            <S.Error>
              {httpError?.response?.data?.error || errMsgs.somethingWentWrong}
            </S.Error>
          )}
        </Col>
      </Row>
    </Layout>
  );
};

const mapStateToProps = state => ({
  httpError: state.auth.error,
  loading: state.loading.signUpTttTrainer
});

export default connect(mapStateToProps, {
  signUpTttTrainer: Actions.signUpTttTrainer
})(HowItWorks);
