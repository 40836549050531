import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from '../../common/Grid';
import NewTable from '../../common/NewTable';
import Button from '../../common/Button';
import * as T from '../../common/Typography';
import Modal from '../../common/modal';
import * as R from '../../../constants/navigationRoutes';
import { TableColumns } from './TableColumns';

import {
  archiveModule,
  fetchCustomModules
} from '../../../actions/customModulesActions';

const ModulesTable = ({
  modules,
  loading,
  archiveLoading,
  archiveModuleAction,
  fetchModulesAction,
  selectedCourse
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedModule, setSelectedModule] = useState(null);

  useEffect(() => {
    if (selectedCourse) {
      fetchModulesAction({ course: selectedCourse });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCourse]);

  const handleArchive = ({ item }) => {
    setSelectedModule(item);
    setShowModal(true);
  };

  const archiveModule = async () => {
    await archiveModuleAction({
      moduleId: selectedModule._id,
      data: {
        archived: true
      }
    });
    setShowModal(false);
    fetchModulesAction({});
  };

  return (
    <>
      <Row mt="7">
        <Col w={[4, 12, 12]} mb="5">
          <Button
            type="primary"
            label="Add module"
            width="200px"
            to={R.CREATE_MODULE}
          />
          <Button
            ml="2"
            type="secondary"
            label="View archived modules"
            width="200px"
            to={R.ARCHIVED_MODULES}
          />
        </Col>
        <Col w={[4, 12, 12]}>
          <NewTable
            columns={TableColumns({ handleArchive })}
            data={modules}
            loading={loading}
          />
        </Col>
      </Row>
      <Modal visible={showModal} setModalOpen={setShowModal}>
        <T.H4W color="darkGray" mb="2" mt="2">
          No longer need this module?
        </T.H4W>
        <T.P color="gray" mb="7">
          Archiving this module will make it no longer be available to
          participants, but any collected results will still be saved in All
          Results
        </T.P>
        <Button
          label="Confirm"
          type="primary"
          width="200px"
          loading={archiveLoading}
          onClick={archiveModule}
        />
      </Modal>
    </>
  );
};

const mapStateToProps = state => ({
  modules: state.modules.modules,
  archiveLoading: state.loading.archiveModuleLoading,
  loading: state.loading.fetchModulesLoading,
  selectedCourse: state.course.selectedCourse
});

export default connect(mapStateToProps, {
  archiveModuleAction: archiveModule,
  fetchModulesAction: fetchCustomModules
})(ModulesTable);
