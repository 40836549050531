import React, { Component } from 'react';
import {
  Modal,
  message,
  Tooltip,
  Button as AntButton,
  Checkbox,
  Row as AntRow,
  Col as AntCol,
  DatePicker,
  TimePicker
} from 'antd';
import 'moment-timezone';
import { concatModules } from '../../../helpers';

import moment from 'moment';
import 'moment-timezone';
import Swal from 'sweetalert2';

import { connect } from 'react-redux';
import * as Yup from 'yup';

import {
  getPostSurveyLink,
  getPreSurveyLink,
  splitEmailsList
} from '../../../helpers';

import { courseLabelMap, modules as moduleData } from '../../../constants';
import { REGISTER_URL } from '../../../constants/navigationRoutes';

import InfoPopUp from '../InfoPopup';
import Collapse from '../Collapse';
import * as T from '../Typography';
import CopyLink from '../CopyLink';
import EmailTemplate from '../EmailTemplate';
import Button from '../Button';
import { Row, Col } from '../Grid';
import { Dropdown, TextArea } from '../Inputs';
import InfoCard from '../InfoCard';

import {
  sendEmailReminder as sendEmailReminderAction,
  scheduleNewEmail as scheduleNewEmailAction
} from '../../../actions/sessionAction';

import {
  Wrapper,
  SelecetWrapper,
  IconsWrapper,
  AddEmailsButton,
  BackLink
} from './EditEmail.style';
import { customSwal } from '../../../theme';

const format = 'HH:mm';

const defaultCheckedList = ['Apple', 'Orange'];

const emailSchema = Yup.string()
  .email()
  .required();

const text =
  'Email addresses of invitees can be either added one by one or copied from a list separated by commas, spaces etc. This list is not required to set up sessions and you can add new email addresses to send out more invites as many times as you.';

const getTrainersNamesInString = trainers => {
  return trainers
    .map(trainer => `${trainer.name[0].toUpperCase()}${trainer.name.slice(1)}`)
    .join(' & ');
};
class EditEmail extends Component {
  state = {
    isEditView: false,
    // plain text if canAddParticipants = true
    // {email, status} if canAddParticipants = false
    participantsEmails: [],
    checkedList: defaultCheckedList,
    indeterminateAll: false,
    isAllChecked: true,
    isNewChecked: true,
    sessionDetails: {},
    extraInformation: ''
  };

  selectRef = React.createRef();

  topElementRef = React.createRef();

  componentDidMount() {
    const {
      participantsEmails,
      canAddParticipants,
      shortId,
      modules,
      sessionDetails
    } = this.props;

    const preSurveyLink =
      modules?.includes(moduleData.MODULE_1) && getPreSurveyLink({ shortId });

    const postSurveyLink = getPostSurveyLink({ shortId });

    // set emails into state
    const plainAllEmails = participantsEmails.map(item => item.email);
    const plainNewEmails = participantsEmails
      .filter(item => item.status === 'new')
      .map(item => item.email);

    // plain text if canAddParticipants = true
    // {email, status} if canAddParticipants = false
    let newparticipantsEmails;

    if (canAddParticipants) {
      newparticipantsEmails = plainAllEmails;
    } else {
      newparticipantsEmails = participantsEmails;
    }
    this.setState({
      participantsEmails: newparticipantsEmails,
      plainAllEmails,
      plainNewEmails,
      checkedList: plainAllEmails,
      preSurveyLink,
      postSurveyLink,
      sessionDetails
    });
    if (window.ClipboardEvent) {
      let dT = null;
      try {
        dT = new DataTransfer();
      } catch (e) {
        // ignore the error
      }
      const evt = new ClipboardEvent('paste', { clipboardData: dT });
      if (evt.clipboardData || window.clipboardData) {
        (evt.clipboardData || window.clipboardData).setData('text/plain', '');
        document.addEventListener('paste', this.pasteEmails);
        document.dispatchEvent(evt);
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('paste', this.pasteEmails);
  }

  toggleEditView = visible => {
    this.setState({ isEditView: visible });
    const drawerWrapper = document.querySelector('.ant-drawer-wrapper-body');
    if (drawerWrapper) {
      drawerWrapper.scrollBy(-1000, -1000);
    } else {
      window.scrollBy(-1000, -1000);
    }
  };

  handleExtraInformation = value => {
    this.setState({ extraInformation: value });
  };

  handleUpdateEmails = (values, blur) => {
    const validEmails = [];

    values.forEach(email => {
      if (
        !validEmails.some(
          _item =>
            _item.replace(/[, ;"']/g, '') === email.replace(/[, ;"']/g, '')
        )
      ) {
        try {
          const validEmail = emailSchema.validateSync(
            email.replace(/[, ;"']/g, '')
          );
          if (validEmail) {
            validEmails.push(email.replace(/[, ;"']/g, ''));
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error('Invalid email entered or email already on list');
        }
      }
    });

    this.setState({
      participantsEmails: validEmails,
      focused: blur ? false : true
    });
  };

  onTypingEmails = value => {
    const { participantsEmails } = this.state;

    if (
      value &&
      (value.includes(' ') || value.includes(',') || value.includes(';'))
    ) {
      const splittedEmails = splitEmailsList(value);

      // get latest added email
      const latestEmail =
        splittedEmails && splittedEmails[splittedEmails.length - 1];

      if (latestEmail.includes('@') && latestEmail.includes('.')) {
        this.handleUpdateEmails(
          [...splittedEmails, ...participantsEmails],
          true
        );
      } else {
        this.handleUpdateEmails(
          [
            ...splittedEmails.slice(0, splitEmailsList.length - 1),
            ...participantsEmails
          ],
          true
        );
      }
    }
  };

  handleSendEmail = () => {
    const { extraInformation, checkedList, participantsEmails } = this.state;

    const {
      sessionId,
      sessionDate,
      sessionType,
      startTime,
      endTime,
      shortId,
      address,
      sendEmailReminder,
      canAddParticipants,
      name,
      surveyType,
      course,
      modules,
      remote,
      meetingLink,
      meetingPlatform,
      extraInfo,
      endDate
    } = this.props;

    const emailsToSend = canAddParticipants ? participantsEmails : checkedList;
    if (emailsToSend.length < 1) {
      return message.error('You should add recipients emails');
    }
    const emailData = {
      sessionId,
      // should be sent plain
      recipients: emailsToSend,
      sendDate: new Date(),
      sessionDate,
      sessionType,
      // string of trainers' names
      trainers: getTrainersNamesInString(this.props.trainers),
      startTime,
      endTime,
      endDate,
      shortId,
      address,
      course,
      extraInformation,
      trainer: `${name[0].toUpperCase()}${name.slice(1)}`,
      surveyType,
      modules,
      remote,
      meetingPlatform,
      meetingLink,
      extraInfo,
      type: this.props.type
    };

    return sendEmailReminder(emailData, this.done);
  };

  done = () => {
    const { backCallback, sessionId, isSchedule, history } = this.props;

    Modal.success({
      title: 'Done!',
      content: isSchedule
        ? 'Email successfully scheduled'
        : 'Emails have been sent successfully',
      onOk: () => {
        if (typeof backCallback === 'function') return backCallback();
        return history.push(`/session-details/${sessionId}`);
      }
    });
  };

  onSelectBlur = () => {
    this.setState({ focused: false });
  };

  onSelectFocus = () => {
    this.setState({ focused: true });
  };

  onCopy = () => {
    const { participantsEmails } = this.state;
    const emailsToString = participantsEmails?.join(', ');
    const el = document.createElement('textArea');
    el.value = emailsToString;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    message.success('Copied!');
  };

  pasteEmails = event => {
    const { focused, participantsEmails } = this.state;
    let emailsArray;
    if (focused) {
      event.preventDefault();
      const pastedString = event.clipboardData.getData('text/plain');
      emailsArray = splitEmailsList(pastedString);
      this.handleUpdateEmails([...participantsEmails, ...emailsArray]);
      this.onSelectBlur();
    }
  };

  // to handle individual emails checkboxes changes
  onChangeCheckbox = checkedList => {
    const { plainAllEmails, plainNewEmails } = this.state;
    const isAllChecked = checkedList.length === plainAllEmails.length;
    const isNewChecked =
      plainNewEmails.length > 0 &&
      checkedList.length > 0 &&
      plainNewEmails.every(item => checkedList.includes(item));

    this.setState({
      checkedList,
      indeterminateAll:
        !!checkedList.length && checkedList.length < plainAllEmails.length,
      isAllChecked,
      isNewChecked
    });
  };

  // to handle toggle the checkbox for "seslect all" checbox
  onCheckAllChange = e => {
    const { plainAllEmails } = this.state;

    this.setState({
      checkedList: e.target.checked ? plainAllEmails : [],
      indeterminateAll: false,
      isAllChecked: e.target.checked,
      isNewChecked: e.target.checked
    });
  };

  // to handle toggle the checkbox for "seslect new" checbox
  onCheckNewChange = e => {
    const { plainNewEmails } = this.state;

    this.setState({
      checkedList: e.target.checked ? plainNewEmails : [],
      isNewChecked: e.target.checked,
      isAllChecked: false
    });
  };

  handleSelectDate = (date, dateString) => {
    this.setState({ scheduledDate: dateString });
  };

  handleSelectTime = (time, timeString) => {
    this.setState({ selectedTime: timeString });
  };

  handleSubmitSchedule = () => {
    const {
      scheduledDate,
      selectedTime,
      extraInformation,
      checkedList
    } = this.state;
    const {
      scheduleNewEmail,
      sessionId,
      surveyType,
      address,
      remote,
      modules
    } = this.props;

    if (checkedList.length < 1) {
      return message.error('You should add recipients emails');
    }

    if (scheduledDate && selectedTime) {
      const date = moment(`${scheduledDate} ${selectedTime}`);
      return scheduleNewEmail(
        {
          date,
          sessionId,
          surveyType,
          extraInformation,
          recipients: checkedList,
          address,
          remote,
          modules: concatModules({ modules })
        },
        () => {
          if (typeof this.props.backCallback === 'function')
            return this.props.backCallback();
        }
      );
    }
    this.setState({ error: 'Select schedule date and time' });
    return message.error('Select schedule date and time');
  };

  handleAddEmailsClick = () => {
    const { handleAddEmailsClick, type, drawerKey } = this.props;
    switch (type) {
      case 'registration':
        return handleAddEmailsClick('view-invitees', drawerKey);

      case 'reminder':
      case 'surveyLink':
        return handleAddEmailsClick('viewAttendeesList', drawerKey);

      default:
        return null;
    }
  };

  copyRegistrationLink = () => {
    const copyText = document.getElementById('registration-link');
    let range;
    let selection;
    if (document.body.createTextRange) {
      range = document.body.createTextRange();
      range.moveToElementText(copyText);
      range.select();
    } else if (window.getSelection) {
      selection = window.getSelection();
      range = document.createRange();
      range.selectNodeContents(copyText);
      selection.removeAllRanges();
      selection.addRange(range);
    }

    try {
      document.execCommand('copy');
      Swal.fire({
        title: 'Success',
        text: 'Link copied!',
        timer: 2000,
        confirmButtonText: 'Ok',
        ...customSwal
      });
    } catch (err) {
      Swal.fire({
        title: 'Error',
        text: 'Unable to cop the Link',
        timer: 2000,
        confirmButtonText: 'Ok',
        ...customSwal
      });
    }
  };

  render() {
    const {
      successMessage,
      sessionId,
      loading,
      // Boolean
      canAddParticipants,
      // registration
      shortId,

      // template details
      type,
      trainer,
      sessionDate,
      address,
      startTime,
      endTime,
      backCallback,
      isSchedule,
      surveyType,
      course,
      modules,
      remote,
      meetingPlatform,
      meetingLink,
      extraInfo,
      drawerKey,
      endDate,
      defaultSessionForCustomModule
    } = this.props;

    let title = '';
    let paragraph = '';
    let panelTitle = '';
    switch (type) {
      case 'registration':
        title = 'Invite people by email';
        paragraph = `To make things really simple, type the email addresses of the people you would like to invite below and we will send them an email with all the session info and the registration link for you.`;
        panelTitle = 'Select from your invitees list';
        break;

      case 'reminder':
        title = 'Remind participants via email: ';
        paragraph = `Send a session reminder to the participants who confirmed their attendance,
        reminding them with the session date and it's details and surveys links.`;
        panelTitle = 'Select from confirmed emails';
        break;

      case 'surveyLink':
        title = 'Send evaluation link to participants via email: ';
        paragraph = defaultSessionForCustomModule
          ? `Send a evaluation links to the participants who confirmed their attendance.`
          : `Send a evaluation links to the participants who confirmed their attendance,
        reminding them with the session date and it's details.`;
        panelTitle = 'Select from confirmed emails';
        break;

      default:
        break;
    }
    const {
      isEditView,
      extraInformation,
      participantsEmails,
      checkedList,
      indeterminateAll,
      isAllChecked,
      isNewChecked,
      preSurveyLink,
      postSurveyLink,
      error,
      focused
    } = this.state;
    const trainers = getTrainersNamesInString(this.props.trainers);

    return (
      <>
        <div ref={this.myRef} />
        {/* <Header label="Edit Session" type="view" /> */}
        <Wrapper inner={drawerKey && true}>
          <Col w={[4, 12, 8]} dir="column" ai="flex-start">
            <Row inner>
              <Col w={[4, 12, 12]}>
                {isEditView ? (
                  <>
                    <InfoCard
                      mb="7"
                      text="Details such as type, date, location and trainers cannot be edited here. If you would like to make changes to the core session information, please go to Sessions and edit the session directly."
                    />
                    <TextArea
                      label="Enter any other information or special message you would like to share in the email below"
                      placeholder="Type extra information here"
                      handleChange={this.handleExtraInformation}
                      value={extraInformation}
                      mb="7"
                    />
                    <EmailTemplate
                      type={type}
                      trainer={trainer}
                      sessionDate={sessionDate}
                      course={course}
                      modules={modules}
                      address={address}
                      meetingLink={meetingLink}
                      meetingPlatform={meetingPlatform}
                      extraInfo={extraInfo}
                      remote={remote}
                      trainers={trainers}
                      startTime={startTime}
                      endTime={endTime}
                      endDate={endDate}
                      extraInformation={extraInformation}
                      preSurveyLink={preSurveyLink}
                      postSurveyLink={postSurveyLink}
                      confirmLink={`${
                        window.location.host
                      }${REGISTER_URL.replace(':id', shortId)}`}
                      surveyType={surveyType}
                      shortId={shortId}
                      recipients={checkedList}
                    />
                  </>
                ) : (
                  <>
                    {successMessage && <T.H1 mb="7">Session created</T.H1>}
                    {type === 'registration' && (
                      <>
                        {drawerKey ? (
                          <>
                            <T.H1 mb="6" style={{ width: '100%' }}>
                              Send Email Invitation
                            </T.H1>
                            <T.P mb="6">
                              Let's make sure you have people attending your
                              upcoming session!
                            </T.P>
                          </>
                        ) : (
                          <T.P mb="6">
                            You have successfully created a new session. Now
                            let's make sure you have people attending!
                          </T.P>
                        )}

                        <CopyLink
                          big
                          title="Here is the registration link for this session"
                          link={`${window.location.host}${REGISTER_URL.replace(
                            ':id',
                            shortId
                          )}`}
                          mb="6"
                        />
                        <T.P mb="8">
                          Copy the link above to share with people so they can
                          register, access their required course materials and
                          let you know about any special requirements
                        </T.P>
                      </>
                    )}

                    <>
                      <T.P weight={700} mb="4" style={{ width: '100%' }}>
                        Course Name
                      </T.P>
                      <T.P mb={6} style={{ width: '100%' }}>
                        {courseLabelMap[course]}
                      </T.P>

                      <></>
                      <T.P weight={700} mb="4">
                        {title}
                      </T.P>
                      <T.P mb={6}>{paragraph}</T.P>
                    </>
                    <>
                      <SelecetWrapper>
                        {isSchedule && (
                          <div
                            style={{
                              display: 'flex',
                              width: '100%',
                              justifyContent: 'space-between'
                            }}
                          >
                            <DatePicker
                              onChange={this.handleSelectDate}
                              placeholder="Select date"
                              size="large"
                              style={{ width: '60%', marginBottom: '1rem' }}
                            />
                            <TimePicker
                              onChange={this.handleSelectTime}
                              style={{ width: '35%', marginBottom: '1rem' }}
                              format={format}
                              minuteStep="60"
                              size="large"
                            />
                          </div>
                        )}

                        {canAddParticipants ? (
                          <>
                            <Dropdown
                              addNew
                              id="participantsEmails"
                              // get emails string array
                              selected={participantsEmails}
                              placeholder="Type or paste the email addresses here"
                              handleChange={val => this.handleUpdateEmails(val)}
                              style={{ width: '100%' }}
                              size="large"
                              onSearch={this.onTypingEmails}
                              ref={this.selectRef}
                              focused={focused}
                              label="Email addresses"
                            />
                          </>
                        ) : (
                          <>
                            <div style={{ width: '100%' }}>
                              <Collapse
                                items={[
                                  {
                                    title: panelTitle,
                                    content: (
                                      <>
                                        <Checkbox
                                          indeterminate={indeterminateAll}
                                          onChange={this.onCheckAllChange}
                                          checked={isAllChecked}
                                        >
                                          Select all
                                        </Checkbox>
                                        {type === 'registration' && (
                                          <Checkbox
                                            onChange={this.onCheckNewChange}
                                            checked={isNewChecked}
                                          >
                                            Select New
                                          </Checkbox>
                                        )}

                                        <Checkbox.Group
                                          style={{ width: '100%' }}
                                          onChange={this.onChangeCheckbox}
                                          value={checkedList}
                                        >
                                          <AntRow id="selectGroup">
                                            {participantsEmails.map(item => (
                                              <AntCol
                                                span={24}
                                                style={{
                                                  marginBottom: '0.5rem'
                                                }}
                                                key={item.email}
                                              >
                                                <Checkbox value={item.email}>
                                                  <span
                                                    style={{
                                                      width: '80%',
                                                      display: 'inline-flex',
                                                      justifyContent:
                                                        'space-between'
                                                    }}
                                                  >
                                                    {item.email}
                                                    <span>{item.status}</span>
                                                  </span>
                                                </Checkbox>
                                              </AntCol>
                                            ))}
                                          </AntRow>
                                        </Checkbox.Group>

                                        <div
                                          style={{
                                            width: '90%',
                                            borderTop: '1px solid #E9E9E9',
                                            margin: '0.5rem auto'
                                          }}
                                        >
                                          <AddEmailsButton
                                            onClick={this.handleAddEmailsClick}
                                          >
                                            Add new email address(es)
                                          </AddEmailsButton>
                                        </div>
                                      </>
                                    )
                                  }
                                ]}
                              />
                            </div>
                          </>
                        )}
                        {canAddParticipants && (
                          <IconsWrapper>
                            <Tooltip placement="top" title="Copy">
                              <AntButton
                                type="primary"
                                icon="copy"
                                ghost
                                onClick={this.onCopy}
                              />
                            </Tooltip>
                            <InfoPopUp text={text} />
                          </IconsWrapper>
                        )}
                      </SelecetWrapper>
                    </>
                  </>
                )}
              </Col>
            </Row>

            {isEditView ? (
              <Row inner>
                <Col w={[4, 6, 6]}>
                  <Button
                    onClick={() => this.toggleEditView(false)}
                    type="primary"
                    label="Save changes"
                    mt="2"
                    style={{ minWidth: '250px' }}
                  />
                </Col>
              </Row>
            ) : (
              <Row inner mt="7">
                {!defaultSessionForCustomModule && (
                  <Col w={[4, 6, 6]}>
                    <Button
                      onClick={() => this.toggleEditView(true)}
                      type="primary"
                      label="View & edit email"
                      mb="2"
                      style={{ minWidth: '250px' }}
                    />
                  </Col>
                )}
                <Col w={[4, 6, 6]}>
                  <Button
                    onClick={
                      isSchedule
                        ? this.handleSubmitSchedule
                        : this.handleSendEmail
                    }
                    type="secondary"
                    label={isSchedule ? 'Schedule Email' : 'Send Email'}
                    loading={loading}
                    mb="2"
                    style={{ minWidth: '250px' }}
                  />
                </Col>
                {error}
                {/* after creating session immediately */}

                {canAddParticipants && (
                  <Col w={[4, 8, 8]} mt="6">
                    <BackLink
                      onClick={() => {
                        if (typeof backCallback === 'function')
                          return backCallback();
                        return this.props.history.push(
                          `/session-details/${sessionId}`
                        );
                      }}
                    >
                      Skip for now, I'll invite people later
                    </BackLink>
                  </Col>
                )}
              </Row>
            )}
          </Col>
        </Wrapper>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    name: state.auth.name,
    loading: state.loading.sendEmail,
    role: state.auth.role
  };
};

export default connect(mapStateToProps, {
  sendEmailReminder: sendEmailReminderAction,
  scheduleNewEmail: scheduleNewEmailAction
})(EditEmail);
