import * as S from './style';
import * as T from '../Typography';
import MaxWidthContainer from '../MaxWidthContainer';

const WebsiteHeader = ({ title, subtitle, ...props }) => {
  return (
    <S.Wrapper subtitle={subtitle} {...props}>
      {/* desktop view */}
      <MaxWidthContainer bgColor="headerBg">
        <S.HideInTablet>
          <T.H1W color="headerTitle" mb={subtitle && '6'}>
            {title}
          </T.H1W>
          <T.H2W color="headerSubtitle">{subtitle}</T.H2W>
        </S.HideInTablet>
      </MaxWidthContainer>

      {/* mobile view */}
      <S.HideInDesktop>
        <T.H4W color="headerTitle" mb={subtitle && '2'} ta="center">
          {title}
        </T.H4W>
        <T.P color="headerSubtitle" ta="center">
          {subtitle}
        </T.P>
      </S.HideInDesktop>
    </S.Wrapper>
  );
};

export default WebsiteHeader;
