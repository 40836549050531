import { message as antMessage } from 'antd';
import axios from 'axios';
import download from 'downloadjs';
import { useHistory } from 'react-router-dom';

import Layout from '../../Layouts';
import Button from '../../common/Button';
import { Col, Row } from '../../common/Grid';
import * as T from '../../common/Typography';

import { courseLabelMap } from '../../../constants';
import { TRAINER_RESOURCES_AND_ADVICE } from '../../../constants/navigationRoutes';

const CertifiedTrainer = () => {
  const history = useHistory();

  const downloadCertificate = () => {
    const fileName = `module-ttt-${Date.now()}.pdf`;

    axios
      .post(
        `/api/certificate/ttt`,
        {},
        {
          responseType: 'blob'
        }
      )
      .then(res => {
        const content = res.headers['content-type'];
        download(res.data, fileName, content);
      })
      .catch(err => {
        antMessage.error(
          err?.response?.data?.message || 'Internal server error'
        );
      });
  };

  const course = history.location.state?.course;
  const courseName = courseLabelMap[course];

  return (
    <Layout>
      <Row mb="4">
        <Col w={[4, 8, 8]} display="block">
          <T.H1 mb="6" width="100%">
            You are now a certified trainer!
          </T.H1>
        </Col>
      </Row>
      <Row mb="5">
        <Col w={[4, 8, 8]}>
          <T.P mr="1">
            Thank you for your hard work already to become a {courseName}{' '}
            trainer! Please find below a link to download your official{' '}
            {courseName} trainer certificate.
          </T.P>
        </Col>
      </Row>

      {
        // Fetch certificate link
      }
      <Row mb="8">
        <Col w={[4, 8, 8]} display="block">
          <T.Link
            color="secondary"
            underline={false}
            onClick={downloadCertificate}
          >
            Download certificate
          </T.Link>
        </Col>
      </Row>

      <Row mb="6">
        <Col w={[4, 8, 8]}>
          <T.P mr="1">
            On the next pages we will provide you some tips and useful
            information on being a trainer and how to use this tool.
          </T.P>
        </Col>
      </Row>
      <Row mt={7}>
        <Col w={[4, 4, 4]}>
          <Button
            onClick={() => history.push(TRAINER_RESOURCES_AND_ADVICE)}
            type="primary"
            label="Let's get started!"
          />
        </Col>
      </Row>
    </Layout>
  );
};

export default CertifiedTrainer;
