import styled from 'styled-components';

export const Form = styled.div`
  border-radius: 10px;
  width: 100%;
  padding-top: ${({ theme }) => theme.spacings[5]};

  @media (min-width: 678px) {
    width: 65%;
  }
`;

export const EmailsList = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 2rem 0.5rem;
  margin-bottom: 2rem;
  background: ${({ theme }) => theme.colors.offWhite};
  box-shadow: ${({ theme }) => theme.shadows.secondary};
`;

export const InputDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }

  @media (min-width: 768px) {
    width: 90%;
  }
`;

export const EmailInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${({ theme }) => theme.spacings[6]};
  padding: ${({ theme }) => theme.spacings[7]};
  background: ${({ theme }) => theme.colors.lightPrimary};
  border-radius: 12px;
`;

export const InfoTitle = styled.p`
  font-size: 1rem;
  font-weight: 400;
  color: black;
`;

export const SessionInfoTitle = styled.span`
  color: ${({ theme }) => theme.colors.blackSecondary};
  margin-right: 2px;
`;

export const List = styled.ul`
  padding-left: 1.5rem;
  & > * {
    margin-left: 0.5rem;
    font-size: 16px;
  }
`;
