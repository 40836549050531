import axios from 'axios';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';

import { Empty, Modal } from 'antd';

// ROUTES
import { ADD_TRAINER_URL } from '../../../constants/navigationRoutes';

// COMMON COMPONENTS
import Layout from '../../Layouts';
import Button from '../../common/Button';
import { Dropdown } from '../../common/Inputs';
import TrainerList from '../../common/List/TrainerList';
import Toggle from '../../common/Toggle';
import * as T from '../../common/Typography';
import SpinWrapper from './../../common/Spin';
// STYLING
import { courseOptions } from '../../../constants';
import {
  HeaderNumber,
  HeaderSection,
  TrainerCoursesFilterWrapper,
  TrainerMenuWrapper,
  Wrapper
} from './TrainerListPage.style';

const { confirm } = Modal;

class TrainerListPage extends Component {
  state = {
    trainerCount: 0,
    localLeadCount: 0,
    trainers: null,
    localLeads: null,
    loaded: false,
    toggle: 'left',
    showDeleteModal: false,
    selectedCourses: []
  };

  componentDidMount() {
    this.fetchData();
  }

  localLeadFetchData = () => {
    axios
      .get('/api/users/my-trainers')
      .then(response => {
        this.setState({
          trainerCount: response.data.trainerCount,
          trainers: response.data.trainerList,
          loaded: true
        });
      })
      .catch(err => {
        Modal.error({
          title: 'Error',
          content: err.response.data.error
        });
      });
  };

  adminFetchData = () => {
    axios
      .get('/api/users/admin/trainers-and-leads')
      .then(response => {
        this.setState({
          trainerCount: response.data.trainerCount,
          trainers: response.data.trainerList,
          localLeadCount: response.data.localLeadCount,
          localLeads: response.data.localLeadList,
          loaded: true
        });
      })
      .catch(err =>
        Modal.error({
          title: 'Error',
          content: err.response.data.error
        })
      );
  };

  fetchData = () => {
    const { role } = this.props;
    if (role === 'admin') {
      this.adminFetchData();
    } else {
      this.localLeadFetchData();
    }
  };

  clickToggle = direction => {
    this.setState({ toggle: direction });
  };

  deleteUser = async trainerId => {
    const { userId: localLeadId } = this.props;
    try {
      await axios.delete(`/api/local-lead/${localLeadId}/trainer/${trainerId}`);
      this.localLeadFetchData();
    } catch (err) {
      Modal.error({
        title: 'Error',
        content: 'Internal server Error'
      });
    }
  };

  showDeleteConfirm = trainerId => {
    confirm({
      title: 'Are you sure you want to delete this trainer?',
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        this.deleteUser(trainerId);
      }
    });
  };

  handleFilterChange = value => {
    this.setState({
      selectedCourses: value
    });
  };

  getFilteredEntries = () => {
    const { trainers, localLeads, toggle, selectedCourses } = this.state;

    const entries = toggle === 'left' ? trainers : localLeads;

    if (!selectedCourses || selectedCourses.length === 0) {
      return entries;
    }

    return entries.filter(entry => {
      const { coursesCanDeliver } = entry;
      return coursesCanDeliver.some(course => selectedCourses.includes(course));
    });
  };

  render() {
    const { loaded, toggle } = this.state;

    const { role } = this.props;

    const entries = this.getFilteredEntries();

    const count = entries?.length || 0;

    if (!loaded) return <SpinWrapper />;
    return (
      <Layout>
        <Wrapper>
          <T.H1 mb={5}>Trainers & Local Leads</T.H1>
          <T.P mb={5}>
            Here you can view trainers registered on the platform and manage
            their authorised programme(s)
          </T.P>
          <HeaderSection>
            {role === 'admin' && (
              <Toggle
                leftText="Trainers"
                rightText="Local Leads"
                selected={toggle}
                onClick={this.clickToggle}
              />
            )}
            {role === 'localLead' && (
              <Link to={ADD_TRAINER_URL}>
                <Button label="Add Trainer" type="tertiary" width="146px" />
              </Link>
            )}
            <TrainerMenuWrapper>
              <div>
                <T.P weight={700} color="black" mt="5">
                  {toggle === 'left' ? 'Total Trainers:' : 'Total Local Leads:'}
                </T.P>
                <HeaderNumber>{count}</HeaderNumber>
              </div>
              <TrainerCoursesFilterWrapper>
                <T.P color="black">Filter by programme</T.P>
                <Dropdown
                  selected={this.state.selectedCourses}
                  placeholder="All"
                  multi
                  options={courseOptions}
                  name="courses"
                  handleChange={this.handleFilterChange}
                />
              </TrainerCoursesFilterWrapper>
            </TrainerMenuWrapper>
          </HeaderSection>
          <div style={{ paddingTop: '30px' }}>
            {count ? (
              <TrainerList
                dataList={entries}
                role={role}
                deleteUser={this.showDeleteConfirm}
                refetchData={this.fetchData}
              />
            ) : (
              <Empty
                description={
                  toggle === 'left' ? 'No Trainers' : 'No Local Leads'
                }
              />
            )}
          </div>
        </Wrapper>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return { userId: state.auth.userId };
};

export default connect(mapStateToProps)(TrainerListPage);
