import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Icon from '../Icon';

export const MenuDiv = styled.div`
  z-index: 1000;

  position: fixed;
  @media ${({ theme }) => theme.breakpoints.laptop} {
    margin-top: 0;
    position: static;
  }
`;

export const MenuWrapper = styled.div`
  height: 100%;
  left: 0;
  transition: all 1s ease;
  z-index: 2000;
  overflow: auto;
  background-color: ${({ theme }) => theme.colors.mainPrimary};
  min-height: 100vh;
  padding: ${({ theme }) =>
    `${theme.spacings['40']} 0 ${theme.spacings[9]} ${theme.spacings['40']}`};

  width: 100vw;
  display: flex;
  flex-direction: column;
  @media ${({ theme }) => theme.breakpoints.laptop} {
    width: 300px;
  }
`;

export const MainDiv = styled.div`
  margin-top: ${({ theme }) => theme.spacings[2]};
`;

export const MenuItem = styled(({ sub, ...props }) => <Link {...props} />)`
margin-top: ${({ sub }) => (sub ? '0' : '1.5rem')};
margin-bottom: ${({ lastItem }) => (lastItem ? '1.5rem' : '0')};
display: ${({ block }) => (block ? 'block' : 'flex')};
padding: 4px;
align-items: center;
color: ${({ dark, theme }) => (dark ? '#fff' : theme.colors.lightPrimary)};
font-weight: bold;
transition: all 500ms ease;
cursor: pointer;
margin-left: ${({ theme, sub }) => (sub ? theme.spacings[6] : 0)};
i {
  margin-right: 0.5rem;
}
:hover {
  text-indent: 4px;
  /* background: ${({ theme }) => theme.colors.secondary}50; */
  color: ${({ theme }) => theme.colors.white}80;
}
`;

export const GroupMenuTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const MenuIcon = styled(Icon)`
  color: '#F7F7F9';
  cursor: pointer;
`;

export const Logo = styled.img`
  margin: 0 auto;
  margin-left: 10px;
  display: inline-block;
  margin-top: ${({ theme }) => theme.spacings[5]};
`;

export const BottomImg = styled.img`
  margin: auto auto 0 0;
  display: inline-block;
`;

export const CloseIconWrapper = styled.button`
  align-self: flex-end;
  width: fit-content;
  margin-right: ${({ theme }) => theme.spacings[6]};
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
`;
