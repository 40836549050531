import { BasicInput, TextArea } from '../../common/Inputs';

const FaceToFaceQs = ({
  address = {},
  extraInfo,
  onAddressChange,
  onInputChange,
  error,
  extraInfoError,
  disabled
}) => {
  return (
    <>
      <BasicInput
        value={address.addressLine1}
        handleChange={onAddressChange}
        mb="6"
        label="Address Line 1"
        required
        name="addressLine1"
        error={error?.addressLine1}
        disabled={disabled}
      />
      <BasicInput
        value={address.addressLine2}
        handleChange={onAddressChange}
        mb="6"
        label="Address Line 2"
        name="addressLine2"
        error={error?.addressLine2}
        disabled={disabled}
      />
      <BasicInput
        value={address.town}
        handleChange={onAddressChange}
        mb="6"
        label="Town / City"
        required
        name="town"
        error={error?.town}
        disabled={disabled}
      />
      <BasicInput
        value={address.postcode}
        handleChange={onAddressChange}
        mb="6"
        label="Postcode"
        required
        name="postcode"
        error={error?.postcode}
        disabled={disabled}
      />
      <TextArea
        value={extraInfo}
        handleChange={onInputChange}
        mb={6}
        label="Any extra information"
        error={extraInfoError}
        disabled={disabled}
        name="extraInfo"
      />
    </>
  );
};

export default FaceToFaceQs;
