import WebsiteMenu from '../../common/WebsiteMenu';
import Footer from '../../common/Footer';

import * as S from './style';

const Website = ({ children, showNHSEnglandLogo }) => {
  return (
    <S.Wrapper>
      <WebsiteMenu showNHSEnglandLogo={showNHSEnglandLogo} />
      <S.ContentWrapper>{children}</S.ContentWrapper>
      <Footer />
    </S.Wrapper>
  );
};

export default Website;
