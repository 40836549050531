import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import SpinWrapper from '../Spin';
import { userRoles } from '../../../constants';

import authorization from '../../../helpers/authorization';
import * as R from '../../../constants/navigationRoutes';
import { upgradeTttSteps } from '../../../constants';
import { LOGIN_URL, UNAUTHORIZED } from '../../../constants/navigationRoutes';

const PrivateRoute = ({
  loaded,
  Component,
  path,
  exact,
  isAuthenticated,
  allowedRoles,
  role,
  navbar,
  isMobile,
  userId,
  isPrivate,
  publicOnly,
  multipleUsers,
  decideRole,
  nextUpgradeToTttStep,
  ...rest
}) => {
  // const history = useHistory();
  const location = useLocation();

  const signupAsMeccTrainer = location.state?.signupAsMeccTrainer;

  if (!loaded) {
    return <SpinWrapper />;
  }

  if (multipleUsers && !userId) {
    if (decideRole) {
      return (
        <Route
          path={path}
          {...rest}
          render={LinkProps => (
            <Component {...LinkProps} {...rest} role={role} />
          )}
        />
      );
    }

    return <Redirect to={R.DECIDE_ROLE} />;
  }

  if ((publicOnly || decideRole) && userId && !signupAsMeccTrainer) {
    if (role === userRoles.participant) {
      return (
        <Redirect to={location?.state?.redirectTo || R.PARTICIPANT_DASHBOARD} />
      );
    }

    return <Redirect to={location?.state?.redirectTo || R.DASHBOARD_URL} />;
  }

  if (isPrivate) {
    const authorized = authorization(role, allowedRoles);
    if (userId) {
      const shouldWatchTttVideo =
        nextUpgradeToTttStep?.step === upgradeTttSteps.watchTttVideo;
      if (shouldWatchTttVideo && path !== R.HOW_IT_WORKS)
        return <Redirect to={R.HOW_IT_WORKS} />;

      return (
        <Route
          path={path}
          {...rest}
          render={LinkProps =>
            // eslint-disable-next-line no-nested-ternary

            authorized ? (
              <Component {...LinkProps} {...rest} role={role} />
            ) : (
              <Redirect to={UNAUTHORIZED} />
            )
          }
        />
      );
    }

    return <Redirect to={LOGIN_URL} />;
  }

  return (
    <Route
      path={path}
      {...rest}
      render={LinkProps => <Component {...LinkProps} {...rest} role={role} />}
    />
  );
};

const mapStateToProps = state => {
  return {
    isMobile: state.checkBrowserWidth.isMobile,
    userId: state.auth.userId,
    role: state.auth.role,
    loaded: state.auth.loaded,
    credentialId: state.auth.credentialId,
    multipleUsers: state.auth.multipleUsers,
    nextUpgradeToTttStep: state.auth.nextUpgradeToTttStep
  };
};

export default connect(mapStateToProps)(PrivateRoute);
