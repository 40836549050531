import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import * as Sentry from '@sentry/react';
import CookieBot from 'react-cookiebot';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// PLEASE DO NOT PUT ANTD STYLING SHEET HERE AS OVERRIDES EXISTING STYLES

import styled from 'styled-components';
import moment from 'moment';

import { checkAuth } from '../actions/authAction';
import { checkBrowserWidth } from '../actions/checkBrowserWidth';

import ScrollToTop from '../helpers/ScrollToTop';

import { mainTheme, povertyTheme, PovertyGlobalStyles } from '../theme';
import { userRoles } from '../constants';
// PAGES
import CreateSession from './pages/CreateSession';
import Login from './pages/Login';
import UserDashboard from './pages/ParticipantDashboard';
import Dashboard from './pages/Dashboard';
import Home from './pages/LandingPage';
import SignUp, { ParticipantSignUp } from './pages/SignUp';
import UserResults from './pages/UserResults';
import AddTrainer from './pages/AddTrainer';
import Survey from './pages/Survey';
import SurveyDetails from './pages/Survey/SurveyDetails';
import TrainerListPage from './pages/TrainerListPage';
import ViewSessions from './pages/ViewSessions';
import SessionDetails from './pages/SessionDetails';
import EditSession from './pages/SessionDetails/SessionActions/SessionEdit';
import SurveyResults from './pages/SurveyResults';
import ChangePassword from './pages/ChangePassword';
import ParticipantProgress from './pages/ParticipantProgress';
import Certificate from './pages/Certificate';
// import DecideView from './pages/DecideView';
import ThankYouPage from './pages/ThankYouPage';
import ForgetPassword from './pages/ForgetPassword';
import ResetPassword from './pages/ForgetPassword/ResetPassword';
import SessionsFiles from './pages/SessionsFiles';

import SessionRegistration from './pages/SessionRegistration';

import MyProfile from './pages/MyProfile';
import LocalLeadsAndManagersList from './pages/LocalLeadsAndManagersList';
import AboutUs from './pages/LandingPage/AboutUs';
import ConfirmTrainerRemoval from './pages/ConfirmTrainerRemoval';
import PrintSessionDetails from './pages/SessionDetails/PrintSessionDetails';
import NotFound from './pages/ErrorPages/404';
import ServerError from './pages/ErrorPages/500';
import Unauthorized from './pages/ErrorPages/403';
import ParticipantSessions from './pages/ParticipantSessions';
import SessionCreated from './pages/CreateSession/SessionCreated';
import MyAccount from './pages/MyAccount';
import TrainerSessions from './pages/TrainerSessions';
import DecideRole from './pages/DecideRole';
import TrainerAdvice from './pages/TrainerAdvice';
import ChooseCourse from './pages/ChooseCourse';
import Modules from './pages/Modules';
import Module from './pages/Module';
import ArchivedModules from './pages/ArchivedModules';
import TrainerThankYouPage from './pages/TrainerSignupSteps';
import CertifiedTrainer from './pages/TrainerSignupSteps/CertifiedTrainer';
import TrainerResourcesAndAdvice from './pages/TrainerSignupSteps/TrainerResoursesAndAdvice';
import HowItWorks from './pages/TrainerSignupSteps/HowItWorks';
import ChooseCourseForMaterials from './pages/ChooseCourseForMaterials';
import ChooseCourseForSessions from './pages/ChooseCourseForSessions';
import AlcoholStudies from './pages/AlcoholStudies';
import UpdateModule from './pages/UpdateModule';
import AlcoholCatalogue from './pages/AlcoholCatalogue';
import AboutUsPage from './pages/AlcoholStudiesAboutUs';
import ModuleSteps from './pages/ModuleSteps';
import ParticipantAssessment from './pages/ParticipantAssessment';
// COMPONENTS
import PrivateRoute from './common/PrivateRoute';

// ROUTES
import * as R from '../constants/navigationRoutes';

import StoryBook, { Layout } from './pages/StoryBook';
import ParticipantSessionDetails from './pages/ParticipantSessionDetails';
import ParticipantMaterials from './pages/ParticipantMaterials';
import Programmes from './pages/Programmes';
import PovertyProofing from './pages/PovertyProofing';
import PovertyCatalogue from './pages/PovertyCatalogue';
import Tobacco from './pages/Tobacco';
import TobaccoCatalogue from './pages/TobaccoCatalogue';
import Connect5AndMECC from './pages/Connect5AndMECC';

const domainGroupId = process.env.REACT_APP_COOKIEBOT_DOMAIN_ID;

moment.updateLocale('en', {
  week: {
    dow: 1
  }
});

const Wrapper = styled.div`
  min-height: 100vh;
  background-color: ${mainTheme.colors.offWhite};
`;

class App extends Component {
  state = {
    width: window.innerWidth
  };

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentDidMount() {
    const {
      checkAuth: checkAuthActionCreator,
      checkBrowserWidth: checkBrowserWidthActionCreator
    } = this.props;
    const { width } = this.state;
    const isMobile = width <= 700;
    const isDeskTop = width >= 700;

    const data = {
      width,
      isMobile,
      isDeskTop
    };
    checkAuthActionCreator();
    checkBrowserWidthActionCreator(data);
  }

  componentDidUpdate() {
    const { checkBrowserWidth: checkBrowserWidthActionCreator } = this.props;

    const { width } = this.state;
    const isMobile = width <= 700;
    const isDeskTop = width >= 700;

    const data = {
      width,
      isMobile,
      isDeskTop
    };

    checkBrowserWidthActionCreator(data);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    return (
      <Wrapper id="wrapper">
        <ThemeProvider theme={mainTheme}>
          <Router>
            <ScrollToTop />
            <Switch>
              {process.env.NODE_ENV === 'development' && (
                <Route path="/storybook" component={StoryBook} />
              )}
              {process.env.NODE_ENV === 'development' && (
                <Route
                  path="/layout"
                  component={() => (
                    <Layout layout="website">
                      <p>test</p>
                    </Layout>
                  )}
                />
              )}
              <PrivateRoute
                exact
                path={R.CREATE_MODULE}
                Component={UpdateModule}
                allowedRoles={['admin']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.EDIT_MODULE}
                Component={UpdateModule}
                allowedRoles={['admin']}
                isPrivate
              />

              <PrivateRoute
                exact
                path="/print/:sessionId"
                Component={PrintSessionDetails}
                allowedRoles={['trainer', 'admin', 'localLead']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.DECIDE_ROLE}
                Component={DecideRole}
                decideRole
              />
              <PrivateRoute
                exact
                path={R.EVAL_RESULTS}
                Component={SurveyResults}
                allowedRoles={['trainer', 'admin', 'localLead']}
                isPrivate
              />
              <PrivateRoute exact path="/" Component={Home} publicOnly />
              <PrivateRoute exact path={R.PROGRAMMES} Component={Programmes} />
              {/* ALCOHOL PROGRAMME PAGES */}
              <PrivateRoute
                exact
                path={R.ALCOHOL_STUDIES}
                Component={AlcoholStudies}
              />
              <PrivateRoute
                exact
                path={R.ALCOHOL_CATALOGUE_URL}
                Component={AlcoholCatalogue}
              />
              <PrivateRoute
                exact
                path={R.ALCOHOL_STUDIES_ABOUT_US_URL}
                Component={AboutUsPage}
              />
              {/* TOBACCO PROGRAMME PAGES */}
              <PrivateRoute exact path={R.TOBACCO_URL} Component={Tobacco} />
              <PrivateRoute
                exact
                path={R.TOBACCO_CATALOGUE_URL}
                Component={TobaccoCatalogue}
              />
              <PrivateRoute
                exact
                path={R.TOBACCO_MODULE_URL}
                Component={() => <Module tobaccoModule />}
              />
              {/* CONNECT5 AND MECC PAGES */}
              <PrivateRoute
                exact
                path={R.CONNECT5_URL}
                Component={Connect5AndMECC}
              />
              <PrivateRoute
                exact
                path={R.MECC_URL}
                Component={Connect5AndMECC}
              />
              {/* OTHER PAGES */}
              <PrivateRoute
                exact
                path={R.PARTICIPANT_FILLED_SURVEY_SUCCESSFULLY}
                Component={ThankYouPage}
                isPrivate
                allowedRoles={['participant']}
              />
              <PrivateRoute
                exact
                path="/reset-password/:token"
                Component={ResetPassword}
                publicOnly
              />
              <PrivateRoute
                exact
                path={R.TRAINER_RESULTS_URL}
                Component={UserResults}
                allowedRoles={['trainer', 'admin', 'localLead']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.MY_RESULTS_URL}
                Component={UserResults}
                allowedRoles={['trainer', 'admin', 'localLead']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.USER_RESULTS_FOR_SPECIFIC_COURSE}
                Component={ChooseCourse}
                allowedRoles={['trainer', 'admin', 'localLead']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.MODULES_URL}
                Component={Modules}
                allowedRoles={['admin']}
                isPrivate
              />
              <PrivateRoute exact path={R.MODULE_URL} Component={Module} />
              <PrivateRoute
                exact
                path={R.ARCHIVED_MODULES}
                Component={ArchivedModules}
                allowedRoles={['admin']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.MODULE_STEPS_URL}
                Component={ModuleSteps}
                allowedRoles={['participant']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.PARTICIPANT_ASSESSMENT}
                Component={ParticipantAssessment}
                allowedRoles={['participant']}
                isPrivate
              />

              <PrivateRoute
                exact
                path={R.MY_ACCOUNT_URL}
                Component={MyAccount}
                allowedRoles={['trainer', 'localLead', 'participant']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.TRAINER_ADVICE_URL}
                Component={TrainerAdvice}
                allowedRoles={['trainer']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.ALL_RESULTS_URL}
                Component={UserResults}
                allowedRoles={['admin']}
                isPrivate
              />

              <PrivateRoute
                exact
                path={R.GROUP_RESULTS_URL}
                Component={UserResults}
                allowedRoles={['admin', 'localLead']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.CHOOSE_RESULTS_FOR_MATERIALS}
                Component={ChooseCourseForMaterials}
                allowedRoles={['participant']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.CHOOSE_RESULTS_FOR_SESSIONS}
                Component={ChooseCourseForSessions}
                allowedRoles={['participant']}
                isPrivate
              />
              <PrivateRoute
                exact
                path="/add-trainer"
                Component={AddTrainer}
                allowedRoles={['admin', 'localLead', 'trainer']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.DASHBOARD_URL}
                Component={Dashboard}
                allowedRoles={['admin', 'localLead', 'trainer']}
                isPrivate
              />
              <PrivateRoute
                exact
                path="/change-password"
                Component={ChangePassword}
                allowedRoles={['admin', 'localLead', 'trainer']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.TRAINERS_URL}
                Component={TrainerListPage}
                allowedRoles={['admin', 'localLead']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.SESSION_DETAILS_URL}
                Component={SessionDetails}
                allowedRoles={['admin', 'localLead', 'trainer']}
                isPrivate
              />
              {/* <PrivateRoute
              exact
              path={R.DECIDE_VIEW_URL}
              Component={DecideView}
              
              
              allowedRoles={['admin', 'localLead']}
              
              navbar
            />
          */}
              <PrivateRoute
                exact
                path="/create-session"
                Component={CreateSession}
                allowedRoles={['admin', 'localLead', 'trainer']}
                isPrivate
              />

              <PrivateRoute
                exact
                path={R.SESSION_CREATED_URL}
                Component={SessionCreated}
                allowedRoles={['admin', 'localLead', 'trainer']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.SURVEY_QUESTIONS_URL}
                Component={Survey}
                isPrivate
                allowedRoles={'participant'}
              />
              <PrivateRoute
                exact
                path={R.SURVEY_URL}
                Component={SurveyDetails}
              />
              <PrivateRoute
                exact
                path="/session-edit/:id"
                Component={EditSession}
                allowedRoles={['admin', 'localLead', 'trainer']}
                isPrivate
              />

              <PrivateRoute
                path={R.MY_PROFILE_URL}
                Component={MyProfile}
                allowedRoles={['admin', 'localLead', 'trainer']}
                isPrivate
              />

              <PrivateRoute
                path={R.TRAINER_MANAGERS_GROUPS}
                Component={LocalLeadsAndManagersList}
                allowedRoles={['trainer']}
                isPrivate
              />

              <PrivateRoute
                exact
                path={R.LOGIN_URL}
                Component={Login}
                publicOnly
              />

              <PrivateRoute
                exact
                path={R.FORGET_PASSWORD}
                component={ForgetPassword}
                publicOnly
              />

              <PrivateRoute
                exact
                path={R.TRAINER_SIGN_UP_URL}
                component={SignUp}
              />

              <PrivateRoute
                exact
                path={R.TRAINER_TTT_SIGN_UP}
                component={TrainerThankYouPage}
              />
              <PrivateRoute
                exact
                path={R.CERTIFIED_TRAINER}
                component={CertifiedTrainer}
              />
              <PrivateRoute
                exact
                path={R.TRAINER_RESOURCES_AND_ADVICE}
                component={TrainerResourcesAndAdvice}
              />
              <PrivateRoute
                exact
                path={R.HOW_IT_WORKS}
                component={HowItWorks}
              />
              <PrivateRoute
                exact
                path={R.SIGN_UP_URL}
                component={SignUp}
                publicOnly
              />
              <PrivateRoute
                exact
                path={R.PARTICIPANT_SIGN_UP_URL}
                component={ParticipantSignUp}
                publicOnly
              />
              <PrivateRoute
                path={R.PARTICIPANT_SESSION_DETAILS}
                Component={ParticipantSessionDetails}
                allowedRoles={['participant']}
                isPrivate
              />
              <PrivateRoute
                path={R.PARTICIPANT_MATERIALS}
                Component={ParticipantMaterials}
                allowedRoles={['participant']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.PARTICIPANT_LOGIN}
                Component={Login}
                publicOnly
                loginRole={userRoles.participant}
              />
              <PrivateRoute
                exact
                path="/participant-dashboard"
                Component={UserDashboard}
                allowedRoles={['participant']}
                isPrivate
              />

              <PrivateRoute
                exact
                path="/participant/progress"
                Component={ParticipantProgress}
                allowedRoles={['participant']}
                isPrivate
              />
              <PrivateRoute
                exact
                path="/certificate/:sessionId"
                Component={Certificate}
                isPrivate
              />

              <PrivateRoute
                exact
                path={R.ALL_SESSIONS_URL}
                Component={ViewSessions}
                allowedRoles={['trainer', 'localLead', 'admin']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.MY_SESSIONS_URL}
                Component={ViewSessions}
                allowedRoles={['trainer', 'localLead', 'admin']}
              />

              <PrivateRoute
                exact
                path={R.TRAINER_SESSIONS_URL}
                Component={ViewSessions}
                allowedRoles={['trainer', 'localLead', 'admin']}
                isPrivate
              />

              <PrivateRoute
                exact
                path={R.GROUP_SESSIONS_URL}
                Component={ViewSessions}
                allowedRoles={['localLead', 'admin']}
                isPrivate
              />

              <PrivateRoute
                exact
                path="/sessions-files"
                Component={SessionsFiles}
                allowedRoles={['participant']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.PARTICIPANT_SESSIONS_LIST}
                Component={ParticipantSessions}
                allowedRoles={['participant']}
                isPrivate
              />

              <PrivateRoute
                exact
                path={R.REGISTER_URL}
                Component={SessionRegistration}
              />

              <PrivateRoute
                publicOnly
                exact
                path={R.TRAINER_PUBLIC_SESSIONS}
                Component={TrainerSessions}
              />

              <PrivateRoute
                publicOnly
                exact
                path={R.REMOVE_TRAINER_FROM_GROUP}
                Component={ConfirmTrainerRemoval}
              />

              <Route exact path={R.ABOUT_URL} component={AboutUs} />

              {/* When this start to cause conflicts if we have multiple themes
              and Order becomes important, we can have adjacent theme providers
              instead of outer & inner ones */}
              <ThemeProvider theme={povertyTheme}>
                <PovertyGlobalStyles />
                {/* ALL POVERTY ROUTES */}
                <Switch>
                  <PrivateRoute
                    exact
                    path={R.POVERTY_PROOFING_URL}
                    Component={PovertyProofing}
                  />
                  <PrivateRoute
                    exact
                    path={R.POVERTY_CATALOGUE_URL}
                    Component={PovertyCatalogue}
                  />
                  <PrivateRoute
                    exact
                    path={R.POVERTY_MODULE_URL}
                    Component={() => <Module povertyProofingModule />}
                  />
                </Switch>
              </ThemeProvider>

              <Route path="/404err" render={() => <NotFound />} />
              <Route path="/500err" render={() => <ServerError />} />
              <Route path="/unauthorized" render={() => <Unauthorized />} />
              <Route render={() => <NotFound />} />
            </Switch>
          </Router>
          {process.NODE_ENV === 'production' && (
            <CookieBot domainGroupId={domainGroupId} />
          )}
        </ThemeProvider>
      </Wrapper>
    );
  }
}

export default connect(null, {
  checkAuth,
  checkBrowserWidth
})(Sentry.withProfiler(App));
