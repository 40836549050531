import { useHistory } from 'react-router-dom';

import Button from '../../common/Button';
import { Col, Row } from '../../common/Grid';
import * as T from '../../common/Typography';
import Layout from '../../Layouts';
import {
  CHOOSE_RESULTS_FOR_SESSIONS,
  CHOOSE_RESULTS_FOR_MATERIALS
} from '../../../constants/navigationRoutes';

const ThankYouPage = () => {
  const history = useHistory();
  return (
    <Layout>
      <Row>
        <Col w={[4, 12, 8]}>
          <T.H1 mb="5">Thank you!</T.H1>
          <T.P mb="6">
            Thank you for taking the time to complete this evaluation. You can
            now access your course and any course materials by clicking the
            buttons below.
          </T.P>
          <Row>
            <Col w={[4, 12, 6]}>
              <Button
                type="primary"
                label="My course"
                mb={3}
                height="53px"
                onClick={() => history.push(CHOOSE_RESULTS_FOR_SESSIONS)}
              />
              <Button
                type="secondary"
                label="Materials"
                mb={3}
                height="53px"
                onClick={() => history.push(CHOOSE_RESULTS_FOR_MATERIALS)}
              />
              {/* <Button
                type="tertiary"
                label="Insights"
                height="53px"
                onClick={() => history.push(USER_INSIGHTS)}
              /> */}
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  );
};

export default ThankYouPage;
