import React, { Component } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
// import { Icon } from 'antd';

import { Row, Col } from '../../../common/Grid';
import * as T from '../../../common/Typography';
import CopyLink from '../../../common/CopyLink';
import Icon from '../../../common/Icon';

import { SurveyLinkInfo, FeedbackAction } from './SessionSurveys.Style';

import { SessionTopDetailsWrapper } from '../SessionDetails.Style';

import { EVAL_RESULTS } from '../../../../constants/navigationRoutes';

import {
  surveyTypesNew,
  modules as modulesConstants
} from '../../../../constants/index';

import 'moment-timezone';
import { customSwal } from '../../../../theme';
import { courses } from '../../../../constants';

const getSurveyType = (modules = [], preSurvey, course) => {
  if (course === courses.MECC && preSurvey) {
    if (
      modules?.includes(modulesConstants.TRAIN_THE_TRAINER) &&
      !modules?.includes(modulesConstants.MODULE_1)
    ) {
      return surveyTypesNew.PRE_TRAIN_THE_TRAINER;
    }
  }

  if (preSurvey) {
    return surveyTypesNew.PRE_COURSE;
  }

  if (modules?.includes(modulesConstants.TRAIN_THE_TRAINER)) {
    return surveyTypesNew.POST_TRAIN_THE_TRAINER;
  } else {
    return surveyTypesNew.POST;
  }
};
class SurveyContent extends Component {
  // Fire Info pop up
  onInfoClick = () => {
    Swal.fire({
      title: 'Info',
      text:
        'Please copy and send the evaluation link to all participants. You will see the evaluation results for your session as soon as they are being submitted',
      confirmButtonText: 'Ok',
      ...customSwal
    });
  };

  render() {
    const { onInfoClick } = this;

    const {
      surveyURL,
      id,
      sessionDetails,
      handleDrawerOpen,
      preSurvey
    } = this.props;

    const confirmedAttendeesNumber =
      sessionDetails &&
      sessionDetails.participantsEmails.filter(
        ({ status }) => status === 'confirmed'
      ).length;
    const surveyType = getSurveyType(
      sessionDetails?.modules,
      preSurvey,
      sessionDetails.course
    );

    const responseCount = sessionDetails?.responses?.filter(
      e => e.surveyType === surveyType
    ).length;
    return (
      <SessionTopDetailsWrapper>
        <Row mb="5" mt="4">
          <Col w={[4, 12, 12]}>
            <T.P>
              To share the surveys for this session please share the link with
              your attendees. You can send it directly via email clicking on
              "Email surveys to attendees" or schedule emails using the
              "Schedule emails" option.
            </T.P>
          </Col>
        </Row>
        <Row mb="2">
          <Col w={[4, 12, 12]} ai="center">
            <T.P weight={700} mr="2">
              {' '}
              {preSurvey ? 'Pre-Course Evaluation' : 'Post-Session Evaluation'}
            </T.P>
            <SurveyLinkInfo onClick={onInfoClick}>
              <Icon icon="info" height="16" width="16" color="primary" />
            </SurveyLinkInfo>
          </Col>
        </Row>

        <CopyLink link={surveyURL} mb="2" />
        <Row mb="6">
          <Col w={[4, 12, 12]} ai="center">
            <T.P>
              Responses: <b>{responseCount}</b> out of{' '}
              <b>{confirmedAttendeesNumber}</b> attendees
            </T.P>
          </Col>
        </Row>

        <FeedbackAction
          as="div"
          to=""
          onClick={handleDrawerOpen}
          data-key="sendSurveyLinkEmail"
          data-survey-type={surveyType}
          // data-survey-type={preSurvey ? 'pre-course' : 'post'}
          style={{ cursor: 'pointer' }}
        >
          <T.P ml="3">Email evaluation to attendees</T.P>
          <Icon
            icon="chevron"
            direction="right"
            color="primary"
            width="16"
            height="16"
          />
        </FeedbackAction>

        {!sessionDetails?.defaultSessionForCustomModule && (
          <FeedbackAction
            as="div"
            to=""
            onClick={handleDrawerOpen}
            data-key="scheduleTable"
            data-survey-type={surveyType}
            sessionId={id}
            style={{ cursor: 'pointer' }}
          >
            <T.P ml="3">Schedule emails to attendees</T.P>
            <Icon
              icon="chevron"
              direction="right"
              color="primary"
              width="16"
              height="16"
            />
          </FeedbackAction>
        )}
        <FeedbackAction
          to={{
            pathname: EVAL_RESULTS.replace(':id', id).replace(
              ':surveyType',
              surveyType
            ),
            state: {
              course: sessionDetails.course
            }
          }}
        >
          <T.P ml="3">View evaluation results</T.P>
          <Icon
            icon="chevron"
            direction="right"
            color="primary"
            width="16"
            height="16"
          />
        </FeedbackAction>
      </SessionTopDetailsWrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    sessionDetails: state.sessions.sessionDetails,
    loading: state.session.loading
  };
};
export default connect(mapStateToProps, {})(SurveyContent);
