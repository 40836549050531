import { useEffect, useReducer, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import { Select } from 'antd';
import { fetchCustomModules } from '../../../../actions/customModulesActions';

import {
  BasicInput,
  DatePicker,
  Dropdown,
  TimePicker,
  Radio
} from '../../../common/Inputs';

import Button from '../../../common/Button';

import Layout from '../../../Layouts';
import * as T from '../../../common/Typography';
import { Form } from '../../CreateSession/CreateSession.style';

import {
  moduleOptions,
  coursesCanDeliverOptions
} from '../../../../constants/sessionData';
import { userRoles, courses, courseOptions } from '../../../../constants';

import { fetchAllTrainers } from '../../../../actions/trainerAction';
import {
  fetchLocalLeads,
  fetchLocalLeadTrainersGroup
} from '../../../../actions/users';

import {
  fetchSessionDetails,
  sessionUpdateAction
} from '../../../../actions/groupSessionsAction';

import validate from '../../../../validation/schemas/createSession';

import FaceToFaceQs from './../../CreateSession/FaceToFaceQs';
import OnlineQs from './../../CreateSession/OnlineQs';
import { isCustomCourse } from '../../../../helpers';

const { Option } = Select;

const children = [];
for (let i = 10; i < 36; i += 1) {
  children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}

const stateReducer = (state, newState) => {
  return { ...state, ...newState };
};

const initFormState = {
  date: '',
  endDate: '',
  startTime: '',
  endTime: '',
  sessionType: '',
  course: '',
  modules: [],
  capacity: null,
  partnerTrainer1: { key: '', label: '' },
  partnerTrainer2: { key: '', label: '' },
  address: {
    addressLine1: '',
    addressLine2: '',
    town: '',
    postcode: ''
  },
  online: {
    platform: '',
    link: ''
  },
  extraInfo: '',
  contactEmail: '',
  validationErrors: {},
  submitAttempt: false
};

const EditSession = ({
  id,
  role,
  localLeadTrainersGroup,
  coursesCanDeliver,
  leadsAndTrainers,
  loading,
  createdSession,
  fetchAllTrainers,
  sessionUpdateAction,
  fetchLocalLeads,
  fetchLocalLeadTrainersGroup,
  addSessionSuccess,
  fetchSessionDetails,
  sessionDetails,
  fetchCustomModules,
  modules: customModules
}) => {
  const [formState, setFormState] = useReducer(stateReducer, initFormState);
  const [decidedModuleOptions, setDecidedModulesOptions] = useState([]);
  const { id: sessionId } = useParams();
  const history = useHistory();

  const {
    date,
    endDate,
    startTime,
    endTime,
    course,
    modules,
    capacity,
    partnerTrainer1,
    contactEmail,
    partnerTrainer2,
    address,
    online,
    extraInfo,
    sessionType,
    submitAttempt,
    validationErrors
  } = formState;

  useEffect(() => {
    if (sessionDetails._id) {
      setFormState({
        date: sessionDetails.date,
        endDate: sessionDetails.endDate,
        startTime: sessionDetails.startTime,
        endTime: sessionDetails.endTime,
        course: sessionDetails.course,
        sessionType: sessionDetails.remote ? 'online' : 'faceToFace',
        modules: sessionDetails.modules,
        capacity: sessionDetails.capacity,
        partnerTrainer1: {
          key: sessionDetails?.trainers[0]?._id,
          label: sessionDetails?.trainers[0]?.name
        },
        partnerTrainer2: {
          key: sessionDetails?.trainers[1]?._id,
          label: sessionDetails?.trainers[1]?.name
        },
        address: {
          addressLine1: sessionDetails?.address?.addressLine1 || '',
          addressLine2: sessionDetails?.address?.addressLine2 || '',
          town: sessionDetails?.address?.town || '',
          postcode: sessionDetails?.address?.postcode || ''
        },
        online: {
          platform: sessionDetails.meetingPlatform,
          link: sessionDetails.meetingLink
        },
        extraInfo: sessionDetails.extraInfo,
        contactEmail: sessionDetails.contactEmail,
        validationErrors: {},
        submitAttempt: false
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sessionDetails._id,
    sessionDetails?.address?.addressLine1,
    sessionDetails?.address?.addressLine2,
    sessionDetails?.address?.postcode,
    sessionDetails?.address?.town,
    sessionDetails.capacity,
    sessionDetails.contactEmail,
    sessionDetails.date,
    sessionDetails.endDate,
    sessionDetails.endTime,
    sessionDetails.extraInfo,
    sessionDetails.meetingLink,
    sessionDetails.meetingPlatform,
    sessionDetails.modules,
    sessionDetails.remote,
    sessionDetails.startTime,
    sessionDetails?.trainers
  ]);
  const onSessionTypeChange = (val, name) => {
    if (val === 'online' && sessionType === 'faceToFace') {
      // clear address
      return setFormState({
        ...formState,
        [name]: val,
        address: initFormState.address
      });
    } else if (val === 'faceToFace' && sessionType === 'online') {
      // clear online
      return setFormState({
        ...formState,
        [name]: val,
        online: initFormState.online
      });
    }
    return setFormState({ ...formState, [name]: val });
  };

  const onInputChange = (val, name) => {
    if (name === 'sessionType') {
    }
    setFormState({ ...formState, [name]: val });
  };

  const onDropdownChange = (val, option, name) => {
    if (!val || !option) return setFormState({ ...formState, [name]: val });
    if (['partnerTrainer1', 'partnerTrainer2'].includes(name)) {
      const { props } = option;
      return setFormState({
        ...formState,
        [name]: { key: val, label: props?.name }
      });
    }

    if (name === 'modules' && course === courses.MECC) {
      return setFormState({ ...formState, [name]: [val] });
    }
    if (name === 'course') {
      return setFormState({ ...formState, [name]: val, modules: [] });
    }

    return setFormState({ ...formState, [name]: val });
  };

  const onAddressChange = (val, field) => {
    const { address } = formState;
    setFormState({ ...formState, address: { ...address, [field]: val } });
  };

  const onOnlineChange = (val, field, dropdownName) => {
    const name = dropdownName === 'platform' ? dropdownName : field;
    const { online } = formState;
    setFormState({
      ...formState,
      online: { ...online, [name]: val }
    });
  };

  const validateForm = () => {
    try {
      validate({
        date,
        endDate,
        startTime,
        endTime,
        sessionType,
        course,
        modules:
          course === 'MECC' && role !== 'admin'
            ? [moduleOptions[0].value]
            : modules,
        capacity,
        partnerTrainer: partnerTrainer1,
        address,
        online,
        extraInfo,
        contactEmail
      });

      setFormState({ validationErrors: {} });

      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setFormState({ validationErrors: error.inner });
      }
      return false;
    }
  };

  useEffect(() => {
    if (submitAttempt) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    date,
    endDate,
    startTime,
    endTime,
    sessionType,
    modules,
    capacity,
    partnerTrainer1,
    address,
    online,
    extraInfo,
    contactEmail
  ]);

  const onFormSubmit = e => {
    e.preventDefault();
    setFormState({ submitAttempt: true });

    const isValid = validateForm();
    if (isValid) {
      handleCreateSession();
    }
  };

  const handleCreateSession = () => {
    setFormState({ loading: true });

    sessionUpdateAction(
      {
        date,
        endDate,
        startTime,
        endTime,
        remote: sessionType === 'online',
        course,
        modules:
          course === 'MECC' && role !== 'admin'
            ? [moduleOptions[0].value]
            : modules,
        partnerTrainer1: partnerTrainer1?.key,
        partnerTrainer2: partnerTrainer2?.key,
        address: sessionType === 'online' ? null : address,
        meetingPlatform: online.platform,
        meetingLink: online.link,
        contactEmail,
        extraInfo,
        capacity
      },
      sessionId,
      history
    );
  };

  const renderTrainersList = partnerTrainer => {
    if (role && role === 'localLead') {
      const nonDuplicatedTrainersAndLocalLeads = [];
      const trainersAndLocalLeads = [
        ...localLeadTrainersGroup,
        ...leadsAndTrainers
      ];
      trainersAndLocalLeads.forEach(e => {
        if (
          nonDuplicatedTrainersAndLocalLeads.find(prev => prev._id === e._id)
        ) {
          return;
        }
        nonDuplicatedTrainersAndLocalLeads.push(e);
      });

      const sortedNonDuplicatedTrainersAndLocalLeads = nonDuplicatedTrainersAndLocalLeads.sort(
        (a, b) => a.lowerName.localeCompare(b.lowerName)
      );

      return (
        sortedNonDuplicatedTrainersAndLocalLeads
          .filter(({ _id }) =>
            partnerTrainer?.key ? _id !== partnerTrainer.key : true
          )
          // sort the the array to get the logged in user at the first of the array
          .sort(({ _id }) => (_id === id ? -1 : 1))
          .map(({ name, _id }) => {
            return (
              <Option
                key={_id}
                value={_id}
                name={name}
                style={{ textTransform: 'capitalize' }}
              >
                {`${name[0].toUpperCase()}${name.slice(1)}`}{' '}
                {_id === id && '(Me)'}
              </Option>
            );
          })
      );
    } else if (leadsAndTrainers) {
      const sortedLeadsAndTrainers = leadsAndTrainers.sort((a, b) =>
        a.lowerName.localeCompare(b.lowerName)
      );

      return sortedLeadsAndTrainers
        .filter(({ _id }) =>
          partnerTrainer?.key ? _id !== partnerTrainer.key : true
        )
        .map(({ name, _id }) => (
          <Option
            key={_id}
            value={_id}
            name={name}
            style={{ textTransform: 'capitalize' }}
          >
            {`${name[0].toUpperCase()}${name.slice(1)}`}
          </Option>
        ));
    }
    return null;
  };

  const showModuleQuestion = () => {
    if (
      (course === 'MECC' && (role === 'admin' || role === 'localLead')) ||
      course === 'C5' ||
      isCustomCourse(course)
    ) {
      return true;
    }
  };

  const decideModuleOptions = () => {
    if (isCustomCourse(course)) {
      return setDecidedModulesOptions(
        customModules.map(({ title, _id }) => ({
          value: _id,
          label: title
        }))
      );
    }

    if (role === 'admin' || role === 'localLead') {
      if (course === 'MECC') {
        setDecidedModulesOptions([moduleOptions[0], moduleOptions[3]]);
      } else {
        setDecidedModulesOptions(moduleOptions);
      }
    } else {
      setDecidedModulesOptions(moduleOptions.slice(0, 3));
    }
  };

  useEffect(() => {
    decideModuleOptions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course]);

  useEffect(() => {
    fetchCustomModules({ liveOnly: true });

    if (role === 'localLead') {
      fetchLocalLeadTrainersGroup(id);
      fetchLocalLeads();
    } else {
      fetchAllTrainers();
      fetchLocalLeads();
      fetchSessionDetails(sessionId, history);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <T.H1 mb="7">Edit Session</T.H1>
      <Form onSubmit={onFormSubmit}>
        <DatePicker
          id="DatePicker"
          onDateChange={onInputChange}
          name="date"
          size="large"
          value={date}
          style={{ width: '100%' }}
          required
          label="Start Date"
          mb="6"
          error={validationErrors.date}
        />
        <DatePicker
          id="DatePicker"
          onDateChange={onInputChange}
          name="endDate"
          size="large"
          value={endDate}
          style={{ width: '100%' }}
          required
          label="End Date"
          error={validationErrors.endDate}
          mb="6"
        />
        <TimePicker
          onTimeChange={onInputChange}
          value={startTime}
          label="Start time"
          name="startTime"
          mb="6"
          error={validationErrors.startTime}
          popOver={{
            text:
              'If your session is running across multiple dates, select the general daily start and end time and then add further details in the extra information section below'
          }}
        />
        <TimePicker
          onTimeChange={onInputChange}
          value={endTime}
          label="End time"
          name="endTime"
          error={validationErrors.endTime}
          mb="6"
        />
        {/* PUT IN RADIO QUESTION HERE */}
        {/* ADDRESS AND ONLINE FIELDS HERE */}

        <Radio
          selected
          label="How are you delivering this session?"
          required
          name="sessionType"
          error={validationErrors.sessionType}
          handleChange={onSessionTypeChange}
          options={[
            { label: 'Remote / online', value: 'online' },
            { label: 'Face to Face', value: 'faceToFace' }
          ]}
          value={sessionType}
        />

        {sessionType === 'faceToFace' && (
          <FaceToFaceQs
            address={address}
            extraInfo={extraInfo}
            onAddressChange={onAddressChange}
            onInputChange={onInputChange}
            error={validationErrors.address}
            extraInfoError={validationErrors.extraInfo}
          />
        )}

        {sessionType === 'online' && (
          <OnlineQs
            online={online}
            extraInfo={extraInfo}
            onOnlineChange={onOnlineChange}
            onInputChange={onInputChange}
            error={validationErrors.online}
            extraInfoError={validationErrors.extraInfo}
          />
        )}

        <Dropdown
          selected={course}
          m={{ mb: '6' }}
          label="Which course are you delivering?"
          placeholder="Select..."
          required
          options={
            [userRoles.trainer, userRoles.localLead].includes(role) &&
            coursesCanDeliver
              ? coursesCanDeliverOptions(coursesCanDeliver)
              : courseOptions
          }
          name="course"
          handleChange={onDropdownChange}
          error={validationErrors.course}
        />

        {showModuleQuestion() && (
          <Dropdown
            selected={modules}
            m={{ mb: '6' }}
            label="Which modules are you delivering in this session?"
            placeholder="Select as many as you like"
            required={!(course === 'MECC' && role !== 'admin')}
            multi={course !== courses.MECC}
            options={decidedModuleOptions}
            name="modules"
            handleChange={onDropdownChange}
            error={validationErrors.modules}
          />
        )}

        <BasicInput
          value={capacity}
          handleChange={onInputChange}
          mb="6"
          label="Maximum capacity"
          required
          name="capacity"
          type="number"
          popOver={{
            text:
              'This is the total amount of people allowed to attend the session'
          }}
          error={validationErrors.capacity}
        />

        <Dropdown
          selected={partnerTrainer1.label}
          m={{ mb: '6' }}
          label="Trainer"
          placeholder="Select"
          required
          options={renderTrainersList(partnerTrainer2)}
          customOptions
          name="partnerTrainer1"
          handleChange={onDropdownChange}
          error={validationErrors.partnerTrainer?.key}
        />

        <BasicInput
          value={contactEmail}
          handleChange={onInputChange}
          mb="6"
          label="Trainer contact email"
          required
          name="contactEmail"
          popOver={{
            text:
              'This is the email address that will be shared with those who register to attend this session'
          }}
          error={validationErrors.contactEmail}
        />

        <Dropdown
          selected={partnerTrainer2?.label}
          m={{ mb: '6' }}
          label="Second / partner trainer"
          placeholder="Select"
          options={renderTrainersList(partnerTrainer1)}
          customOptions
          name="partnerTrainer2"
          handleChange={onDropdownChange}
          allowClear
        />

        <Button
          onClick={onFormSubmit}
          type="primary"
          label="Submit"
          height="40px"
          width="100%"
          loading={loading}
          disabled={loading}
        />
      </Form>
    </Layout>
  );
};

const mapStateToProps = state => {
  const { trainers } = state.trainers;
  const localLeads = state.fetchedData.localLeadsList;

  const leadsAndTrainers = [...localLeads, ...trainers];
  const sessionDetails = state.sessions.sessionDetails;
  if (isCustomCourse(sessionDetails?.course)) {
    sessionDetails.modules = sessionDetails.customModules?.map(
      ({ _id }) => _id
    );
  }
  const coursesCanDeliver = state?.auth?.coursesCanDeliver || [];

  return {
    id: state.auth.userId,
    role: state.auth.role,
    localLeadTrainersGroup: state.fetchedData.localLeadGroup,
    coursesCanDeliver,
    leadsAndTrainers,
    loading: state.session.sessionEditLoading,
    addSessionSuccess: state.session.addSessionSuccess,
    sessionDetails: sessionDetails,
    modules: state.modules.modules
  };
};

export default connect(mapStateToProps, {
  fetchAllTrainers,
  fetchLocalLeads,
  fetchSessionDetails,
  sessionUpdateAction,
  fetchLocalLeadTrainersGroup,
  fetchCustomModules
})(EditSession);
