import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { MODULES_URL } from '../../../constants/navigationRoutes';
import Layout from '../../Layouts';
import { Col, Row } from '../../common/Grid';
import { Dropdown } from '../../common/Inputs';
import * as T from '../../common/Typography';

import { setCourseContext } from '../../../actions/courseActions';
import { courseOptions, customCourses } from '../../../constants';
import { isCustomCourse } from '../../../helpers/index';
import ModulesTable from './ModulesTable';

const Modules = ({ setCourseContext }) => {
  const location = useLocation();
  const _course = new URLSearchParams(location.search).get('course');
  const [selected, setSelected] = useState(_course);
  const history = useHistory();

  const onSelectProgramme = value => {
    history.replace(`${MODULES_URL}?course=${value}`);
    setSelected(value);
  };

  useEffect(() => {
    if (!!selected) {
      setCourseContext({ course: selected });
    }
  }, [selected, setCourseContext]);

  return (
    <Layout withGraph>
      <T.H1 mb={7}>Modules</T.H1>
      <Row mb={4}>
        <Col w={[4, 8, 5]}>
          <T.P weight={600} color="darkGray">
            Please select the programme you would like to view
          </T.P>
        </Col>
      </Row>
      <Row mb={4}>
        <Col w={[4, 8, 5]}>
          <Dropdown
            selected={selected}
            placeholder="Select..."
            required
            options={courseOptions.filter(option =>
              customCourses.includes(option.value)
            )}
            name="course"
            handleChange={value => onSelectProgramme(value)}
          />
        </Col>
      </Row>
      {isCustomCourse(selected) && <ModulesTable />}
    </Layout>
  );
};

export default connect(() => {}, {
  setCourseContext
})(Modules);
