import General from './General';
import Website from './Website';

const Layout = ({ layout, withGraph, ...props }) => {
  switch (layout) {
    case 'website':
      return <Website {...props} />;
    default:
      return <General withGraph={withGraph} {...props} />;
  }
};

export default Layout;
