import { useReducer, useEffect } from 'react';

import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Layout from '../../Layouts';
import * as T from '../../common/Typography';
import * as S from '../SignUp/SignUp.style';
import { Row, Col } from '../../common/Grid';
import { BasicInput } from '../../common/Inputs';
import Button from '../../common/Button';
import validate from '../../../validation/schemas/addTrainer';

import { addTrainerToGroup } from '../../../actions/users';

const initialState = {
  email: '',
  name: '',
  submitAttempt: false,
  validationErrors: {}
};

const stateReducer = (state, newState) => {
  return { ...state, ...newState };
};

const cleanEmail = email => email.toLowerCase().trim();

const AddTrainer = ({ httpError, loading, addTrainerToGroup }) => {
  const [state, setState] = useReducer(stateReducer, initialState);
  const history = useHistory();

  const { name, email, submitAttempt, validationErrors } = state;

  const capitalize = string => {
    // capitalize each word in a string
    return string.replace(
      /\w\S*/g,
      txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
  };

  const validateForm = () => {
    try {
      validate({
        email: cleanEmail(email),
        name
      });

      setState({ validationErrors: {} });

      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({ validationErrors: error.inner });
      }
      return false;
    }
  };

  useEffect(() => {
    if (submitAttempt) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, email]);

  const handleSignup = e => {
    setState({ loading: true });

    addTrainerToGroup({
      email: cleanEmail(email),
      name: capitalize(name),
      history
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setState({ submitAttempt: true });

    const isValid = validateForm();
    if (isValid) {
      handleSignup();
    }
  };

  return (
    <Layout>
      <T.H1 mb="7">Adding a trainer to your group</T.H1>
      <Row>
        <Col w={[4, 12, 8]}>
          <>
            <S.Form>
              <T.P weight={400} mb="5">
                Enter your trainer's email to add them, a registration
                invitation will be sent if the trainer doesn't have account.
              </T.P>

              <Row inner>
                <Col w={[4, 12, 10]}>
                  <BasicInput
                    value={name}
                    handleChange={value => setState({ name: value })}
                    placeholder="Full name"
                    mb={3}
                    required
                    error={validationErrors.name}
                  />

                  <BasicInput
                    value={email}
                    handleChange={value => setState({ email: value })}
                    placeholder="Email"
                    mb={3}
                    required
                    error={
                      validationErrors.email ||
                      (httpError?.response?.status === 409 &&
                        httpError?.response?.data?.error)
                    }
                  />
                </Col>
              </Row>

              <Row inner>
                <Col w={[4, 6, 6]}>
                  <Button
                    onClick={handleSubmit}
                    type="primary"
                    label="Add Trainer"
                    loading={loading}
                  />
                </Col>
              </Row>
            </S.Form>
          </>
        </Col>
      </Row>
    </Layout>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  httpError: state.auth.error,
  loading: state.loading.signupLoading
});

export default connect(mapStateToProps, {
  addTrainerToGroup
})(AddTrainer);
