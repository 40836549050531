import { Dropdown } from '../../../common/Inputs';

const GroupedDropdown = ({ question, setQuestion, onBlur, disabled }) => {
  const { _id: questionId, options, answered, answer } = question;

  const onChange = (_, value) => {
    const answer = value?.key?.replace('_', ' - ');

    setQuestion({
      _id: questionId,
      answer,
      answered: !!answer
    });

    if (answered) onBlur(answer);
  };

  return (
    <Dropdown
      selected={answer}
      groupedOptions
      options={options}
      handleChange={onChange}
      disabled={disabled}
    />
  );
};

export default GroupedDropdown;
