import { fields, createSchema, validate as _validate } from '..';

const { arrayOfString, organisationOther, requiredText } = fields;

const trainerSchema = createSchema({
  localAuthorities: arrayOfString,
  organisationOther,
  jobRole: requiredText,
  team: requiredText,
  department: requiredText
});

const validate = data => {
  return _validate(trainerSchema, data);
};

export default validate;
