import moment from 'moment';
import useDownloadCertificate from '../../../hooks/useDownloadCertificate';

import * as T from '../../common/Typography';
import Layout from '../../Layouts';
import Button from '../../common/Button';
import Icon from '../../common/Icon';
import * as R from '../../../constants/navigationRoutes';

import * as S from './style';
import { courses, surveyTypesNew } from '../../../constants';

const CompletedModule = ({ module, email, showPostCourse }) => {
  const { downloading, handleDownload } = useDownloadCertificate();

  const hasCourseMaterials =
    module?.preCourseMaterials?.length > 0 ||
    module?.postCourseMaterials?.length > 0;

  const handleClick = () => {
    handleDownload({
      module: module._id,
      email: email,
      sessionId: module.participantAttendance.session,
      date: module?.participantAttendance?.completedAt
    });
  };

  const povertyProofingModule = module.type === courses.POVERTY_PROOFING;

  return (
    <Layout withGraph>
      <S.Wrapper>
        <T.H1 mb={3}>{module?.title}</T.H1>
        <T.Link
          external
          underline={true}
          color="mainPrimary"
          to={(povertyProofingModule
            ? R.POVERTY_MODULE_URL
            : R.MODULE_URL
          ).replace(':id', module._id)}
        >
          <S.IconWrapper mb={7}>
            <Icon icon="openInNew" mr="1" color="mainPrimary" />
            <T.P weight={600} small color="mainPrimary">
              View module details
            </T.P>
          </S.IconWrapper>
        </T.Link>
        <T.P color="gray" mb={4}>
          Congratulations, you successfully completed this module on{' '}
          <T.P weight={600} d="inline">
            {moment(module?.participantAttendance?.completedAt).format(
              'DD/MM/YYYY'
            )}
          </T.P>
        </T.P>

        {hasCourseMaterials && (
          <>
            <T.H2 mb="2" mt="6" weight={600}>
              Materials
            </T.H2>
            {module?.preCourseMaterials?.length > 0 && (
              <>
                {' '}
                <T.P mb="2" color="gray">
                  Feel free to review any of the pre-course materials below.
                </T.P>
                {module.preCourseMaterials.map(link => (
                  <T.Link
                    key={link}
                    external
                    to={link}
                    color="mainPrimary"
                    underline
                    weight={600}
                    d="block"
                  >
                    {link}
                  </T.Link>
                ))}
              </>
            )}
            {module?.postCourseMaterials?.length > 0 && (
              <>
                <T.P
                  mb="2"
                  color="gray"
                  mt={module?.preCourseMaterials?.length > 0 ? 5 : 0}
                >
                  Feel free to review any of the post-course materials below.
                </T.P>
                {module.postCourseMaterials.map(link => (
                  <T.Link
                    key={link}
                    external
                    to={link}
                    color="mainPrimary"
                    underline
                    weight={600}
                    d="block"
                  >
                    {link}
                  </T.Link>
                ))}
              </>
            )}
          </>
        )}

        {!showPostCourse && module.enableCertificate && (
          <>
            <T.H2 mt="6" weight={600}>
              Certificate
            </T.H2>
            <T.P mb="3" color="gray">
              Here is your certificate of completion
            </T.P>
            <Button
              label="View certificate"
              type="primary"
              width="200px"
              disabled={downloading}
              loading={downloading}
              onClick={() => handleClick(module)}
            />
          </>
        )}

        {showPostCourse && (
          <>
            <T.H2 mt="6" weight={600}>
              Complete Post evaluation
            </T.H2>
            <T.P mb="3" color="gray">
              Click to fill the post evaluation form (it just takes 2 minutes)
            </T.P>
            <Button
              label="Fill Post Evaluation"
              type="primary"
              width="200px"
              to={R.SURVEY_QUESTIONS_URL.replace(
                ':id',
                module?.sessions[0]?.shortId
              )
                .replace(':type', surveyTypesNew.POST)
                .replace(':step', 0)}
            />
          </>
        )}

        {module?.preRequisiteFor?.length > 0 && (
          <>
            <T.H2 mt="6" weight={600} mb="2">
              Further modules
            </T.H2>
            <T.P mb="3" color="gray">
              Now you have completed this module, you are eligible to register
              for these other modules that may be of interest{' '}
            </T.P>
            {module?.preRequisiteFor?.map(mod => (
              <S.StyledDiv>
                <T.H3 weight={600} color="darkGray" mb="2">
                  {mod.title}
                </T.H3>
                <T.P mb="2" color="gray">
                  {mod.description}
                </T.P>
                <T.Link
                  color="gray"
                  to={(povertyProofingModule
                    ? R.POVERTY_MODULE_URL
                    : R.MODULE_URL
                  ).replace(':id', mod._id)}
                >
                  Read more
                </T.Link>
              </S.StyledDiv>
            ))}
          </>
        )}
      </S.Wrapper>
    </Layout>
  );
};

export default CompletedModule;
