import { useReducer, useEffect } from 'react';
import { connect } from 'react-redux';

import Layout from '../../Layouts';
import * as T from '../../common/Typography';
import * as S from './SignUp.style';
import { Row, Col } from '../../common/Grid';
import { BasicInput, Checkbox } from '../../common/Inputs';
import Button from '../../common/Button';
import Modal from '../../common/modal';
import validate from '../../../validation/schemas/signup';
import { validateConfirmAttendance as validateConfirmAttendanceSchema } from '../../../validation/schemas/sessionRegistration';
import useSearchParams from '../../../hooks/useSearchParams';

import {
  userRoles,
  courses,
  modules,
  courseLabelMap
} from '../../../constants';
import { PARTICIPANT_LOGIN } from '../../../constants/navigationRoutes';
import { signUpParticipant } from '../../../actions/authAction';
import useRedirectAfterAuth from '../../../hooks/useRedirectAfterAuth';

const initialState = {
  email: '',
  password: '',
  name: '',
  submitAttempt: false,
  validationErrors: {},
  modal1Open: false,
  confirmAttendance: false
};

const stateReducer = (state, newState) => {
  return { ...state, ...newState };
};

const cleanEmail = email => email.toLowerCase().trim();

const ParticipantSignUp = ({
  httpError,
  loading,
  signUpParticipant,
  course,
  sessionModules = []
}) => {
  const [state, setState] = useReducer(stateReducer, initialState, () => {
    if (
      course === courses.C5 &&
      [modules.MODULE_1, modules.MODULE_2, modules.MODULE_3].some(mod =>
        sessionModules.includes(mod)
      )
    ) {
      initialState.modal1Open = true;
    }
    return initialState;
  });
  const searchParams = useSearchParams();

  const redirectAfterAuth = useRedirectAfterAuth();

  const {
    name,
    email,
    password,
    submitAttempt,
    validationErrors,
    modal1Open,
    confirmAttendance
  } = state;

  const capitalize = string => {
    // capitalize each word in a string
    return string.replace(
      /\w\S*/g,
      txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
  };

  const validateForm = () => {
    try {
      validate({
        role: userRoles.participant,
        email: cleanEmail(email),
        password,
        name
      });

      setState({ validationErrors: {} });

      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({ validationErrors: error.inner });
      }
      return false;
    }
  };

  useEffect(() => {
    if (submitAttempt) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, email, password]);

  const handleSignup = async e => {
    setState({ loading: true });

    const data = await signUpParticipant({
      email: cleanEmail(email),
      password,
      name: capitalize(name)
    });
    if (!data) return;
    redirectAfterAuth(data.role);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setState({ submitAttempt: true });

    const isValid = validateForm();
    if (isValid) {
      handleSignup();
    }
  };

  const validateConfirmAttendance = () => {
    try {
      validateConfirmAttendanceSchema({ confirmAttendance });
      setState({ validationErrors: {} });
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({ validationErrors: error.inner });
      }
    }
  };

  const courseName = courseLabelMap[course];

  const Content = (
    <>
      <T.H1 mb="7">Please create an account</T.H1>
      <S.Wrapper className="sign-up">
        <Row>
          <Col w={[4, 12, 8]}>
            <S.ContentWrapper>
              <S.Form /* onSubmit={handleSubmit} */ className="signup-form">
                <T.P weight={400} mb="5">
                  We require you to create an account so that we can link your
                  responses to this evaluation with your responses to other{' '}
                  {courseName} evaluations.
                </T.P>
                <T.P weight={400} mb="5">
                  You will also be able to log in to track your progress
                  throughout the course, access course materials and receive
                  official certificates.
                </T.P>
                <T.P weight={600} mb="5">
                  You will remain entirely anonymous to trainers.
                </T.P>
                <Row inner>
                  <Col w={[4, 12, 10]}>
                    <BasicInput
                      value={name}
                      name="name"
                      handleChange={value => setState({ name: value })}
                      placeholder="Full name"
                      mb={3}
                      required
                      error={validationErrors.name}
                    />

                    <BasicInput
                      value={email}
                      name="email"
                      handleChange={value => setState({ email: value })}
                      placeholder="Email"
                      mb={3}
                      required
                      error={
                        validationErrors.email ||
                        (httpError?.response?.status === 409 &&
                          httpError?.response?.data?.error)
                      }
                    />

                    <BasicInput
                      value={password}
                      placeholder="Password"
                      name="password"
                      mb={6}
                      type="password"
                      handleChange={value => setState({ password: value })}
                      error={validationErrors.password}
                    />
                  </Col>
                </Row>
                <Row inner mb={4}>
                  <Col w={[4, 12, 10]}>
                    <T.P small>
                      By creating an account I confirm that Northumbria
                      Healthcare Trust and partners can access individual
                      profile data such as name, email address and organisation
                      as well as evaluation results collected via the app
                    </T.P>
                  </Col>
                </Row>
                <Row inner>
                  <Col w={[4, 6, 6]}>
                    <Button
                      onClick={handleSubmit}
                      type="primary"
                      label="Create account"
                      loading={loading}
                    />
                  </Col>
                </Row>

                <T.P mt="6">
                  Already have an account?{' '}
                  <T.Link
                    color="secondary"
                    to={{
                      pathname: PARTICIPANT_LOGIN,
                      search: searchParams.toString()
                    }}
                  >
                    Log in
                  </T.Link>
                </T.P>
              </S.Form>
            </S.ContentWrapper>
          </Col>
        </Row>

        {/* Modals */}
        <Modal
          type="confirm"
          title="Important Message - Please read"
          visible={modal1Open}
          setModalOpen={() => setState({ modal1Open: false })}
          parentFunc={validateConfirmAttendance}
          closeOnOK={confirmAttendance}
        >
          <Row mt="4" mb="4">
            <Col w={[4, 12, 12]}>
              <T.P color="gray">
                To attend Connect 5 training, you <b>must</b> have completed the
                MECC course first.
              </T.P>
            </Col>
          </Row>
          <Row mt="4" mb="4">
            <Col w={[4, 12, 12]}>
              <Checkbox
                checked={confirmAttendance}
                handleChange={value => setState({ confirmAttendance: value })}
                label=" I confirm I have or will have completed the MECC course by the time I attend this event"
                error={validationErrors.confirmAttendance}
              />
            </Col>
          </Row>
        </Modal>
      </S.Wrapper>
    </>
  );

  return <Layout>{Content}</Layout>;
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  httpError: state.auth.error,
  loading: state.loading.signupLoading
});

export default connect(mapStateToProps, {
  signUpParticipant
})(ParticipantSignUp);
