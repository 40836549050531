import { fields, createSchema, validate as _validate } from '..';

const { email, name } = fields;

const volunteer = createSchema({
  email,
  name: name
});

const validate = data => _validate(volunteer, data);

export default validate;
