import { useReducer, useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  createModule,
  updateModule,
  fetchCustomModule,
  fetchCustomModules
} from '../../../actions/customModulesActions';
import { moduleValidation as validate } from '../../../validation/schemas';
import * as R from '../../../constants/navigationRoutes';

import MDEditor from '../../common/MDEditor';
import Button from '../../common/Button';
import Modal from '../../common/modal';
import * as T from '../../common/Typography';
import * as I from '../../common/Inputs';
import Layout from '../../Layouts';

import MultiInputField from './MultiInputField';
import { categoryOptions, yesNoOptions } from './dropdownsData';
import * as S from './style';
import Assessment from './Assesment';

const initialState = {
  preCourseMaterials: [''],
  postCourseMaterials: [''],
  archived: false,
  type: '',
  title: '',
  description: '',
  developers: '',
  learningObjectives: '',
  supportedCompetencies: '',
  whatIsItFor: '',
  additionalInfo: '',
  showPostCourseEvaluation: false,
  category: '',
  isLive: '',
  link: '',
  duration: '',
  enableCertificate: '',
  assessmentNeeded: false,
  assessmentPassMark: '',
  questions: [
    {
      question: '',
      options: [
        { text: '', isCorrect: false },
        { text: '', isCorrect: false }
      ]
    }
  ],
  isPreRequisite: false,
  preRequisiteFor: [],
  validationErrs: {}
};

function reducer(state, newState) {
  return { ...state, ...newState };
}

const UpdateModule = ({
  createModule,
  updateModule,
  fetchCustomModule,
  httpError,
  loading,
  module,
  modules,
  fetchModulesAction,
  selectedCourse
}) => {
  const submitAttempt = useRef(false);
  const [showModal, setShowModal] = useState(false);
  const { id } = useParams() || {};
  const isEditMode = !!id;
  const modulesOptions = modules
    ?.filter(e => e._id !== id)
    ?.map(e => {
      return { label: e.title, value: e._id };
    });
  const [state, setState] = useReducer(reducer, initialState);
  const history = useHistory();

  const handleSubmit = async e => {
    e.preventDefault();
    submitAttempt.current = true;
    const isValid = validateForm();
    if (isValid) {
      let res;
      if (isEditMode) {
        res = await updateModule({
          _id: module._id,
          ...state,
          preCourseMaterials: state.preCourseMaterials.filter(e => !!e),
          postCourseMaterials: state.postCourseMaterials.filter(e => !!e)
        });
      } else {
        res = await createModule({
          ...state,
          preCourseMaterials: state.preCourseMaterials.filter(e => !!e),
          postCourseMaterials: state.postCourseMaterials.filter(e => !!e)
        });
      }
      if (!res?.error) {
        setShowModal(true);
      }
    }
  };

  const validateForm = () => {
    try {
      validate({
        ...state,
        preCourseMaterials: state.preCourseMaterials.filter(e => !!e),
        postCourseMaterials: state.postCourseMaterials.filter(e => !!e)
      });
      setState({ validationErrs: {} });
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({ validationErrs: error.inner });
      }
      return false;
    }
  };
  useEffect(() => {
    if (selectedCourse) {
      fetchModulesAction({ course: selectedCourse });
      setState({
        type: selectedCourse
      });
    } else {
      history.push(R.MODULES_URL); // Must select first on which program we are
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCourse]);

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.preCourseMaterials,
    state.postCourseMaterials,
    state.archived,
    state.title,
    state.description,
    state.developers,
    state.learningObjectives,
    state.supportedCompetencies,
    state.whatIsItFor,
    state.additionalInfo,
    state.showPostCourseEvaluation,
    state.category,
    state.isLive,
    state.link,
    state.enableCertificate,
    state.assessmentNeeded,
    state.duration,
    state.questions,
    state.assessmentPassMark,
    state.isPreRequisite,
    state.preRequisiteFor
  ]);

  useEffect(() => {
    if (id) {
      fetchCustomModule({ id });
    }
  }, [fetchCustomModule, id, state]);

  useEffect(() => {
    if (id && module?._id) {
      setState({
        ...module,
        preCourseMaterials: module?.preCourseMaterials?.length
          ? module?.preCourseMaterials
          : [''],
        postCourseMaterials: module?.postCourseMaterials?.length
          ? module?.postCourseMaterials
          : [''],
        isLive: module?.isLive?.toString(),
        enableCertificate: module.enableCertificate?.toString(),
        assessmentNeeded: module.assessmentNeeded?.toString(),
        questions: module?.questions?.length
          ? module?.questions
          : [
              {
                question: '',
                options: [
                  { text: '', isCorrect: false },
                  { text: '', isCorrect: false }
                ]
              }
            ],
        preRequisiteFor:
          module?.preRequisiteFor?.length > 0
            ? module?.preRequisiteFor?.map(e => {
                return e._id;
              })
            : []
      });
    } else {
      setState({
        questions: [
          {
            question: '',
            options: [
              { text: '', isCorrect: false },
              { text: '', isCorrect: false }
            ]
          }
        ]
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, module._id]);

  return (
    <Layout withGraph>
      <T.H1 mb={7}>{isEditMode ? 'Edit module' : 'Add a module'}</T.H1>
      <S.Form>
        <I.BasicInput
          label="Module title"
          required
          placeholder="Module title..."
          mb="5"
          value={state.title}
          handleChange={title => setState({ title })}
          error={state.validationErrs.title}
        />
        <MDEditor
          color="darkGray"
          placeholder="Summary description..."
          rows="20"
          value={state.description}
          onChange={description => setState({ description })}
          label="Summary description"
          m={{ mb: 5 }}
          optional
          error={state.validationErrs.description}
        />
        <I.BasicInput
          label="Name/organisation of module developer"
          placeholder="Name/organisation of module developer..."
          mb="5"
          value={state.developers}
          handleChange={developers => setState({ developers })}
          error={state.validationErrs.developers}
        />

        <MDEditor
          color="darkGray"
          label="Learning objectives"
          placeholder="Learning objectives..."
          rows="20"
          value={state.learningObjectives}
          onChange={learningObjectives => setState({ learningObjectives })}
          m={{ mb: 5 }}
          optional
          error={state.validationErrs.learningObjectives}
        />

        <MDEditor
          color="darkGray"
          label="What clinical or professional competencies does the module support?"
          placeholder="What clinical or professional competencies does the module support?..."
          rows="20"
          value={state.supportedCompetencies}
          onChange={supportedCompetencies =>
            setState({ supportedCompetencies })
          }
          m={{ mb: 5 }}
          optional
          error={state.validationErrs.supportedCompetencies}
        />

        <MDEditor
          color="darkGray"
          label="Who is the module for?"
          placeholder="Who is the module for?"
          rows="20"
          value={state.whatIsItFor}
          onChange={whatIsItFor => setState({ whatIsItFor })}
          m={{ mb: 5 }}
          optional
          error={state.validationErrs.whatIsItFor}
        />
        <I.BasicInput
          label="Module duration"
          placeholder="1 hour..."
          mb="5"
          value={state.duration}
          handleChange={duration => setState({ duration })}
          error={state.validationErrs.duration}
        />

        {/* 
          disable editing this as this change is changing the these cases as we can't create or delete the default session for the module
          live means that this module can be added to the sessions and can be attended by the participants
          non-live means that we create a default session for it to collect responses, and manage attendance
          so changing this value will change will have a lot of implications
        */}
        <I.Dropdown
          m={{
            mb: '5'
          }}
          label={'Live?'}
          required
          addNew={false}
          search={false}
          options={yesNoOptions}
          selected={state.isLive}
          handleChange={value => setState({ isLive: value })}
          error={state.validationErrs.isLive}
          disabled={isEditMode}
          helper={
            isEditMode
              ? `You can't change a module from live to non-live and vice versa`
              : ''
          }
        />

        <MDEditor
          color="darkGray"
          label="Additional information"
          placeholder="Additional information..."
          rows="20"
          value={state.additionalInfo}
          onChange={additionalInfo => setState({ additionalInfo })}
          m={{ mb: 5 }}
          optional
          error={state.validationErrs.additionalInfo}
        />

        <>
          <T.P color="darkGray" weight={600} mb="1">
            Evaluation{' '}
          </T.P>
          <I.Checkbox
            checked={state.showPostCourseEvaluation}
            handleChange={value =>
              setState({ showPostCourseEvaluation: value })
            }
            label={<T.P color="gray">Post-course evaluation</T.P>}
            error={state.validationErrs.showPostCourseEvaluation}
          />
        </>

        <MultiInputField
          fields={state.preCourseMaterials}
          name="preCourseMaterials"
          setState={setState}
          label="Pre-course materials"
          placeholder="Pre-course material link..."
          required={false}
          errors={state.validationErrs}
        />
        <MultiInputField
          fields={state.postCourseMaterials}
          name="postCourseMaterials"
          setState={setState}
          label="Post-course materials"
          placeholder="Post-course material link..."
          required={false}
          errors={state.validationErrs}
        />
        <I.Dropdown
          m={{
            mb: '5'
          }}
          label={'Category'}
          required
          addNew={false}
          search={false}
          options={categoryOptions.filter(
            category => category.course === selectedCourse
          )}
          selected={state.category}
          handleChange={value => setState({ category: value })}
          error={state.validationErrs.category}
        />

        <I.BasicInput
          label="Module link"
          placeholder="Module link..."
          mb="5"
          value={state.link}
          handleChange={link => setState({ link })}
          required={state.isLive === 'false'}
          error={state.validationErrs.link}
        />

        <I.Dropdown
          m={{
            mb: '5'
          }}
          label={'Certificate'}
          required
          addNew={false}
          search={false}
          options={yesNoOptions}
          selected={state.enableCertificate}
          handleChange={value => setState({ enableCertificate: value })}
          error={state.validationErrs.enableCertificate}
        />
        {state.isLive === 'false' ? (
          <I.Dropdown
            m={{
              mb: '5'
            }}
            label={'Assessment needed?'}
            required
            addNew={false}
            search={false}
            options={yesNoOptions}
            selected={state.assessmentNeeded}
            handleChange={value => setState({ assessmentNeeded: value })}
            error={state.validationErrs.assessmentNeeded}
          />
        ) : null}
        {state.assessmentNeeded === 'true' ? (
          <Assessment setState={setState} state={state} />
        ) : null}
        <T.P color="darkGray" weight={600} mb="1">
          Is this a pre-requisite module?
        </T.P>
        <I.Checkbox
          m={{
            mb: '5'
          }}
          checked={state.isPreRequisite}
          handleChange={value => {
            if (value) {
              setState({ isPreRequisite: value });
            } else {
              setState({ isPreRequisite: value, preRequisiteFor: [] });
            }
          }}
          label={<T.P color="gray">Yes</T.P>}
          error={state.validationErrs.isPreRequisite}
        />
        {state.isPreRequisite && (
          <I.Dropdown
            m={{
              mb: '5'
            }}
            label={'Select which modules this is a pre-requisite for'}
            required
            showSearch={true}
            multi={true}
            options={modulesOptions}
            selected={
              state.preRequisiteFor?.length > 0 &&
              state.preRequisiteFor?.map(e => {
                return e._id ? e._id : e;
              })
            }
            handleChange={value => setState({ preRequisiteFor: value })}
            error={state.validationErrs.preRequisiteFor}
          />
        )}
        {httpError && (
          <T.P mb="2" color="red">
            {httpError}
          </T.P>
        )}
        <Button
          type="primary"
          label={isEditMode ? 'Save' : 'Create module'}
          width="200px"
          onClick={handleSubmit}
          loading={loading}
        />
      </S.Form>
      <Modal visible={showModal} setModalOpen={setShowModal}>
        <T.H4W color="darkGray" mb="2" mt="2">
          {isEditMode
            ? 'Module successfully edited'
            : 'Module successfully created'}
        </T.H4W>
        <T.P color="gray" mb="10">
          {isEditMode
            ? 'Thank you for editing the module.'
            : 'Thank you for creating a new module.'}
        </T.P>
        <Button
          label="Return to modules"
          type="primary"
          width="200px"
          to={R.MODULES_URL}
        />
      </Modal>
    </Layout>
  );
};

const mapStateToProps = state => ({
  httpError: state.modules.httpError,
  loading: state.loading.createModuleLoading,
  module: state.modules.module,
  modules: state.modules.modules,
  selectedCourse: state.course.selectedCourse
});

export default connect(mapStateToProps, {
  createModule,
  updateModule,
  fetchCustomModule,
  fetchModulesAction: fetchCustomModules
})(UpdateModule);
