import { Icon as AntdIcon, Popover } from 'antd';
import moment from 'moment';
import React from 'react';
import { useTheme } from 'styled-components';
import Spin from '../Spin';

import { concatModules } from '../../../helpers';
import Icon from '../Icon';
import * as T from '../Typography';

import { StyledLink, WarningWrapper, Wrapper } from './List.style';

import { Col, Row } from '../Grid';

import {
  PARTICIPANT_SESSION_DETAILS,
  SESSION_DETAILS_URL
} from '../../../constants/navigationRoutes';
import { courseLabelMap } from '../../../constants';

// NOTE: this component expects dataList to look something like this:

const SessionList = ({ dataList, isParticipant, linkText }) => {
  const { colors, spacings } = useTheme();

  const baseURL = isParticipant
    ? PARTICIPANT_SESSION_DETAILS
    : SESSION_DETAILS_URL;

  return (
    <Wrapper>
      <Row
        style={{
          borderBottom:
            dataList?.length > 0 ? 'none' : `1px solid ${colors.midPrimary}`
        }}
      >
        <Col w={[1, 3, 3]}>
          <T.P weight={700} color="darkGray" mb="2">
            Date
          </T.P>
        </Col>
        <Col w={[1, 3, 3]}>
          <T.P weight={700} color="darkGray" mb="2">
            Module(s)
          </T.P>
        </Col>
        <Col w={[1, 3, 3]}>
          <T.P weight={700} color="darkGray" mb="2">
            Course
          </T.P>
        </Col>
        <Col w={[1, 3, 3]}>
          <T.P weight={700} color="darkGray" mb="2">
            Details
          </T.P>
        </Col>
      </Row>

      {!dataList || !Array.isArray(dataList) ? (
        <div style={{ width: '100%', height: '400px' }}>
          <Spin />
        </div>
      ) : (
        <>
          {dataList?.length > 0 ? (
            dataList.map(dataItem => (
              <Row
                key={dataItem.id}
                href={dataItem.link || undefined}
                to={dataItem.link || baseURL.replace(':id', dataItem._id)}
                target={dataItem.blank ? '_blank' : '_self'}
                style={{
                  borderTop: `1px solid ${colors.midPrimary}`,
                  padding: `${spacings[3]} 0`
                }}
              >
                <WarningWrapper>
                  {!!dataItem.participantsDidNotAttendPrevMods?.length && (
                    <Icon icon="warning" />
                  )}
                </WarningWrapper>
                {dataItem.defaultSessionForCustomModule && !isParticipant && (
                  <WarningWrapper>
                    <Popover
                      title={
                        <T.P color="white">
                          Default session for alcohol modules
                        </T.P>
                      }
                      content={
                        <T.P color="white">
                          You can manage registered participants and view their
                          responses.
                        </T.P>
                      }
                    >
                      <Icon icon="info" width="20" color="mainPrimary" />
                    </Popover>
                  </WarningWrapper>
                )}
                <Col w={[1, 3, 3]}>
                  <T.P color="gray">
                    {dataItem.defaultSessionForCustomModule
                      ? 'Anytime'
                      : moment(dataItem.date).format('DD/MM/YYYY')}
                  </T.P>
                </Col>
                <Col w={[1, 3, 3]}>
                  <T.P color="gray">
                    {concatModules({
                      modules: dataItem?.modules,
                      customModules: dataItem?.customModules
                    })}
                  </T.P>
                </Col>
                <Col w={[1, 3, 3]}>
                  <T.P color="gray">
                    {' '}
                    {courseLabelMap[dataItem?.course] || 'N/A'}
                  </T.P>
                </Col>
                <Col w={[2, 3, 3]}>
                  <StyledLink
                    as={dataItem.asLink ? 'a' : undefined}
                    href={dataItem.link || undefined}
                    to={dataItem.link || baseURL.replace(':id', dataItem._id)}
                    target={dataItem.blank ? '_blank' : '_self'}
                  >
                    <AntdIcon
                      type="right"
                      aria-label="view-details-icon"
                      role="link"
                    />
                    {dataItem.linkText || linkText || 'View'}
                  </StyledLink>
                </Col>
              </Row>
            ))
          ) : (
            <Row jc="center" mt="5">
              <Col w={[4, 12, 12]} jc="center">
                <T.P weight="700" color="gray" ta="center">
                  No data found
                </T.P>
              </Col>
            </Row>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default SessionList;
