import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { BasicInput } from '../../common/Inputs';

import { loginUser } from '../../../actions/authAction';
import { clearErrors } from '../../../actions/errorAction';

import Layout from './../../Layouts';
import * as T from './../../common/Typography';
import { Row, Col } from '../../common/Grid';
import Button from '../../common/Button';
import useSearchParams from '../../../hooks/useSearchParams';
import useRedirectAfterAuth from '../../../hooks/useRedirectAfterAuth';

import * as S from './style';

// ROUTES
import {
  FORGET_PASSWORD,
  PARTICIPANT_SIGN_UP_URL,
  SIGN_UP_URL
} from '../../../constants/navigationRoutes';
import validate from '../../../validation/schemas/login';
import { userRoles } from '../../../constants';

const cleanEmail = email => email.toLowerCase().trim();

const ExtraInfo = ({ contactEmail, courseName }) => {
  return (
    <>
      <T.P weight={400} mb="5">
        Please log into the account you created when you started the{' '}
        {courseName} course. If you have yet to complete Module 1 and/or to
        complete the pre-course evaluation,{' '}
        {contactEmail && (
          <>
            please do this first. Contact your trainer on{' '}
            <T.Link color="primary" external to={`mailto:${contactEmail}`}>
              {contactEmail}
            </T.Link>{' '}
            if you require help.
          </>
        )}
      </T.P>
      <T.P weight={400} mb="5">
        If you have forgotten your password click Forgot password below.
      </T.P>
    </>
  );
};

const Login = ({
  loginUser: loginUserAction,
  clearErrors: clearErrorsAction,
  httpError,
  loginLoading,
  header,
  loginRole: _loginRole
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(loginLoading);
  const [errors, setErrors] = useState({});
  const [submitAttempt, setSubmitAttempt] = useState(false);
  const history = useHistory();
  const signupAsMeccTrainer = history.location.state?.signupAsMeccTrainer;
  const searchParams = useSearchParams();
  const loginRole = searchParams.params.loginRole || _loginRole;
  const redirectAfterAuth = useRedirectAfterAuth();

  const validateForm = () => {
    try {
      validate({
        email: cleanEmail(email),
        password
      });
      setErrors({});
      clearErrorsAction();
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setErrors(error.inner);
      }
      return false;
    }
  };

  useEffect(() => {
    if (submitAttempt) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, password]);

  useEffect(() => {
    setErrors({});
    clearErrorsAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleLogin = async () => {
    setLoading(true);
    const data = await loginUserAction({
      email,
      password,
      signupAsMeccTrainer,
      loginRole
    });

    setLoading(false);

    if (!data) return;

    redirectAfterAuth(data.role);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setSubmitAttempt(true);

    const isValid = validateForm();

    if (isValid) {
      handleLogin();
    }
  };

  return (
    <Layout maxWidth={'800px'}>
      <T.H1 ml={2}>{header || 'Log in to your account'}</T.H1>
      <Row mt={7}>
        <Col w={[4, 12, 10]}>
          <S.Wrapper onSubmit={handleSubmit}>
            <Row>
              {searchParams.params?.showExtraInfo && (
                <ExtraInfo
                  contactEmail={searchParams.params.contactEmail}
                  courseName={searchParams.params.courseName}
                />
              )}
              <Col w={[4, 9, 10]}>
                <BasicInput
                  placeholder="Email"
                  name="email"
                  type="text"
                  value={email}
                  handleChange={setEmail}
                  error={errors.email}
                />
              </Col>
              <Col w={[4, 9, 10]} mt={4}>
                <BasicInput
                  placeholder="Password"
                  name="password"
                  type="password"
                  value={password}
                  handleChange={setPassword}
                  error={errors.password}
                />
                <T.Link
                  to={FORGET_PASSWORD}
                  underline={false}
                  color="gray"
                  mt={1}
                >
                  Forgot password?
                </T.Link>
              </Col>

              {httpError?.msg && typeof httpError?.msg === 'string' && (
                <Col w={[4, 12, 12]}>
                  <T.P mt="4" color="error">
                    {httpError?.msg}
                  </T.P>
                </Col>
              )}
              <Col w={[4, 6, 8]}>
                <Button
                  onClick={() => {}}
                  loading={loading}
                  type="primary"
                  label="Log in"
                  mt={4}
                />
              </Col>
              <Col w={[4, 12, 12]}>
                <T.P mt="6">
                  Don't have an account?{' '}
                  <T.Link
                    color="secondary"
                    to={{
                      pathname:
                        loginRole === userRoles.participant
                          ? PARTICIPANT_SIGN_UP_URL
                          : SIGN_UP_URL,
                      search: searchParams.toString()
                    }}
                  >
                    Sign up
                  </T.Link>
                </T.P>
              </Col>
            </Row>
          </S.Wrapper>
        </Col>
      </Row>
    </Layout>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  role: state.auth.role,
  httpError: state.error,
  loginLoading: state.loading.loginLoading
});

export default connect(mapStateToProps, { loginUser, clearErrors })(Login);
