import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Layout from '../../Layouts';
import moment from 'moment';

import * as T from '../../common/Typography';
import { Row, Col } from '../../common/Grid';
import Button from '../../common/Button';
import * as S from './style';
import { SURVEY_URL } from '../../../constants/navigationRoutes';
import {
  surveyTypesNew,
  modules as modulesData,
  courses,
  courseLabelMap
} from '../../../constants/index';
import { concatModules, isCustomCourse } from './../../../helpers';

const SuccessRegistration = ({
  session,
  alreadyRegistered,
  showDemographicLink
}) => {
  const {
    date,
    trainers,
    meetingLink,
    contactEmail,
    extraInfo,
    remote,
    address,
    meetingPlatform,
    modules,
    customModules,
    shortId,
    course
  } = session;

  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const trainersNames = trainers
    ? trainers.map(trainer => trainer.name).join(' & ')
    : 'N/A';

  const isMeccTTT =
    course === courses.MECC && modules[0] === modulesData.TRAIN_THE_TRAINER;

  const isAlcoholCourse = course === courses.ALCOHOL_STUDIES;

  const handleClick = () => {
    let type = modules.includes(modulesData.MODULE_1)
      ? surveyTypesNew.PRE_COURSE
      : surveyTypesNew.POST;

    if (isMeccTTT) {
      type = surveyTypesNew.PRE_TRAIN_THE_TRAINER;
    }

    history.push(SURVEY_URL.replace(':type', type).replace(':id', shortId));
  };

  let surveyTypeForSecondButton = isAlcoholCourse
    ? surveyTypesNew.ALCOHOL_DEMOGRAPHICS
    : surveyTypesNew.PRE_COURSE;

  if (isMeccTTT) {
    surveyTypeForSecondButton = surveyTypesNew.PRE_TRAIN_THE_TRAINER;
  }

  const createAddress = () => {
    if (address) {
      const { addressLine1, addressLine2, postcode, town } = address;
      const fullAddress = [addressLine1, addressLine2, town, postcode]
        .filter(item => !!item)
        .join(', ');

      return fullAddress;
    }
    return 'N/A';
  };

  const courseName = courseLabelMap[course];

  return (
    <Layout>
      <T.H1>
        {alreadyRegistered ? 'Already' : 'Successfully'} registered
        {alreadyRegistered && '!'}
      </T.H1>
      <Row mt="7">
        <Col w={[4, 8, 8]}>
          <T.P color="gray">
            Thank you for registering for this session. Please find all the
            relevant information for your session below.
          </T.P>
        </Col>
      </Row>
      {(modules?.includes(modulesData.MODULE_1) || isMeccTTT) &&
      showDemographicLink ? (
        <>
          <Row mt="5">
            <Col w={[4, 8, 8]}>
              <T.P mb="3" color="gray">
                Before you attend we require all participants to answer a short
                2-minute pre-course evaluation. This will help us provide you
                with insight on how the session has helped you and for you to
                access materials throughout the course.
              </T.P>
              <T.P weight="700">
                Please note, you cannot begin the course or access any materials
                until you have completed this step.
              </T.P>
            </Col>
          </Row>
          <Row mt="5">
            <Col w={[4, 4, 4]}>
              <Button
                onClick={handleClick}
                type="primary"
                label="Fill survey"
                width="200px"
              />
            </Col>
          </Row>
        </>
      ) : (
        <Row mt="7">
          <Col w={[4, 8, 8]}>
            <T.P weight="700" mb="3" color="darkGray">
              What now?
            </T.P>
            <T.P color="gray">
              You may have course materials to look through ahead of your
              session. Please visit Home, log into your {courseName} participant
              account and visit 'Materials' to see.
            </T.P>
            {isCustomCourse(course) && (
              <T.P color="gray" mt={6}>
                Please note, this is the account you create when you attend your
                first {courseName} session and complete the mandatory pre-course
                evaluation. If you have not yet done this, please speak to the
                trainer of your first session and ask them to send you the
                pre-course evaluation link again.
              </T.P>
            )}
          </Col>
        </Row>
      )}
      <Row mt={8}>
        <Col w={[4, 8, 8]}>
          <S.Wrapper>
            <T.P mb="4" color="gray">
              <S.Bold>Date of session: </S.Bold>{' '}
              {date ? moment(session.date).format('DD-MM-YYYY') : 'N/A'}
            </T.P>
            {session?.endDate && (
              <T.P mb="4" color="gray">
                <S.Bold>End date:</S.Bold>{' '}
                {moment(session.endDate).format('DD-MM-YYYY')}
              </T.P>
            )}
            <T.P mb="4" color="gray">
              <S.Bold>Time of Session:</S.Bold> {session?.startTime || 'TBC'}{' '}
              {session?.endTime && `to ${session?.endTime}`}
            </T.P>
            <T.P mb="4" color="gray">
              <S.Bold>Module(s):</S.Bold>{' '}
              {concatModules({ modules, customModules })}
            </T.P>
            <T.P mb="4" color="gray">
              <S.Bold>Location:</S.Bold>{' '}
              {remote ? meetingPlatform : createAddress()}
            </T.P>
            <T.P mb="4" color="gray" transform="capitalize">
              <S.Bold>Trainers:</S.Bold> {trainersNames}
            </T.P>{' '}
            {remote && (
              <T.P mb="4" color="gray" style={{ wordBreak: 'break-all' }}>
                <S.Bold style={{ display: 'inline' }}>Meeting link:</S.Bold>
                {meetingLink ? (
                  <T.Link external href={meetingLink} color="primary">
                    {' '}
                    {meetingLink}
                  </T.Link>
                ) : (
                  ' N/A'
                )}
              </T.P>
            )}
            <T.P mb="4" color="gray">
              <S.Bold>Email: </S.Bold>
              {contactEmail ? (
                <T.Link
                  external
                  href={`mailto:${contactEmail}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  color="primary"
                >
                  {contactEmail}
                </T.Link>
              ) : (
                ' N/A'
              )}
            </T.P>
            <T.P color="gray">
              <S.Bold style={{ display: 'block' }}>
                {' '}
                Any other joining information:
              </S.Bold>
              {extraInfo || 'N/A'}
            </T.P>
          </S.Wrapper>
        </Col>
      </Row>

      {showDemographicLink && (
        <Row mt="7" mb="7">
          <Col w={[4, 8, 8]}>
            <T.P color="gray" mb={4}>
              Before attending the session, can you please answer a few
              questions about yourself.
            </T.P>

            <Button
              to={{
                pathname: SURVEY_URL.replace(':id', shortId).replace(
                  ':type',
                  surveyTypeForSecondButton
                ),
                state: { fromRegistration: true }
              }}
              type="primary"
              label="Fill survey"
              width="200px"
            />
          </Col>
        </Row>
      )}
    </Layout>
  );
};

export default SuccessRegistration;
