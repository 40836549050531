import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { getUsedFilters } from '../helpers';
import getCsvDataAndHeadersForExportResponses from '../helpers/get-csv-data-and-headers-for-export-responses';

function useCsvDownload({ exportType, filters, target }) {
  const [size, setSize] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const buttonRef = useRef(null);
  const [csvData, setCsvData] = useState([]);
  const [csvHeaders, setCsvHeaders] = useState();
  const mounted = useRef(true);

  const handleClick = async () => {
    let link;

    if (exportType === 'responses') {
      link = '/api/export-csv/responses';
    }
    if (exportType === 'sessions') {
      link = '/api/export-csv/sessions';
    }
    if (exportType === 'trainers') {
      link = '/api/export-csv/trainers';
    }
    if (exportType === 'participants') {
      link = '/api/export-csv/participants';
    }

    try {
      setLoading(true);
      setSize(0);
      setError('');
      const file = await axios({
        url: link,
        method: 'POST',
        params: {
          isExport: true,
          target
        },
        data: {
          filters: getUsedFilters(filters)
        },
        onDownloadProgress(progressEvent) {
          setSize(s => s + progressEvent.loaded);
        }
      });

      setLoading(false);
      if (!file?.data?.length) {
        setCsvData([{ [exportType]: 'No data found' }]);
        setCsvHeaders([exportType]);
      } else if (exportType === 'responses') {
        const { csvData, csvHeaders } = getCsvDataAndHeadersForExportResponses(
          file.data
        );
        setCsvData(csvData);
        setCsvHeaders(csvHeaders);
      } else {
        setCsvData(file.data);
      }

      if (mounted.current) {
        buttonRef.current.link.click();
      }
    } catch (err) {
      setLoading(false);
      setError(err.message);
    }
  };

  useEffect(
    () => () => {
      mounted.current = false;
    },
    []
  );

  const data = { csvData, csvHeaders, size, loading, error };
  return [data, handleClick, buttonRef];
}

export default useCsvDownload;
