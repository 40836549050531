import download from 'downloadjs';
import axios from 'axios';
import { useState } from 'react';
import { message } from 'antd';

const useDownloadCertificate = () => {
  const [downloading, setDownloading] = useState(false);

  const handleDownload = ({
    module,
    email,
    sessionId,
    date,
    customModuleName
  }) => {
    const fileName =
      module === 'CUSTOM'
        ? `module-${customModuleName}-${Date.now()}-cert.pdf`
        : `module-${module}-${Date.now()}.pdf`;
    const data = {
      email,
      module,
      sendEmail: true,
      date
    };
    setDownloading(true);
    axios
      .post(`/api/certificate/${sessionId}`, data, {
        responseType: 'blob'
      })
      .then(res => {
        const content = res.headers['content-type'];
        download(res.data, fileName, content);
        setDownloading(false);
      })
      .catch(() => {
        setDownloading(false);
        message.error('Error! something went wrong');
      });
  };
  return { downloading, handleDownload };
};

export default useDownloadCertificate;
