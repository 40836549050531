import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { confirmRegistration } from '../../../actions/confirmRegistration';
import { fetchCustomModule } from '../../../actions/customModulesActions';
import { fetchParticipantSessions } from '../../../actions/groupSessionsAction';
import Layout from '../../Layouts';
import Button from '../../common/Button';
import Icon from '../../common/Icon';
import Markdown from '../../common/Markdown';
import Modal from '../../common/modal';
import { checkIfSessionIsFinished } from '../../../helpers';

import moment from 'moment';
import {
  MODULE_STEPS_URL,
  MODULE_URL,
  POVERTY_MODULE_URL,
  PARTICIPANT_LOGIN,
  PARTICIPANT_SIGN_UP_URL,
  REGISTER_URL,
  SURVEY_QUESTIONS_URL
} from './../../../constants/navigationRoutes';
import { convertAddressObjToString } from './../../../helpers';
import useSearchParams from './../../../hooks/useSearchParams';

import MaxWidthContainer from '../../common/MaxWidthContainer';
import * as T from '../../common/Typography';
import WebsiteHeader from '../../common/WebsiteHeader';

import { courses, surveyTypesNew, userRoles } from '../../../constants';
import { Col, Row } from '../../common/Grid';
import Table from '../../common/Table';
import { LearningObjectivesRow, RegisterRow, TableWrapperRow } from './style';
import CNECard from '../../common/CNECard';

const Module = ({
  fetchCustomModule,
  module,
  userId,
  role,
  confirmRegistration,
  loading,
  fetchParticipantSessions,
  sessions,
  povertyProofingModule
}) => {
  const searchParams = useSearchParams();
  const history = useHistory();
  const { id } = useParams();
  const { colors } = useTheme();
  const [showModal, setShowModal] = useState(false);
  const [nonCompletedModules, setNonCompletedModules] = useState([]);
  useEffect(() => {
    fetchCustomModule({ id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (userId && role === userRoles.participant) {
      fetchParticipantSessions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  searchParams.set('source', 'moduleRegistration');
  searchParams.set('loginRole', userRoles.participant);
  searchParams.set('moduleId', id);

  if (povertyProofingModule) {
    searchParams.set('povertyProofingModule', true);
  }

  const sessionsColumns = [
    { label: 'Date', value: 'date' },
    { label: 'Location', value: 'location' },
    { label: 'Details', value: 'details' }
  ];

  const isAlcoholCourse = module.type === courses.ALCOHOL_STUDIES;

  const completedAlcoholModuleDemographic = sessions?.find(s =>
    s?.completedSurvey?.find(
      r => r?.surveyType === surveyTypesNew.ALCOHOL_DEMOGRAPHICS
    )
  );

  const completedGeneralPreCourseQuestions = sessions
    ?.filter(s => s.course === module.type)
    ?.find(s =>
      s?.completedSurvey?.find(r => r?.surveyType === surveyTypesNew.PRE_COURSE)
    );

  const completedPreCourseQuestions = isAlcoholCourse
    ? completedAlcoholModuleDemographic
    : completedGeneralPreCourseQuestions;

  const onSubmit = async short => {
    let notCompletedModules = [];

    if (module.prerequisiteModules?.length > 0) {
      if (sessions?.length === 0) {
        notCompletedModules = module.prerequisiteModules;
        setNonCompletedModules(notCompletedModules);
      } else {
        let filteredSessions = sessions.filter(session => {
          // Filter the session's customModules array to only include modules that are also in the prerequisiteModules array
          const matchingModules = session.customModules.filter(customModule => {
            return module.prerequisiteModules.some(prerequisiteModule => {
              return prerequisiteModule._id === customModule._id;
            });
          });
          // If there are any matching modules, include the session in the filteredSessions array
          if (matchingModules.length > 0) {
            return { ...session, customModules: matchingModules };
          }
          return null;
        });
        const notCompletedSessions = filteredSessions?.filter(ses => {
          const hasFinished = checkIfSessionIsFinished(ses);
          const completedPostSurvey = ses?.completedSurvey?.find(
            survey => survey.surveyType === surveyTypesNew.POST
          );
          return !(ses.completedAt || hasFinished || completedPostSurvey);
        });
        notCompletedModules = notCompletedSessions
          .reduce((acc, session) => {
            // Filter the custom modules that exist in preModules for this session
            const matchingCustomModules = session.customModules.filter(
              customModule =>
                module.prerequisiteModules.some(
                  preModule => preModule._id === customModule._id
                )
            );

            // Add the matching custom modules to the accumulator array
            return acc.concat(matchingCustomModules);
          }, [])
          .reduce((acc, current) => {
            // remove duplicate values
            const existingObject = acc.find(obj => obj.id === current.id);
            if (!existingObject) {
              return [...acc, current];
            }
            return acc;
          }, []);

        setNonCompletedModules(notCompletedModules);
      }
    }

    if (notCompletedModules?.length > 0) {
      setShowModal(true);
    } else if (module.isLive) {
      history.push(REGISTER_URL.replace(':id', short));
    } else {
      await confirmRegistration({
        userId,
        sessionId: module?.sessions?.[0]?._id
      });

      if (completedPreCourseQuestions) {
        history.push({
          pathname: MODULE_STEPS_URL.replace(':id', id),
          state: {
            justRegistered: true
          }
        });
      } else {
        history.push({
          pathname: SURVEY_QUESTIONS_URL.replace(
            ':id',
            module?.sessions?.[0]?.shortId
          )
            .replace(
              ':type',
              isAlcoholCourse
                ? surveyTypesNew.ALCOHOL_DEMOGRAPHICS
                : surveyTypesNew.PRE_COURSE
            )
            .replace(':step', 0),
          state: { fromRegistration: true }
        });
      }
    }
  };

  const nonLiveSessionWithNoSession =
    !module.isLive && !module?.sessions?.[0]?._id;

  module?.sessions?.forEach(session => {
    session.isCompleted = checkIfSessionIsFinished(session);
  });

  // sessions in future or today
  const upcomingSessions =
    module?.sessions?.filter(
      session => !session.defaultSessionForCustomModule && !session.isCompleted
    ) || [];

  return (
    <Layout layout="website">
      <WebsiteHeader title={module.title} />
      <MaxWidthContainer>
        <Row mt={7}>
          <Col w={[4, 12, 7]}>
            <Row inner>
              <Col w={[4, 12, 12]}>
                {module.description && module.description.trim().length > 0 && (
                  <>
                    <T.H2 weight={600} mt={5} d="block">
                      Summary
                    </T.H2>
                    <T.P color="neutralGray" mt={2} d="block">
                      <Markdown text={module.description} />
                    </T.P>
                  </>
                )}

                {module.supportedCompetencies &&
                  module.supportedCompetencies.trim().length > 0 && (
                    <>
                      <T.H2 weight={600} mt={6} d="block">
                        What clinical or professional competencies does the
                        module support?
                      </T.H2>
                      <T.P color="neutralGray" mt={2} d="block">
                        <Markdown text={module.supportedCompetencies} />
                      </T.P>
                    </>
                  )}

                {module.whatIsItFor && module.whatIsItFor.trim().length > 0 && (
                  <>
                    <T.H2 weight={600} mt={6} d="block">
                      Who is the module for?
                    </T.H2>
                    <T.P color="neutralGray" mt={2} d="block">
                      <Markdown text={module.whatIsItFor} />
                    </T.P>
                  </>
                )}

                {module.additionalInfo &&
                  module.additionalInfo.trim().length > 0 && (
                    <>
                      <T.H2 weight={600} mt={6} d="block">
                        Additional information
                      </T.H2>
                      <T.P color="neutralGray" mt={2} d="block">
                        <Markdown text={module.additionalInfo} />
                      </T.P>
                    </>
                  )}

                {module.prerequisiteModules?.length > 0 && (
                  <>
                    {' '}
                    <T.H2 weight={600} mt={6} d="block">
                      Pre-requisite module(s)
                    </T.H2>
                    <T.P color="neutralGray" mt={2} d="block">
                      To complete this module you must first complete:
                    </T.P>
                    {module?.prerequisiteModules?.map(mod => (
                      <T.P d={'block'} weight={700} color="gray">
                        -{' '}
                        <T.Link
                          weight={700}
                          to={(povertyProofingModule
                            ? POVERTY_MODULE_URL
                            : MODULE_URL
                          ).replace(':id', mod._id)}
                          color="gray"
                        >
                          {mod.title}
                        </T.Link>
                      </T.P>
                    ))}
                  </>
                )}
              </Col>
            </Row>
          </Col>
          <Col w={[0, 0, 1]}></Col>
          <Col
            w={[4, 12, 4]}
            style={{
              backgroundColor: povertyProofingModule
                ? colors.mainSecondary
                : colors.mainPrimary,
              color: povertyProofingModule ? colors.darkGray : colors.white,
              borderRadius: 4
            }}
            mtT={7}
          >
            <LearningObjectivesRow inner>
              {module.learningObjectives &&
                module.learningObjectives.trim().length > 0 && (
                  <>
                    <Col w={[4, 12, 12]}>
                      <T.H2 weight={600} color="inherit" d="block">
                        Learning objectives
                      </T.H2>
                    </Col>
                    <Col w={[4, 12, 12]} mt={4}>
                      <Markdown
                        text={module.learningObjectives}
                        color="inherit"
                      />
                    </Col>
                  </>
                )}
              {module.developers && module.developers.trim().length > 0 && (
                <>
                  <Col w={[4, 12, 12]} mt={4}>
                    <T.H3 weight={600} color="inherit" d="block">
                      Name/organisation of module developer
                    </T.H3>
                    <T.P color="inherit" mt={2} d="block">
                      {module.developers}
                    </T.P>
                  </Col>
                </>
              )}
              <Col w={[4, 12, 12]} mt={4}>
                <T.H3 weight={600} color="inherit" d="block">
                  Module format
                </T.H3>
                <T.P color="inherit" mt={2} d="block">
                  {module.isLive ? 'Live' : 'eLearning'}
                </T.P>
              </Col>
              {module.duration && module.duration.trim().length > 0 && (
                <>
                  <Col w={[4, 12, 12]} mt={5}>
                    <T.H3 weight={600} color="inherit" d="block">
                      Module duration
                    </T.H3>
                  </Col>
                  <Col w={[4, 12, 12]} mt={2}>
                    <Icon icon="time" width="24" weight="24" color="inherit" />
                    <T.P color="inherit" ml={1}>
                      {module.duration}
                    </T.P>
                  </Col>
                </>
              )}
            </LearningObjectivesRow>
          </Col>
        </Row>
        {!module.isLive ? (
          <RegisterRow mt={7}>
            <Col w={[4, 12, 12]}>
              <T.H1 weight={600}>Register now</T.H1>
            </Col>
            {userId ? (
              <>
                <Col w={[4, 12, 12]} mt={5}>
                  <Button
                    label="Register"
                    width="200px"
                    height="100%"
                    type="primary"
                    disabled={
                      role !== userRoles.participant ||
                      nonLiveSessionWithNoSession
                    }
                    onClick={onSubmit}
                    loading={loading}
                  />
                </Col>
                {role !== userRoles.participant &&
                  !nonLiveSessionWithNoSession && (
                    <Col w={[4, 12, 12]} mt={2}>
                      <T.P>
                        You must log in with participant account to register
                      </T.P>
                    </Col>
                  )}

                {nonLiveSessionWithNoSession && (
                  <Col w={[4, 12, 12]} mt={2}>
                    <T.P mt={2}>
                      Sorry, There are no sessions available for this module!
                    </T.P>
                  </Col>
                )}
              </>
            ) : (
              <>
                <Col w={[4, 12, 12]} mt={3}>
                  <T.P>
                    To register you must create a free training account.
                    <br />
                    This account will let you attend courses, receive your
                    certificates and track your progress.
                  </T.P>
                </Col>
                <Col w={[4, 12, 12]} mt={5}>
                  <Button
                    label="Sign up"
                    width="200px"
                    type="primary"
                    to={{
                      pathname: PARTICIPANT_SIGN_UP_URL,
                      search: searchParams.toString()
                    }}
                  />
                </Col>
                <Col w={[4, 12, 12]} mt={3}>
                  <Button
                    label="Log in"
                    width="200px"
                    type="tertiary"
                    to={{
                      pathname: PARTICIPANT_LOGIN,
                      search: searchParams.toString()
                    }}
                  />
                </Col>
              </>
            )}
          </RegisterRow>
        ) : (
          <Row mt={9}>
            <Col w={[4, 12, 12]}>
              <T.H1 weight={600}>Register now</T.H1>
            </Col>
            <Col w={[4, 12, 12]}>
              <T.P weight={400} mt={3} color="neutralGray">
                {upcomingSessions?.length
                  ? 'Here are our current available sessions'
                  : 'There are no sessions available for this module'}
              </T.P>
            </Col>
            {!!upcomingSessions?.length && (
              <Col w={[4, 12, 12]} mt={5}>
                <TableWrapperRow inner>
                  <Col w={[4, 12, 12]}>
                    <Table
                      data={upcomingSessions?.map(
                        ({ shortId, date, address, remote }) => {
                          return {
                            date: { value: moment(date).format('DD/MM/YYYY') },
                            location: {
                              value: remote
                                ? 'Online'
                                : convertAddressObjToString(address) || 'TBC'
                            },
                            details: {
                              value: (
                                <T.Link
                                  onClick={() => onSubmit(shortId)}
                                  color="primary"
                                >
                                  View & Register
                                </T.Link>
                              )
                            }
                          };
                        }
                      )}
                      columns={sessionsColumns}
                    />
                  </Col>
                </TableWrapperRow>
              </Col>
            )}
          </Row>
        )}
        {povertyProofingModule && <CNECard footer />}
      </MaxWidthContainer>
      <Modal visible={showModal} setModalOpen={setShowModal}>
        <>
          {' '}
          <T.H3 weight={600} mt={6} d="block" color="darkGray">
            Pre-requisite module(s)
          </T.H3>
          <T.P color="neutralGray" mt={2} d="block">
            To be eligible for this module, you must first complete:{' '}
          </T.P>
          {nonCompletedModules?.map(mod => (
            <T.P d={'block'} weight={700} color="gray">
              -{' '}
              <T.Link
                weight={700}
                onClick={() => {
                  setShowModal(false);
                  setTimeout(() => {
                    window.scrollTo(0, 0);
                  }, 0);
                }}
                to={(povertyProofingModule
                  ? POVERTY_MODULE_URL
                  : MODULE_URL
                ).replace(':id', mod._id)}
                color="gray"
                mt={2}
              >
                {mod.title}
              </T.Link>
            </T.P>
          ))}
        </>
        <Button
          mt={8}
          label="Close"
          type="primary"
          width="200px"
          onClick={() => setShowModal(false)}
        />
      </Modal>
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    httpError: state.error,
    loading: state.loading.confirmRegistrationLoading,
    module: state.modules.module,
    userId: state.auth.userId,
    role: state.auth.role,
    sessions: state.sessions?.participantSessionsByEmail
  };
};

export default connect(mapStateToProps, {
  fetchCustomModule,
  confirmRegistration,
  fetchParticipantSessions
})(Module);
