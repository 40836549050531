import TextInput from './TextInput';
import Date from './Date';
import FromZeroToTen from './FromZeroToTen';
import Rate from './Rate';
import MatrixRateFrom0To5 from './MatrixRateFrom0To5';
import Radio from './Radio';
import Dropdown from './Dropdown';
import GroupedDropdown from './GroupedDropdown';
import RateFromOneToTen from './RateFromOneToTen';

const Question = ({ question, setQuestion, inputType, onBlur, disabled }) => {
  switch (inputType) {
    case 'text':
      return (
        <TextInput
          question={question}
          setQuestion={setQuestion}
          onBlur={onBlur}
          disabled={disabled}
        />
      );
    case 'date':
      return (
        <Date
          question={question}
          setQuestion={setQuestion}
          onBlur={onBlur}
          disabled={disabled}
        />
      );
    case 'fromZeroToTen':
      return (
        <FromZeroToTen
          question={question}
          setQuestion={setQuestion}
          onBlur={onBlur}
          disabled={disabled}
        />
      );
    case 'rateFrom0To5':
      return (
        <Rate
          question={question}
          setQuestion={setQuestion}
          onBlur={onBlur}
          disabled={disabled}
        />
      );
    case 'matrixRateFrom0To5':
      return (
        <MatrixRateFrom0To5
          question={question}
          setQuestion={setQuestion}
          onBlur={onBlur}
          disabled={disabled}
        />
      );
    case 'radio':
      return (
        <Radio
          question={question}
          setQuestion={setQuestion}
          onBlur={onBlur}
          disabled={disabled}
        />
      );
    case 'dropdown':
      return (
        <Dropdown
          question={question}
          setQuestion={setQuestion}
          onBlur={onBlur}
          disabled={disabled}
        />
      );
    case 'groupedDropDown':
      return (
        <GroupedDropdown
          question={question}
          setQuestion={setQuestion}
          onBlur={onBlur}
          disabled={disabled}
        />
      );
    case 'rateFrom1To10':
      return (
        <RateFromOneToTen
          question={question}
          setQuestion={setQuestion}
          onBlur={onBlur}
          disabled={disabled}
        />
      );

    default:
      return null;
  }
};

export default Question;
