import styled from 'styled-components';
import setMargin from '../../../helpers/setMargin';

export const Wrapper = styled.div`
  ${setMargin}
  min-height: 172px;
  background: ${({ theme, bgColor }) =>
    theme.colors[bgColor] || theme.colors.mainPrimary};
  padding: 24px;
  border-radius: 4px;
  @media ${({ theme }) => theme.breakpoints.tablet} {
    border-radius: 8px;
    min-height: 216px;
    padding: 48px 32px 32px 32px;
  }
`;

export const HideInDesktop = styled.div`
  max-width: 292px;
  display: block;
  @media ${({ theme }) => theme.breakpoints.tablet} {
    display: none;
  }
`;

export const HideInTablet = styled.div`
  display: none;
  max-width: 720px;
  @media ${({ theme }) => theme.breakpoints.tablet} {
    display: block;
  }
`;

export const TitleWrapper = styled.div`
  max-width: 720px;
`;
