import Layout from '../../Layouts';

import MaxWidthContainer from '../../common/MaxWidthContainer';
import * as T from '../../common/Typography';
import WebsiteHeader from '../../common/WebsiteHeader';
import { Col, Row } from '../../common/Grid';
import Button from '../../common/Button';
import { ALCOHOL_STUDIES } from '../../../constants/navigationRoutes';

const AlcoholStudiesAboutUs = () => {
  return (
    <Layout layout="website">
      <WebsiteHeader title="About the ICS Alcohol Training Programme" />
      <MaxWidthContainer>
        <Row mt="7" mtT="4" mb="8">
          <Col w={[4, 12, 8]}>
            <T.P color="gray" mb="6">
              The North East & North Cumbria experiences the greatest level of
              harm due to alcohol in the UK. This has led to the{' '}
              <T.Link
                underline
                color="gray"
                external
                weight="400"
                to="https://northeastnorthcumbria.nhs.uk/"
              >
                {' '}
                North East & North Cumbria Health and Care Partnership
              </T.Link>{' '}
              identifying alcohol harm as a priority for prevention. The{' '}
              <T.Link
                underline
                color="gray"
                external
                weight="400"
                to=" https://northeastnorthcumbria.nhs.uk/our-work/workstreams/health-and-prevention/alcohol-programme/"
              >
                ICS Alcohol Programme
              </T.Link>{' '}
              leads on this work and has a strategic plan to reduce alcohol
              harm.
              <br /> <br /> From listening to our workforce we know that there
              is a lack of training and development opportunities in some areas
              of alcohol related harm. The Programme of Alcohol Studies has been
              developed as a collaboration between the{' '}
              <T.Link
                underline
                color="gray"
                external
                weight="400"
                to="https://northeastnorthcumbria.nhs.uk/"
              >
                {' '}
                North East & North Cumbria Health and Care Partnership
              </T.Link>{' '}
              and
              <T.Link
                underline
                color="gray"
                external
                weight="400"
                to="https://madeinheene.hee.nhs.uk/"
              >
                {' '}
                NHS England Education
              </T.Link>{' '}
              to provide consistent access to training on the prevention, early
              identification and management of alcohol harm. The programme
              provides links to existing good quality training where available.
              <br /> <br />
              The programme is available for all staff in health, social care
              and the third sector providing alcohol related care in this
              region.{' '}
            </T.P>
            <Button
              to={ALCOHOL_STUDIES}
              type="primary"
              label="View programme"
              width="270px"
            />
          </Col>
        </Row>
      </MaxWidthContainer>
    </Layout>
  );
};

export default AlcoholStudiesAboutUs;
