import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Drawer } from 'antd';

import Icon from '../Icon';

import Spin from '../Spin';
import { Row, Col } from '../Grid';
import * as T from '../Typography';
import { withTheme } from 'styled-components';

import InviteEmails from '../../pages/SessionDetails/InviteAndPromote/InviteEmails';

import { Wrapper, BackWrapper } from './List.style';

class EmailsList extends Component {
  state = {
    visible: false,
    emailId: null
  };

  showDrawer = id => {
    this.setState({
      visible: true,
      emailId: id
    });
  };

  onDrawerClose = () => {
    this.setState({
      visible: false
    });
  };

  render() {
    const { dataList, theme } = this.props;
    const { visible, emailId } = this.state;
    const { showDrawer, onDrawerClose } = this;
    const activeEmail =
      dataList &&
      dataList.sentEmails.filter(item => {
        return item._id === emailId;
      });

    const { colors } = theme;

    if (!dataList) {
      return <Spin />;
    }

    const filteredEmails =
      dataList.sentEmails &&
      dataList.sentEmails.filter(item => item.type === 'registration');

    return (
      <>
        <T.H1 ml="5" mb="5">
          Sent emails
        </T.H1>
        <T.P ml="5" mb="6">
          Here you can view a log of each email you have sent and the core
          information in each email.
        </T.P>
        {filteredEmails?.length > 0 ? (
          <Wrapper ml="5">
            <Row
              mb="3"
              style={{
                borderBottom:
                  filteredEmails?.length > 0
                    ? 'none'
                    : `1px solid ${colors.midPrimary}`
              }}
            >
              <Col w={[1, 6, 6]}>
                <T.P weight={700} color="darkGray" mb="2">
                  Date
                </T.P>
              </Col>
              <Col w={[1, 6, 6]}>
                <T.P weight={700} color="darkGray" mb="2">
                  Info
                </T.P>
              </Col>
            </Row>

            {filteredEmails.map(dataItem => (
              <Row
                key={dataItem._id}
                onClick={() => showDrawer(dataItem && dataItem._id)}
                mb="2"
              >
                <Col w={[2, 6, 6]}>
                  <T.P color="gray">
                    {moment(dataItem.date).format('DD/MM/YYYY')}
                  </T.P>
                </Col>
                <Col w={[2, 6, 6]}>
                  <button
                    type="button"
                    onClick={() => showDrawer(dataItem && dataItem._id)}
                    style={{
                      background: 'none',
                      border: 'none',
                      marginRight: '.5rem',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <T.P mr="2" color="primary">
                      View email
                    </T.P>
                    <Icon
                      icon="chevron"
                      direction="right"
                      width="16"
                      height="16"
                      color="primary"
                    />
                  </button>
                </Col>
              </Row>
            ))}

            <Drawer
              placement="right"
              closable={false}
              onClose={onDrawerClose}
              visible={visible}
              width="100%"
            >
              <>
                <BackWrapper onClick={onDrawerClose}>
                  <Icon
                    icon="chevron"
                    direction="left"
                    color="primary"
                    width="16px"
                    height="16px"
                    mr="3"
                  />
                  <p
                    style={{
                      marginBottom: '0'
                    }}
                  >
                    Back
                  </p>
                </BackWrapper>
                <InviteEmails
                  sessionDetails={dataList}
                  emailInfo={activeEmail && activeEmail[0]}
                  onClose={onDrawerClose}
                />
              </>
            </Drawer>
          </Wrapper>
        ) : (
          <Row jc="center" mt="5">
            <Col w={[4, 12, 12]} jc="center">
              <T.P weight="700" color="gray" ta="center">
                No emails have been sent
              </T.P>
            </Col>
          </Row>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    sessionDetails: state.sessions.sessionDetails[0]
  };
};
export default connect(mapStateToProps, {})(withTheme(EmailsList));
