import { useHistory } from 'react-router-dom';
import useSearchParams from './useSearchParams';
import {
  PARTICIPANT_DASHBOARD,
  DASHBOARD_URL,
  REGISTER_URL,
  MODULE_URL,
  SURVEY_URL,
  POVERTY_MODULE_URL
} from '../constants/navigationRoutes';
import { userRoles } from '../constants';

const useRedirectAfterAuth = () => {
  const history = useHistory();
  const searchParams = useSearchParams();

  const redirectAfterAuth = role => {
    if (searchParams.params.source === 'survey') {
      history.push({
        pathname: SURVEY_URL.replace(
          ':id',
          searchParams.params.shortId
        ).replace(':type', searchParams.params.surveyType)
      });
    } else if (searchParams.params.source === 'moduleRegistration') {
      history.push(
        (searchParams.params.povertyProofingModule
          ? POVERTY_MODULE_URL
          : MODULE_URL
        ).replace(':id', searchParams.params.moduleId)
      );
    } else if (searchParams.params.source === 'registration') {
      history.push(REGISTER_URL.replace(':id', searchParams.params.shortId));
    } else if (role === userRoles.participant) {
      history.push(PARTICIPANT_DASHBOARD);
    } else {
      history.push(DASHBOARD_URL);
    }
  };

  return redirectAfterAuth;
};

export default useRedirectAfterAuth;
