import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  fetchManagersNames,
  removeTrainerFromGroupAction
} from '../../../actions/users';

import * as T from './../../common/Typography';
import Layout from './../../Layouts';
import Icon from '../../common/Icon';

// STYLING
import {
  Wrapper,
  TopInfo,
  Detail,
  DetailsContent,
  Owner,
  StyledLink,
  Hint,
  LeaveBtn
} from './LocalLeadsAndManagersList.style';

// ROUTES
import { MY_PROFILE_URL } from '../../../constants/navigationRoutes';

//  COMMON COMPONENTS
import Spin from '../../common/Spin';

class LocalLeadsAndManagersList extends Component {
  state = {};

  componentDidMount() {
    const { fetchManagersNames: fetchManagersNamesActionCreator } = this.props;
    fetchManagersNamesActionCreator(this.props.history);
  }

  removeTrainerFromGroup = managerId => {
    const {
      removeTrainerFromGroupAction: removeTrainerFromGroupActionCreator
    } = this.props;
    removeTrainerFromGroupActionCreator(managerId, this.props.history);
  };

  render() {
    const { trainerManagers, localLead } = this.props;

    const notMyLocalLeads = trainerManagers.filter(manager => {
      return manager._id !== localLead;
    });

    const [myLocalLead] = trainerManagers.filter(manager => {
      return manager._id === localLead;
    });

    if (!notMyLocalLeads.length && !myLocalLead) {
      return <Spin />;
    }

    return (
      <Layout>
        <Wrapper>
          <T.H1>Manage My Groups</T.H1>{' '}
          <TopInfo>
            <p>
              Certain Trainers and Local Leads may add you to their trainer
              group. This allows them to set up sessions for you and view your
              results to help with reporting.
            </p>
            <p>Below are the trainer groups you are part of:</p>
          </TopInfo>
          <DetailsContent>
            <Detail>Local Lead Group</Detail>
            <Owner>Owner: {myLocalLead && myLocalLead.name}</Owner>
            <Hint>
              It is required that you are part of your Local Lead’s group. If
              they are not your Local Lead any more, please edit your Local Lead
              in your{' '}
              <StyledLink to={MY_PROFILE_URL} target="_blank">
                <span
                  style={{ fontWeight: 'bold', textDecoration: 'underline' }}
                >
                  {' '}
                </span>
              </StyledLink>
            </Hint>
          </DetailsContent>
          {notMyLocalLeads &&
            notMyLocalLeads.map(item => {
              return (
                <DetailsContent>
                  <Detail>Trainer Group</Detail>
                  <Owner>Owner: {item.name}</Owner>
                  <LeaveBtn
                    onClick={() => this.removeTrainerFromGroup(item._id)}
                  >
                    <Icon
                      icon="time"
                      width="16px"
                      height="16px"
                      color="primary"
                      mr={2}
                    />
                    Leave This Group
                  </LeaveBtn>
                </DetailsContent>
              );
            })}
        </Wrapper>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    userName: state.auth.name,
    email: state.auth.email,
    userId: state.auth.userId,
    role: state.auth.role,
    organization: state.auth.organization,
    region: state.auth.region,
    localLead: state.auth.localLead,
    localLeadsList: state.fetchedData.localLeadsList,
    updateUserLoading: state.loading.updateUserLoading,
    deleteAccountLoading: state.loading.deleteAccountLoading,
    managers: state.auth.managers,
    trainerManagers: state.fetchedData.trainerManagers
  };
};

export default connect(mapStateToProps, {
  fetchManagersNames,
  removeTrainerFromGroupAction
})(LocalLeadsAndManagersList);
