import { Table as AntdTable } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
`;
export const Table = styled(AntdTable)`
  width: 100%;

  background: ${({ theme }) => theme.colors.lightPrimary} !important;
  border-radius: 12px !important;
  table {
    border-radius: 12px;
    overflow: hidden;
  }
  th {
    background: ${({ theme }) => theme.colors.lightPrimary} !important;
  }
  tr:hover > td {
    background: ${({ theme }) => theme.colors.lightPrimary} !important;
  }
`;
