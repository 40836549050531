import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Select, Modal as AntModal, Input } from 'antd';

import {
  fetchLocalLeads as fetchLocalLeadsAction,
  updateUserInfo as updateUserInfoAction,
  fetchStatsData
} from '../../../actions/users';

import { logout } from '../../../actions/authAction';
import { deleteAccountAction } from '../../../actions/deleteAccountAction';
import { TRAINER_MANAGERS_GROUPS } from '../../../constants/navigationRoutes';

import * as T from './../../common/Typography';
import Modal from '../../common/modal';
import { Row, Col } from '../../common/Grid';
import Button from '../../common/Button';
import Layout from './../../Layouts';
import {
  createGroupedLocalLeads,
  captalizesName
} from '../../../helpers/createGroupedLocalLeads';

// STYLING
import {
  Wrapper,
  DetailsContent,
  Detail,
  BoldSpan,
  Row as StyledRow,
  DeteteAccountBtn,
  StyledLink
} from './MyProfile.style';

const { confirm } = AntModal;

const { Option, OptGroup } = Select;

class MyProfile extends Component {
  state = {
    organization: null,
    localLead: null,
    visible: false,
    activeStatus: ''
  };

  componentDidMount() {
    const { fetchLocalLeads } = this.props;
    fetchLocalLeads();
  }

  componentDidUpdate(prevProps) {
    const {
      organization: currentOrganization,
      localLead: currentLocalLead
    } = this.props;
    const {
      organization: prevOrganization,
      localLead: prevLocalLead
    } = prevProps;
    if (currentOrganization !== prevOrganization) {
      this.updateState({ organization: currentOrganization });
    }
    if (currentLocalLead !== prevLocalLead) {
      this.updateState({ localLead: currentLocalLead });
    }
  }

  updateState = data => {
    this.setState(data);
  };

  showModal = key => {
    const { organization } = this.props;
    this.setState({
      visible: true,
      activeStatus: key,
      organization
    });
  };

  handleOk = () => {
    const {
      localLead: localLeadState,
      organization: organizationState
    } = this.state;

    const {
      localLead: localLeadProps,
      organization: organizationProps,
      updateUserInfo
    } = this.props;

    const data = {};

    if (localLeadProps !== localLeadState) {
      data.localLead = localLeadState;
    }

    if (organizationProps !== organizationState) {
      data.organization = organizationState;
    }

    if (Object.keys(data).length > 0) {
      updateUserInfo(data, this.updateState);
    }
  };

  handleCancel = () => {
    this.setState({
      visible: false
    });
  };

  handleChangeOrg = e => {
    const { value } = e.target;
    this.setState({ organization: value });
  };

  handleChangeLocalLead = localLead => {
    this.setState({ localLead });
  };

  deleteAccount = (userId, role) => {
    const {
      deleteAccountAction: deleteAccountActionCreator,
      deleteAccountLoading
    } = this.props;
    if (role === 'trainer' || role === 'localLead') {
      confirm({
        title: 'Are you sure that you want to delete your account?',
        content:
          'By doing this you will not be able to log into any of your Connect 5 accounts. This cannot be undone.',
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        okButtonProps: {
          loading: deleteAccountLoading
        },
        loading: true,
        onOk() {
          deleteAccountActionCreator(userId);
        }
      });
    }
  };

  render() {
    const { deleteAccount } = this;
    const {
      userName,
      role,
      region,
      email,
      localLeadsList,
      localLead,
      organization,
      userId
    } = this.props;

    const {
      visible,
      activeStatus,
      updateUserLoading,
      organization: organizationState
    } = this.state;

    const [trainerLocalLead] = localLeadsList.filter(item => {
      return item._id === localLead;
    });

    const groupedLocalLeads = createGroupedLocalLeads(localLeadsList);

    return (
      <Layout>
        <Wrapper>
          <T.H1 mb="8">My Profile</T.H1>
          <DetailsContent>
            <StyledRow>
              <Detail>
                <BoldSpan>Name: </BoldSpan>
                {captalizesName(userName)}
              </Detail>
            </StyledRow>
            <StyledRow>
              <Detail>
                <BoldSpan>Email: </BoldSpan>
                {email}
              </Detail>
            </StyledRow>
            <StyledRow>
              <Detail>
                <BoldSpan>Role: </BoldSpan>
                {role === 'localLead' ? 'Local Lead' : role}
              </Detail>
            </StyledRow>
            <StyledRow>
              <Detail>
                <BoldSpan>Region: </BoldSpan>
                {region || 'N/A'}
              </Detail>
            </StyledRow>

            <StyledRow>
              <Detail>
                <BoldSpan>Organisation: </BoldSpan>
                {organization || 'N/A'}
              </Detail>
              <Detail>
                <BoldSpan
                  onClick={() => this.showModal('Organisation')}
                  pointer
                >
                  Edit
                </BoldSpan>
              </Detail>
            </StyledRow>

            {role === 'trainer' && (
              <StyledRow>
                <Detail>
                  <BoldSpan>Local lead: </BoldSpan>
                  {captalizesName(trainerLocalLead && trainerLocalLead.name) ||
                    'N/A'}
                </Detail>
                <Detail>
                  <BoldSpan
                    onClick={() => this.showModal('Local Lead')}
                    pointer
                  >
                    Edit
                  </BoldSpan>
                </Detail>
              </StyledRow>
            )}

            {role === 'trainer' && (
              <StyledRow>
                <Detail>
                  <BoldSpan>Groups I belong to: </BoldSpan>
                </Detail>
                <Detail>
                  <StyledLink to={TRAINER_MANAGERS_GROUPS}>
                    <span style={{ fontWeight: 'bold' }}>View</span>
                  </StyledLink>
                </Detail>
              </StyledRow>
            )}
          </DetailsContent>
          <StyledLink to="/change-password">Change My Password</StyledLink>
          <DeteteAccountBtn onClick={() => deleteAccount(userId, role)}>
            Delete My Account
          </DeteteAccountBtn>
          <Modal
            title={`Edit ${activeStatus}`}
            visible={visible}
            parentFunc={this.handleOk}
            loading={updateUserLoading}
            setModalOpen={this.handleCancel}
          >
            {activeStatus === 'Organisation' ? (
              <>
                <Row mb={4}>
                  <Col w={[4, 12, 12]}>
                    <Input
                      placeholder="Organisation"
                      onChange={this.handleChangeOrg}
                      value={organizationState}
                      label="Edit organisation"
                    />
                  </Col>
                </Row>
                <Row mb={2}>
                  <Col w={[4, 12, 6]}>
                    <Button
                      type="primary"
                      onClick={this.handleCancel}
                      label="Back"
                      loading={updateUserLoading}
                    />
                  </Col>
                  <Col w={[4, 12, 6]}>
                    <Button
                      type="secondary"
                      onClick={() => this.handleOk()}
                      label="Confirm"
                      loading={updateUserLoading}
                    />
                  </Col>
                </Row>
              </>
            ) : (
              <Select
                style={{ width: '100%' }}
                placeholder="Select your trainer"
                defaultValue={captalizesName(
                  (trainerLocalLead && trainerLocalLead.name) || undefined
                )}
                onChange={this.handleChangeLocalLead}
              >
                {Object.keys(groupedLocalLeads).map(item => (
                  <OptGroup key={`OptGroup${item.region}`} label={item}>
                    {groupedLocalLeads[item]
                      .filter(el => el.officialLocalLead === true)
                      .map(_localLead => {
                        return (
                          <Option key={_localLead._id} value={_localLead._id}>
                            {_localLead.name}
                          </Option>
                        );
                      })}
                  </OptGroup>
                ))}
              </Select>
            )}
          </Modal>
        </Wrapper>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    userName: state.auth.name,
    email: state.auth.email,
    userId: state.auth.userId,
    role: state.auth.role,
    organization: state.auth.organization,
    region: state.auth.region,
    localLead: state.auth.localLead,
    localLeadsList: state.fetchedData.localLeadsList,
    updateUserLoading: state.loading.updateUserLoading,
    deleteAccountLoading: state.loading.deleteAccountLoading,
    managers: state.auth.managers
  };
};

export default connect(mapStateToProps, {
  fetchLocalLeads: fetchLocalLeadsAction,
  updateUserInfo: updateUserInfoAction,
  fetchStatsData,
  logout,
  deleteAccountAction
})(MyProfile);
