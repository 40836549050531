import styled from 'styled-components';

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.lightPrimary};
  padding: ${({ theme }) => theme.spacings[6]};
  border-radius: ${({ theme }) => theme.borders.radiusLarge};
  width: 100%;
`;

export const Bold = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.darkGray};
`;

export const Error = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.error};
  font-size: 0.9rem;
`;
