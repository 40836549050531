import styled from 'styled-components';

export const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
`;

export const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const GraphWrapper = styled.div``;

export const GraphImg = styled.img`
  width: 100%;
  max-height: 272px;
  min-height: 130px;
  @media ${({ theme }) => theme.breakpoints.tablet} {
    max-height: none;
  }
`;

export const Content = styled.div`
  max-width: 1440px;
  margin-top: ${({ theme }) => theme.layoutConstants.headerHeightM};
  margin-bottom: ${({ theme }) => theme.layoutConstants.headerHeightM};
  flex: 1;
  padding: ${({ theme: { layoutConstants } }) =>
    `${layoutConstants.topMarginM} ${layoutConstants.rightMarginM} ${layoutConstants.bottomMarginM} ${layoutConstants.leftMarginM}`};
  padding-bottom: 0;
  @media ${({ theme }) => theme.breakpoints.tablet} {
    max-width: ${({ maxWidth }) => maxWidth || '1440px'};
  }
  @media ${({ theme }) => theme.breakpoints.laptop} {
    margin-top: ${({ theme }) => theme.layoutConstants.headerHeight};

    padding: ${({ theme: { layoutConstants } }) =>
      `${layoutConstants.topMargin} ${layoutConstants.rightMargin} ${layoutConstants.bottomMargin} ${layoutConstants.leftMargin}`};
    padding-bottom: 0;
  }

  width: 100%;
`;
