import * as S from './style';
import Icon from '../Icon';

const IconCard = ({
  bgColor,
  icon,
  iconColor = 'white',
  children,
  Img,
  ...props
}) => {
  return (
    <S.Wrapper {...props}>
      <S.CardHead bgColor={bgColor}>
        {!!Img && <Img />}
        {!!icon && <Icon icon={icon} color={iconColor} />}
      </S.CardHead>
      {children}
    </S.Wrapper>
  );
};

export default IconCard;
