// get headers array from headers object
const convertDynamicHeadersObjToArray = dynamicHeadersObj => {
  const dynamicHeaders = [];
  Object.keys(dynamicHeadersObj)
    .sort((a, b) => a - b)
    .forEach(groupOrder => {
      Object.keys(dynamicHeadersObj[groupOrder])
        .sort((a, b) => a - b)
        .forEach(subGroupOrder => {
          Object.keys(dynamicHeadersObj[groupOrder][subGroupOrder])
            .sort((a, b) => a - b)
            .forEach(questionText => {
              dynamicHeaders.push(
                dynamicHeadersObj[groupOrder][subGroupOrder][questionText]
              );
            });
        });
    });
  return dynamicHeaders;
};

// add question to answers headers object
const updateAnswersHeadersObj = ({
  groupOrder,
  subGroupOrder,
  groupText,
  questionText,
  dynamicHeadersObj
}) => {
  if (!dynamicHeadersObj[groupOrder]) {
    dynamicHeadersObj[groupOrder] = {};
  }
  if (!dynamicHeadersObj[groupOrder][subGroupOrder]) {
    dynamicHeadersObj[groupOrder][subGroupOrder] = {};
  }
  const questionKey = `${groupText} - ${questionText}`;
  dynamicHeadersObj[groupOrder][subGroupOrder][questionKey] = questionKey;
};

// add answer to response object
const addAnswerToResponse = ({
  responseObj,
  groupText,
  questionText,
  answer
}) => {
  let _answer = answer;
  if (Array.isArray(_answer)) {
    _answer = _answer.join(', ');
  } else if (typeof _answer === 'object') {
    let str = '';
    Object.entries(_answer).forEach(([key, value]) => {
      str += `${key}: ${value}，`;
    });
    _answer = str;
  }
  const questionKey = `${groupText} - ${questionText}`;

  if (typeof _answer === 'string') {
    // replace commas with full-width commas so it doesn't parse as array/object
    return (responseObj[questionKey] = _answer?.replace(/,/g, '，'))?.replace(
      /"/g,
      '”'
    );
  }
  return (responseObj[questionKey] = _answer);
};

// transforms response object into csv row and updates csv headers
const transformer = ({ response, headers }) => {
  const responseObj = { ...response };

  // if fixedHeaders is empty, add all keys from responseObj
  if (headers.fixedHeaders.length === 0) {
    const keys = Object.keys(responseObj).filter(key => key !== 'answers');
    headers.fixedHeaders = [...new Set([...headers.fixedHeaders, ...keys])];
  } else {
    // if headers.fixedHeaders is not empty, add any new keys from responseObj
    Object.keys(responseObj).forEach(key => {
      if (!headers.fixedHeaders.includes(key) && key !== 'answers') {
        headers.fixedHeaders = [...new Set([...headers.fixedHeaders, key])];
      }
    });
  }

  // for each answer, add question to dynamicHeadersObj and add answer to responseObj
  if (response.answers && response.answers.length > 0) {
    response.answers.forEach(answer => {
      const { question } = answer;
      const groupOrder = question.group.order || 1000;
      const subGroupOrder = question?.subGroup?.order;
      updateAnswersHeadersObj({
        groupOrder,
        subGroupOrder,
        groupText: question.group.text,
        questionText: question.text,
        dynamicHeadersObj: headers.dynamicHeadersObj
      });
      addAnswerToResponse({
        responseObj,
        groupText: question.group.text,
        questionText: question.text,
        answer: answer.answer
      });
    });
  }

  delete responseObj.answers;
  return responseObj;
};

const getCsvDataAndHeadersForExportResponses = data => {
  const headers = {
    fixedHeaders: [], // non-answer keys
    dynamicHeadersObj: {} // answers keys
  };

  const CsvDataWithAnswers = data.map(response =>
    transformer({
      response,
      headers
    })
  );

  const dynamicHeaders = convertDynamicHeadersObjToArray(
    headers.dynamicHeadersObj
  );
  return {
    csvData: CsvDataWithAnswers,
    csvHeaders: [...headers.fixedHeaders, ...dynamicHeaders]
  };
};

export default getCsvDataAndHeadersForExportResponses;
