import { courseLabelMap, modules } from '.';
export const meetingPlatforms = [
  { label: 'Zoom', value: 'zoom' },
  { label: 'Teams', value: 'teams' },
  { label: 'Google Meet', value: 'google' },
  { label: 'Other', value: 'other' }
];

export const sessionRemoteOrFace2FaceTypes = [
  { label: 'Remote / online', value: 'online' },
  { label: 'Face to Face', value: 'faceToFace' }
];

export const moduleOptions = Object.values(modules).map(module => ({
  value: module,
  label: module
}));

export const coursesCanDeliverOptions = courses =>
  courses.map(course => ({
    value: course,
    label: courseLabelMap[course]
  }));
