import styled from 'styled-components';
import setMargin from '../../../helpers/setMargin';

export const Wrapper = styled.div`
  ${setMargin}
  background: ${({ theme }) => theme.colors.headerBg};
  min-height: ${({ subtitle }) => (subtitle ? '138px' : '70px')};
  padding: 24px 32px;
  @media (min-width: 860px) {
  min-height: ${({ subtitle }) => (subtitle ? '276px' : 'auto')};

    padding: 48px 0 48px 0;
  }

`;

export const HideInDesktop = styled.div`
  max-width: 337px;
  flex-direction: column;
  margin: 0 auto;
  display: flex;
  @media (min-width: 860px) {
    display: none;
  }
`;

export const HideInTablet = styled.div`
  max-width: 800px;
  display: none;
  flex-direction: column;
  @media (min-width: 860px) {
    display: flex;
  }
`;
