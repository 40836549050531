import styled from 'styled-components';
import setMargin from '../../../helpers/setMargin';

export const Wrapper = styled.div`
  ${setMargin}
  background-color: ${({ theme }) => theme.colors.lightPrimary};
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacings[6]};
  margin-bottom: 5.5rem;
  max-width: 600px;
  border-radius: 12px;
  width: 100%;
`;
