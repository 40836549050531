import styled, { css } from 'styled-components';
import setMargins from '../../../helpers/setMargin';

const sharedStyles = css`
  ${setMargins}
  position: relative;
  text-align: center;
  text-decoration: none;
  outline: none;
  border: 0;
  color: ${({ theme }) => theme.colors.gray};
  cursor: ${({ loading }) => (loading ? 'not-allowed' : 'pointer')};
  font-weight: 700;
  font-size: 1rem;
  transition: all linear 0.1s;

  &:active::after {
    content: '';
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background: ${({ theme }) => theme.colors.transGray};
    box-shadow: none;
  }
`;

const disabledStyles = css`
  opacity: 0.5;
  color: ${({ theme }) => theme.colors.gray};
  cursor: not-allowed;

  :hover {
    opacity: 0.5;
    color: ${({ theme }) => theme.colors.gray};
  }
`;

export const StyledButton = styled.button`
  ${sharedStyles};
  ${props => props.disabled && disabledStyles}
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
`;
