import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import EditEmail from '../../common/EditEmail';
import Layout from '../../Layouts';
import { getSessionDetails } from '../../../actions/sessionAction';

const SessionCreated = ({
  getSessionDetailsAction,
  session,
  loading,
  name
}) => {
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    getSessionDetailsAction(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (!session) return <div>Loading</div>;
  return (
    <Layout>
      <EditEmail
        successMessage="Session created!"
        participantsEmails={session.participantsEmails}
        type="registration"
        trainer={name}
        sessionDate={session.date}
        address={session.address}
        trainers={session.trainers}
        startTime={session.startTime}
        endTime={session.endTime}
        shortId={session.shortId}
        endDate={session.endDate}
        sessionId={session._id}
        extraInfo={session.extraInfo}
        canAddParticipants
        course={session.course}
        modules={session.modules}
        sessionDetails={session}
        remote={session.remote}
        meetingLink={session.meetingLink}
        meetingPlatform={session.meetingPlatform}
        history={history}
      />
    </Layout>
  );
};

const mapStateToProps = state => ({
  loading: state.session.loading,
  session: state.session,
  name: state.auth.name
});

const mapActionToProps = {
  getSessionDetailsAction: getSessionDetails
};

export default connect(mapStateToProps, mapActionToProps)(SessionCreated);
