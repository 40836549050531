import { Icon } from 'antd';
import moment from 'moment';
import React from 'react';
import { useTheme } from 'styled-components';
import { concatModules } from '../../../helpers';
import Spin from '../Spin';
import * as T from '../Typography';

import { StyledLink, Wrapper } from './List.style';

import { Col, Row } from '../Grid';

import {
  PARTICIPANT_SESSION_DETAILS,
  SESSION_DETAILS_URL
} from '../../../constants/navigationRoutes';

// NOTE: this component expects dataList to look something like this:

const CompletedSessionList = ({ dataList, isParticipant, linkText }) => {
  const baseURL = isParticipant
    ? PARTICIPANT_SESSION_DETAILS
    : SESSION_DETAILS_URL;

  const { colors, spacings } = useTheme();

  if (!dataList || !Array.isArray(dataList)) {
    return <Spin />;
  }
  return (
    <Wrapper>
      <Row
        style={{
          borderBottom:
            dataList?.length > 0 ? 'none' : `1px solid ${colors.midPrimary}`
        }}
      >
        <Col w={[1, 4, 4]}>
          <T.P weight={700} color="darkGray" mb="2">
            Session Date
          </T.P>
        </Col>
        <Col w={[1, 4, 4]}>
          <T.P weight={700} color="darkGray" mb="2">
            Module(s)
          </T.P>
        </Col>
        <Col w={[2, 4, 4]}>
          <T.P weight={700} color="darkGray" mb="2">
            Certificate
          </T.P>
        </Col>
      </Row>

      {dataList?.length > 0 ? (
        dataList.map(dataItem => (
          <Row
            key={dataItem.id}
            href={dataItem.link || undefined}
            to={dataItem.link || baseURL.replace(':id', dataItem._id)}
            target={dataItem.blank ? '_blank' : '_self'}
            style={{
              borderTop: `1px solid ${colors.midPrimary}`,
              padding: `${spacings[3]} 0`
            }}
          >
            <Col w={[1, 4, 4]}>
              <T.P color="gray">
                {moment(dataItem.date).format('DD/MM/YYYY')}
              </T.P>
            </Col>
            <Col w={[1, 4, 4]}>
              <T.P color="gray">
                {concatModules({
                  modules: dataItem?.modules,
                  customModules: dataItem?.customModules
                })}
              </T.P>
            </Col>
            <Col w={[2, 4, 4]}>
              <StyledLink
                as={dataItem.asLink ? 'a' : undefined}
                href={dataItem.link || undefined}
                to={dataItem.link || baseURL.replace(':id', dataItem._id)}
                target={dataItem.blank ? '_blank' : '_self'}
              >
                {dataItem.linkText || linkText || <Icon type="right" />}
              </StyledLink>
            </Col>
          </Row>
        ))
      ) : (
        <Row jc="center" mt="5">
          <Col w={[4, 12, 12]} jc="center">
            <T.P weight="700" color="gray" ta="center">
              No data found
            </T.P>
          </Col>
        </Row>
      )}
    </Wrapper>
  );
};

export default CompletedSessionList;
