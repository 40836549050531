import { fields, createSchema, validate as _validate } from '..';

const {
  email,
  arrayOfString,
  requiredDate,
  requiredNumber,
  sessionType,
  partnerTrainer,
  address,
  onlineDetails,
  optionalText,
  coursesTypes
} = fields;

const createSessionSchema = createSchema({
  date: requiredDate,
  endDate: requiredDate,
  startTime: optionalText,
  endTime: optionalText,
  sessionType,
  course: coursesTypes,
  modules: arrayOfString,
  capacity: requiredNumber,
  partnerTrainer,
  address,
  online: onlineDetails,
  extraInfo: optionalText,
  contactEmail: email
});

const validate = data => {
  const x = _validate(createSessionSchema, data);
  return x;
};

export default validate;
