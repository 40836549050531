import React from 'react';
import * as S from './style';

const NewTable = ({ columns, data, loading }) => {
  return (
    <S.Wrapper>
      <S.Table
        columns={columns}
        loading={loading}
        pagination={{
          hideOnSinglePage: true
        }}
        dataSource={data}
      />
    </S.Wrapper>
  );
};
export default NewTable;
