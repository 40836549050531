import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { fetchParticipantSessions } from '../../../actions/groupSessionsAction';
import CustomCourseParticipantSessions from './CustomCourseParticipantSessions';

import { Col, Row } from '../../common/Grid';
import CompletedSessionList from '../../common/List/CompletedSessionList';
import SessionList from '../../common/List/SessionList';
import * as T from '../../common/Typography';

import { isCustomCourse } from '../../../helpers/index';
import Layout from '../../Layouts';
import { checkIfSessionIsFinished } from '../../../helpers';

const ParticipantSessions = ({ sessions }) => {
  sessions.forEach(session => {
    session.isCompleted = checkIfSessionIsFinished(session);
  });
  return (
    <Layout>
      <Row mb={7}>
        <Col w={[4, 8, 8]}>
          <T.H1>My course</T.H1>
        </Col>
      </Row>
      <Row mb={7}>
        <Col w={[4, 8, 8]} dir="column" ai="flex-start">
          <T.P weight={700} mb={3}>
            Upcoming sessions
          </T.P>
          <SessionList
            dataList={sessions?.filter(ses => !ses.isCompleted)}
            isParticipant
          />
        </Col>
      </Row>
      <Row mb={7}>
        <Col w={[4, 8, 8]} dir="column" ai="flex-start">
          <T.P weight={700} mb={3}>
            Completed modules
          </T.P>
          <CompletedSessionList
            dataList={sessions?.filter(ses => ses.isCompleted)}
            isParticipant
            linkText="View"
          />
        </Col>
      </Row>
    </Layout>
  );
};

const ParticipantSessionsWrapper = ({ sessions, fetchSessions, ...props }) => {
  const { course } = useParams();

  useEffect(() => {
    fetchSessions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course]);

  const filteredSessions = sessions?.filter(ses => ses.course === course);

  if (isCustomCourse(course)) {
    return (
      <CustomCourseParticipantSessions
        course={course}
        sessions={filteredSessions}
        {...props}
      />
    );
  }
  return (
    <ParticipantSessions
      course={course}
      sessions={filteredSessions}
      {...props}
    />
  );
};

const mapStateToProps = state => ({
  sessions: state.sessions?.participantSessionsByEmail
});

const mapActionsToProps = {
  fetchSessions: fetchParticipantSessions
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(ParticipantSessionsWrapper);
