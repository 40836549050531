import styled from 'styled-components';

export const Wrapper = styled.div`
  flex-direction: column;
`;

export const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0;
`;

export const HeaderText = styled.h2`
  /* color: ${({ theme }) => theme.colors.gray}; */
  font-size: 1rem;
  font-weight: 300;
  margin-bottom: 0.5rem;
`;

export const HeaderNumber = styled.p`
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  color: black;
`;

export const TrainerMenuWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const TrainerCoursesFilterWrapper = styled.div`
  min-width: 250px;
  max-width: 600px;
  height: fit-content;
  margin-left: 48px;
  align-self: center;
`;
