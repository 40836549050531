import { RateDiv } from '../Questions.style';
import { Rate } from 'antd';

const RateComponent = ({ question, setQuestion, onBlur, disabled }) => {
  const { _id: questionId, answer } = question;

  const onChange = value => {
    setQuestion({
      _id: questionId,
      answer: value,
      answered: !!value
    });
    onBlur(value);
  };

  return (
    <RateDiv>
      <Rate
        id={`sliderInput-${questionId}`}
        name={questionId}
        allowClear={false}
        count={5}
        onChange={onChange}
        value={answer}
        disabled={disabled}
      />
    </RateDiv>
  );
};

export default RateComponent;
