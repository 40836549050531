import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 469px;
  width: 100%;
`;
export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 48px;
`;

export const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  width: 389.33px;
  background: ${({ theme }) => theme.colors.lightPrimary};
  border-radius: 4px;
  margin-bottom: ${({ theme }) => theme.spacings[4]};
`;
