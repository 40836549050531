import { useEffect, useReducer, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';

import { getInvolved as validate } from '../../../validation/schemas';

import Button from '../../common/Button';
import { Col, Row } from '../../common/Grid';
import { BasicInput, TextArea } from '../../common/Inputs';
import * as T from '../../common/Typography';
import Modal from '../../common/modal';

import * as S from './style';

import { clearErrors } from '../../../actions/errorAction';
import { getInvolved } from '../../../actions/getInvolvedAction';

const initialState = {
  name: '',
  jobTitle: '',
  email: '',
  message: '',
  validationErrs: {}
};

function reducer(state, newState) {
  return { ...state, ...newState };
}

const GetInvolved = ({
  getInvolved: getInvolvedAction,
  clearErrors: clearErrorsAction,
  httpError,
  getInvolvedLoading
}) => {
  const { breakpoints } = useTheme();
  const submitAttempt = useRef(false);
  const [state, setState] = useReducer(reducer, initialState);
  const [showModal, setShowModal] = useState(false);

  const { name, jobTitle, email, message, validationErrs } = state;
  const isDesktop = useMediaQuery({
    query: breakpoints.laptop
  });

  const handleSendMessage = async () => {
    await getInvolvedAction({
      name,
      jobTitle,
      email,
      message
    });
    submitAttempt.current = false;
    setShowModal(true);

    setState(initialState);
  };

  const handleSubmit = e => {
    e.preventDefault();
    submitAttempt.current = true;
    const isValid = validateForm();
    if (isValid) {
      handleSendMessage();
    }
  };
  const validateForm = () => {
    try {
      validate({
        name,
        jobTitle,
        email,
        message
      });
      setState({ validationErrs: {} });
      clearErrorsAction();
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({ validationErrs: error.inner });
      }
      return false;
    }
  };

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, jobTitle, email, message]);

  return (
    <S.GetInvolvedWrapper>
      <Row>
        <Col w={[4, 12, 7]}>
          <T.H2W color="darkGray" mb="4">
            Get involved
          </T.H2W>
          <T.P color="gray" mb="5" small={!isDesktop}>
            If you have any comments on the Programme for Alcohol Studies, or
            how it could be improved then please contact us
          </T.P>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 12, 3.5]}>
          <BasicInput
            label="Name"
            placeholder="Type your name"
            value={name}
            handleChange={name => setState({ name })}
            error={validationErrs.name}
            small={!isDesktop}
          />
        </Col>
        <Col w={[4, 12, 3.5]} mtT="4">
          <BasicInput
            label="Job title"
            placeholder="Type your job title"
            value={jobTitle}
            handleChange={jobTitle => setState({ jobTitle })}
            error={validationErrs.jobTitle}
            small={!isDesktop}
          />
        </Col>
      </Row>
      <Row>
        <Col w={[4, 12, 7]} mt="5" mtT="4">
          <BasicInput
            label="Email"
            placeholder="Type your email"
            value={email}
            handleChange={email => setState({ email })}
            error={validationErrs.email}
            small={!isDesktop}
          />
        </Col>
      </Row>
      <Row mt="5" mtT="4">
        <Col w={[4, 12, 7]}>
          <TextArea
            label="Message"
            placeholder="Type your message"
            rows="4"
            value={message}
            handleChange={message => setState({ message })}
            error={validationErrs.message}
            labelWeight="600"
            small={!isDesktop}
          />
          <Button
            mt="5"
            label="Submit"
            type="primary"
            onClick={handleSubmit}
            loading={getInvolvedLoading}
          />
          {httpError?.id && (
            <T.P color="error" mt="3">
              {httpError.msg}
            </T.P>
          )}
        </Col>
      </Row>
      <Modal visible={showModal} setModalOpen={setShowModal}>
        <T.H2W mb="4">Success!</T.H2W>
        <T.P>Your message has been sent</T.P>
        <Button
          mt="5"
          type="primary"
          label="Close"
          onClick={() => setShowModal(false)}
        />
      </Modal>
    </S.GetInvolvedWrapper>
  );
};

const mapStateToProps = state => {
  return {
    httpError: state.error,
    getInvolvedLoading: state.loading.getInvolvedLoading,
    loaded: state.getInvolved.loaded
  };
};

export default connect(mapStateToProps, { getInvolved, clearErrors })(
  GetInvolved
);
