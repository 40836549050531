import { combineReducers } from 'redux';

import { LOGOUT } from '../constants/actionTypes';

import userResults from './user';
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import trainerReducer from './trainerReducer';
import sessionReducer from './sessionReducer';
import fetchedDataReducer from './fetchedDataReducer';
import statsReducer from './statsReducer';
import fetchedSessions from './fetchSessionReducer';
import groupsReducer from './groups';
import viewReducer from './viewReducer';
import changePasswordReducer from './changePasswordReducer';
import exportDataReducer from './exportDataReducer';
import demographicsReducer from './demographics';
import surveyReducer from './surveyReducer';
import confirmRegistration from './confirmRegistration';
import storeSessionData from './storeSessionData';
import checkBrowserWidth from './checkBrowserWidthReducer';
import loadingReducer from './loading';
import filters from './filters';
import participantSession from './participantSessionReducer';
import participantResponses from './participantResponsesReducer';
import getInvolvedReducer from './getInvolvedReducer';
import modulesReducer from './modulesReducer';
import courseReducer from './courseReducer';

const appReducer = combineReducers({
  auth: authReducer,
  error: errorReducer,
  fetchedData: fetchedDataReducer,
  stats: statsReducer,
  results: userResults,
  sessions: fetchedSessions,
  trainers: trainerReducer,
  session: sessionReducer,
  groups: groupsReducer,

  viewLevel: viewReducer,
  changePassword: changePasswordReducer,
  exportData: exportDataReducer,
  demographics: demographicsReducer,
  survey: surveyReducer,
  confirmRegistration,
  storeSessionData,
  checkBrowserWidth,
  loading: loadingReducer,
  filters,
  participantSession,
  participantResponses,
  getInvolved: getInvolvedReducer,
  modules: modulesReducer,
  course: courseReducer
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    state = {};
  }

  return appReducer(state, action);
};

export default rootReducer;
