import { useMediaQuery } from 'react-responsive';

import { useTheme } from 'styled-components';
import { POVERTY_CATALOGUE_URL } from '../../../constants/navigationRoutes';
import * as T from '../../common/Typography';
import Button from './../../common/Button';
import * as S from './style';

import CNECard from '../../common/CNECard/index';

const CalloutSection = () => {
  const { breakpoints } = useTheme();

  const isDesktop = useMediaQuery({
    query: breakpoints.laptop
  });

  return (
    <S.CallOut>
      <S.BlueCard>
        <T.H2W color="white" mb="4" mbT="2">
          Get started with training
        </T.H2W>
        <S.SubtitleWrapper>
          <T.P
            color="white"
            mb="4"
            mbT="3"
            smallMedium={!!isDesktop}
            small={!isDesktop}
            weight={'400'}
          >
            To view training modules browse the catalogue. Modules are
            accessible to all relevant health, social care and third sector
            staff in North East & North Cumbria region. Please note sign in is
            required
          </T.P>
        </S.SubtitleWrapper>

        <Button
          to={POVERTY_CATALOGUE_URL}
          type={isDesktop ? 'secondary' : 'small'}
          label="View the training catalogue"
          width={isDesktop ? '270px' : '213px'}
        />
      </S.BlueCard>
      <CNECard dark shrink />
    </S.CallOut>
  );
};

export default CalloutSection;
