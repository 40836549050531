import { useReducer } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { signUpTttTrainer } from '../../../actions/authAction';
import {
  courseLabelMap,
  errMsgs,
  localAuthoritiesBasic as localAuthoritiesOptions,
  upgradeTttSteps,
  userRoles
} from '../../../constants';
import validate from '../../../validation/schemas/trainerSignup';
import Layout from '../../Layouts';
import Button from '../../common/Button';
import { Col, Row } from '../../common/Grid';
import { BasicInput, Checkbox, Dropdown } from '../../common/Inputs';
import * as T from '../../common/Typography';

import * as S from './HowItWorks.style';

const stateReducer = (state, newState) => {
  return { ...state, ...newState };
};

const initialState = {
  name: '',
  email: '',
  password: '',
  localAuthorities: '',
  acceptLocalLeadAccess: '',
  isManager: false,
  organisation: { value: '', category: '' },
  organisationOther: '',
  submitAttempt: false,
  validationErrors: {},
  showOrgOther: false,
  jobRole: '',
  team: '',
  department: ''
};

const TrainerThankYouPage = ({ loading, signUpTttTrainer, httpError }) => {
  const [state, setState] = useReducer(stateReducer, initialState);
  const history = useHistory();
  const {
    isManager,
    localAuthorities,
    acceptLocalLeadAccess,
    validationErrors,
    organisationOther,
    jobRole,
    team,
    department
  } = state;
  const course = history.location.state?.course;

  const handleSubmit = e => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      signUpTttTrainer({
        role: isManager ? userRoles.localLead : userRoles.trainer,
        localAuthorities,
        history,
        step: upgradeTttSteps.signupAsTtt,
        course,
        jobRole,
        team,
        department
      });
    }
  };
  const validateForm = () => {
    try {
      validate({
        localAuthorities,
        organisationOther,
        jobRole,
        team,
        department
      });

      setState({ validationErrors: {} });

      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({ validationErrors: error.inner });
      }
      return false;
    }
  };

  const courseName = courseLabelMap[course];

  return (
    <Layout>
      <Row>
        <Col w={[4, 12, 8]}>
          <T.H1 mb="5">Thank you!</T.H1>
        </Col>
      </Row>
      <Row mt={5}>
        <Col w={[4, 12, 8]}>
          <T.P mb="6">
            Thank you for taking the time to complete this evaluation. To
            complete your certification as a {courseName} trainer, please
            provide a few more details below so we can create your trainer
            account.
          </T.P>
        </Col>
      </Row>
      <Row mt={6}>
        <Col w={[4, 5, 5]}>
          <Dropdown
            m={{ mb: 4 }}
            label={`In which local authorities do you expect to deliver ${courseName} training?`}
            required
            placeholder="Select as many as you like or add new"
            options={localAuthoritiesOptions.map(i => ({
              label: i,
              value: i
            }))}
            addNew
            multi
            selected={localAuthorities}
            handleChange={values => setState({ localAuthorities: values })}
            error={validationErrors.localAuthorities}
          />
        </Col>
      </Row>
      <Row mt={6}>
        <Col w={[4, 5, 5]}>
          <BasicInput
            value={jobRole}
            handleChange={value => setState({ jobRole: value })}
            mb={4}
            label="Job role"
            placeholder="Type your job role here"
            required
            error={validationErrors.jobRole}
          />{' '}
        </Col>
      </Row>
      <Row mt={6}>
        <Col w={[4, 5, 5]}>
          <BasicInput
            value={team}
            handleChange={value => setState({ team: value })}
            mb={4}
            label="team"
            placeholder="Type your team here"
            required
            error={validationErrors.team}
          />{' '}
        </Col>
      </Row>
      <Row mt={6}>
        <Col w={[4, 5, 5]}>
          <BasicInput
            value={department}
            handleChange={value => setState({ department: value })}
            mb={4}
            label="Business Unit / Department"
            placeholder="Type your Business Unit / Department  here"
            required
            error={validationErrors.department}
          />{' '}
        </Col>
      </Row>
      <Row mt={6}>
        <Col w={[4, 8, 8]}>
          <Checkbox
            checked={isManager}
            handleChange={value => setState({ isManager: value })}
            mb={6}
            mt={6}
            label="I'm managing groups of trainers"
            helper={{
              title: '',
              text: `In case you are responsible for helping manage other trainers (e.g. organising their sessions) please select this option.`
            }}
          />
        </Col>
      </Row>
      <Row mt={6}>
        <Col w={[4, 6, 6]}>
          <Checkbox
            checked={acceptLocalLeadAccess}
            handleChange={value => setState({ acceptLocalLeadAccess: value })}
            label="I confirm that my local and regional lead can access individual profile data such as name, email and organisation as well as session results collected via the app after each session which I was assigned to as trainer"
          />
        </Col>
      </Row>
      <Row mt={6}>
        <Col w={[4, 4, 4]}>
          <Button
            onClick={handleSubmit}
            type="primary"
            label="Sign Up"
            m={4}
            loading={loading}
            disabled={!acceptLocalLeadAccess}
          />
        </Col>
      </Row>
      <Row>
        <Col w={[4, 12, 12]}>
          {httpError && (
            <S.Error>
              {httpError?.response?.data?.error || errMsgs.somethingWentWrong}
            </S.Error>
          )}
        </Col>
      </Row>
    </Layout>
  );
};
const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  httpError: state.auth.error,
  loading: state.loading.signUpTttTrainer
});
export default connect(mapStateToProps, {
  signUpTttTrainer
})(TrainerThankYouPage);
