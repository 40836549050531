import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { fetchParticipantSessions } from '../../../actions/groupSessionsAction';

import { upgradeTttSteps } from '../../../constants';
import {
  CHOOSE_RESULTS_FOR_MATERIALS,
  CHOOSE_RESULTS_FOR_SESSIONS,
  MODULE_STEPS_URL,
  PARTICIPANT_SESSION_DETAILS,
  TRAINER_TTT_SIGN_UP
} from '../../../constants/navigationRoutes';
import { addStatusToSessions, isCustomCourse } from '../../../helpers';
import Layout from '../../Layouts';
import Button from '../../common/Button';
import { Col, Row } from '../../common/Grid';
import Icon from '../../common/Icon';
import SessionList from '../../common/List/SessionList';
import * as T from '../../common/Typography';

const ParticipantDashboard = ({
  sessions,
  fetchSessions,
  nextUpgradeToTttStep
}) => {
  const history = useHistory();

  useEffect(() => {
    fetchSessions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sessionsWithStatus = addStatusToSessions(sessions);

  const shouldFinishTttSignup =
    nextUpgradeToTttStep?.step === upgradeTttSteps.signupAsTtt;

  return (
    <Layout>
      <Row mb={7}>
        <Col w={[4, 8, 8]}>
          <T.H1>Welcome back</T.H1>
        </Col>
      </Row>
      {shouldFinishTttSignup && (
        <Row mb={7}>
          <Col w={[0.4, 0.4, 0.4]}>
            <Icon icon="warning" />
          </Col>
          <Col w={[3.6, 7, 7]}>
            <T.P mb={3}>
              You have completed your training to become a Connect 5 trainer,
              but have not finished signing up to get your certification.
            </T.P>
            <T.Link
              color="secondary"
              underline
              to={{
                pathname: TRAINER_TTT_SIGN_UP,
                state: { course: nextUpgradeToTttStep?.course }
              }}
            >
              Click here to sign up
            </T.Link>
          </Col>
        </Row>
      )}

      <Row mb={7}>
        <Col w={[4, 8, 8]} dir="column" ai="flex-start">
          <T.P weight={700} mb={3}>
            My upcoming sessions
          </T.P>
          <SessionList
            dataList={sessionsWithStatus
              ?.filter(ses => ses.showInCompleted === false)
              .map(ses => {
                const { customModules, course, _id } = ses;
                const isLive =
                  customModules?.[0]?.isLive || !isCustomCourse(course);
                const moduleId = customModules?.[0]?._id;
                return {
                  ...ses,
                  link: isLive
                    ? PARTICIPANT_SESSION_DETAILS.replace(':id', _id)
                    : MODULE_STEPS_URL.replace(':id', moduleId)
                };
              })}
            isParticipant
          />
        </Col>
      </Row>
      <Row>
        <Col w={[4, 6, 4]}>
          <T.P weight={700} mb={4}>
            My course progress
          </T.P>
          <Button
            label="Sessions"
            onClick={() => history.push(CHOOSE_RESULTS_FOR_SESSIONS)}
            type="tertiary"
            mb={3}
          />
          <Button
            label="Materials"
            onClick={() => history.push(CHOOSE_RESULTS_FOR_MATERIALS)}
            type="primary"
            mb={3}
          />
          {/* <Button
            label="Insights"
            onClick={() => history.push(USER_INSIGHTS)}
            type="secondary"
            mb={3}
          /> */}
        </Col>
      </Row>
    </Layout>
  );
};

const mapStateToProps = state => ({
  sessions: state.sessions?.participantSessionsByEmail,
  nextUpgradeToTttStep: state.auth.nextUpgradeToTttStep
});

const mapActionsToProps = {
  fetchSessions: fetchParticipantSessions
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(ParticipantDashboard);
