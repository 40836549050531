import axios from 'axios';
import { message } from 'antd';

import {
  FETCH_PARTICIPANT_SESSION_DETAILS_SUCCESS,
  FETCH_PARTICIPANT_RESPONSES_SUCCESS,
  LOADING_START
} from '../constants/actionTypes';

export const fetchParticipantSessionDetails = (
  sessionId,
  history
) => async dispatch => {
  dispatch({
    type: LOADING_START
  });
  axios
    .get(`/api/participant/session-details/${sessionId}`)
    .then(({ data }) => {
      return dispatch({
        type: FETCH_PARTICIPANT_SESSION_DETAILS_SUCCESS,
        payload: data
      });
    })
    .catch(error => {
      if (error?.response?.status === 404) history.push('/404');
      message.error(
        error?.response?.data?.error || 'Error! something went wrong'
      );
    });
};

export const fetchParticipantResponses = () => async dispatch => {
  dispatch({
    type: LOADING_START
  });
  axios
    .get(`/api/participant/responses`)
    .then(({ data }) => {
      return dispatch({
        type: FETCH_PARTICIPANT_RESPONSES_SUCCESS,
        payload: data
      });
    })
    .catch(() => message.error('Error! something went wrong'));
};
