import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';

import { ALCOHOL_CATALOGUE_URL } from '../../../constants/navigationRoutes';
import Layout from '../../Layouts';

import { Col, Row } from '../../common/Grid';
import IconCard from '../../common/IconCard';
import MaxWidthContainer from '../../common/MaxWidthContainer';
import * as T from '../../common/Typography';
import * as S from './style';

import CalloutSection from './CalloutSection';
import GetInvolved from './GetInvolved';

const AlcoholStudies = () => {
  const { breakpoints } = useTheme();

  const isDesktop = useMediaQuery({
    query: breakpoints.laptop
  });
  return (
    <Layout layout="website" showNHSEnglandLogo>
      <MaxWidthContainer>
        <S.HeadWrapper>
          <T.H1W ta="center" color="darkGray" mb={4} mt={6} mtT={'27'}>
            North East & North Cumbria Programme for Alcohol Studies
          </T.H1W>
          <T.P medium={!!isDesktop} small={!isDesktop} ta="center" color="gray">
            A comprehensive training programme to equip our workforce with the
            skills to prevent and manage alcohol related harm
          </T.P>
        </S.HeadWrapper>
        <Row mt="7" mtT="27" mb="7" mbT="6" inner>
          <Col w={[4, 12, 4]} mbT="5">
            <IconCard
              bgColor="mainPrimary"
              icon="glass"
              to={`${ALCOHOL_CATALOGUE_URL}?div=${1}`}
            >
              <T.H4W mt="4">Alcohol Awareness</T.H4W>
              <T.P mt="2" mb="2" color="gray" small={!isDesktop}>
                Learn about the evidence based drivers of alcohol related harm,
                how this impacts health inequalities and how you can make a
                difference in your day-to-day work
              </T.P>
              <T.Link
                color="gray"
                to={`${ALCOHOL_CATALOGUE_URL}?div=${1}`}
                small={!isDesktop}
              >
                Learn more
              </T.Link>
            </IconCard>
          </Col>
          <Col w={[4, 12, 4]} mbT="5">
            <IconCard
              bgColor="mainSecondary"
              icon="chat"
              to={`${ALCOHOL_CATALOGUE_URL}?div=${2}`}
            >
              <T.H4W mt="4">Alcohol Identification and Brief Advice</T.H4W>
              <T.P mt="2" mb="2" color="gray" small={!isDesktop}>
                Learn how to have effective conversations about alcohol and
                respond appropriately when alcohol-related harm is identified
              </T.P>
              <T.Link
                color="gray"
                to={`${ALCOHOL_CATALOGUE_URL}?div=${2}`}
                small={!isDesktop}
              >
                Learn more
              </T.Link>
            </IconCard>
          </Col>

          <Col w={[4, 12, 4]}>
            <IconCard
              bgColor="darkGray"
              icon="glass2"
              to={`${ALCOHOL_CATALOGUE_URL}?div=${3}`}
            >
              <T.H4W mt="4">Management of alcohol harm and dependence</T.H4W>
              <T.P mt="2" mb="2" color="gray" small={!isDesktop}>
                Learn about interventions to manage alcohol use disorder and
                supporting the journey through to recovery
              </T.P>
              <T.Link
                color="gray"
                to={`${ALCOHOL_CATALOGUE_URL}?div=${3}`}
                small={!isDesktop}
              >
                Learn more
              </T.Link>
            </IconCard>
          </Col>
        </Row>
        <CalloutSection />
        <GetInvolved />
      </MaxWidthContainer>
    </Layout>
  );
};

export default AlcoholStudies;
