import { useMediaQuery } from 'react-responsive';

import { useTheme } from 'styled-components';
import * as T from '../../common/Typography';
import * as S from './style';

import NorthEastNorthCumbriaImg from '../../../assets/NorthEastNorthCumbria-NHS.svg';
import NorthEastPartnershipImg from '../../../assets/NorthEastPartnership.png';
import MakingEveryContactCountImg from '../../../assets/MakingEveryContactCount.svg';
import Connect5LogoImg from '../../../assets/Connect_5_logo_colour.png';

const CalloutSection = ({ isMECC, isC5 }) => {
  const { breakpoints } = useTheme();

  const isDesktop = useMediaQuery({
    query: breakpoints.laptop
  });

  return (
    <S.CallOut>
      <S.CallOutRow2>
        <S.BlueCard>
          <T.H2W color="white" mb="4" mbT="2">
            Get started with training
          </T.H2W>
          <S.SubtitleWrapper>
            <T.P
              color="white"
              mb="4"
              mbT="3"
              smallMedium={!!isDesktop}
              small={!isDesktop}
              weight={'400'}
            >
              To complete this course, you will need to attend one or more
              training sessions. You can view all upcoming sessions in the table
              below. The course is accessible to all relevant health, social
              care and third sector staff in North East & North Cumbria region.
              Please note sign in is required.
            </T.P>
          </S.SubtitleWrapper>
        </S.BlueCard>
        <S.BlackCard>
          <T.H2W color="white" mb="4" mbT="2">
            The programme covers a wide range of online training materials and
            access to live training opportunities
          </T.H2W>
          <T.P
            color="white"
            mb="5"
            mbT="4"
            smallMedium={!!isDesktop}
            small={!isDesktop}
            weight={'400'}
          >
            It was created in collaboration with
          </T.P>

          <S.collaborateCol>
            {isMECC && (
              <S.collaborateRow>
                <S.collaborateDiv jc="center">
                  <S.Img
                    src={NorthEastNorthCumbriaImg}
                    alt="NorthEastNorthCumbriaImg"
                    maxWidth="146px"
                  />
                </S.collaborateDiv>
              </S.collaborateRow>
            )}
            <S.collaborateRow>
              <S.collaborateDiv small>
                <S.ImgNoHeight
                  src={NorthEastPartnershipImg}
                  alt="NorthEastPartnershipImg"
                  maxWidth="112px"
                />
              </S.collaborateDiv>
              {isMECC && (
                <S.collaborateDiv small>
                  <S.Img
                    src={MakingEveryContactCountImg}
                    alt="MakingEveryContactCountImg"
                    maxWidth="92px"
                  />
                </S.collaborateDiv>
              )}
              {isC5 && (
                <S.collaborateDiv small>
                  <S.Img src={Connect5LogoImg} alt="Connect5LogoImg" />
                </S.collaborateDiv>
              )}
            </S.collaborateRow>
          </S.collaborateCol>
        </S.BlackCard>
      </S.CallOutRow2>
    </S.CallOut>
  );
};

export default CalloutSection;
