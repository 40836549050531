import { useEffect, useReducer, useRef } from 'react';
import { useHistory, useParams } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import { message as antdMessage } from 'antd';

import Layout from '../../Layouts';
import * as T from '../../common/Typography';
import { Row, Col } from '../../common/Grid';
import { TextArea, Checkbox } from '../../common/Inputs';
import Button from '../../common/Button';
import InfoCard from '../../common/InfoCard';
import { courseLabelMap, externalLinks } from '../../../constants';
import { getSessionDetails } from '../../../actions/sessionAction';
import {
  confirmRegistration,
  resetConfirmRegistration
} from '../../../actions/confirmRegistration';
import useSearchParams from './../../../hooks/useSearchParams';
import { concatModules, isCustomCourse } from './../../../helpers';
import { userRoles } from '../../../constants';

import Modal from '../../common/modal';
import { validateConfirmAttendance } from '../../../validation/schemas/sessionRegistration';
import SuccessRegistration from './SuccessRegistration';
import { convertAddressObjToString } from './../../../helpers';
import * as S from './style';
import { signUpParticipant, loginUser } from '../../../actions/authAction';
import { fetchParticipantSessions } from '../../../actions/groupSessionsAction';
import { courses, modules as MODS, surveyTypesNew } from '../../../constants';
import * as R from '../../../constants/navigationRoutes';

const initialState = {
  email: '',
  password: '',
  name: '',
  validationErrors: {},
  isAgree: false,
  isSigned: false,
  modal1Open: false,
  modal2Open: false,
  modal3Open: false,
  confirmAttendance: false,
  postSurveyLink: ''
};

const roles = {
  trainer: 'a trainer',
  localLead: 'a local lead',
  admin: 'an admin'
};

const Buttons = ({ search, disabled }) => {
  return (
    <>
      <Col w={[4, 12, 12]} mt={5}>
        <Button
          label="Sign up"
          width="200px"
          type="primary"
          to={{
            pathname: disabled ? undefined : R.PARTICIPANT_SIGN_UP_URL,
            search: search
          }}
          disabled={disabled}
        />
      </Col>
      <Col w={[4, 12, 12]} mt={3}>
        <Button
          label="Log in"
          width="200px"
          type="tertiary"
          to={{
            pathname: disabled ? undefined : R.PARTICIPANT_LOGIN,
            search: search
          }}
          disabled={disabled}
        />
      </Col>
    </>
  );
};
const stateReducer = (state, newState) => {
  return { ...state, ...newState };
};

const SessionRegistration = ({
  getSessionDetails,
  confirmRegistrationAction,
  session,
  confirmSuccess,
  alreadyRegistered,
  userId,
  role,
  sessions,
  fetchParticipantSessions,
  resetConfirmRegistrationAction
}) => {
  const [state, setState] = useReducer(stateReducer, initialState);
  const submitAttempt = useRef(false);

  const {
    message,
    validationErrors,

    modal1Open,
    modal2Open,
    modal3Open,
    confirmAttendance,
    postSurveyLink,
    loading
  } = state;

  const { id: shortId } = useParams();
  const history = useHistory();
  const searchParams = useSearchParams();

  searchParams.set('source', 'registration');
  searchParams.set('loginRole', userRoles.participant);
  searchParams.set('shortId', shortId);

  useEffect(() => {
    getSessionDetails(shortId, history);

    if (userId) {
      fetchParticipantSessions();
    }

    return () => {
      resetConfirmRegistrationAction();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      course === courses.C5 &&
      [MODS.MODULE_1, MODS.MODULE_2, MODS.MODULE_3].some(mod =>
        session.modules.includes(mod)
      )
    ) {
      setState({ modal1Open: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  const trainersNames = session?.trainers
    ? session.trainers.map(trainer => trainer.name).join(' & ')
    : 'N/A';

  const modulesAsString = concatModules({
    modules: session.modules,
    customModules: session.customModules
  });

  const fullAddress = convertAddressObjToString(session.address);
  const course = session?.course;

  const isAlcoholCourse = course === courses.ALCOHOL_STUDIES;

  const completedAlcoholModuleDemographic = sessions?.find(s =>
    s?.completedSurvey?.find(
      r => r?.surveyType === surveyTypesNew.ALCOHOL_DEMOGRAPHICS
    )
  );

  const completedGeneralPreCourseQuestions = sessions
    ?.filter(s => s.course === course)
    ?.find(s =>
      s?.completedSurvey?.find(
        r =>
          r?.surveyType === surveyTypesNew.PRE_COURSE ||
          r?.surveyType === surveyTypesNew.PRE_TRAIN_THE_TRAINER
      )
    );

  const completedPreCourseQuestions = isAlcoholCourse
    ? !!completedAlcoholModuleDemographic
    : !!completedGeneralPreCourseQuestions;

  const validateConfirmAttendence = () => {
    try {
      validateConfirmAttendance({ confirmAttendance });
      setState({ validationErrors: {} });

      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({ validationErrors: error.inner });
      }
      return false;
    }
  };

  const checkForTrainModule = async () => {
    if (
      session?.course === courses.C5 &&
      session?.modules.includes(MODS.TRAIN_THE_TRAINER) &&
      !session?.modules.includes(MODS.MODULE_3)
    ) {
      try {
        const { data } = await axios.get(
          `/api/sessions/${session._id}/match-requirement`
        );
        const {
          isAttendedModule3,
          isFilledSurvey,
          surveyLink,
          haveModule3
        } = data;

        if ((isAttendedModule3 && isFilledSurvey) || haveModule3) {
          return true;
        } else if (isAttendedModule3 && !isFilledSurvey) {
          setState({ postSurveyLink: surveyLink, modal3Open: true });
          return false;
        } else {
          setState({ modal2Open: true });
          return false;
        }
      } catch (error) {
        antdMessage.error(
          error?.response?.data?.message || 'Internal server error'
        );
      }
    } else {
      return true;
    }
  };

  const handleConfirmRegistration = async () => {
    const confirmRegistration = async () => {
      const isAllowed = await checkForTrainModule();
      if (isAllowed) {
        await confirmRegistrationAction({
          sessionId: session._id,
          message
        });
      }
      setState({ loading: false });
    };
    confirmRegistration();
  };

  const handleSubmit = async e => {
    e.preventDefault();
    submitAttempt.current = true;
    handleConfirmRegistration();
  };

  const spacesAvailable = capacity => {
    const participants =
      session?.participantsEmails?.filter(
        ({ status, user }) => status === 'confirmed' && user !== userId
      )?.length || 0;
    const available = capacity > participants;
    return available;
  };

  if (confirmSuccess) {
    return (
      <SuccessRegistration
        session={session}
        alreadyRegistered={alreadyRegistered}
        history={history}
        showDemographicLink={!completedPreCourseQuestions}
      />
    );
  }

  let courseName = courseLabelMap[course];
  if (isCustomCourse(course) && session?.customModules?.length === 1) {
    courseName = session.customModules[0].title;
  }

  return (
    <Layout>
      <T.H1>{courseName} Session Registration</T.H1>
      {session.capacity && (
        <Row mt="40">
          <Col w={[4, 12, 8]}>
            <InfoCard text="Your personal information is safe with us. All materials are confidential and in line with the Data Protection Act (2018). We only keep your data for as long as we need it which will be at least the duration of your course or accessing our services." />
          </Col>
        </Row>
      )}
      <Row mt={7}>
        <Col w={[4, 12, 8]}>
          <S.Wrapper>
            <T.P mb="4" color="gray">
              <S.Bold>Date of Session:</S.Bold>{' '}
              {session?.date
                ? moment(session.date).format('DD-MM-YYYY')
                : 'N/A'}
            </T.P>
            {session?.endDate && (
              <T.P mb="4" color="gray">
                <S.Bold>End date:</S.Bold>{' '}
                {moment(session.endDate).format('DD-MM-YYYY')}
              </T.P>
            )}
            <T.P mb="4" color="gray">
              <S.Bold>Time of Session:</S.Bold> {session?.startTime || 'TBC'}{' '}
              {session?.endTime && `to ${session?.endTime}`}
            </T.P>
            <T.P mb="4" color="gray">
              <S.Bold>Location:</S.Bold>{' '}
              {session.remote ? 'Online' : fullAddress}
            </T.P>
            <T.P mb="4" color="gray" transform="capitalize">
              <S.Bold>Trainers:</S.Bold> {trainersNames}
            </T.P>
            <T.P mb="4" color="gray">
              <S.Bold>Modules:</S.Bold> {modulesAsString}
            </T.P>
            <T.P color="gray">
              <S.Bold>Contact email:</S.Bold>{' '}
              {session.contactEmail ? (
                <T.Link
                  external
                  href={`mailto:${session.contactEmail}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  color="primary"
                >
                  {session.contactEmail}
                </T.Link>
              ) : (
                ' N/A'
              )}
            </T.P>
          </S.Wrapper>
        </Col>
      </Row>
      {spacesAvailable(session.capacity) ? (
        <>
          <form /* onSubmit={handleSubmit} */ className="signup-form">
            {userId && role === 'participant' && (
              <>
                <Row mt="7">
                  <Col w={[4, 12, 6]}>
                    <TextArea
                      value={message}
                      handleChange={value => setState({ message: value })}
                      label="Do you have any special requirements or anything else we should know ahead of the workshop?"
                      placeholder="Requirements/message"
                      mb={3}
                      error={validationErrors.message}
                      labelWeight="700"
                      rows="4"
                    />
                  </Col>
                </Row>
                <Row mt="6">
                  <Col w={[4, 6, 4]}>
                    <Button
                      onClick={handleSubmit}
                      type="primary"
                      label="Register"
                      loading={loading}
                    />
                  </Col>
                </Row>
              </>
            )}

            {userId && role !== 'participant' && (
              <>
                <Col w={[4, 12, 12]} mt={5}>
                  <T.P>
                    You can't register for a session as {roles[role]}. Please
                    log in as a participant to register.
                  </T.P>
                </Col>
                <Buttons disabled />
              </>
            )}

            {!userId && (
              <>
                <Col w={[4, 12, 12]} mt={3}>
                  <T.P>
                    To register you must create a free training account.
                    <br />
                    This account will let you attend courses, receive your
                    certificates and track your progress.
                  </T.P>
                </Col>
                <Buttons search={searchParams.toString()} />
              </>
            )}
          </form>
        </>
      ) : (
        <>
          <Row mt="5">
            <Col w={[4, 12, 8]}>
              <T.P weight={700} mt={5}>
                Sorry, this session is now full. Please register for a different
                session. For more information, please use the contact email
                above.
              </T.P>
            </Col>
          </Row>
        </>
      )}
      <Modal
        type="confirm"
        title="Important Message - Please read"
        visible={modal1Open}
        setModalOpen={() => setState({ modal1Open: false })}
        parentFunc={() => {
          const isValid = validateConfirmAttendence();
          if (isValid) setState({ confirmAttendance: true });
        }}
        closeOnOK={confirmAttendance}
        handleBack={() => history.goBack()}
      >
        <Row mt="4" mb="4">
          <Col w={[4, 12, 12]}>
            <T.P color="gray">
              To attend Connect 5 training, you <b>must</b> have completed the
              MECC course first.
            </T.P>
          </Col>
        </Row>
        <Row mt="4" mb="4">
          <Col w={[4, 12, 12]}>
            <Checkbox
              checked={confirmAttendance}
              handleChange={value => setState({ confirmAttendance: value })}
              label=" I confirm I have or will have completed the MECC course by the time I attend this event"
              error={validationErrors.confirmAttendance}
            />
          </Col>
        </Row>
      </Modal>

      <Modal
        type="confirm"
        title="Sorry you cannot proceed"
        visible={modal2Open}
        setModalOpen={() => setState({ modal2Open: false })}
        parentFunc={() => {
          window.open(`mailto:${externalLinks.MECC_CONTACT_EMAIL}`, '_blank');
        }}
        closeOnOK
        okLabel="Contact us"
        handleBack={() => history.push(R.PARTICIPANT_DASHBOARD)}
      >
        <Row mt="4" mb="4">
          <Col w={[4, 12, 12]}>
            <T.P color="gray">
              To attend training to become a Connect 5 trainer you must first
              complete all three modules of the Connect 5 course. Please contact
              us using the button below and we will help find you upcoming
              sessions to complete the course.
            </T.P>
          </Col>
        </Row>
      </Modal>

      <Modal
        type="confirm"
        title="You must complete your post-course evaluation"
        visible={modal3Open}
        setModalOpen={() => setState({ modal3Open: false })}
        parentFunc={() => {
          history.push(postSurveyLink);
        }}
        closeOnOK
        okLabel="Complete evaluation"
      >
        <Row mt="4" mb="4">
          <Col w={[4, 12, 12]}>
            <T.P color="gray">
              To attend training to become a Connect 5 trainer you must first
              complete your Connect 5 course evaluation. Click the button below
              to do this - it only takes 2 minutes to complete.
            </T.P>
          </Col>
        </Row>
      </Modal>
    </Layout>
  );
};

const mapStateToProps = state => ({
  userEmail: state.auth.email,
  session: state.session,
  confirmRegistration: state.confirmRegistration,
  confirmSuccess: state.confirmRegistration.confimSuccess,
  alreadyRegistered: state.confirmRegistration.alreadyRegistered,
  httpError: state.auth.error,
  userId: state.auth.userId,
  role: state.auth.role,
  sessions: state.sessions?.participantSessionsByEmail
});

const mapActionsToProps = {
  getSessionDetails,
  confirmRegistrationAction: confirmRegistration,
  resetConfirmRegistrationAction: resetConfirmRegistration,
  signUpParticipant,
  loginUser,
  fetchParticipantSessions
};

export default connect(mapStateToProps, mapActionsToProps)(SessionRegistration);
