import { useEffect, useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { message as antMessage } from 'antd';

import { useHistory, useParams } from 'react-router-dom';
import ModalCommon from '../../common/modal';

import * as T from '../../common/Typography';
import { Row, Col } from '../../common/Grid';
import { Modal } from 'antd';

import {
  courses,
  modules,
  externalLinks,
  courseLabelMap
} from '../../../constants';
import { PARTICIPANT_DASHBOARD } from '../../../constants/navigationRoutes';

import {
  getParticipantByEmail,
  resetGetParticipantByEmail
} from '../../../actions/surveyAction';

const CheckRequirements = ({
  surveyData,
  setShowSurvey,
  contentMsg,
  redirectTo,
  shortId,
  answeredSameModule,
  sessionDidNotStart,
  getParticipantByEmail,
  resetGetParticipantByEmail
}) => {
  const history = useHistory();
  const [modal3Open, setModal3Open] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const [postSurveyLink, setPostSurveyLink] = useState('');
  const { id, type } = useParams();

  const { course, modules: mods, sessionId } = surveyData;

  if (answeredSameModule) {
    Modal.error({
      title: 'Error!',
      content: "you've already submitted this evaluation.",
      onOk: () => history.push('/participant-dashboard')
    });
  } else if (sessionDidNotStart) {
    Modal.error({
      title: 'Sorry! session has not started.',
      content: contentMsg,
      onOk: () => {
        if (redirectTo) {
          const _shortId = shortId || id;
          history.push(`/survey/${_shortId}/${redirectTo}`);
        } else {
          history.push(PARTICIPANT_DASHBOARD);
        }
      }
    });
  } else if (redirectTo) {
    const _shortId = shortId || id;
    Modal.error({
      title: 'Sorry! you have to fill out another evaluation before this.',
      content:
        'Before filling out this evaluation please submit the pre-course evaluation. Clicking OK will bring you to the right evaluation.',
      onOk: () => {
        history.push(`/survey/${_shortId}/${redirectTo}`);
        window.location.reload();
      }
    });
  }

  useEffect(() => {
    const { sessionId } = surveyData;
    if (!sessionId) return;
    getParticipantByEmail({ sessionId, surveyType: type });
    return () => {
      resetGetParticipantByEmail();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getParticipantByEmail, surveyData.sessionId, type]);

  useEffect(() => {
    const checkForTrainModule = async () => {
      if (
        course === courses.C5 &&
        mods.includes(modules.TRAIN_THE_TRAINER) &&
        !mods.includes(modules.MODULE_3)
      ) {
        try {
          const { data } = await axios.get(
            `/api/sessions/${sessionId}/match-requirement`
          );
          const {
            isAttendedModule3,
            isFilledSurvey,
            surveyLink,
            haveModule3
          } = data;

          if ((isAttendedModule3 && isFilledSurvey) || haveModule3) {
            return;
          } else if (isAttendedModule3 && !isFilledSurvey) {
            setPostSurveyLink(surveyLink);
            setModal3Open(true);
            setShowSurvey(false);
          } else {
            setModal2Open(true);
            setShowSurvey(false);
          }
        } catch (error) {
          if (error?.response?.status !== 401) {
            antMessage.error(
              error?.response?.data?.error || 'Something went wrong!'
            );
          }
        }
      }
    };
    checkForTrainModule();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]);

  const courseName = courseLabelMap[course];

  return (
    <div>
      <ModalCommon
        type="confirm"
        title="Sorry you cannot proceed"
        visible={modal2Open}
        setModalOpen={() => setModal2Open(false)}
        parentFunc={() => {
          window.open(`mailto:${externalLinks.MECC_CONTACT_EMAIL}`, '_blank');
        }}
        closeOnOK
        okLabel="Contact us"
        handleBack={() => history.push('/')}
      >
        <Row mt="4" mb="4">
          <Col w={[4, 12, 12]}>
            <T.P color="gray">
              To attend training to become a {courseName} trainer you must first
              complete all three modules of the {courseName} course. Please
              contact us using the button below and we will help find you
              upcoming sessions to complete the course.
            </T.P>
          </Col>
        </Row>
      </ModalCommon>
      <ModalCommon
        type="confirm"
        title="You must complete your post-course evaluation"
        visible={modal3Open}
        setModalOpen={() => setModal3Open(false)}
        parentFunc={() => {
          history.push(postSurveyLink);
        }}
        closeOnOK
        okLabel="Complete evaluation"
      >
        <Row mt="4" mb="4">
          <Col w={[4, 12, 12]}>
            <T.P color="gray">
              To attend training to become a {courseName} trainer you must first
              complete your {courseName} course evaluation. Click the button
              below to do this - it only takes 2 minutes to complete.
            </T.P>
          </Col>
        </Row>
      </ModalCommon>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    surveyData: state.survey.surveyData || {},
    answeredSameModule: state.survey.answeredSameModule,
    sessionDidNotStart: state.survey.sessionDidNotStart,
    contentMsg: state.survey.contentMsg,
    redirectTo: state.survey.redirectTo,
    shortId: state.survey.shortId
  };
};

export default connect(mapStateToProps, {
  getParticipantByEmail,
  resetGetParticipantByEmail
})(CheckRequirements);
