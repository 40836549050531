import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import * as T from '../../common/Typography';
import CheckRequirements from './CheckRequirements';

import {
  PARTICIPANT_FILLED_SURVEY_SUCCESSFULLY,
  TRAINER_TTT_SIGN_UP,
  SURVEY_QUESTIONS_URL,
  MODULE_STEPS_URL
} from '../../../constants/navigationRoutes';

import NextAndBackButtons from './NextAndBackButtons';

import Spin from '../../common/Spin';

import { surveyTypesNew } from '../../../constants';

// Styles
import Progress from '../../common/Progress';
import Layout from '../../Layouts';

import { Container, SpinWrapper, SpinDiv } from './Survey.style';

// Actions
import {
  fetchSurveyData,
  submitSurvey,
  getParticipantByEmail
} from '../../../actions/surveyAction';
// Components
import SurveyQs from './SurveyQs';

const Spinner = () => (
  <SpinWrapper>
    <Spin size="large" />
  </SpinWrapper>
);

const Survey = ({
  id,
  step,
  surveyData,
  uniqueGroups,
  submitSurvey,
  type,
  success,
  submissionLoading
}) => {
  const sectionTitle = uniqueGroups?.[step];
  const [questions, setQuestions] = useState([]);

  const history = useHistory();

  const visibleQuestions =
    questions?.filter(question => question.isVisible) || [];

  const currentQuestions = visibleQuestions.filter(
    question => question.group.text === sectionTitle
  );

  // If page opened with step !== 0, redirect to step 0
  useEffect(() => {
    if (Number(step) !== 0) {
      history.replace(
        SURVEY_QUESTIONS_URL.replace(':id', id)
          .replace(':step', 0)
          .replace(':type', type)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Initially, all children questions are invisible
    setQuestions(
      surveyData?.questionsForSurvey.map(question => ({
        ...question,
        isVisible: question.metadata?.parentQuestion ? false : true
      })) || []
    );
  }, [surveyData]);

  const setQuestion = useCallback(
    ({ _id, ...data }) => {
      const newQuestions =
        questions?.map(q => {
          if (q?._id?.toString() === _id?.toString()) {
            return { ...q, ...data };
          }
          return q;
        }) || [];
      setQuestions(newQuestions);
    },
    [questions]
  );

  const allCurrentGroupAnswered = currentQuestions
    .filter(question => question.isRequired)
    .every(question => question.answered);

  const answeredQuestionsCount = visibleQuestions.filter(
    question => question.answered && question.isRequired
  ).length;

  const requiredQuestionsCount = visibleQuestions.filter(
    question => question.isRequired
  ).length;

  const allQuestionsAnswered =
    answeredQuestionsCount === requiredQuestionsCount;

  const onSubmit = async () => {
    const answeredQuestions = visibleQuestions.filter(
      question => !!question.answer
    );

    await submitSurvey({
      sessionId: surveyData.sessionId,
      surveyType: type,
      questions: answeredQuestions.map(q => ({
        _id: q._id,
        answer: q.answer,
        answered: q.answered,
        otherAnswer: q.otherAnswer,
        participantField: q.participantField
      })),
      cb: () => {
        let pathname = PARTICIPANT_FILLED_SURVEY_SUCCESSFULLY;
        if (type === surveyTypesNew.POST_TRAIN_THE_TRAINER) {
          pathname = TRAINER_TTT_SIGN_UP;
        } else if (
          surveyData.defaultSessionForCustomModule &&
          type === surveyTypesNew.ALCOHOL_DEMOGRAPHICS
        ) {
          pathname = MODULE_STEPS_URL.replace(
            ':id',
            surveyData.customModules?.[0]?._id
          );
        }
        history.push({
          pathname: pathname,
          state: {
            sessionId: surveyData.sessionId,
            surveySubmitted: true,
            course: surveyData.course
          }
        });
      }
    });
    surveyData.questionsForSurvey = [];
  };
  const fromRegistration = history.location.state?.fromRegistration;

  useEffect(() => {
    questions.forEach(question => {
      const { metadata, isVisible } = question;
      if (metadata?.parentQuestion) {
        const parentQuestion = questions.find(
          question => question.participantField === metadata.parentQuestion.ref
        );
        if (
          !isVisible &&
          parentQuestion.answer === metadata.parentQuestion.showOnValue
        ) {
          setQuestion({
            ...question,
            isVisible: true
          });
        } else if (
          // Question was visible and not it's not
          isVisible &&
          parentQuestion.answer !== metadata.parentQuestion.showOnValue
        ) {
          setQuestion({
            ...question,
            isVisible: false
          });
        }
      }
    });
  }, [questions, setQuestion, allQuestionsAnswered]);

  return (
    <Layout>
      <CheckRequirements />

      <Container
        mb="-48px" // set negative margin to keep the sticky progress bar at the bottom
      >
        {type === surveyTypesNew.ALCOHOL_DEMOGRAPHICS && fromRegistration && (
          <T.H1 color="darkGray" mb="7">
            Thanks for registering!
          </T.H1>
        )}
        {type === surveyTypesNew.ALCOHOL_DEMOGRAPHICS && (
          <T.P color="gray" mb="3">
            Before we begin can you answer a few questions about yourself.
          </T.P>
        )}
        {success === 'true' && (
          <>
            <T.H1 color="darkGray" mb="7" weight="700">
              Success!
            </T.H1>
            <div style={{ maxWidth: '583px' }}>
              <T.P color="gray" mb="4" weight="400">
                Congratulations, you have successfully passed the assessment.
                Now complete this short evaluation and you will then receive
                your certification and post-module materials.
              </T.P>
            </div>
          </>
        )}
        {visibleQuestions.length === 0 ? (
          <SpinDiv>
            <Spin size="large" />
          </SpinDiv>
        ) : (
          <SurveyQs
            key={sectionTitle}
            questions={currentQuestions}
            setQuestion={setQuestion}
          />
        )}
        <NextAndBackButtons
          section={sectionTitle}
          uniqueGroups={uniqueGroups}
          step={step}
          allCurrentGroupAnswered={allCurrentGroupAnswered}
          allQuestionsAnswered={allQuestionsAnswered}
          onSubmit={onSubmit}
          loading={submissionLoading}
        />
        <Progress
          readyForSubmission={allQuestionsAnswered}
          completionRate={Math.round(
            (answeredQuestionsCount / requiredQuestionsCount) * 100
          )}
          currentStep={step}
          totalSteps={uniqueGroups.length}
        />
      </Container>
    </Layout>
  );
};

const Waiter = props => {
  const { id, type, step, success } = useParams();

  useEffect(() => {
    props.fetchSurveyData({ id, type });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  if (!props.loaded || !props.surveyLoaded) return <Spinner />;
  return (
    <Survey step={step} type={type} id={id} success={success} {...props} />
  );
};

const mapStateToProps = state => {
  return {
    surveyData: state.survey.surveyData,
    uniqueGroups: state.survey.uniqueGroups,
    surveyLoaded: state.survey.loaded,
    loaded: state.auth.loaded,
    submissionLoading: state.survey.submissionLoading
  };
};

export default connect(mapStateToProps, {
  fetchSurveyData,
  submitSurvey,
  getParticipantByEmail
})(Waiter);
