import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  CHECK_UNIQUE_EMAIL_UNIQUE,
  CHECK_UNIQUE_EMAIL_UNUNIQUE,
  USER_AUTHENTICATED,
  USER_UNAUTHENTICATED,
  RESET_UNIQUE_EMAIL,
  CHECK_UNIQUE_EMAIL_ERROR,
  CHECK_USER_BY_EMAIL_SUCCESS,
  CHECK_USER_BY_EMAIL_FAIL,
  LOGOUT,
  SIGNUP_FAIL,
  PARTICIPANT_SIGNUP_SUCCESS,
  PARTICIPANT_SIGNUP_FAIL,
  UPDATE_MY_ACCOUNT_SUCCESS,
  UPDATE_MY_ACCOUNT_FAIL,
  DELETE_ACCOUNT_SUCCESS,
  SURVEY_SUBMISSION_SUCCESS,
  SELECT_ROLE_SUCCESS,
  SELECT_ROLE_FAIL,
  RESET_AUTH,
  SIGNUP_TTT_SUCCESS,
  SIGNUP_TTT_FAIL,
  RESET_ROLE_SUCCESS
} from '../constants/actionTypes';

const initialState = {
  isAuthenticated: null,
  isEmailUnique: null,
  loaded: false,
  name: '',
  email: '',
  role: null,
  _id: null,
  checkedUserInfo: {},
  error: null,
  userLevel: null,
  viewLevel: null,
  PIN: null,
  localLead: null,
  organisation: {},
  localAuthorities: [],
  coursesCanDeliver: []
};

export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loaded: true,
        userLevel: payload.role
      };

    case SIGNUP_FAIL:
    case PARTICIPANT_SIGNUP_FAIL:
      return {
        ...initialState,
        loaded: true,
        error: payload
      };

    case PARTICIPANT_SIGNUP_SUCCESS:
      return {
        ...state,
        ...payload,
        participantAuthForSurvey: true,
        loaded: true,
        userLevel: payload.role
      };

    case LOGIN_FAIL:
    case LOGOUT:
    case DELETE_ACCOUNT_SUCCESS:
      return {
        ...initialState,
        loaded: true
      };

    case CHECK_UNIQUE_EMAIL_UNIQUE:
      return {
        ...state,
        isEmailUnique: true,
        checkedUserInfo: {},
        loaded: true
      };

    case CHECK_UNIQUE_EMAIL_UNUNIQUE:
      return {
        ...state,
        isEmailUnique: false,
        checkedUserInfo: payload,
        loaded: true
      };

    case CHECK_UNIQUE_EMAIL_ERROR:
      return {
        ...state,
        isEmailUnique: false,
        checkedUserInfo: {},
        loaded: true,
        error: payload
      };

    case USER_AUTHENTICATED:
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loaded: true,
        userLevel: payload.role
      };

    case USER_UNAUTHENTICATED:
      return {
        ...initialState,
        isAuthenticated: false,
        loaded: true
      };

    case RESET_UNIQUE_EMAIL:
      return {
        ...state,
        checkedUserInfo: {},
        isEmailUnique: null
      };

    case CHECK_USER_BY_EMAIL_SUCCESS:
      return {
        ...state,
        ...payload
      };

    case CHECK_USER_BY_EMAIL_FAIL:
      return {
        ...initialState,
        msg: 'you are not a user'
      };
    case UPDATE_MY_ACCOUNT_SUCCESS:
      return {
        ...state,
        ...payload
      };
    case UPDATE_MY_ACCOUNT_FAIL:
      return {
        ...state,
        error: payload
      };
    case SURVEY_SUBMISSION_SUCCESS:
      return {
        ...state,
        isAuthenticated: true
      };

    case SELECT_ROLE_SUCCESS:
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loaded: true
      };

    case RESET_ROLE_SUCCESS:
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loaded: true,
        multipleUsers: true,
        role: null,
        userId: null
      };

    case SELECT_ROLE_FAIL:
      return {
        ...initialState,
        loaded: true,
        error: payload
      };

    case SIGNUP_TTT_SUCCESS:
      return {
        ...state,
        ...payload,
        loaded: true,
        error: null
      };

    case SIGNUP_TTT_FAIL:
      return {
        ...initialState,
        loaded: true,
        error: payload
      };

    case RESET_AUTH:
      return {
        ...initialState,
        loaded: true
      };

    default:
      return state;
  }
}
