/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/destructuring-assignment */
import { useEffect, useReducer } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Select } from 'antd';
import { fetchCustomModules } from '../../../actions/customModulesActions';

import {
  BasicInput,
  DatePicker,
  Dropdown,
  Radio,
  TimePicker
} from '../../common/Inputs';

import Button from '../../common/Button';

import Layout from '../../Layouts';
import * as T from '../../common/Typography';

import { courseOptions, courses, userRoles } from '../../../constants';
import { SESSION_CREATED_URL } from '../../../constants/navigationRoutes';
import {
  coursesCanDeliverOptions,
  moduleOptions
} from '../../../constants/sessionData';

import {
  clearInputData,
  createSessionAction,
  resetSessionState,
  storeInputData
} from '../../../actions/sessionAction';
import { fetchAllTrainers } from '../../../actions/trainerAction';
import {
  fetchLocalLeadTrainersGroup,
  fetchLocalLeads
} from '../../../actions/users';

import validate from '../../../validation/schemas/createSession';

import { Form, InformationBox } from './CreateSession.style';

import { useState } from 'react';
import { isCustomCourse } from '../../../helpers';
import FaceToFaceQs from './FaceToFaceQs';
import OnlineQs from './OnlineQs';

const { Option } = Select;

const children = [];
for (let i = 10; i < 36; i += 1) {
  children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}

const stateReducer = (state, newState) => {
  return { ...state, ...newState };
};

const initFormState = ({ name = '', id = '' }) => ({
  date: '',
  endDate: '',
  startTime: '',
  endTime: '',
  sessionType: '',
  course: '',
  modules: '',
  capacity: null,
  partnerTrainer1: { key: id, label: name },
  partnerTrainer2: { key: '', label: '' },
  address: {
    addressLine1: '',
    addressLine2: '',
    town: '',
    postcode: ''
  },
  online: {
    platform: '',
    link: ''
  },
  extraInfo: '',
  contactEmail: '',
  validationErrors: {},
  submitAttempt: false
});

const CreateSession = ({
  id,
  role,
  currentUser,
  name,
  localLeadTrainersGroup,
  coursesCanDeliver,
  leadsAndTrainers,
  loading,
  inputData,
  createdSession,
  userObj,
  fetchAllTrainers,
  createSessionAction,
  fetchLocalLeads,
  fetchLocalLeadTrainersGroup,
  addSessionSuccess,
  storeInputData,
  clearInputData,
  resetSessionState,
  fetchCustomModules,
  modules: customModules
}) => {
  const [formState, setFormState] = useReducer(
    stateReducer,
    initFormState({ name, id })
  );

  const history = useHistory();
  const [decidedModuleOptions, setDecidedModulesOptions] = useState([]);
  let {
    date,
    endDate,
    startTime,
    endTime,
    course,
    modules,
    capacity,
    partnerTrainer1,
    contactEmail,
    partnerTrainer2,
    address,
    online,
    extraInfo,
    sessionType,
    submitAttempt,
    validationErrors
  } = formState;
  course =
    [userRoles.trainer, userRoles.localLead].includes(role) &&
    coursesCanDeliver.length === 1
      ? coursesCanDeliver[0]
      : course;

  const onSessionTypeChange = (val, name) => {
    if (val === 'online' && sessionType === 'faceToFace') {
      // clear address
      return setFormState({
        ...formState,
        [name]: val,
        address: initFormState.address
      });
    } else if (val === 'faceToFace' && sessionType === 'online') {
      // clear online
      return setFormState({
        ...formState,
        [name]: val,
        online: initFormState.online
      });
    }
    return setFormState({ ...formState, [name]: val });
  };

  const onInputChange = (val, name) => {
    if (name === 'sessionType') {
    }
    setFormState({ ...formState, [name]: val });
  };

  const onDropdownChange = (val, option, name) => {
    if (!val || !option) return setFormState({ ...formState, [name]: val });
    if (['partnerTrainer1', 'partnerTrainer2'].includes(name)) {
      const { props } = option;
      return setFormState({
        ...formState,
        [name]: { key: val, label: props?.name }
      });
    }

    if (name === 'modules' && course === courses.MECC) {
      return setFormState({ ...formState, [name]: [val] });
    }
    if (name === 'course') {
      return setFormState({ ...formState, [name]: val, modules: [] });
    }

    return setFormState({ ...formState, [name]: val });
  };

  const onAddressChange = (val, field) => {
    const { address } = formState;
    setFormState({ ...formState, address: { ...address, [field]: val } });
  };

  const onOnlineChange = (val, field, dropdownName) => {
    const name = dropdownName === 'platform' ? dropdownName : field;
    const { online } = formState;
    setFormState({
      ...formState,
      online: { ...online, [name]: val }
    });
  };

  const validateForm = () => {
    try {
      validate({
        date,
        endDate,
        startTime,
        endTime,
        sessionType,
        course,
        modules:
          course === 'MECC' && role === 'trainer'
            ? [moduleOptions[0].value]
            : modules,
        capacity,
        partnerTrainer: partnerTrainer1,
        address,
        online,
        extraInfo,
        contactEmail
      });

      setFormState({ validationErrors: {} });

      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setFormState({ validationErrors: error.inner });
      }
      return false;
    }
  };
  useEffect(() => {
    decideModuleOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course]);

  useEffect(() => {
    if (submitAttempt) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    date,
    endDate,
    startTime,
    endTime,
    sessionType,
    course,
    modules,
    capacity,
    partnerTrainer1,
    address,
    online,
    extraInfo,
    contactEmail
  ]);

  const onFormSubmit = e => {
    e.preventDefault();
    setFormState({ submitAttempt: true });

    const isValid = validateForm();
    if (isValid) {
      handleCreateSession();
    }
  };

  const handleCreateSession = () => {
    setFormState({ loading: true });

    createSessionAction({
      date,
      endDate,
      startTime,
      endTime,
      remote: sessionType === 'online',
      course,
      modules:
        course === 'MECC' && role === 'trainer'
          ? [moduleOptions[0].value]
          : modules,
      partnerTrainer1: partnerTrainer1?.key,
      partnerTrainer2: partnerTrainer2?.key,
      address: sessionType === 'online' ? null : address,
      meetingPlatform: online?.platform,
      meetingLink: online?.link,
      contactEmail,
      extraInfo,
      capacity
    });
  };

  const renderTrainersList = partnerTrainer => {
    if (role && role === 'localLead') {
      const nonDuplicatedTrainersAndLocalLeads = [];
      const trainersAndLocalLeads = [
        ...localLeadTrainersGroup,
        ...leadsAndTrainers
      ];
      trainersAndLocalLeads.forEach(e => {
        if (
          nonDuplicatedTrainersAndLocalLeads.find(prev => prev._id === e._id)
        ) {
          return;
        }
        nonDuplicatedTrainersAndLocalLeads.push(e);
      });

      const sortedNonDuplicatedTrainersAndLocalLeads = nonDuplicatedTrainersAndLocalLeads.sort(
        (a, b) => a.lowerName.localeCompare(b.lowerName)
      );

      return (
        sortedNonDuplicatedTrainersAndLocalLeads
          .filter(({ _id }) =>
            partnerTrainer?.key ? _id !== partnerTrainer.key : true
          )
          // sort the the array to get the logged in user at the first of the array
          .sort(({ _id }) => (_id === id ? -1 : 1))
          .map(({ name, _id }) => {
            return (
              <Option
                key={_id}
                value={_id}
                name={name}
                style={{ textTransform: 'capitalize' }}
              >
                {`${name[0].toUpperCase()}${name.slice(1)}`}{' '}
                {_id === id && '(Me)'}
              </Option>
            );
          })
      );
    } else if (leadsAndTrainers) {
      const sortedLeadsAndTrainers = leadsAndTrainers.sort((a, b) =>
        a.lowerName.localeCompare(b.lowerName)
      );

      return sortedLeadsAndTrainers
        .filter(({ _id }) =>
          partnerTrainer?.key ? _id !== partnerTrainer.key : true
        )
        .map(({ name, _id }) => (
          <Option
            key={_id}
            value={_id}
            name={name}
            style={{ textTransform: 'capitalize' }}
          >
            {`${name[0].toUpperCase()}${name.slice(1)}`}
          </Option>
        ));
    }
    return null;
  };

  const showCourseQuestion = () => {
    if (
      role === 'admin' ||
      ([userRoles.trainer, userRoles.localLead].includes(role) &&
        coursesCanDeliver.length > 1)
    ) {
      return true;
    }
    return false;
  };

  const showModuleQuestion = () => {
    if (
      (course === 'MECC' && (role === 'admin' || role === 'localLead')) ||
      course === 'C5' ||
      isCustomCourse(course)
    ) {
      return true;
    }
  };

  const decideModuleOptions = () => {
    if (isCustomCourse(course)) {
      return setDecidedModulesOptions(
        customModules
          .filter(module => module.type === course)
          .map(({ title, _id }) => ({
            value: _id,
            label: title
          }))
      );
    }
    if (role === 'admin') {
      if (course === 'MECC') {
        setDecidedModulesOptions([moduleOptions[0], moduleOptions[3]]);
      } else {
        setDecidedModulesOptions(moduleOptions);
      }
    } else {
      if (course === 'MECC') {
        setDecidedModulesOptions([moduleOptions[0]]);
      } else {
        setDecidedModulesOptions(moduleOptions.slice(0, 3));
      }
    }
  };

  useEffect(() => {
    fetchCustomModules({ liveOnly: true });

    if (role === 'localLead') {
      fetchLocalLeadTrainersGroup(id);
      fetchLocalLeads();
    } else {
      fetchAllTrainers();
      fetchLocalLeads();
    }

    return () => resetSessionState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (addSessionSuccess) {
      history.push(SESSION_CREATED_URL.replace(':id', createdSession.shortId));
    }
  }, [addSessionSuccess, createdSession.shortId, history]);

  const trainerHasNoCourses =
    [userRoles.trainer, userRoles.localLead].includes(role) &&
    coursesCanDeliver.length < 1;

  return (
    <Layout>
      <T.H1 mb="7">Create New Session</T.H1>
      {trainerHasNoCourses && (
        <InformationBox>
          You can't add new sessions because you are still not authorised to
          deliver any programme.
        </InformationBox>
      )}
      <Form onSubmit={onFormSubmit}>
        <DatePicker
          id="DatePicker"
          onDateChange={onInputChange}
          name="date"
          size="large"
          style={{ width: '100%' }}
          required
          label="Start Date"
          mb="6"
          value={date}
          error={validationErrors.date}
          disabled={trainerHasNoCourses}
        />
        <DatePicker
          id="DatePicker"
          onDateChange={onInputChange}
          name="endDate"
          size="large"
          style={{ width: '100%' }}
          required
          label="End Date"
          value={endDate}
          error={validationErrors.endDate}
          mb="6"
          disabled={trainerHasNoCourses}
        />
        <TimePicker
          onTimeChange={onInputChange}
          value={startTime}
          label="Start time"
          name="startTime"
          mb="6"
          error={validationErrors.startTime}
          popOver={{
            text:
              'If your session is running across multiple dates, select the general daily start and end time and then add further details in the extra information section below'
          }}
          disabled={trainerHasNoCourses}
        />
        <TimePicker
          onTimeChange={onInputChange}
          value={endTime}
          label="End time"
          name="endTime"
          error={validationErrors.endTime}
          mb="6"
          disabled={trainerHasNoCourses}
        />
        {/* PUT IN RADIO QUESTION HERE */}
        {/* ADDRESS AND ONLINE FIELDS HERE */}

        <Radio
          selected
          label="How are you delivering this session?"
          required
          name="sessionType"
          error={validationErrors.sessionType}
          handleChange={onSessionTypeChange}
          value={sessionType}
          options={[
            { label: 'Remote / online', value: 'online' },
            { label: 'Face to Face', value: 'faceToFace' }
          ]}
          disabled={trainerHasNoCourses}
        />

        {sessionType === 'faceToFace' && (
          <FaceToFaceQs
            address={address}
            extraInfo={extraInfo}
            onAddressChange={onAddressChange}
            onInputChange={onInputChange}
            error={validationErrors.address}
            extraInfoError={validationErrors.extraInfo}
            disabled={trainerHasNoCourses}
          />
        )}

        {sessionType === 'online' && (
          <OnlineQs
            online={online}
            extraInfo={extraInfo}
            onOnlineChange={onOnlineChange}
            onInputChange={onInputChange}
            error={validationErrors.online}
            extraInfoError={validationErrors.extraInfo}
            disabled={trainerHasNoCourses}
          />
        )}

        {showCourseQuestion() && (
          <Dropdown
            selected={course}
            m={{ mb: '6' }}
            label="Which course are you delivering?"
            placeholder="Select..."
            required
            options={
              [userRoles.trainer, userRoles.localLead].includes(role) &&
              coursesCanDeliver
                ? coursesCanDeliverOptions(coursesCanDeliver)
                : courseOptions
            }
            name="course"
            handleChange={onDropdownChange}
            error={validationErrors.course}
            disabled={trainerHasNoCourses}
          />
        )}

        {showModuleQuestion() && (
          <Dropdown
            selected={modules}
            m={{ mb: '6' }}
            label="Which modules are you delivering in this session?"
            placeholder="Select as many as you like"
            required
            multi={course !== courses.MECC}
            options={decidedModuleOptions}
            name="modules"
            handleChange={onDropdownChange}
            error={validationErrors.modules}
            disabled={trainerHasNoCourses}
          />
        )}

        <BasicInput
          value={capacity}
          handleChange={onInputChange}
          mb="6"
          label="Maximum capacity"
          required
          name="capacity"
          type="number"
          popOver={{
            text:
              'This is the total amount of people allowed to attend the session'
          }}
          error={validationErrors.capacity}
          disabled={trainerHasNoCourses}
        />

        {['localLead', 'admin'].includes(role) && (
          <Dropdown
            selected={partnerTrainer1.label}
            m={{ mb: '6' }}
            label="Trainer"
            placeholder="Select"
            required
            options={renderTrainersList(partnerTrainer2)}
            customOptions
            name="partnerTrainer1"
            handleChange={onDropdownChange}
            error={validationErrors.partnerTrainer?.key}
            disabled={trainerHasNoCourses}
          />
        )}

        <BasicInput
          value={contactEmail}
          handleChange={onInputChange}
          mb="6"
          label="Trainer contact email"
          required
          name="contactEmail"
          popOver={{
            text:
              'This is the email address that will be shared with those who register to attend this session'
          }}
          error={validationErrors.contactEmail}
          disabled={trainerHasNoCourses}
        />

        <Dropdown
          selected={partnerTrainer2?.label}
          m={{ mb: '6' }}
          label="Second / partner trainer"
          placeholder="Select"
          options={renderTrainersList(partnerTrainer1)}
          customOptions
          name="partnerTrainer2"
          handleChange={onDropdownChange}
          allowClear
          disabled={trainerHasNoCourses}
        />
        {Object.keys(validationErrors)?.length > 0 && (
          <T.P mb="4" mt="2" color="error">
            There are errors, please check above.
          </T.P>
        )}
        <Button
          onClick={onFormSubmit}
          type="primary"
          label="Submit"
          height="40px"
          width="100%"
          loading={loading}
          disabled={loading || trainerHasNoCourses}
        />
      </Form>
    </Layout>
  );
};

const mapStateToProps = state => {
  const { trainers } = state.trainers;
  const localLeads = state.fetchedData.localLeadsList;
  const inputData = state.storeSessionData;
  const leadsAndTrainers = [...localLeads, ...trainers];
  const coursesCanDeliver = state?.auth?.coursesCanDeliver || [];

  return {
    id: state.auth.userId,
    role: state.auth.role,
    coursesCanDeliver,
    currentUser: state.auth,
    name: state.auth.name,
    localLeadTrainersGroup: state.fetchedData.localLeadGroup,

    leadsAndTrainers,
    loading: state.session.loading,
    addSessionSuccess: state.session.addSessionSuccess,
    inputData,
    createdSession: state.session,
    userObj: state.auth,
    modules: state.modules.modules
  };
};

export default connect(mapStateToProps, {
  fetchAllTrainers,
  createSessionAction,
  fetchLocalLeads,
  fetchLocalLeadTrainersGroup,
  storeInputData,
  clearInputData,
  resetSessionState,
  fetchCustomModules
})(CreateSession);
