import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const MobileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.midPrimary};
  @media (min-width: 918px) {
    display: none;
  }
`;

export const DesktopWrapper = styled.div`
  display: none;
  max-width: 1440px;
  margin: 0 auto;
  padding: 24px 8.3vw 24px 8.3vw;
  background: ${({ theme }) => theme.colors.white};
  justify-content: space-between;
  @media (min-width: 918px) {
    display: flex;
  }
`;

export const IconWrapper = styled.button`
  border: none;
  background: none;
  cursor: pointer;
`;

export const Container = styled.div`
  position: ${({ open }) => (!!open ? 'fixed' : 'relative')};
  z-index: 10000;
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
`;

export const Mask = styled.div`
  background: rgba(0, 0, 0, 0.68);
  position: fixed;
  top: 73px;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  @media (min-width: 918px) {
    display: none;
  }
`;

export const MenuLinksWrapper = styled.div`
  background: ${({ theme }) => theme.colors.mainPrimary};
  width: 100%;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 22px;
`;

export const LogosSide = styled.div`
  display: flex;
  gap: 8px;
  flex: 1;
  max-width: 345px;
`;

export const LogoImage = styled.img`
  margin-right: 10px;
  @media (min-width: 918px) {
    margin-right: 22px;
  }
`;

export const Logo1 = styled(LogoImage)`
  height: ${({ NHSEngland }) => (NHSEngland ? '65px' : '48px')};
  @media (min-width: 918px) {
    height: ${({ NHSEngland }) => (NHSEngland ? '65px' : '54px')};
  }
`;

export const Logo2 = styled(LogoImage)`
  height: 70px;
  @media (min-width: 918px) {
    height: 75px;
  }
`;

export const LinksSide = styled.div`
  display: flex;
  gap: 24px;
`;

export const DesktopLink = styled(Link)`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
