import React from 'react';

import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Modal } from 'antd';
import { Link } from 'react-router-dom';
import { selectRole } from '../../../actions/authAction';
import { userRoles } from '../../../constants';
import { DECIDE_ROLE } from '../../../constants/navigationRoutes';
import { isCustomCourse } from '../../../helpers';
import Button from '../../common/Button';
import { Col, Row } from '../../common/Grid';
import * as T from '../../common/Typography';

const ConfirmButtons = ({
  onYes,
  role,
  course,
  multipleUsers,
  loggedInUserRoles,
  selectRole
}) => {
  const history = useHistory();
  const location = useLocation();

  const resetRole = async () => {
    await selectRole(null);
    history.push({
      pathname: DECIDE_ROLE,
      state: {
        redirectTo: location.pathname
      }
    });
  };

  return (
    <Row>
      <Col w={[4, 12, 8]}>
        <Row inner>
          <Col w={[4, 12, 12]}>
            <T.H3 mt="6" mb="4">
              {isCustomCourse(course)
                ? 'Complete evaluation for this session'
                : 'Are these details correct?'}
            </T.H3>
          </Col>
          <Col w={[4, 6, 6]}>
            <Button
              label={isCustomCourse(course) ? 'Start' : 'Yes'}
              type="secondary"
              onClick={onYes}
              disabled={role && role !== userRoles.participant}
            />
          </Col>
          {!isCustomCourse(course) && (
            <Col w={[4, 6, 6]}>
              <Button
                label="No"
                type="primary"
                onClick={() => {
                  const modal = Modal.error({
                    title: 'Wrong Session Details?',
                    content:
                      'Please contact your trainer to send you the correct link.',
                    onOk: e => {
                      history.push('/');
                      modal.destroy();
                    }
                  });
                }}
                disabled={role && role !== userRoles.participant}
              />
            </Col>
          )}
        </Row>
        {role && role !== userRoles.participant && (
          <Col w={[4, 12, 12]} mt={4}>
            <T.P color="error">
              Only participants can fill in the survey
              {multipleUsers && loggedInUserRoles?.includes('participant') && (
                <>
                  , non-participants must switch accounts to participate via the{' '}
                  <Link onClick={resetRole}>account switch page</Link>
                </>
              )}
            </T.P>
          </Col>
        )}
      </Col>
    </Row>
  );
};

const mapStateToProps = state => {
  return {
    loggedInUserRoles: state.auth.userRoles,
    multipleUsers: state.auth.multipleUsers,
    role: state.auth.role
  };
};

export default connect(mapStateToProps, {
  selectRole
})(ConfirmButtons);
