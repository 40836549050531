import { Slider } from 'antd';
import {
  OneToTenRateWrapper,
  SliderWrapper,
  SliderLabel
} from '../Questions.style';

const RateFromOneToTen = ({ question, setQuestion, onBlur, disabled }) => {
  const { _id: questionId, answer } = question;

  const onChange = value => {
    setQuestion({
      _id: questionId,
      answer: value,
      answered: !!value
    });
  };

  return (
    <OneToTenRateWrapper>
      <SliderLabel>1</SliderLabel>
      <SliderWrapper>
        <Slider
          id={`one-to-10-slider-${questionId}`}
          min={1}
          max={10}
          name={questionId}
          onChange={onChange}
          value={answer || 1}
          onAfterChange={value => {
            if (value === 1 && !answer) {
              onChange(value);
            }
            onBlur(value);
          }}
          disabled={disabled}
        />
      </SliderWrapper>
      <SliderLabel>10</SliderLabel>
    </OneToTenRateWrapper>
  );
};

export default RateFromOneToTen;
