import * as T from '../Typography';
import * as S from './style';
import TobaccoAwareness from '../../../assets/NHS 01060-23 Illustration Awareness White On Blue.png';

const TobaccoHeader = ({ title, description }) => {
  return (
    <S.Container>
      <S.Wrapper>
        <S.LogosSide>
          <S.LogoImage src={TobaccoAwareness} />
        </S.LogosSide>
        <S.ContentSide>
          <T.H1 color="white" weight="700" mb="4">
            {title}
          </T.H1>
          {description && (
            <T.P weight="700" smallMedium color="gray">
              {description}
            </T.P>
          )}
        </S.ContentSide>
      </S.Wrapper>
    </S.Container>
  );
};

export default TobaccoHeader;
