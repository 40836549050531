import React, { useState } from 'react';
import { connect } from 'react-redux';

import NorthEastAndNorthCumbria from '../../../assets/NorthEastNorthCumbria-NHS.svg';
import NorthEastPartnership from '../../../assets/NorthEastPartnership.png';
import NHSEngland from '../../../assets/NHS-England.jpg';
import Icon from '../Icon';
import * as T from '../Typography';
import * as S from './style';

import { userRoles } from '../../../constants';
import {
  HOME_URL,
  MY_ACCOUNT_URL,
  PROGRAMMES
} from '../../../constants/navigationRoutes';

const WebsiteMenu = ({ role, showNHSEnglandLogo }) => {
  const [open, setOpen] = useState(false);

  return (
    <S.Container open={open}>
      <S.DesktopWrapper>
        <S.LogosSide>
          <S.Logo2 src={NorthEastPartnership} alt="HealthEducationEngland" />
          <S.Logo1
            src={showNHSEnglandLogo ? NHSEngland : NorthEastAndNorthCumbria}
            alt="NorthEastAndNorthCumbria"
            NHSEngland={showNHSEnglandLogo}
          />
        </S.LogosSide>
        <S.LinksSide>
          <S.DesktopLink to={PROGRAMMES}>
            <T.P color="darkGray">Programmes</T.P>
          </S.DesktopLink>
          <S.DesktopLink
            to={{
              pathname:
                role && role !== userRoles.admin ? MY_ACCOUNT_URL : HOME_URL,
              state: {
                preventRedirect: true
              }
            }}
          >
            <T.P color="darkGray">My account</T.P>
          </S.DesktopLink>
        </S.LinksSide>
      </S.DesktopWrapper>
      <S.MobileWrapper>
        <S.LogosSide>
          <S.Logo2 src={NorthEastPartnership} alt="HealthEducationEngland" />
          <S.Logo1
            src={showNHSEnglandLogo ? NHSEngland : NorthEastAndNorthCumbria}
            alt="NorthEastAndNorthCumbria"
            NHSEngland={showNHSEnglandLogo}
          />
        </S.LogosSide>
        {!open && (
          <S.IconWrapper onClick={() => setOpen(true)}>
            <Icon icon="menu" color="darkGray" width="20px" height="20px" />
          </S.IconWrapper>
        )}
        {open && (
          <S.IconWrapper onClick={() => setOpen(false)}>
            <Icon icon="cross" color="darkGray" width="20px" height="20px" />
          </S.IconWrapper>
        )}
      </S.MobileWrapper>
      {open && (
        <S.Mask>
          <S.MenuLinksWrapper>
            <S.DesktopLink to={PROGRAMMES}>
              <T.P color="white">Programmes</T.P>
            </S.DesktopLink>
            <S.DesktopLink
              to={{
                pathname:
                  role && role !== userRoles.admin ? MY_ACCOUNT_URL : HOME_URL,
                state: {
                  preventRedirect: true
                }
              }}
            >
              <T.P color="white">My account</T.P>
            </S.DesktopLink>
          </S.MenuLinksWrapper>
        </S.Mask>
      )}
    </S.Container>
  );
};

const mapStateToProps = state => {
  return {
    role: state.auth.role
  };
};
export default connect(mapStateToProps, {})(WebsiteMenu);
