import ChildrenNorthEast from '../../../assets/childrennortheast_logo.png';
import WhiteChildrenNorthEast from '../../../assets/childrennortheast_logo_white.png';
import { CNE_POVERTY_PROOFING } from '../../../constants/navigationRoutes';
import * as T from '../../common/Typography';
import * as S from './style';

const CNECard = ({ dark, footer, ...props }) => {
  return (
    <S.Container dark={dark} {...props}>
      <S.Wrapper footer={footer}>
        <S.LogosSide>
          <S.LogoImage
            src={dark ? WhiteChildrenNorthEast : ChildrenNorthEast}
            alt="ChildrenNorthEast"
          />
        </S.LogosSide>
        <S.ContentSide>
          <T.H2 color={dark ? 'white' : 'mainPrimary'} weight="700" mb="4">
            This programme has been developed and delivered by Children North
            East on behalf of the NENC ICB
          </T.H2>
          <T.P small color={dark ? 'white' : 'darkGray'}>
            Children North East is an independent charity and is commissioned to
            deliver Poverty Proofing© interventions as part of the ICBs
            Healthier and Fairer Outcomes for All Programme. For further
            information on Poverty Proofing© Healthcare services please visit
            Children North East's website:{' '}
            <T.Link small external to={CNE_POVERTY_PROOFING} color="inherit">
              children-ne.org.uk/povertyproofing
            </T.Link>
          </T.P>
        </S.ContentSide>
      </S.Wrapper>
    </S.Container>
  );
};

export default CNECard;
