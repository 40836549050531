import * as T from '../Typography';

import * as S from './style';
import NorthEastAndNorthCumbria from '../../../assets/NorthEastNorthCumbria-NHS.svg';
import NorthEastPartnership from '../../../assets/NorthEastPartnership.png';
import {
  NHS,
  TERMS_OF_USE,
  PRIVACY_POLICY,
  HOME_URL
} from '../../../constants/navigationRoutes';

const Footer = () => {
  return (
    <S.Container>
      <S.Wrapper>
        <S.LogosSide>
          <S.LogoImage
            height={'75px'}
            src={NorthEastPartnership}
            alt="HealthEducationEngland"
          />
          <S.LogoImage
            src={NorthEastAndNorthCumbria}
            alt="NorthEastAndNorthCumbria"
          />
        </S.LogosSide>
        <S.LinksSide>
          <T.Link
            color="darkGray"
            to={{
              pathname: HOME_URL,
              state: {
                preventRedirect: true
              }
            }}
          >
            Home
          </T.Link>
          <T.Link color="darkGray" to={NHS} external>
            NHS
          </T.Link>
          {/* Sarah will provide missing URLs */}
          <T.Link color="darkGray">Data Policy</T.Link>
          <T.Link color="darkGray" to={TERMS_OF_USE} external>
            Terms
          </T.Link>
          <T.Link color="darkGray" to={PRIVACY_POLICY} external>
            Privacy
          </T.Link>
        </S.LinksSide>
      </S.Wrapper>
    </S.Container>
  );
};

export default Footer;
