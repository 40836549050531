import React from 'react';

import useSearchParams from '../../../hooks/useSearchParams';

import Button from '../../common/Button';
import * as T from '../../common/Typography';
import { Row, Col } from '../../common/Grid';

import {
  PARTICIPANT_LOGIN,
  PARTICIPANT_SIGN_UP_URL
} from '../../../constants/navigationRoutes';

const LoginButtons = ({ courseName, contactEmail, type, shortId }) => {
  const searchParams = useSearchParams();

  searchParams.set('source', 'survey');
  searchParams.set('showExtraInfo', true);
  searchParams.set('courseName', courseName);
  searchParams.set('contactEmail', contactEmail);
  searchParams.set('shortId', shortId);
  searchParams.set('surveyType', type);

  return (
    <Row>
      <Col w={[4, 12, 12]} mt={8}>
        <T.P>
          To fill this evaluation you must create a free iCS training account.
          This account will let you attend courses, receive your certificates
          and track your progress.
        </T.P>
      </Col>
      <Col w={[4, 12, 12]} mt={5}>
        <Button
          label="Sign up"
          width="200px"
          type="primary"
          to={{
            pathname: PARTICIPANT_SIGN_UP_URL,
            search: searchParams.toString()
          }}
        />
      </Col>
      <Col w={[4, 12, 12]} mt={3}>
        <Button
          label="Log in"
          width="200px"
          type="tertiary"
          to={{
            pathname: PARTICIPANT_LOGIN,
            search: searchParams.toString()
          }}
        />
      </Col>
    </Row>
  );
};

export default LoginButtons;
