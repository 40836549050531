import { fields, createSchema, validate as _validate } from '..';

const {
  email,
  optionalText,
  password,
  name,
  agreedOnTerms,
  confirmAttendance,
  requiredText
} = fields;

const trainerSchema = createSchema({
  email,
  message: optionalText,
  name,
  password,
  isAgree: agreedOnTerms
});

const loginSchema = createSchema({
  email,
  message: optionalText,
  password: requiredText,
  isAgree: agreedOnTerms
});

const ConfirmAttendanceSchema = createSchema({
  confirmAttendance: confirmAttendance
});
export const validateSignup = data => {
  return _validate(trainerSchema, data);
};
export const validateLogin = data => {
  return _validate(loginSchema, data);
};

export const validateConfirmAttendance = data => {
  return _validate(ConfirmAttendanceSchema, data);
};
