import React, { Component } from 'react';
import { Icon } from 'antd';

import {
  SessionTopDetailsWrapper,
  SubDetails,
  DrawerLink,
  Row as StyledRow,
  Edit
} from '../SessionDetails.Style';

import DefaultSessionForCustomModules from './DefaultSessionForCustomModules';

import { Row, Col } from '../../../common/Grid';
import * as T from '../../../common/Typography';

class ManageAttendees extends Component {
  render() {
    const {
      handleDrawerOpen,
      confirmedAttendeesList,
      sessionDetails
    } = this.props;

    if (sessionDetails?.defaultSessionForCustomModule) {
      return <DefaultSessionForCustomModules {...this.props} />;
    }
    return (
      <SessionTopDetailsWrapper>
        <Row mb="5" mt="4">
          <Col w={[4, 8, 10]}>
            <T.P>
              Here you can see who has registered so far and manage all the
              usual admin before a training session. You can view & edit your
              registered attendees, view special requirements and email out
              session reminders.
            </T.P>
          </Col>
        </Row>
        <Row mb="2">
          <Col w={[2, 6, 6]}>
            <T.P weight={700}>
              Registered attendees: {confirmedAttendeesList.length}
            </T.P>
          </Col>
          <Col w={[2, 6, 6]}>
            <Edit onClick={handleDrawerOpen} data-key="viewAttendeesList">
              Edit
            </Edit>
          </Col>
        </Row>
        <SubDetails>
          <StyledRow
            onClick={handleDrawerOpen}
            data-key="viewAttendeesList"
            data-target="update"
          >
            <DrawerLink>View & edit registered attendees</DrawerLink>
            <Icon type="right" />
          </StyledRow>
        </SubDetails>

        <SubDetails>
          <StyledRow
            onClick={handleDrawerOpen}
            data-key="specialRequirements"
            data-target="specialRequirements"
          >
            <DrawerLink>View special requirements</DrawerLink>
            <Icon type="right" />
          </StyledRow>
        </SubDetails>

        <SubDetails>
          <StyledRow
            onClick={handleDrawerOpen}
            data-key="sendEmails"
            data-target="send"
          >
            <DrawerLink>Send session info email reminder</DrawerLink>
            <Icon type="right" />
          </StyledRow>
        </SubDetails>

        <SubDetails>
          <StyledRow onClick={handleDrawerOpen} data-key="viewEmails">
            <DrawerLink>View emails you have sent</DrawerLink>
            <Icon type="right" />
          </StyledRow>
        </SubDetails>
      </SessionTopDetailsWrapper>
    );
  }
}

export default ManageAttendees;
