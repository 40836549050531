export const MATERIALS = {
  C5: {
    1: {
      PRE: [
        {
          displayName: 'Module 1 In-course Workbook',
          link:
            'https://drive.google.com/file/d/1E7BzRYQvKbvqVzTrMXmuxttUuDUQNGjh/view?usp=sharing'
        }
      ],
      POST: [
        {
          displayName: 'Module 1 Participant Notes Participant Guide',
          link:
            'https://drive.google.com/file/d/1g_Xy_VreDL9B2fy2RNyR59jYdb8HSfSt/view?usp=sharing'
        }
      ]
    },
    2: {
      PRE: [
        {
          displayName: 'Module 2 In-course Workbook',
          link:
            'https://drive.google.com/file/d/1w_zGkZQohy_LzsdbtWHUriuvzFen3onc/view?usp=sharing'
        }
      ],
      POST: [
        {
          displayName: 'Module 2 Participant Notes Participant Guide',
          link:
            'https://drive.google.com/file/d/1U4ODIOfaF6Dw1vmEspYwWG3ar5hWp91A/view?usp=sharing'
        }
      ]
    },
    3: {
      PRE: [
        {
          displayName: 'Module 3 In-course Workbook',
          link:
            'https://drive.google.com/file/d/1O3HDQ5hshvZuP5Wht-aXW_KZXkn0hcOQ/view?usp=sharing'
        }
      ],
      POST: [
        {
          displayName: 'Module 3 Participant Notes Participant Guide',
          link:
            'https://drive.google.com/file/d/1i0Gi4BMOzMaqWE4bdbKOrjCb-FhwNvp0/view?usp=sharing'
        }
      ]
    },
    'Train the Trainer': {
      PRE: [
        {
          displayName: 'Trainer Course Workbook',
          link:
            'https://drive.google.com/file/d/19b0XLQaKraynCTRdg5Pa3oziQloak6Uu/view?usp=sharing'
        },
        {
          displayName: 'Community Trainer Micro Teach Peer Feedback Form',
          link:
            'https://drive.google.com/file/d/1Lez2tF2UDTkQpVSdvqHNODTwU_kRwWJR/view?usp=sharing'
        }
      ]
    }
  },
  MECC: {
    1: {
      PRE: [
        {
          displayName: 'What is MECC',
          link: 'https://youtu.be/ywuPditXaZM'
        },
        {
          displayName: 'MECC Impact',
          link: 'https://youtu.be/oUJSQfjr5X4'
        },
        {
          displayName: 'MECC as a Public Health Intervention',
          link: 'https://youtu.be/tUHQeYLofWU'
        },
        {
          displayName: 'MECC Behaviour Change Models',
          link: 'https://youtu.be/9VuOyrdz8Ig'
        }
      ],
      POST: [
        {
          displayName: 'NHS Futures',
          link: 'https://future.nhs.uk/NEastNCumbriaMECC/grouphome'
        },
        {
          displayName: 'MECC | Home',
          link: 'https://www.meccgateway.co.uk'
        }
      ]
    },
    'Train the Trainer': {
      PRE: [
        {
          displayName: 'What is MECC',
          link: 'https://youtu.be/ywuPditXaZM'
        },
        {
          displayName: 'MECC Impact',
          link: 'https://youtu.be/oUJSQfjr5X4'
        },
        {
          displayName: 'MECC as a Public Health Intervention',
          link: 'https://youtu.be/tUHQeYLofWU'
        },
        {
          displayName: 'MECC Behaviour Change Models',
          link: 'https://youtu.be/9VuOyrdz8Ig'
        }
      ],
      POST: [
        {
          displayName: 'Trainers FAQ Sheet',
          link:
            'https://github.com/yalla-coop/nhct-c5/files/7527714/Trainers.FAQ.Sheet.docx'
        },
        {
          displayName: 'MECC slides',
          link:
            'https://github.com/yalla-coop/nhct-c5/files/7527724/MECC.-.longer.slides.without.videos.pdf'
        },
        {
          displayName: 'NHS Futures',
          link: 'https://future.nhs.uk/NEastNCumbriaMECC/grouphome'
        },
        {
          displayName: 'MECC | Home',
          link: 'https://www.meccgateway.co.uk'
        }
      ]
    }
  }
};
