import styled from 'styled-components';
import Call_out from '../../../assets/Call out.png';
import Call_out_mobile from '../../../assets/Hero.png';

export const HeadWrapper = styled.div`
  max-width: 698px;
  margin: 0 auto;
`;

export const CallOut = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  @media (max-width: 918px) {
    flex-direction: column;
  }
`;
export const BlueCard = styled.div`
  background: ${({ theme }) => theme.colors.mainPrimary};
  background-image: ${`url("${Call_out}")`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom right;
  padding: 50px 36px;
  flex: 1.2;
  max-width: 642px;
  border-radius: 4px;
  min-height: 658px;
  @media (max-width: 918px) {
    padding: 24px;
    min-height: 416px;

    max-width: 100%;
    background-image: ${`url("${Call_out_mobile}")`};
  }
`;

export const SubtitleWrapper = styled.div`
  max-width: 451px;
`;
