import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import Layout from './../../Layouts';

import {
  Wrapper,
  DescriptionContainer,
  Headline,
  Paragraph,
  ButtonContainer,
  ButtonLink,
  ButtonDiv
} from './LandingPage.style';

// ROUTES
import {
  LOGIN_URL,
  PARTICIPANT_LOGIN,
  ALCOHOL_STUDIES
} from '../../../constants/navigationRoutes';

import Button from '../../common/Button';

const LandingPage = ({ isAuthenticated, role }) => {
  const history = useHistory();
  const location = useLocation();
  const currentDomain = window.location.href;
  const desiredDomain = 'https://madeinheene.alcoholstudies.hee.nhs.uk/';
  const desiredDomain2 = 'https://madeinheene.alcoholstudies.hee.nhs.uk';
  const preventRedirect = location.state?.preventRedirect;

  if (
    (currentDomain === desiredDomain || currentDomain === desiredDomain2) &&
    !preventRedirect
  ) {
    // Load the alcohol studies landing page
    history.replace(ALCOHOL_STUDIES);
    return null;
  }

  if (isAuthenticated) {
    switch (role) {
      case 'admin':
      case 'localLead':
      case 'trainer':
        history.replace('/dashboard');
        return null;
      case 'participant':
        history.replace('/participant-dashboard');
        return null;
      default:
        break;
    }
  }

  return (
    <Layout layout={'website'}>
      <Wrapper>
        <DescriptionContainer>
          <Headline>Welcome to the ICS Training Platform</Headline>
          <Paragraph style={{ textAlign: 'center' }}>
            Please select whether you want to view a course you are currently
            attending, or to manage courses in which you are a certified trainer
          </Paragraph>
        </DescriptionContainer>
        <ButtonContainer>
          <ButtonLink to={`${LOGIN_URL}`}>
            <ButtonDiv>
              <Button
                label="I'm a Trainer"
                width="100%"
                height="100%"
                type="primary"
              />
            </ButtonDiv>
          </ButtonLink>
          <ButtonLink to={`${PARTICIPANT_LOGIN}`}>
            <ButtonDiv>
              <Button
                label="I'm a Course Participant"
                width="100%"
                height="100%"
                type="primary"
              />
            </ButtonDiv>
          </ButtonLink>
        </ButtonContainer>
      </Wrapper>
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    role: state.auth.role
  };
};

export default connect(mapStateToProps)(LandingPage);
