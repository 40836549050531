import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  archiveModule,
  fetchCustomModules
} from '../../../actions/customModulesActions';
import Layout from '../../Layouts';
import { Col, Row } from '../../common/Grid';
import * as T from '../../common/Typography';

import { useHistory } from 'react-router-dom';
import { MODULES_URL } from '../../../constants/navigationRoutes';
import Button from '../../common/Button';
import NewTable from '../../common/NewTable';
import Modal from '../../common/modal';
import { TableColumns } from './TableColumns';

const ArchivedModules = ({
  archivedModules,
  loading,
  archiveLoading,
  fetchModulesAction,
  unarchiveModuleAction,
  selectedCourse
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedModule, setSelectedModule] = useState(null);

  const history = useHistory();

  const handleUnarchive = ({ item }) => {
    setSelectedModule(item);
    setShowModal(true);
  };

  const unarchiveModule = async () => {
    await unarchiveModuleAction({
      moduleId: selectedModule._id,
      data: {
        archived: false
      }
    });
    setShowModal(false);
    fetchModulesAction({ archived: true, course: selectedCourse });
  };

  useEffect(() => {
    if (selectedCourse) {
      fetchModulesAction({ archived: true, course: selectedCourse });
    } else {
      history.push(MODULES_URL); // Must select first on which program we are
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCourse]);

  return (
    <Layout withGraph>
      <T.H1 mb={7}>Archived modules</T.H1>

      <Row mt="7">
        <Col w={[4, 12, 12]}>
          <NewTable
            columns={TableColumns({ handleUnarchive })}
            data={archivedModules}
            loading={loading}
          />
        </Col>
      </Row>
      <Modal visible={showModal} setModalOpen={setShowModal}>
        <T.H4W color="darkGray" mb="2" mt="2">
          Are you sure?
        </T.H4W>
        <T.P color="gray" mb="10">
          Unarchiving this module will make it visible again.
        </T.P>
        <Button
          label="Confirm unarchive"
          type="primary"
          width="200px"
          loading={archiveLoading}
          onClick={unarchiveModule}
        />
      </Modal>
    </Layout>
  );
};

const mapStateToProps = state => ({
  archivedModules: state.modules.modules,
  loading: state.loading.fetchModulesLoading,
  archiveLoading: state.loading.archiveModuleLoading,
  selectedCourse: state.course.selectedCourse
});

export default connect(mapStateToProps, {
  fetchModulesAction: fetchCustomModules,
  unarchiveModuleAction: archiveModule
})(ArchivedModules);
