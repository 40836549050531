import React, { useEffect, useState } from 'react';
import { Select, Tooltip, Button as AntButton } from 'antd';
import Modal from './../../../common/modal';

import Button from '../../../common/Button';
import { Row, Col } from '../../../common/Grid';
import * as T from '../../../common/Typography';

import { SelecetWrapper, IconsWrapper } from '../SessionDetails.Style';

import UserManual from '../UserManual';

const { Option } = Select;

const UpdateAttendeesList = ({
  handleSubmitUpdateAttendees,
  confirmedAttendeesList,
  handleUpdateAttendees,
  onTypingEmails,
  onSelectBlur,
  onSelectFocus,
  loading,
  onCopy,
  onClear,
  selectRef,
  sessionDetails
}) => {
  const [originalList, setOriginalList] = useState();
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const haveNotCreatedAccount = confirmedAttendeesList.filter(
    email => !sessionDetails.createdAccount.find(acc => acc.email === email)
  );

  const notCompletedSurvey = surveyType =>
    sessionDetails.createdAccount?.reduce(
      (acc, curr) => {
        const foundSurvey = sessionDetails.completedSessionSurveys.find(
          survey => survey.user === curr.id && survey.surveyType === surveyType
        );

        if (!foundSurvey) return [...acc, curr.email];
        return acc;
      },
      [...haveNotCreatedAccount]
    );

  useEffect(() => {
    setOriginalList(confirmedAttendeesList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUpdate = () => {
    let oneEmailDeleted = false;
    originalList.forEach(email => {
      if (!confirmedAttendeesList.includes(email)) {
        oneEmailDeleted = true;
      }
    });

    if (oneEmailDeleted) {
      return setConfirmModalVisible(true);
    } else {
      handleSubmitUpdateAttendees('confirmed');
    }
  };

  const notCompletedSurveyPre = notCompletedSurvey('pre-course');
  const notCompletedSurveyPost = notCompletedSurvey('post');
  return (
    <>
      <Row inner ml="2">
        <Col inner w={[4, 12, 8]}>
          <T.H1 mb="6">View & edit registered attendees</T.H1>
        </Col>
      </Row>
      <Row inner ml="2">
        <Col inner w={[4, 12, 8]}>
          <UserManual type="registrationList" />
        </Col>
      </Row>
      <Row>
        <Col inner w={[4, 6, 6]}>
          <SelecetWrapper>
            <IconsWrapper>
              <Tooltip placement="top" title="Copy">
                <AntButton
                  type="primary"
                  icon="copy"
                  ghost
                  onClick={() => onCopy('confirmed')}
                  disabled={!confirmedAttendeesList.length}
                />
              </Tooltip>
              <Tooltip placement="top" title="Delete">
                <AntButton
                  type="danger"
                  icon="delete"
                  ghost
                  onClick={() => onClear('confirmed')}
                  disabled={!confirmedAttendeesList.length}
                />
              </Tooltip>
            </IconsWrapper>

            <Select
              mode="tags"
              value={confirmedAttendeesList}
              placeholder="Select users"
              onChange={values => handleUpdateAttendees(values, 'confirmed')}
              onSearch={value => onTypingEmails(value, 'confirmed')}
              style={{ width: '100%' }}
              size="large"
              onBlur={onSelectBlur}
              onFocus={onSelectFocus}
              ref={selectRef}
            >
              {confirmedAttendeesList.map(item => (
                <Option value={item} key={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </SelecetWrapper>
        </Col>
      </Row>
      <Row>
        <Col inner w={[4, 6, 4]}>
          <Button
            type="primary"
            mt="5"
            onClick={() => onUpdate()}
            loading={loading}
            label="Update"
          />
        </Col>
      </Row>
      <Row mt="6">
        <Col inner w={[4, 6, 8]}>
          <T.H3 mt="5" weight={700}>
            Participant response status
          </T.H3>
          <T.P mt="2">
            Below you can see the status of each of your participants who has
            registered for this session so you know what actions to take. Please
            remember that every participant must first attend a session
            delivering module 1 to create their account.
          </T.P>
          <T.P mt={5} style={{ fontStyle: 'italic' }}>
            If you are delivering a recap session (i.e. a participant has
            already attended another session teaching these modules) it may be
            that they have completed the evaluations for those sessions instead
            as we only ask them to complete the evaluation once.
          </T.P>
        </Col>
      </Row>
      <Row mt="4">
        <Col inner w={[4, 6, 4]}>
          <T.P mt="5" weight={600}>
            The following users have not yet created account:
          </T.P>
          {haveNotCreatedAccount.length ? (
            haveNotCreatedAccount.map(item => (
              <T.P mt="2" style={{ width: '100%' }}>
                {item}
              </T.P>
            ))
          ) : (
            <T.P mt="2" style={{ width: '100%' }}>
              --
            </T.P>
          )}
        </Col>
      </Row>
      {sessionDetails.modules?.includes('1') && (
        <Row mt="2">
          <Col inner w={[4, 6, 4]}>
            <T.P mt="5" weight={600} style={{ width: '100%' }}>
              The following users have not yet completed their pre-course
              evaluation:
            </T.P>
            {notCompletedSurveyPre.length ? (
              notCompletedSurveyPre.map(item => (
                <T.P mt="2" style={{ width: '100%' }}>
                  {item}
                </T.P>
              ))
            ) : (
              <T.P mt="2" style={{ width: '100%' }}>
                --
              </T.P>
            )}
          </Col>
        </Row>
      )}
      <Row mt="2" mb="7">
        <Col inner w={[4, 6, 4]}>
          <T.P weight={600} mt="5">
            The following users have not yet completed their post-course
            evaluation:
          </T.P>
          {notCompletedSurveyPost.length ? (
            notCompletedSurveyPost.map(item => (
              <T.P mt="2" style={{ width: '100%' }}>
                {item}
              </T.P>
            ))
          ) : (
            <T.P mt="2" style={{ width: '100%' }}>
              --
            </T.P>
          )}
        </Col>
      </Row>
      <Modal
        type="confirm"
        visible={confirmModalVisible}
        setModalOpen={setConfirmModalVisible}
        onCancel={values => handleUpdateAttendees(values, 'confirmed')}
        parentFunc={() =>
          handleSubmitUpdateAttendees('confirmed', originalList)
        }
        text="Please note that removing this participant will also delete any evaluations they may have already done for this session"
      />
    </>
  );
};

export default UpdateAttendeesList;
