import _getUsedFilters from './getUsedFilters';
import { customCourses, surveyTypesNew } from '../constants';
import _checkIfSessionIsCompleted from './checkIfSessionIsFinished';

import {
  copyTextToClipBoard as copyText,
  splitEmailsList as splitEmails
} from './copyTextToClipBoard';
import { modules as moduleData, courses } from '../constants';

export const uppercaseSurvey = surveyType =>
  surveyType
    .split('-')
    .map(item => item[0].toLocaleUpperCase() + item.slice(1))
    .join(' ');

/**
 *  return the pre survey link
 * @param {String} shortId - session shortId
 */
export const getPreSurveyLink = ({ shortId, trainTheTrainer }) => {
  let surveyURL = `${window.location.host}/survey/${shortId}/pre-course`;
  if (trainTheTrainer) {
    surveyURL = `${window.location.host}/survey/${shortId}/pre-train-the-trainer`;
  }

  let url = `https://${surveyURL}`;

  if (process.env.NODE_ENV === 'development') {
    url = `http://${surveyURL}`;
  }

  return url;
};

/**
 *  return the post survey link
 * @param {String} shortId - session shortId
 */

export const getPostSurveyLink = ({ shortId, trainTheTrainer }) => {
  let surveyURL = `${window.location.host}/survey/${shortId}/post`;
  if (trainTheTrainer) {
    surveyURL = `${window.location.host}/survey/${shortId}/post-train-the-trainer`;
  }
  let url = `https://${surveyURL}`;

  if (process.env.NODE_ENV === 'development') {
    url = `http://${surveyURL}`;
  }

  return url;
};

/**
 * return array of survey links post & pre if exists
 * @param {Array} modules - session type ["1","2","3","special-2-days"...]
 * @param {String} shortId - session shortId
 */
export const getAllSurveyLinks = (modules, shortId, course) => {
  const includesTTT = modules?.includes(moduleData.TRAIN_THE_TRAINER);
  const includesModule1 = modules?.includes(moduleData.MODULE_1);

  if (includesTTT && course === courses.MECC) {
    return [
      getPreSurveyLink({ shortId, trainTheTrainer: true }),
      getPostSurveyLink({ shortId, trainTheTrainer: true })
    ];
  }

  if (includesTTT && !includesModule1) {
    return [getPostSurveyLink({ shortId, trainTheTrainer: true })];
  }

  if (includesTTT && includesModule1) {
    return [
      getPreSurveyLink({ shortId }),
      getPostSurveyLink({ shortId, trainTheTrainer: true })
    ];
  }

  if (includesModule1)
    return [getPreSurveyLink({ shortId }), getPostSurveyLink({ shortId })];

  return [getPostSurveyLink({ shortId })];
};

/**
 * create survey link using the survey type and the short id
 * @param {String} surveyType - survey type eg. pre-day-1, post-day-1,....
 * @param {Strin} shortId - the session short id
 */
export const getSurveyLink = (surveyType, shortId) => {
  const surveyURL = `${window.location.host}/survey/${surveyType}&${shortId}`;
  let url = `https://${surveyURL}`;

  if (process.env.NODE_ENV === 'development') {
    url = `http://${surveyURL}`;
  }

  return url;
};

/**
 * return array of surveys for the session base on the survey type
 * @param {String} sessionType - session type ["1","2","3","special-2-days"...]
 */
export const getSessionSurveys = sessionType => {
  // return surveysTypes[sessionType];
};

export const validPostcode = postcode => {
  postcode.replace(/\s/g, '');
  const regex = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;
  return regex.test(postcode);
};

export const handleEnterKey = (event, cb) => {
  event.preventDefault();
  return cb(event);
};

export const getUsedFilters = _getUsedFilters;
export const copyTextToClipBoard = copyText;
export const splitEmailsList = splitEmails;
export const checkIfSessionIsFinished = _checkIfSessionIsCompleted;

export const convertAddressObjToString = address => {
  let fullAddress;
  if (address) {
    const { postcode, town, addressLine1, addressLine2 } = address;
    if (postcode || addressLine1 || addressLine2 || town) {
      fullAddress = [addressLine1, addressLine2, town, postcode]
        .filter(item => !!item)
        .join(', ');
    }
  }
  return fullAddress;
};

export const concatModules = ({ modules = [], customModules = [] }) => {
  if (!modules.length && !customModules.length) return 'N/A';
  let modulesArr = [
    ...modules
      .filter(module => module !== 'CUSTOM')
      .map(module =>
        module.replace(/module /g, '').replace('Train the Trainer', 'Train')
      )
  ];
  if (customModules?.length) {
    customModules.forEach(module => {
      modulesArr.push(module.title);
    });
  }

  return modulesArr.join(' & ');
};

export const addStatusToSessions = sessions => {
  const sessionsWithStatus = sessions?.map(session => {
    const hasFinished = checkIfSessionIsFinished(session);
    if (!isCustomCourse(session.course)) {
      return {
        ...session,
        showInCompleted: hasFinished
      };
    }
    const module = session.customModules?.[0];
    const completedPostSurvey = session?.completedSurvey?.find(
      survey => survey.surveyType === surveyTypesNew.POST
    );
    const completedAt = session.completedAt;

    const showInCompleted = module.isLive
      ? !!completedPostSurvey || hasFinished
      : !!completedAt || !!completedPostSurvey;

    const completedAtDate = module.isLive
      ? session.date
      : completedAt || completedPostSurvey?.createdAt;

    return {
      ...session,
      showInCompleted,
      completedAtDate
    };
  });

  return sessionsWithStatus;
};

export const isCustomCourse = course => !!customCourses.includes(course);
