import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Span = styled.p`
  text-align: left;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
`;

export const BackWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80px;
  padding-left: 5%;
  cursor: pointer;
  margin: 2rem 0;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
`;

export const TableWrapperRow = styled.div`
  padding-top: 32px;
  width: 100%;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.lightPrimary};
`;

export const InfoWrapper = styled.div`
  padding: ${({ theme }) =>
    `${theme.spacings[7]} ${theme.spacings[6]} ${theme.spacings[6]}`};
  width: 100%;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.mainPrimary};
`;
