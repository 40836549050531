import moment from 'moment';
import React from 'react';

import modulesCategories from '../../../constants/dataTypes';
import Badge from '../../common/Badge';
import Icon from '../../common/Icon';
import * as T from '../../common/Typography';
import { categoryOptions } from '../UpdateModule/dropdownsData';
import * as S from './style';

const handleBadges = category => {
  const title = categoryOptions.find(_category => _category.value === category)
    .label;
  switch (category) {
    case modulesCategories.MANAGEMENT_OF_ALCOHOL:
    case modulesCategories.POVERTY_PROOFING_INTERVENTIONS:
    case modulesCategories.TOBACCO_TREATMENT:
      return <Badge title={title} variant="tertiary" />;
    case modulesCategories.ALCOHOL_IDENTIFICATION:
    case modulesCategories.POVERTY_IDENTIFICATION_AND_SUPPORT:
    case modulesCategories.TOBACCO_IDENTIFICATION:
      return <Badge title={title} variant="secondary" />;
    case modulesCategories.ALCOHOL_AWARENESS:
    case modulesCategories.POVERTY_AWARENESS:
    case modulesCategories.TOBACCO_AWARENESS:
    case modulesCategories.TOBACCO_CONTROL:
    default:
      return <Badge title={title} variant="primary" />;
  }
};

export const TableColumns = ({ handleUnarchive }) => [
  {
    title: (
      <T.P weight={600} color="darkGray">
        Module name
      </T.P>
    ),
    dataIndex: 'title',
    key: 'title',
    render: text => (
      <T.P weight={600} color="gray">
        {text}
      </T.P>
    ),
    width: '30%'
  },
  {
    title: (
      <T.P weight={600} color="darkGray">
        Created
      </T.P>
    ),
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: date => {
      return (
        <T.P weight={600} color="gray">
          {moment(date).format('DD.MM.YYYY')}
        </T.P>
      );
    },
    sorter: (a, b) =>
      moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf()
  },
  {
    title: (
      <T.P weight={600} color="darkGray">
        Category
      </T.P>
    ),
    dataIndex: 'category',
    key: 'category',
    render: category => handleBadges(category),
    sorter: (a, b) => {
      return a.category.length - b.category.length;
    }
  },

  {
    key: 'action',
    render: (_, record) => (
      <S.ActionsWrapper>
        <S.ActionButton onClick={() => handleUnarchive({ item: record })}>
          <Icon
            ml="4"
            mr="1"
            icon="unarchive"
            color="darkGray"
            width="24px"
            height="24px"
          />
          <T.P weight={600} color="darkGray">
            Unarchive
          </T.P>
        </S.ActionButton>
      </S.ActionsWrapper>
    )
  }
];
