import moment from 'moment';

const checkIfSessionIsFinished = ({ date, endTime }) => {
  const sessionDate = moment(date).startOf('day');
  const sessionEndTime = moment(endTime, 'HH:mm');
  const now = moment();
  const today = moment().startOf('day');

  if (sessionDate.isBefore(today)) {
    return true;
  }

  if (sessionDate.isSame(today) && sessionEndTime.isBefore(now)) {
    return true;
  }

  return false;
};

export default checkIfSessionIsFinished;
