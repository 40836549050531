import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchParticipantSessions } from '../../../actions/groupSessionsAction';
import { courseOptions } from '../../../constants';
import * as R from '../../../constants/navigationRoutes';
import Layout from '../../Layouts';
import { Col, Row } from '../../common/Grid';
import { Dropdown } from '../../common/Inputs';
import * as T from '../../common/Typography';

const ChooseCourseForSessions = ({ sessions, fetchSessions }) => {
  const [course, setCourse] = useState();
  const history = useHistory();
  const _courses = {};
  sessions.forEach(ses => {
    _courses[ses.course] = ses.course;
  });

  useEffect(() => {
    fetchSessions();
    if (Object.values(_courses).length === 1) {
      const _selectedCourse = Object.keys(_courses)[0];
      setCourse(_selectedCourse);
    }
    if (course) {
      history.push(R.PARTICIPANT_SESSIONS_LIST.replace(':course', course));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course, _courses]);

  return (
    <Layout>
      <>
        {!course && (
          <>
            <Row mb={7}>
              <Col w={[4, 8, 6]}>
                <T.H1>My Course</T.H1>
              </Col>
            </Row>
            <Row mb={4}>
              <Col w={[4, 8, 6]}>
                <Dropdown
                  selected={course}
                  label="Please select the course you would like to view"
                  placeholder="Select..."
                  required
                  options={courseOptions.filter(option =>
                    Object.values(_courses).includes(option.value)
                  )}
                  name="course"
                  handleChange={value => setCourse(value)}
                />
              </Col>
            </Row>
          </>
        )}
      </>
    </Layout>
  );
};
const mapStateToProps = state => ({
  sessions: state.sessions?.participantSessionsByEmail
});
const mapActionsToProps = {
  fetchSessions: fetchParticipantSessions
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(ChooseCourseForSessions);
