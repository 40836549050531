import { string, number, boolean, array, date, object, ref, mixed } from 'yup';
import * as errMsgs from './err-msgs';
import { sessionRemoteOrFace2FaceTypes } from '../constants/sessionData';
import './custom-functions';

export const requiredText = string()
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .required(errMsgs.DEFAULT_REQUIRED);

export const requiredDate = date()
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .required(errMsgs.DEFAULT_REQUIRED);

export const requiredNumber = number()
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .required(errMsgs.DEFAULT_REQUIRED);

export const email = string()
  .email(errMsgs.INVALID_EMAIL)
  .max(100, errMsgs.TOO_LONG_MAX_100)
  .required(errMsgs.DEFAULT_REQUIRED)
  .typeError(errMsgs.DEFAULT_REQUIRED);

export const name = string()
  .max(100, errMsgs.TOO_LONG_MAX_100)
  .required(errMsgs.DEFAULT_REQUIRED)
  .typeError(errMsgs.DEFAULT_REQUIRED);

export const password = string()
  .matches(
    // eslint-disable-next-line no-useless-escape
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[§±!@£$%^&*()+={#}:;'"|,.?\/\\[\]<>_-])[A-Za-z\d§±!@£$%^&*()-+={#}:;'"|,.?\/\\[\]<>_-]{8,}$/gm,
    errMsgs.SHORT_PASSWORD
  )
  .required(errMsgs.DEFAULT_REQUIRED);

export const passwordConfirmation = string()
  .oneOf([ref('password'), null], errMsgs.PASSWORDS_MUST_MATCH)
  .required(errMsgs.DEFAULT_REQUIRED);

export const loginPassword = string().required(errMsgs.DEFAULT_REQUIRED);

export const postcode = string()
  .required(errMsgs.DEFAULT_REQUIRED)
  .min(5, errMsgs.TOO_SHORT_MIN_5)
  .max(8, errMsgs.TOO_LONG_MAX_7)
  .matches(
    /\b(([a-z][0-9]{1,2})|(([a-z][a-hj-y][0-9]{1,2})|(([a-z][0-9][a-z])|([a-z][a-hj-y][0-9]?[a-z]))))( |)[0-9][a-z]{2}\b/gi,
    errMsgs.INVALID_POSTCODE
  );

export const agreedOnTerms = boolean()
  .oneOf([true], errMsgs.SHOULD_AGREE_ON_TERMS)
  .required(errMsgs.DEFAULT_REQUIRED);

export const checkbox = boolean()
  .oneOf([true, false], errMsgs.DEFAULT_REQUIRED)
  .required(errMsgs.DEFAULT_REQUIRED);

export const confirmAttendance = boolean()
  .oneOf([true], errMsgs.SHOULD_CONFIRM_MECC_ATTENDANCE)
  .required(errMsgs.DEFAULT_REQUIRED);

export const optionalText = string()
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .nullable();

export const optionalDate = date()
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .nullable();

export const urlRequired = string()
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .url(errMsgs.INVALID_LINK)
  .required(errMsgs.DEFAULT_REQUIRED);

export const optionalLink = string()
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .url(errMsgs.INVALID_LINK)
  .nullable();

export const arrayOfOptionalLinks = array()
  .of(optionalLink)
  .required(errMsgs.DEFAULT_REQUIRED)
  .typeError(errMsgs.DEFAULT_REQUIRED);

export const optionalPhoneNumber = string().when((value, schema) => {
  if (value) {
    return schema
      .phone()
      .min(9, errMsgs.INVALID_PHONE)
      .max(12, errMsgs.INVALID_PHONE)
      .typeError(errMsgs.INVALID_PHONE);
  }
  return schema.nullable();
});

export const phoneNumber = string()
  .required(errMsgs.DEFAULT_REQUIRED)
  .min(9, errMsgs.INVALID_PHONE)
  .max(12, errMsgs.INVALID_PHONE)
  .when((value, schema) => {
    return schema.phone().typeError(errMsgs.INVALID_PHONE);
  });

export const optionalCheckbox = boolean()
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .nullable();

export const numberField = number()
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .required(errMsgs.DEFAULT_REQUIRED);

export const arrayOfString = array()
  .of(string())
  .min(1, errMsgs.EMPTY_ARRAY)
  .required(errMsgs.DEFAULT_REQUIRED)
  .typeError(errMsgs.DEFAULT_REQUIRED);

export const arrayOfRequiredString = array()
  .of(string().required(errMsgs.DEFAULT_REQUIRED))
  .required(errMsgs.DEFAULT_REQUIRED)
  .typeError(errMsgs.DEFAULT_REQUIRED);

export const organisationOther = string().when('showOrgOther', {
  is: true,
  then: requiredText,
  otherwise: optionalText
});

const allowedSessionValues = sessionRemoteOrFace2FaceTypes.map(
  session => session.value
);
export const sessionType = string()
  .oneOf(allowedSessionValues, errMsgs.VALID_SESSION_TYPE)
  .required(errMsgs.DEFAULT_REQUIRED)
  .typeError(errMsgs.DEFAULT_REQUIRED);

export const coursesTypes = string()
  // .oneOf(allowedCourses, errMsgs.VALID_COURSE)
  .required(errMsgs.DEFAULT_REQUIRED)
  .typeError(errMsgs.DEFAULT_REQUIRED);

export const partnerTrainer = object().shape({
  key: string().required(errMsgs.DEFAULT_REQUIRED),
  label: string().required(errMsgs.DEFAULT_REQUIRED)
});

export const address = object().when('sessionType', {
  is: 'faceToFace',
  then: object().shape({
    addressLine1: string().required(errMsgs.DEFAULT_REQUIRED),
    addressLine2: string()
      .nullable()
      .typeError(errMsgs.DEFAULT_REQUIRED),
    town: string().required(errMsgs.DEFAULT_REQUIRED),
    postcode: postcode
  }),
  otherwise: object().nullable()
});

export const onlineDetails = object().when('sessionType', {
  is: 'online',
  then: object().shape({
    platform: string().required(errMsgs.DEFAULT_REQUIRED),
    link: string().required(errMsgs.DEFAULT_REQUIRED)
  }),
  otherwise: object().nullable()
});

export const assessmentQuestions = array().of(
  object().shape({
    question: string().required(errMsgs.DEFAULT_REQUIRED),
    options: array()
      .of(
        object().shape({
          text: string().required(errMsgs.OPTION_TEXT_REQUIRED),
          isCorrect: boolean()
        })
      )
      .min(2, errMsgs.TWO_OPTIONS_REQUIRED)

      .test(
        'only-one-correct',
        errMsgs.ONE_CORRECT_ANSWER_REQUIRED,
        options =>
          options.filter(option => option.isCorrect === true).length === 1
      )
  })
);

export const optionalArray = array().optional();

export const requiredPercent = mixed()
  .when('value', {
    is: val => !isNaN(val),
    then: number()
      .min(0)
      .max(100),
    otherwise: string()
      .nullable()
      .notRequired()
      .default(null)
  })
  .required(errMsgs.DEFAULT_REQUIRED);

export const moduleTitle = string()
  .required(errMsgs.DEFAULT_REQUIRED)
  .min(4, errMsgs.TOO_SHORT_MIN_4)
  .max(55, errMsgs.TOO_LONG_MAX_55);
