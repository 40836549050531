import * as I from '../../common/Inputs';
import Icon from '../../common/Icon';
import * as T from '../../common/Typography';
import * as S from './style';

const MultiInputField = ({
  fields,
  name,
  label,
  placeholder,
  setState,
  errors,
  required = true
}) => {
  return (
    <S.MultiInputFieldWrapper mt="5" mb="5">
      {fields.map((item, index) => (
        <I.BasicInput
          key={index}
          label={index === 0 && label}
          placeholder={placeholder}
          required={required}
          mb="3"
          value={item}
          handleChange={value =>
            setState({
              [name]: fields.map((m, idx) => (idx === index ? value : m))
            })
          }
          error={errors[`${name}[${index}]`]}
        />
      ))}
      <S.TextWithIcon
        type="button"
        onClick={() =>
          setState({
            [name]: [...fields, '']
          })
        }
      >
        <Icon icon="add" color="mainPrimary" />
        <T.P color="mainPrimary"> Add additional link</T.P>
      </S.TextWithIcon>
    </S.MultiInputFieldWrapper>
  );
};

export default MultiInputField;
