import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div``;

export const ActionsWrapper = styled.div`
  display: flex;
`;

export const ActionButton = styled.button`
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  display: flex;
`;
export const ActionLink = styled(Link)`
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  display: flex;
`;
