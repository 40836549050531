import { fields, createSchema, validate as _validate } from '..';

const {
  requiredText,
  arrayOfOptionalLinks,
  checkbox,
  urlRequired,
  optionalLink,
  optionalText,
  assessmentQuestions,
  optionalArray,
  requiredPercent,
  arrayOfString,
  moduleTitle
} = fields;

const validate = data => {
  const moduleForm = createSchema({
    preCourseMaterials: arrayOfOptionalLinks,
    postCourseMaterials: arrayOfOptionalLinks,
    archived: requiredText,
    title: moduleTitle,
    description: optionalText,
    developers: optionalText,
    learningObjectives: optionalText,
    supportedCompetencies: optionalText,
    whatIsItFor: optionalText,
    additionalInfo: optionalText,
    showPostCourseEvaluation: checkbox,
    category: requiredText,
    isLive: requiredText,
    link: data.isLive === 'true' ? optionalLink : urlRequired,
    duration: optionalText,
    enableCertificate: requiredText,
    assessmentNeeded: data.isLive === 'false' ? requiredText : optionalText,
    assessmentPassMark:
      data.assessmentNeeded === 'true' ? requiredPercent : optionalText,
    questions:
      data.assessmentNeeded === 'true' ? assessmentQuestions : optionalArray,
    isPreRequisite: optionalText,
    preRequisiteFor:
      data.isPreRequisite === true ? arrayOfString : optionalArray
  });
  return _validate(moduleForm, data);
};

export default validate;
