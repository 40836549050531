import Header from '../../common/Header';

import IEMessage from '../../common/IEMessage';
import landmarks from '../../../assets/landmarks.svg';

import * as S from './style';

const General = ({ children, login, withGraph, maxWidth }) => {
  return (
    <>
      <S.Wrapper>
        <Header login={login} />
        <S.ContentWrapper>
          <S.Content maxWidth={maxWidth}>
            <IEMessage />
            {children}
          </S.Content>
          {withGraph && (
            <S.GraphWrapper>
              <S.GraphImg src={landmarks} alt="graph" />
            </S.GraphWrapper>
          )}
        </S.ContentWrapper>
      </S.Wrapper>
    </>
  );
};

export default General;
