import { fields, createSchema, validate as _validate } from '..';

import { userRoles } from '../../constants';

const { email, name, arrayOfString, organisationOther, requiredText } = fields;

const trainerSchema = createSchema({
  email,
  name,
  localAuthorities: arrayOfString,
  organisationOther,
  jobRole: requiredText,
  team: requiredText,
  department: requiredText
});

const participantSchema = createSchema({
  email
});

const validate = data => {
  if (data.role === userRoles.trainer || data.role === userRoles.localLead) {
    return _validate(trainerSchema, data);
  } else {
    return _validate(participantSchema, data);
  }
};

export default validate;
