import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';

import { TOBACCO_CATALOGUE_URL } from '../../../constants/navigationRoutes';
import Layout from '../../Layouts';

import { Col, Row } from '../../common/Grid';
import IconCard from '../../common/IconCard';
import MaxWidthContainer from '../../common/MaxWidthContainer';
import * as T from '../../common/Typography';
import * as S from './style';

import CalloutSection from './CalloutSection';
import TobaccoHeader from '../../common/TobaccoHeader';
import TobaccoAwareness from '../../../assets/NHS 01060-23 Illustration Awareness White On Blue.png';
import TobaccoIdentification from '../../../assets/NHS 01060-23 Illustration Identification White On Blue.png';
import TobaccoTreatment from '../../../assets/NHS 01060-23 Illustration Treatment White On Blue.png';

const Tobacco = () => {
  const { breakpoints } = useTheme();

  const isDesktop = useMediaQuery({
    query: breakpoints.laptop
  });
  return (
    <Layout layout="website">
      <TobaccoHeader title=" North East and North Cumbria, Programme for Tobacco Dependency" />
      <MaxWidthContainer>
        <S.HeadWrapper>
          <T.P
            medium={!!isDesktop}
            small={!isDesktop}
            ta="center"
            color="gray"
            mt="7"
            weight="700"
          >
            A comprehensive training programme to equip the North East and North
            Cumbria workforce with the skills to identify and treat tobacco
            dependency and support wider tobacco control.
          </T.P>
        </S.HeadWrapper>
        <Row mt="7" mtT="27" mb="7" mbT="6" inner>
          <Col w={[4, 12, 4]} mbT="5">
            <IconCard
              bgColor="mainPrimary"
              Img={() => <S.Img src={TobaccoAwareness} w="100%" h="auto%" />}
              to={`${TOBACCO_CATALOGUE_URL}?div=${1}`}
            >
              <T.H4W mt="4">Tobacco Awareness</T.H4W>
              <T.P mt="2" mb="2" color="gray" small={!isDesktop}>
                Learn about the factors that lead to tobacco smoking, how this
                impacts health and health inequalities in our region and how you
                can make a difference in your day-to-day work.
              </T.P>
              <T.Link
                color="gray"
                to={`${TOBACCO_CATALOGUE_URL}?div=${1}`}
                small={!isDesktop}
              >
                Learn more
              </T.Link>
            </IconCard>
          </Col>
          <Col w={[4, 12, 4]} mbT="5">
            <IconCard
              bgColor="mainSecondary"
              Img={() => (
                <S.Img src={TobaccoIdentification} w="100%" h="auto%" />
              )}
              to={`${TOBACCO_CATALOGUE_URL}?div=${2}`}
            >
              <T.H4W mt="4">
                Identification of Tobacco Dependence (Smoking) and Delivery of
                Brief Advice
              </T.H4W>
              <T.P mt="2" mb="2" color="gray" small={!isDesktop}>
                Learn how to have effective conversations about tobacco and
                respond appropriately when tobacco dependence is identified in
                different settings.
              </T.P>
              <T.Link
                color="gray"
                to={`${TOBACCO_CATALOGUE_URL}?div=${2}`}
                small={!isDesktop}
              >
                Learn more
              </T.Link>
            </IconCard>
          </Col>

          <Col w={[4, 12, 4]}>
            <IconCard
              bgColor="darkGray"
              Img={() => <S.Img src={TobaccoTreatment} w="100%" h="auto%" />}
              to={`${TOBACCO_CATALOGUE_URL}?div=${3}`}
            >
              <T.H4W mt="4">Treatment of Tobacco Dependence</T.H4W>
              <T.P mt="2" mb="2" color="gray" small={!isDesktop}>
                Learn about evidence-based interventions for the treatment of
                tobacco dependence in multiple settings, including community,
                inpatient and maternity settings.
              </T.P>
              <T.Link
                color="gray"
                to={`${TOBACCO_CATALOGUE_URL}?div=${3}`}
                small={!isDesktop}
              >
                Learn more
              </T.Link>
            </IconCard>
          </Col>
        </Row>
        {/* <Row jc="center" mt="7" mtT="27" mb="7" mbT="6" inner>
          <Col w={[4, 12, 4]}>
            <IconCard bgColor="darkGray" icon="glass2">
              <T.H4W mt="4">Tobacco control across the North East</T.H4W>
              <T.P mt="2" mb="2" color="gray" small={!isDesktop}>
                Find out more about our regional approach to wider tobacco
                control, including illicit tobacco and how we can achieve a
                Smokefree North East.
              </T.P>
              <T.Link
                color="gray"
                to={`${TOBACCO_CATALOGUE_URL}?div=${3}`}
                small={!isDesktop}
              >
                Learn more
              </T.Link>
            </IconCard>
          </Col>
        </Row> */}
        <CalloutSection />
      </MaxWidthContainer>
    </Layout>
  );
};

export default Tobacco;
