import React, { useState } from 'react';
import Typography from './Typography';
import Layout from './Layout';
import Menu from './Menu';
import CustomCollapse from '././../../common/Collapse/Collapse.stories';
import { Collapse as AntdCollapse } from 'antd';
import Icon from './Icons';
import Button from './Button';
import CopyLink from './CopyLink';
import GridExample from './../../common/Grid/Grid.stories';
import ModalExample from './../../common/modal/Modal.stories';
import InputExample from '../../common/Inputs/Inputs.stories';
import TableExample from '../../common/Table/Table.stories';
import ProgressExample from '../../common/Progress/Progress.stories';
import ToggleExample from '../../common/Toggle/Toggle.stories';
import InfoPopUpExample from '../../common/InfoPopup/InfoPopup.stories';
import InfoCardExample from '../../common/InfoCard/InfoCard.stories';
import Badge from './Badge';
import Cards from './Cards';
import Colors from './Colors';
import FooterExample from './Footer';
import WebsiteHeaderExample from './WebsiteHeader';
import CatHeaderExample from './CatHeader';
import WebsiteMenu from '../../common/WebsiteMenu';
import AnswerInputExample from './AnswerInput';

import MDEditor from '../../common/MDEditor';
import Markdown from '../../common/Markdown';
import CNECardExample from './CNECard';
import CNEHeaderExample from './CNEHeader';

const { Panel } = AntdCollapse;

const StoryBook = () => {
  const [value, setValue] = useState('*Paying for *\n\nhousing');
  return (
    <>
      <WebsiteMenu />
      <AntdCollapse defaultActiveKey={[]}>
        <Panel header="Typography" key="1">
          <Typography />
        </Panel>
        <Panel header="Layouts" key="2">
          <Layout />
        </Panel>
        <Panel header="Menu" key="3">
          <div style={{ height: '1500px' }}>
            {' '}
            <Menu />{' '}
          </div>
        </Panel>
        <Panel header="Accordions / Collapse" key="accordions">
          <CustomCollapse />
        </Panel>
        <Panel header="Icons" key="4">
          <Icon />
        </Panel>
        <Panel header="Buttons" key="5">
          <Button />
        </Panel>
        <Panel header="Grid" key="6">
          <GridExample />
        </Panel>
        <Panel header="Modal" key="modal">
          <ModalExample />
        </Panel>
        <Panel header="inputs" key="7">
          <InputExample />
        </Panel>
        <Panel header="Update Link" key="8">
          <CopyLink />
        </Panel>

        <Panel header="Tables" key="tables">
          <TableExample />
        </Panel>
        <Panel header="Progress Example" key="progress">
          <ProgressExample />
        </Panel>
        <Panel header="Toggle" key="toggle">
          <ToggleExample />
        </Panel>
        <Panel header="InfoPopUp Example" key="infoPopup">
          <InfoPopUpExample />
        </Panel>
        <Panel header="InfoCard Example" key="infoCard">
          <InfoCardExample />
        </Panel>
        <Panel header="Colors" key="colors">
          <Colors />
        </Panel>
        <Panel header="Badge" key="badge">
          <Badge />
        </Panel>
        <Panel header="Cards" key="cards">
          <Cards />
        </Panel>
        <Panel header="Footer Example" key="footerExample">
          <FooterExample />
        </Panel>
        <Panel header="AnswerInput Example" key="answerInputExample">
          <AnswerInputExample />
        </Panel>
        <Panel header="WebsiteHeader Example" key="websiteHeaderExample">
          <WebsiteHeaderExample />
        </Panel>
        <Panel header="CatHeader Example" key="catHeaderExample">
          <CatHeaderExample />
        </Panel>

        <Panel header="MDEditor" key="MDEditor">
          <div style={{ width: '440px', height: 'auto' }}>
            <MDEditor
              color="darkGray"
              placeholder="placeholder"
              rows="20"
              value={value}
              onChange={setValue}
              label="Introduction"
              m={{ mt: 5 }}
            />
            <Markdown
              text={value}
              customStyles={{
                p: {
                  color: 'darkGray'
                }
              }}
            />
          </div>
        </Panel>
        <Panel header="CNE Card Example" key="CNECardExample">
          <CNECardExample />
        </Panel>
        <Panel header="CNE Header Example" key="CNEHeaderExample">
          <CNEHeaderExample />
        </Panel>
      </AntdCollapse>
    </>
  );
};

export { Layout };

export default StoryBook;
