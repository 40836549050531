import styled from 'styled-components';

export const CreateSessionWrapper = styled.div``;

export const Warning = styled.h1`
  font-family: 'museo-sans', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.error};
  align-self: flex-end;
  position: absolute;
  top: 100%;
`;

export const Form = styled.form`
  background: ${({ theme }) => theme.colors.midPrimary};
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacings[6]};
  border-radius: 10px;
  @media (min-width: 678px) {
    max-width: 550px;
    padding-right: 18%;
  }
`;

export const InformationBox = styled.div`
  background-color: ${({ theme }) => theme.colors.mainPrimary};
  color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => `${theme.spacings[5]} ${theme.spacings[5]}`};
  margin: ${({ theme }) => `${theme.spacings[4]} ${theme.spacings[0]}`};

  font-size: 18px;
  width: 80%;
`;
