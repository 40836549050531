import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { connect } from 'react-redux';

import { fetchParticipantSessions } from '../../../actions/groupSessionsAction';
import { fetchParticipantResponses } from '../../../actions/participantActions';

import Button from '../../common/Button';
import { Col, Row } from '../../common/Grid';
import Spin from '../../common/Spin';
import * as T from '../../common/Typography';
import * as S from './style';

import Layout from '../../Layouts';

import { courses, modules, surveyTypesNew } from '../../../constants';
import { MATERIALS } from '../../../constants/materials';
import { SURVEY_URL } from '../../../constants/navigationRoutes';
import { isCustomCourse } from '../../../helpers';
import CustomCourseMaterials from './CustomCourseMaterials';

const ParticipantMaterials = ({
  sessions,
  responses,
  fetchSessions,
  fetchResponses,
  course
}) => {
  const [loading, setLoading] = useState(true);
  const [completedModules, setCompletedModules] = useState({
    C5: [],
    MECC: []
  });

  const history = useHistory();

  useEffect(() => {
    fetchSessions();
    fetchResponses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!sessions?.length) {
      setLoading(false);
      return;
    }

    // Default fallback
    const courseModules = {
      [courses.C5]: [],
      [courses.MECC]: []
    };

    sessions.forEach(session => {
      if (courseModules[session.course]) {
        session.modules.forEach(module => {
          courseModules[session.course].push(module);
        });
      }
    });

    Object.keys(courseModules).forEach(course => {
      courseModules[course] = Array.from(new Set(courseModules[course])).sort();
    });

    setCompletedModules(courseModules);
    setLoading(false);
  }, [sessions]);

  const showPreMaterials = mod => {
    let filterFunc = () => true;
    if (mod === modules.TRAIN_THE_TRAINER) {
      return true;
    } else {
      filterFunc = resp =>
        resp.surveyType === surveyTypesNew.PRE_COURSE && resp.course === course;
    }
    const preCourseCompleted = responses.filter(filterFunc);
    return preCourseCompleted?.length > 0;
  };

  const showPostMaterials = mod => {
    if (mod === modules.TRAIN_THE_TRAINER) {
      return true;
    }

    let totalModules = [];
    responses
      .filter(resp => surveyTypesNew.PRE_COURSE !== resp.surveyType)
      .forEach(resp => {
        if (resp.course === course) {
          resp.modules.map(
            module => Number(module) && totalModules.push(Number(module))
          );
        }
      });

    const highestModCompleted = Math.max(...totalModules);
    return highestModCompleted >= Number(mod);
  };

  const findSurvey = mod => {
    const session = sessions.filter(
      ses => ses.modules.includes(mod) && ses.course === course
    );
    if (session?.length > 0) return session[0].shortId;

    return sessions[0].shortId;
  };

  const getCoursePostSurvey = mod => {
    if (mod === modules.TRAIN_THE_TRAINER)
      return surveyTypesNew.POST_TRAIN_THE_TRAINER;

    return surveyTypesNew.POST;
  };

  let Content;

  if (course) {
    const courseCompletedModules = completedModules[course];
    Content = () => (
      <>
        {courseCompletedModules.length > 0 ? (
          courseCompletedModules.map(mod => (
            <Row>
              <Col w={[4, 8, 8]} mt={5}>
                <S.Wrapper>
                  <T.H3 weight={700} mb="4">
                    {mod === modules.TRAIN_THE_TRAINER ? mod : `Module ${mod}`}
                  </T.H3>

                  {showPreMaterials(mod) ? (
                    <>
                      <T.P weight={700} mb={2}>
                        Pre-session materials
                      </T.P>
                      {MATERIALS[course][mod] &&
                        MATERIALS[course][mod]?.PRE?.map(material => (
                          <T.Link
                            external
                            to={material.link}
                            color="primary"
                            mb="2"
                          >
                            {material.displayName}
                          </T.Link>
                        ))}
                    </>
                  ) : (
                    <>
                      <T.P mb={4}>
                        To access these you first need to complete the
                        pre-course evaluation
                      </T.P>
                      <Col w={[4, 6, 6]} ml="-10px">
                        <Button
                          type="tertiary"
                          label="Complete evaluation"
                          onClick={() =>
                            history.push(
                              SURVEY_URL.replace(
                                ':id',
                                findSurvey(mod)
                              ).replace(':type', surveyTypesNew.PRE_COURSE)
                            )
                          }
                        />
                      </Col>
                    </>
                  )}
                  {showPostMaterials(mod) ? (
                    MATERIALS[course][mod]?.POST ? (
                      <>
                        <T.P weight={700} mb={2} mt={4}>
                          Post-session materials
                        </T.P>
                        {MATERIALS[course][mod]?.POST?.map(material => (
                          <T.Link
                            external
                            to={material.link}
                            color="primary"
                            mb="2"
                          >
                            {material.displayName}
                          </T.Link>
                        ))}
                      </>
                    ) : null
                  ) : (
                    <>
                      <T.P weight={700} mb={2} mt={4}>
                        Post-session materials
                      </T.P>
                      <T.P mb={4}>
                        To access these you first need to complete the
                        post-session evaluation
                      </T.P>
                      <Col w={[4, 6, 6]} ml="-10px">
                        <Button
                          type="tertiary"
                          label="Complete evaluation"
                          onClick={() =>
                            history.push(
                              SURVEY_URL.replace(
                                ':id',
                                findSurvey(mod)
                              ).replace(':type', getCoursePostSurvey(mod))
                            )
                          }
                        />
                      </Col>
                    </>
                  )}
                </S.Wrapper>
              </Col>
            </Row>
          ))
        ) : (
          <Row>
            <Col w={[4, 8, 8]}>No materials to show</Col>
          </Row>
        )}
      </>
    );
  }

  return (
    <Layout>
      <Row mb={7}>
        <Col w={[4, 8, 8]}>
          <T.H1>My course materials</T.H1>
        </Col>
      </Row>
      {loading ? <Spin /> : <Content />}
    </Layout>
  );
};

const ParentComponent = props => {
  const { course } = useParams();

  if (isCustomCourse(course)) {
    return <CustomCourseMaterials course={course} {...props} />;
  }

  return <ParticipantMaterials course={course} {...props} />;
};

const mapStateToProps = state => ({
  sessions: state.sessions?.participantSessionsByEmail,
  responses: state.participantResponses?.participantResponses
});

const mapActionsToProps = {
  fetchSessions: fetchParticipantSessions,
  fetchResponses: fetchParticipantResponses
};

export default connect(mapStateToProps, mapActionsToProps)(ParentComponent);
