import styled from 'styled-components';
import { Row } from '../../common/Grid';

export const LearningObjectivesRow = styled(Row)`
  padding: 32px 20px;
  border-radius: 4px;
`;

export const RegisterRow = styled(Row)`
  background-color: ${({ theme }) => theme.colors.lightPrimary};
  padding: 48px 60px;
  border-radius: 4px;
`;

export const TableWrapperRow = styled(Row)`
  padding-top: 32px;
  background-color: ${({ theme }) => theme.colors.lightPrimary};
`;
