import axios from 'axios';

import {
  LOADING_TRUE,
  LOADING_FALSE,
  GET_INVOLVED_SUCCESS,
  GET_INVOLVED_FAIL
} from '../constants/actionTypes';
import { returnErrors } from './errorAction';

// get involved message
export const getInvolved = ({
  name,
  jobTitle,
  email,
  message
}) => async dispatch => {
  dispatch({
    type: LOADING_TRUE,
    payload: 'getInvolvedLoading'
  });

  return axios
    .post('/api/get-involved', {
      name,
      jobTitle,
      email,
      message
    })
    .then(res => {
      dispatch({
        type: GET_INVOLVED_SUCCESS,
        payload: { ...res.data }
      });

      dispatch({
        type: LOADING_FALSE,
        payload: 'getInvolvedLoading'
      });
    })
    .catch(err => {
      dispatch(
        returnErrors(
          err?.response?.data?.error || err.message,
          err?.response?.status,
          'GET_INVOLVED_FAIL'
        )
      );
      dispatch({
        type: LOADING_FALSE,
        payload: 'getInvolvedLoading'
      });
      dispatch({
        type: GET_INVOLVED_FAIL
      });
    });
};
