import { useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { concatModules, isCustomCourse } from '../../../helpers';
import useDownloadCertificate from '../../../hooks/useDownloadCertificate';

import { fetchParticipantSessionDetails } from '../../../actions/participantActions';

import * as T from '../../common/Typography';
import * as S from './style';
import { Row, Col } from '../../common/Grid';
import Button from '../../common/Button';
import { fetchParticipantSessions } from '../../../actions/groupSessionsAction';

import Layout from '../../Layouts';
import {
  PARTICIPANT_MATERIALS,
  SURVEY_URL
} from '../../../constants/navigationRoutes';

import { surveyTypesNew, modules, courses } from '../../../constants/index';

const ParticipantSessionDetails = ({
  session = {},
  responses = [],
  fetchSessionDetails,
  loaded,
  email,
  sessions,
  fetchParticipantSessions
}) => {
  const history = useHistory();
  const { id } = useParams();
  const { downloading, handleDownload } = useDownloadCertificate();

  useEffect(() => {
    if (id.length !== 24) {
      history.push('/404');
    } else {
      fetchSessionDetails(id, history);
      fetchParticipantSessions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let completedSession = false;
  if (responses.length > 0) {
    let filterFun = resp => resp.surveyType === surveyTypesNew.POST;

    if (session.modules.includes(modules.TRAIN_THE_TRAINER)) {
      filterFun = resp =>
        resp.surveyType === surveyTypesNew.POST_TRAIN_THE_TRAINER;
    }

    const postEval = responses.filter(filterFun);
    completedSession = postEval.length > 0;
  }

  const trainersNames = trainers => {
    if (!trainers) return 'N/A';
    return session.trainers.map(trainer => trainer.name).join(' & ');
  };

  const modulesAsString = concatModules({
    modules: session.modules,
    customModules: session.customModules
  });

  const fullAddress = address => {
    if (!address) return 'N/A';
    const { addressLine1, addressLine2, town, postcode } = address;

    return [addressLine1, addressLine2, town, postcode]
      .filter(item => !!item)
      .join(', ');
  };

  const handleClick = module => {
    handleDownload({
      module,
      email,
      sessionId: session._id,
      customModuleName: session.customModules?.[0]?.title
    });
  };

  if (!loaded) return <div>Loading</div>;

  // Not sure about this logic but i followed the same logic as in the success registration page client/src/components/pages/SessionRegistration/SuccessRegistration.js
  const isMeccTTT =
    session.course === courses.MECC &&
    session.modules[0] === modules.TRAIN_THE_TRAINER;

  const handleClickPreSurvey = () => {
    let type = session.modules.includes(modules.MODULE_1)
      ? surveyTypesNew.PRE_COURSE
      : surveyTypesNew.POST;

    if (isMeccTTT) {
      type = surveyTypesNew.PRE_TRAIN_THE_TRAINER;
    }

    history.push(
      SURVEY_URL.replace(':type', type).replace(':id', session.shortId)
    );
  };

  const isAlcoholCourse = session.course === courses.ALCOHOL_STUDIES;
  const completedAlcoholModuleDemographic = sessions?.find(s =>
    s?.completedSurvey?.find(
      r => r?.surveyType === surveyTypesNew.ALCOHOL_DEMOGRAPHICS
    )
  );

  const completedGeneralPreCourseQuestions = sessions
    ?.filter(s => s.course === session.course)
    ?.find(s =>
      s?.completedSurvey?.find(
        r =>
          r?.surveyType === surveyTypesNew.PRE_COURSE ||
          r?.surveyType === surveyTypesNew.PRE_TRAIN_THE_TRAINER
      )
    );

  const completedPreCourseQuestions = isAlcoholCourse
    ? !!completedAlcoholModuleDemographic
    : !!completedGeneralPreCourseQuestions;

  const showDemographicLink = !completedPreCourseQuestions;

  return (
    <Layout>
      <Row mb={7}>
        <Col w={[4, 8, 8]}>
          <T.H1>Session Details</T.H1>
        </Col>
      </Row>
      {!completedSession && moment(session.date).isBefore() && (
        <>
          <Row mt={4} mb={4}>
            <Col w={[4, 8, 8]} direction="column" ai="flex-start">
              <T.P weight={700} mb={2}>
                Certificate
              </T.P>
              <T.P>
                To complete this session and receive your certificate(s) you
                first need to complete this post-session evaluation.
              </T.P>
            </Col>
          </Row>
          <Row>
            <Col w={[4, 6, 4]}>
              <Button
                type="tertiary"
                label="Complete evaluation"
                onClick={() =>
                  history.push(
                    SURVEY_URL.replace(':id', session.shortId).replace(
                      ':type',
                      session.modules.includes(modules.TRAIN_THE_TRAINER)
                        ? surveyTypesNew.POST_TRAIN_THE_TRAINER
                        : surveyTypesNew.POST
                    )
                  )
                }
              />
            </Col>
          </Row>
        </>
      )}
      {(session.modules.includes(modules.MODULE_1) || isMeccTTT) &&
        showDemographicLink && (
          <>
            <Row mt={4} mb={4}>
              <Col w={[4, 8, 8]}>
                <T.P mb="3" color="gray">
                  Before you attend we require all participants to answer a
                  short 2-minute pre-course evaluation. This will help us
                  provide you with insight on how the session has helped you and
                  for you to access materials throughout the course.
                </T.P>
              </Col>
            </Row>
            <Row>
              <Col w={[4, 6, 4]}>
                <Button
                  onClick={handleClickPreSurvey}
                  type="tertiary"
                  label="Fill survey"
                  width="200px"
                />
              </Col>
            </Row>
          </>
        )}
      <Row mt={8} mb={7}>
        <Col w={[4, 8, 8]}>
          <S.Wrapper>
            <T.P mb="4" color="gray">
              <S.Bold>Date of session: </S.Bold>{' '}
              {session?.date
                ? moment(session.date).format('DD-MM-YYYY')
                : 'N/A'}
            </T.P>
            {session?.endDate && (
              <T.P mb="4" color="gray">
                <S.Bold>End date: </S.Bold>{' '}
                {moment(session.endDate).format('DD-MM-YYYY')}
              </T.P>
            )}
            {session?.startTime && (
              <T.P mb="4" color="gray">
                <S.Bold>Time: </S.Bold> {session.startTime}
                {session?.endTime && ` to ${session.endTime}`}
              </T.P>
            )}
            <T.P mb="4" color="gray">
              <S.Bold>Modules:</S.Bold> {modulesAsString}
            </T.P>
            <T.P mb="4" color="gray">
              <S.Bold>Location:</S.Bold>{' '}
              {session.remote ? 'Online' : fullAddress(session.address)}
            </T.P>
            <T.P mb="4" color="gray" transform="capitalize">
              <S.Bold>Trainers:</S.Bold> {trainersNames(session.trainers)}
            </T.P>{' '}
            {session?.remote && (
              <T.P mb="4" color="gray" style={{ wordBreak: 'break-all' }}>
                <S.Bold style={{ display: 'inline' }}>Meeting link:</S.Bold>
                {session?.meetingLink ? (
                  <T.Link external href={session?.meetingLink} color="primary">
                    {' '}
                    {session?.meetingLink}
                  </T.Link>
                ) : (
                  ' N/A'
                )}
              </T.P>
            )}
            <T.P mb="4" color="gray">
              <S.Bold>Email: </S.Bold>
              {session?.contactEmail ? (
                <T.Link
                  external
                  href={`mailto:${session?.contactEmail}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  color="primary"
                >
                  {session?.contactEmail}
                </T.Link>
              ) : (
                ' N/A'
              )}
            </T.P>
            <T.P color="gray">
              <S.Bold style={{ display: 'block' }}>
                {' '}
                Any other joining information:
              </S.Bold>
              {session?.extraInfo || 'N/A'}
            </T.P>
          </S.Wrapper>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 6, 4]}>
          <Button
            label="Materials"
            onClick={() =>
              history.push(
                PARTICIPANT_MATERIALS.replace(':course', session.course)
              )
            }
            type="primary"
            mb={3}
          />
          {completedSession &&
            (isCustomCourse(session.course)
              ? session?.customModules?.[0]?.enableCertificate
              : true) &&
            session.modules.map(module => (
              <Button
                label={
                  module === 'CUSTOM'
                    ? 'Claim Certificate'
                    : `Module ${module} Certificate`
                }
                onClick={() => handleClick(module)}
                type="secondary"
                mb={3}
                disabled={downloading}
                loading={downloading}
              />
            ))}
        </Col>
      </Row>
    </Layout>
  );
};

const mapStateToProps = state => ({
  session: state.participantSession?.participantSessionDetails?.sessionDetails,
  responses:
    state.participantSession?.participantSessionDetails?.participantResponses,
  loaded: state.participantSession.loaded,
  email: state.auth.email,
  sessions: state.sessions?.participantSessionsByEmail
});

const mapActionsToProps = {
  fetchSessionDetails: fetchParticipantSessionDetails,
  fetchParticipantSessions
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(ParticipantSessionDetails);
