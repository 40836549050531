import { useHistory, useParams } from 'react-router-dom';
import { SURVEY_QUESTIONS_URL } from '../../../constants/navigationRoutes';

import { SkipButtonsDiv } from './Survey.style';

import Button from '../../common/Button';

const NextAndBackButtons = ({
  section,
  disabled,
  uniqueGroups,
  completionRate,
  step,
  onSubmit,
  allCurrentGroupAnswered,
  allQuestionsAnswered,
  loading
}) => {
  const { id, type } = useParams();
  const history = useHistory();
  const nextStep = Number(step) + 1;
  const isLastStep = nextStep === uniqueGroups.length;

  return (
    <SkipButtonsDiv>
      <Button
        label="Back"
        width="100px"
        height="50px"
        type="primary"
        onClick={() => {
          window.scrollTo(0, 0);
          history.goBack();
        }}
        disabled={Number(step) === 0}
      />

      {isLastStep ? (
        <Button
          label="Submit form"
          width="200px"
          height="50px"
          type="primary"
          onClick={() => {
            onSubmit();
          }}
          disabled={loading || !allQuestionsAnswered}
          loading={loading}
        />
      ) : (
        <Button
          label="Next"
          width="100px"
          height="50px"
          type="primary"
          onClick={() => {
            window.scrollTo(0, 0);
            history.push(
              SURVEY_QUESTIONS_URL.replace(':id', id)
                .replace(':type', type)
                .replace(':step', nextStep)
            );
          }}
          disabled={!allCurrentGroupAnswered}
        />
      )}
    </SkipButtonsDiv>
  );
};

export default NextAndBackButtons;
