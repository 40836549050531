import { Modal } from 'antd';
import axios from 'axios';
import {
  CONFIRM_REGISTRATION_ERROR,
  CONFIRM_REGISTRATION_RESET,
  CONFIRM_REGISTRATION_SUCCESS,
  LOADING_FALSE,
  LOADING_TRUE,
  SEND_SPECIAL_REQUIREMENTS_ERROR,
  SEND_SPECIAL_REQUIREMENTS_SUCCESS
} from '../constants/actionTypes';

export const confirmRegistration = ({
  sessionId,
  message
}) => async dispatch => {
  try {
    dispatch({
      type: LOADING_TRUE,
      payload: 'confirmRegistrationLoading'
    });
    const { data } = await axios.patch(
      `/api/sessions/${sessionId}/confirm-email`,
      {
        message,
        status: 'confirmed'
      }
    );

    dispatch({
      payload: {
        confirmedEmail: data.confirmedEmail,
        alreadyRegistered: data.alreadyRegistered
      },
      type: CONFIRM_REGISTRATION_SUCCESS
    });
    dispatch({
      type: LOADING_FALSE,
      payload: 'confirmRegistrationLoading'
    });
  } catch (err) {
    dispatch({
      type: CONFIRM_REGISTRATION_ERROR
    });
    dispatch({
      type: LOADING_FALSE,
      payload: 'confirmRegistrationLoading'
    });

    Modal.error({
      title: 'Error',
      content: err.response.data.error
    });
  }
};

export const sendSpecialRequirements = ({
  email,
  sessionId,
  message,
  history
}) => dispatch => {
  dispatch({
    type: LOADING_TRUE,
    payload: 'sendSpecialRequirements'
  });
  axios
    .post(`/api/sessions/${sessionId}/special-requirements`, {
      email,
      message
    })
    .then(() => {
      dispatch({
        type: SEND_SPECIAL_REQUIREMENTS_SUCCESS
      });
      dispatch({
        type: LOADING_FALSE,
        payload: 'sendSpecialRequirements'
      });
      Modal.success({
        title: 'Thank You!',
        content: 'You successfully sent your special requirements',
        onOk: () => history.push('/participant-login')
      });
    })
    .catch(err => {
      dispatch({
        type: SEND_SPECIAL_REQUIREMENTS_ERROR
      });
      dispatch({
        type: LOADING_FALSE,
        payload: 'sendSpecialRequirements'
      });

      Modal.error({
        title: 'Error',
        content: err.response.data.error
      });
    });
};

export const resetConfirmRegistration = () => async dispatch => {
  dispatch({
    type: CONFIRM_REGISTRATION_RESET
  });
};
