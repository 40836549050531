import { useEffect } from 'react';

import { connect } from 'react-redux';

import { fetchParticipantSessions } from '../../../actions/groupSessionsAction';

import * as T from '../../common/Typography';
import * as S from './style';
import { Row, Col } from '../../common/Grid';
import Button from '../../common/Button';

import Layout from '../../Layouts';

import { surveyTypesNew } from '../../../constants';
import { SURVEY_URL } from '../../../constants/navigationRoutes';

const CustomCourseMaterials = ({ sessions, fetchSessions, course }) => {
  const modulesObject = {};

  const filteredSessions = sessions?.filter(
    session => session.course === course
  );

  filteredSessions.forEach(session => {
    const completedPostSurvey = !!session?.completedSurvey?.find(
      survey => survey.surveyType === surveyTypesNew.POST
    );
    const completed = !!session.completedAt;

    session.customModules.forEach(module => {
      module.completed = module.isLive
        ? completedPostSurvey
        : completed || completedPostSurvey;

      module.sessionShortId = session.shortId;
      modulesObject[module._id] = module;
    });
  });
  const modules = Object.values(modulesObject);

  useEffect(() => {
    fetchSessions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <Row mb={7}>
        <Col w={[4, 8, 8]}>
          <T.H1>My course materials</T.H1>
        </Col>
      </Row>

      {modules.length > 0 ? (
        modules.map(mod => (
          <Row>
            <Col w={[4, 8, 8]} mt={5}>
              <S.Wrapper>
                <T.H2 weight={700} mb="4">
                  {mod.title}
                </T.H2>

                <T.P weight={700} mb={2}>
                  Pre-session materials
                </T.P>
                {mod.preCourseMaterials?.map(material => (
                  <T.Link
                    external
                    to={material}
                    color="primary"
                    mb="2"
                    d="block"
                  >
                    {material}
                  </T.Link>
                ))}

                {mod.completed || !mod.showPostCourseEvaluation ? (
                  mod.postCourseMaterials?.length ? (
                    <>
                      <T.P weight={700} mb={2} mt={4}>
                        Post-session materials
                      </T.P>
                      {mod.postCourseMaterials?.map(material => (
                        <T.Link
                          external
                          to={material}
                          color="primary"
                          mb="2"
                          d="block"
                        >
                          {material}
                        </T.Link>
                      ))}
                    </>
                  ) : null
                ) : (
                  <>
                    <T.P weight={700} mb={2} mt={4}>
                      Post-session materials
                    </T.P>
                    <T.P mb={4}>
                      To access these you first need to complete the
                      post-session evaluation
                    </T.P>
                    <Col w={[4, 6, 6]} ml="-10px">
                      <Button
                        type="tertiary"
                        label="Complete evaluation"
                        to={SURVEY_URL.replace(
                          ':id',
                          mod.sessionShortId
                        ).replace(':type', surveyTypesNew.POST)}
                      />
                    </Col>
                  </>
                )}
              </S.Wrapper>
            </Col>
          </Row>
        ))
      ) : (
        <Row>
          <Col w={[4, 8, 8]}>No materials to show</Col>
        </Row>
      )}
    </Layout>
  );
};

const mapStateToProps = state => ({
  sessions: state.sessions?.participantSessionsByEmail
});

const mapActionsToProps = {
  fetchSessions: fetchParticipantSessions
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(CustomCourseMaterials);
