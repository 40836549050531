/* eslint-disable no-shadow */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal as AntdModal } from 'antd';

import SurveyContent from './SurveyContent';
import { sendEmails } from '../../../../actions/groupSessionsAction';
import {
  SessionSurveysWrapper,
  SessionSurveyContainer
} from './SessionSurveys.Style';
import { getAllSurveyLinks } from '../../../../helpers';

class SessionSurveys extends Component {
  state = {};

  handleEmailing = (surveyURL, surveyType) => {
    const { sessionDetails } = this.props;
    const { sendEmails } = this.props;

    const { participantsEmails } = sessionDetails;

    if (participantsEmails.length < 1) {
      return AntdModal.error({
        title: 'No participants in this session',
        content:
          'You may need to add participants to be able to send the evaluation'
      });
    }

    return AntdModal.info({
      title:
        'Do you want to send the evaluation link to all participants by emails?',
      content: (
        <div>
          <p>
            click on {'Ok'} to send the evaluation link to all the participants
          </p>
        </div>
      ),
      onOk() {
        sendEmails(
          {
            surveyURL,
            participantsList: participantsEmails,
            surveyType
          },
          this.props.history
        );
      }
    });
  };

  render() {
    const { sessionDetails, handleDrawerOpen } = this.props;
    const { _id, shortId, modules, course } = sessionDetails;

    const links = getAllSurveyLinks(modules, shortId, course);

    return (
      <SessionSurveysWrapper>
        <SessionSurveyContainer>
          {links.map((survey, index) => {
            return (
              <SurveyContent
                subId={index}
                preSurvey={links.length > 1 && index === 0}
                type={survey}
                surveyURL={links[index]}
                id={_id}
                handleEmailing={this.handleEmailing}
                key={index}
                handleDrawerOpen={handleDrawerOpen}
              />
            );
          })}
        </SessionSurveyContainer>
      </SessionSurveysWrapper>
    );
  }
}

const mapStateToProps = state => ({
  emailSuccess: state.groups.emailSuccess
});

export default connect(mapStateToProps, { sendEmails })(SessionSurveys);
