/* eslint-disable react/destructuring-assignment */
import { useHistory } from 'react-router-dom';
// COMMON COMPONENTS
import Layout from '../../Layouts';
import * as T from '../../common/Typography';
import { Row, Col } from '../../common/Grid';
import Button from '../../common/Button';
import { externalLinks } from '../../../constants';
import { HOW_IT_WORKS } from '../../../constants/navigationRoutes';

const TrainerResourcesAndAdvice = () => {
  const history = useHistory();
  return (
    <Layout>
      <Row mb="4">
        <Col w={[4, 8, 8]} display="block">
          <T.H1 mb="6" width="100%">
            Training resources and advice
          </T.H1>
        </Col>
      </Row>
      <Row mb="8">
        <Col w={[4, 8, 8]}>
          <T.P mr="1">
            If you ever need access to trainining resources, tools or advice,
            simply go to{' '}
            <T.Link
              external
              color="secondary"
              underline={false}
              to={externalLinks.NHS_FUTURE_LEARNS}
            >
              NHS Future Learns
            </T.Link>
            . This will provide you all the latest information you need to help
            with training.
          </T.P>
        </Col>
      </Row>

      <Row mb="6">
        <Col w={[4, 8, 8]}>
          <T.P mr="1" color="darkGray">
            If you ever need direct support please contact
            <T.Link
              href={`mailto:${externalLinks.HELP_EMAIL}`}
              external
              rel="noopener noreferrer"
              color="primary"
              underline={false}
            >
              {externalLinks.HELP_EMAIL}
            </T.Link>
          </T.P>
        </Col>
      </Row>
      <Row mt={7}>
        <Col w={[4, 4, 4]}>
          <Button
            onClick={() => history.push(HOW_IT_WORKS)}
            type="primary"
            label="Next"
          />
        </Col>
      </Row>
    </Layout>
  );
};

export default TrainerResourcesAndAdvice;
