import mainTheme, { colors, size, spacings } from './mainTheme';

export const _colors = {
  ...colors, // Take colors from main theme and customize some of them
  mainPrimary: '#00338D',
  midPrimary: '#EBEDF0',
  lightPrimary: '#F7F8FA',

  mainSecondary: '#74D1EA',

  error: '#B70D00',
  offWhite: '#FCFCFC',
  white: '#FFFFFF',
  headerTitle: '#FFFFFF',
  headerSubtitle: '#FCFCFC',
  headerBg: '#00338D'
};

export const _size = {
  ...size,
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  mobileXL: '680px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px'
};

export const _spacings = {
  ...spacings,
  0: '0px',
  1: '4px',
  2: '8px',
  3: '12px',
  4: '16px',
  5: '24px',
  6: '32px',
  '40': '40px',
  '27': '27px',
  7: '48px',
  8: '64px',
  9: '80px',
  10: '96px',
  11: '120px'
};

export const _borders = {
  error: `1px solid ${colors.error}`,
  inputBox: `1px solid ${colors.gray}`,
  button: `1px solid ${colors.gray}`,
  header: `1px solid ${colors.offWhite}`,
  toggle: `1px solid ${colors.black}`,
  heading: `1px solid ${colors.yellow}`,
  inputs: `1px solid ${colors.midPrimary}`,
  radius: '4px',
  radiusLarge: '12px'
};

export const povertyTheme = {
  ...mainTheme,
  colors: _colors,
  size: _size,
  spacings: _spacings,
  borders: _borders
};

export default povertyTheme;
