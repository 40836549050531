import { DatePicker } from 'antd';
import moment from 'moment';

const Date = ({ question, setQuestion, onBlur, disabled }) => {
  const { _id: questionId, answer } = question;

  const onChange = value => {
    setQuestion({
      _id: questionId,
      answer: value,
      answered: !!value
    });
    if (value) onBlur(value);
  };

  return (
    <DatePicker
      id={questionId}
      name={questionId}
      onChange={onChange}
      value={answer}
      defaultValue={moment()}
      disabled={disabled}
    />
  );
};

export default Date;
