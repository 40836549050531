import React from 'react';

import EditEmail from '../../../common/EditEmail';

const SendSurveyLink = ({
  sessionDetails,
  handleAddEmailsClick,
  name,
  isSchedule,
  surveyType,
  drawerKey,
  onClose
}) => {
  return (
    <EditEmail
      participantsEmails={sessionDetails.participantsEmails}
      type="surveyLink"
      trainer={name}
      sessionDate={sessionDetails.date}
      sessionType={sessionDetails.type}
      address={sessionDetails.address}
      trainers={sessionDetails.trainers}
      startTime={sessionDetails.startTime}
      endTime={sessionDetails.endTime}
      shortId={sessionDetails.shortId}
      sessionId={sessionDetails._id}
      endDate={sessionDetails.endDate}
      course={sessionDetails.course}
      handleAddEmailsClick={handleAddEmailsClick}
      isSchedule={isSchedule}
      surveyType={surveyType}
      drawerKey={drawerKey}
      backCallback={onClose}
      modules={sessionDetails.modules}
      defaultSessionForCustomModule={
        sessionDetails.defaultSessionForCustomModule
      }
    />
  );
};

export default SendSurveyLink;
