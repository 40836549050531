import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { fetchSurveyData } from '../../../actions/surveyAction';
import { courseLabelMap } from '../../../constants';
import { SURVEY_QUESTIONS_URL } from '../../../constants/navigationRoutes';
import Layout from '../../Layouts';
import { Col, Row } from '../../common/Grid';
import * as T from '../../common/Typography';
import { concatModules, isCustomCourse } from './../../../helpers';
import CheckRequirements from './CheckRequirements';
import ConfirmButtons from './ConfirmButtons';
import LoginButtons from './LoginButtons';
import { Container, DetailsDiv, SessionDetails } from './Survey.style';

const SurveyDetails = ({
  surveyData,
  fetchSurveyData,
  userId,
  role,
  multipleUsers
}) => {
  const [step, setStep] = useState('details');
  const { id, type } = useParams();
  const history = useHistory();
  const [showSurvey, setShowSurvey] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchSurveyData({ id, type });
  }, [fetchSurveyData, id, type]);

  const {
    course,
    sessionDate,
    trainerNames,
    modules,
    customModules,
    contactEmail,
    defaultSessionForCustomModule
  } = surveyData;
  const trainerNamesString = trainerNames?.join(' and ');

  const courseName = courseLabelMap[course];

  return (
    <Layout>
      <CheckRequirements setShowSurvey={setShowSurvey} />
      <Container>
        <T.H1>{courseName} Evaluation</T.H1>
        <Row>
          <Col w={[4, 12, 8]}>
            <SessionDetails>
              <DetailsDiv>
                <T.P weight={700} d="inline">
                  Date of Session: <T.P d="inline">{sessionDate}</T.P>
                </T.P>
              </DetailsDiv>
              <DetailsDiv>
                <T.P weight={700} d="inline">
                  Module(s):{' '}
                  <T.P d="inline">
                    {concatModules({ modules, customModules })}
                  </T.P>
                </T.P>
              </DetailsDiv>
              {!defaultSessionForCustomModule && (
                <DetailsDiv>
                  <T.P weight={700} d="inline">
                    Trainers:{' '}
                    <T.P d="inline" transform="capitalize">
                      {trainerNamesString}
                    </T.P>
                  </T.P>
                </DetailsDiv>
              )}
            </SessionDetails>
            <T.P d="inline" weight={700} mb={5}>
              This evaluation form should only take a few minutes to complete.
              Your feedback is important in helping us to evaluate and improve
              the {courseName} programme. Many thanks for completing this
              anonymous evaluation form.
            </T.P>
            {!isCustomCourse(course) && (
              <T.P d="inline">
                <T.P d="inline" weight="700">
                  Important notice
                </T.P>
                :The {courseName} for North East and North Cumbria training
                programme has been funded by Northumbria Healthcare. We will
                measure the positive impact {courseName} training has had on the
                people living and working in the North East and North Cumbria.
                For this reason all participants will need to complete a pre and
                post course evaluation form. Responses will be collated by NHCT
                to evaluate the training. NHCT will use anonymised data
                collected for research purposes only. Individuals will never
                been identified by their responses but you will be able to see
                your own results. If you do not consent for your data to be used
                for research purposes, please do not click yes or proceed any
                further.
              </T.P>
            )}
          </Col>
        </Row>
        {step === 'details' ? (
          <ConfirmButtons
            role={role}
            course={course}
            multipleUsers={multipleUsers}
            onYes={() => {
              if (userId) {
                if (!showSurvey) return;
                history.push(
                  SURVEY_QUESTIONS_URL.replace(':id', id)
                    .replace(':type', type)
                    .replace(':step', 0)
                );
              } else {
                setStep('login');
              }
            }}
          />
        ) : (
          <LoginButtons
            courseName={courseName}
            contactEmail={contactEmail}
            type={type}
            shortId={id}
          />
        )}
      </Container>
    </Layout>
  );
};
const mapStateToProps = state => {
  return {
    surveyData: state.survey.surveyData || {},
    userId: state.auth.userId,
    shortId: state.survey.shortId,
    role: state.auth.role,
    multipleUsers: state.auth.multipleUsers
  };
};

export default connect(mapStateToProps, {
  fetchSurveyData
})(SurveyDetails);
