import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

// COMMON COMPONENTS
import { Row, Col } from '../../../common/Grid';

import { REGISTER_URL } from '../../../../constants/navigationRoutes';

// STYLE
import {
  EmailInfo,
  InfoTitle,
  SessionInfoTitle,
  List
} from './InviteAndPromote.style';

class InviteEmails extends Component {
  render() {
    const { sessionDetails, emailInfo } = this.props;
    const { startTime, endTime, shortId, course } = sessionDetails;
    const {
      date: emailDate,
      sessionDate,
      trainers,
      trainer,
      sessionType,
      address,
      recipients,
      extraInformation,
      endDate
    } = emailInfo;

    let fullAddress = '';

    if (address) {
      const { postcode, addressLine1, addressLine2, town } = address;
      if (postcode || addressLine1 || addressLine2 || town) {
        fullAddress = [addressLine1, addressLine2, town, postcode]
          .filter(item => !!item)
          .join(', ');
      }
    }

    return (
      <Row inner ml="2">
        <Col inner w={[4, 12, 8]}>
          <EmailInfo>
            <InfoTitle>
              Date:
              <span style={{ marginLeft: '.5rem', color: '#4f4f4f' }}>
                {moment(emailDate).format('DD/MM/YYYY')}
              </span>
            </InfoTitle>

            <InfoTitle>Message:</InfoTitle>
            <p>Dear course participants,</p>

            <p>
              {trainer} has invited you to register for an upcoming {course}
              training session.
            </p>
            <List>
              <li>
                <SessionInfoTitle> Session Date:</SessionInfoTitle>{' '}
                {moment(sessionDate).format('DD/MM/YYYY')}
              </li>
              <li>
                <SessionInfoTitle>Session Type:</SessionInfoTitle> {sessionType}
              </li>
              <li>
                <SessionInfoTitle> Location:</SessionInfoTitle>{' '}
                {fullAddress || 'TBC'}
              </li>
              <li>
                <SessionInfoTitle>time:</SessionInfoTitle>
                {startTime || '-'} to {endTime || '-'}{' '}
              </li>
              <li>
                <SessionInfoTitle>Trainer(s):</SessionInfoTitle> {trainers}
              </li>
              {endDate && (
                <li>
                  <SessionInfoTitle>Session End Date:</SessionInfoTitle>{' '}
                  {moment(endDate).format('DD/MM/YYYY')}
                </li>
              )}
            </List>
            <p>To confirm your attendance please click this link: </p>
            <p>
              {window.location.host}
              {REGISTER_URL.replace(':id', shortId)}
            </p>
            {extraInformation && <p>{extraInformation}</p>}

            <br />
            <p>Sincerely,</p>

            <p>your {course} team.</p>

            <div>
              <InfoTitle>Sent to</InfoTitle>
              <List>
                {recipients &&
                  recipients.map(email => <li key={email}>{email}</li>)}
              </List>
            </div>
          </EmailInfo>
        </Col>
      </Row>
    );
  }
}

export default connect(null, {})(InviteEmails);
