import { Select } from 'antd';
const { Option } = Select;
const Dropdown = ({ question, setQuestion, onBlur, disabled }) => {
  const { _id: questionId, options, multiple, answer } = question;

  const onChange = value => {
    setQuestion({
      _id: questionId,
      answer: value,
      answered: !!value
    });
    if (value) onBlur(value);
  };

  return (
    <Select
      value={answer}
      defaultActiveFirstOption={false}
      onChange={onChange}
      notFoundContent={null}
      mode={multiple ? 'multiple' : 'default'}
      disabled={disabled}
    >
      {options.map(option => (
        <Option key={option}>{option}</Option>
      ))}
    </Select>
  );
};

export default Dropdown;
