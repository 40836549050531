import moment from 'moment';
import {
  ALCOHOL_STUDIES,
  MODULE_STEPS_URL,
  PARTICIPANT_SESSION_DETAILS,
  POVERTY_PROOFING_URL,
  TOBACCO_URL
} from '../../../constants/navigationRoutes';
import { addStatusToSessions, isCustomCourse } from '../../../helpers';
import Table from '../../common/Table';

import { Col, Row } from '../../common/Grid';
import * as T from '../../common/Typography';

import { courses } from '../../../constants';
import Layout from '../../Layouts';
import CNECard from '../../common/CNECard';
import { InfoWrapper, TableWrapperRow } from './ParticipantSessions.style';

const upcomingColumns = [
  { label: 'Module', value: 'title' },
  { label: 'Date', value: 'date' },
  { label: 'Details', value: 'details' }
];

const completedColumns = [
  { label: 'Module', value: 'title' },
  { label: 'Completed', value: 'date' },
  { label: 'Details', value: 'details' }
];

const courseHeaderData = {
  [courses.ALCOHOL_STUDIES]: {
    header: 'Programme for alcohol studies',
    description:
      'A comprehensive training programme to equip our workforce with the skills to prevent and manage alcohol related harm',
    link: ALCOHOL_STUDIES
  },
  [courses.POVERTY_PROOFING]: {
    header:
      'North East and North Cumbria, Programme for Poverty Proofing© Studies',
    description:
      'Learn about the evidence based drivers of poverty, how this impacts health inequalities and how you can make a difference in your day-to-day work.',
    link: POVERTY_PROOFING_URL
  },
  [courses.TOBACCO_DEPENDENCY]: {
    header: 'Programme for Tobacco Dependency',
    description:
      'A comprehensive training programme to equip the North East and North Cumbria workforce with the skills to identify and treat tobacco dependency and support wider tobacco control.',
    link: TOBACCO_URL
  }
};

const CustomCourseParticipantSessions = ({ sessions, course }) => {
  const sessionsWithStatus = addStatusToSessions(sessions);
  return (
    <Layout>
      <Row mb={7}>
        <Col w={[4, 12, 8]}>
          <T.H1>My course</T.H1>
        </Col>
      </Row>

      {isCustomCourse(course) && courseHeaderData[course] && (
        <Row mb={7}>
          <Col w={[4, 12, 8]} dir="column" ai="flex-start">
            <InfoWrapper>
              <T.H2 color="white">{courseHeaderData[course].header}</T.H2>
              <T.P color="white" mt={4} mb={4}>
                {courseHeaderData[course].description}
              </T.P>
              <T.Link color="white" to={courseHeaderData[course].link}>
                Read more
              </T.Link>
            </InfoWrapper>
          </Col>
        </Row>
      )}

      <Row mb={7}>
        <Col w={[4, 12, 8]} dir="column" ai="flex-start">
          <T.P weight={700} mb={3}>
            Upcoming sessions / modules
          </T.P>
          <TableWrapperRow>
            <Table
              data={sessionsWithStatus
                ?.filter(ses => ses.showInCompleted === false)
                ?.map(({ _id, shortId, date, customModules }) => {
                  const isLive = customModules?.[0]?.isLive;
                  const moduleId = customModules?.[0]?._id;
                  return {
                    title: { value: customModules?.[0]?.title },
                    date: {
                      value: isLive
                        ? moment(date).format('DD/MM/YYYY')
                        : 'Anytime'
                    },
                    details: {
                      value: (
                        <T.Link
                          to={
                            isLive
                              ? PARTICIPANT_SESSION_DETAILS.replace(':id', _id)
                              : MODULE_STEPS_URL.replace(':id', moduleId)
                          }
                          color="primary"
                        >
                          View
                        </T.Link>
                      )
                    }
                  };
                })}
              columns={upcomingColumns}
            />
          </TableWrapperRow>
        </Col>
      </Row>
      <Row mb={7}>
        <Col w={[4, 12, 8]} dir="column" ai="flex-start">
          <T.P weight={700} mb={3}>
            Completed modules
          </T.P>
          <TableWrapperRow>
            <Table
              data={sessionsWithStatus
                ?.filter(ses => ses.showInCompleted === true)
                ?.map(({ _id, completedAtDate, customModules }) => {
                  const isLive = customModules?.[0]?.isLive;
                  const moduleId = customModules?.[0]?._id;
                  return {
                    title: { value: customModules?.[0]?.title },
                    date: {
                      value: completedAtDate
                        ? moment(completedAtDate).format('DD/MM/YYYY')
                        : 'N/A'
                    },
                    details: {
                      value: (
                        <T.Link
                          to={
                            isLive
                              ? PARTICIPANT_SESSION_DETAILS.replace(':id', _id)
                              : MODULE_STEPS_URL.replace(':id', moduleId)
                          }
                          color="primary"
                        >
                          View
                        </T.Link>
                      )
                    }
                  };
                })}
              columns={completedColumns}
            />
          </TableWrapperRow>
        </Col>
      </Row>

      {course === courses.POVERTY_PROOFING && <CNECard footer transparent />}
    </Layout>
  );
};

export default CustomCourseParticipantSessions;
