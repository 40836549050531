import { Collapse, Empty } from 'antd';
import * as S from './style';
import Icon from './../Icon';

const { Panel } = Collapse;

const CustomCollapse = ({ items, isEmpty, onChange }) => {
  return (
    <S.Wrapper>
      <Collapse
        bordered={false}
        accordion
        expandIconPosition="right"
        className="custom-collapse"
        expandIcon={({ isActive }) => (
          <Icon
            icon="chevron"
            color="mainPrimary"
            direction={isActive ? 'right' : 'down'}
            width="20"
            height="20"
          />
        )}
        onChange={onChange}
      >
        {isEmpty ? (
          <Empty description="No Course Materials" />
        ) : (
          items.map(({ title, key, content, onInfoClick }) => (
            <Panel
              header={<S.Title>{title}</S.Title>}
              key={key || title}
              extra={
                onInfoClick ? (
                  <Icon
                    icon="info"
                    width="20"
                    height="20"
                    color="mainPrimary"
                    onClick={e => {
                      e.stopPropagation();
                      onInfoClick();
                    }}
                  />
                ) : null
              }
            >
              {content}
            </Panel>
          ))
        )}
      </Collapse>
    </S.Wrapper>
  );
};

export default CustomCollapse;
