import { useLocation } from 'react-router-dom';

const appendSearchParams = sp => obj => {
  Object.entries(obj).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      sp.delete(key);
      value.forEach(v => sp.append(key, v));
    } else if (value === undefined) {
      sp.delete(key);
    } else {
      sp.set(key, value);
    }
  });
  return sp;
};

const paramsToObject = entries => {
  const result = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tuple
    result[key] = value;
  }
  return result;
};

const toStringWithReplace = additionalParams => {
  const newQuery = new URLSearchParams();
  appendSearchParams(newQuery)(additionalParams);

  return newQuery.toString();
};

const useSearchParams = () => {
  const query = new URLSearchParams(useLocation().search);
  const params = paramsToObject(query.entries());

  const append = appendSearchParams(query);
  query.toStringWithReplace = toStringWithReplace;
  query.append = append;
  query.params = params;
  return query;
};

export default useSearchParams;
