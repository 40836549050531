export const HOME_URL = '/';
export const LOGIN_URL = '/login';
export const SIGN_UP_URL = '/signup';
export const DECIDE_ROLE = '/decide-role';
export const PARTICIPANT_SIGN_UP_URL = '/participant/signup';
export const PARTICIPANT_LOGIN = '/participant-login';
export const TRAINER_TTT_SIGN_UP = '/ttt-signup';
export const UNAUTHORIZED = '/unauthorized';
export const CERTIFIED_TRAINER = '/certified-trainer';
export const TRAINER_RESOURCES_AND_ADVICE = '/trainer-resources-and-advice';
export const HOW_IT_WORKS = '/how-it-works';
export const TRAINER_SIGN_UP_URL = '/signup/mecc';

// ALL
export const DASHBOARD_URL = '/dashboard';
export const ADD_SESSION_URL = '/create-session';
export const MODULES_URL = '/modules';
export const SESSION_CREATED_URL = '/session-created/:id';
export const SURVEY_URL = '/survey/:id/:type';
export const SURVEY_QUESTIONS_URL = '/survey/:id/:type/:step/:success';
export const SESSION_DETAILS_URL = '/session-details/:id';
export const MY_PROFILE_URL = '/my-profile';
export const MY_ACCOUNT_URL = '/my-account';
export const ALCOHOL_STUDIES_ABOUT_US_URL = '/alcohol-studies/about-us';
export const PROGRAMMES = '/programmes';
export const ALCOHOL_CATALOGUE_URL = '/alcohol-studies/catalogue';
export const POVERTY_CATALOGUE_URL = '/poverty-proofing/catalogue';
export const TOBACCO_CATALOGUE_URL = '/tobacco-dependency/catalogue';
export const MODULE_URL = '/module/:id';
export const POVERTY_MODULE_URL = '/poverty-proofing/module/:id';
export const TOBACCO_MODULE_URL = '/tobacco-dependency/module/:id';
export const MODULE_STEPS_URL = '/module/:id/steps';
export const ALCOHOL_STUDIES = '/alcohol-studies';
export const POVERTY_PROOFING_URL = '/poverty-proofing';
export const TOBACCO_URL = '/tobacco-dependency';
export const CONNECT5_URL = '/connect5';
export const MECC_URL = '/mecc';
export const TRAINER_ADVICE_URL = '/trainer-advice';
export const MY_RESULTS_URL = '/my-results/:course';
export const MY_SESSIONS_URL = '/my-sessions';
export const MY_COURSES_URL = '/my-courses';
export const ALL_SESSIONS_URL = '/all-sessions';
export const ABOUT_URL = '/about';
export const REGISTER_URL = '/register/:id';
export const TRAINER_PUBLIC_SESSIONS = '/available-sessions/:id';
export const USER_RESULTS_FOR_SPECIFIC_COURSE = '/choose-course';
export const CHOOSE_RESULTS_FOR_COURSE = '/choose-course';
export const PARTICIPANT_ASSESSMENT = '/module/:id/assessment';

// trainer
export const TRAINER_RESULTS_URL = '/trainer-results';
export const TRAINER_SESSIONS_URL = '/trainer-sessions/trainerId?';
export const TRAINER_FEEDBACK_URL = '/feedback';
export const TRAINER_MANAGERS_GROUPS = '/trainer-managers-groups';
export const REMOVE_TRAINER_FROM_GROUP = '/remove/:localLead/:trainer';
export const CREATE_SESSION = '/create-session';

export const EVAL_RESULTS = '/survey/:id/:surveyType/results';

// local lead & admin
// for menu & navbar
export const MY_GROUP_RESULTS_URL = '/group-results/:course';
export const MY_GROUP_SESSIONS_URL = '/group-sessions';
export const CREATE_MODULE = '/module/create';
export const EDIT_MODULE = '/module/edit/:id';
export const ARCHIVED_MODULES = '/archived-modules';
// for App react router
export const GROUP_RESULTS_URL = `${MY_GROUP_RESULTS_URL}/:localLeadId?/:trainerName?`;
export const GROUP_SESSIONS_URL = `${MY_GROUP_SESSIONS_URL}/:localLeadId?`;
export const TRAINERS_URL = '/trainers';
export const ADD_TRAINER_URL = '/add-trainer';
// export const DECIDE_VIEW_URL = '/welcome-back';

// local lead
export const LOCAL_LEAD_SESSIONS_URL = '/localLead-sessions';

// admin
export const DEMOGRAPHICS_URL = '/demographics';
export const ALL_RESULTS_URL = '/all-results/:course';

// participant
export const USER_INSIGHTS = '/my-insights';
export const USER_PROGRESS = '/my-progress';
export const USER_LOGIN = '/user-login';
export const USER_DASHBOARD = '/my-dashboard';
export const FORGET_PASSWORD = '/forget-password';
export const PARTICIPANT_DASHBOARD = '/participant-dashboard';
export const PARTICIPANT_SESSIONS_LIST = '/participant-sessions-list/:course';
export const PARTICIPANT_SESSION_DETAILS = '/participant-session-details/:id';
export const PARTICIPANT_MATERIALS = '/participant/materials/:course';
export const PARTICIPANT_FILLED_SURVEY_SUCCESSFULLY = '/participant/thank-you';
export const CERTIFICATE = '/certificate/:id';
export const CHOOSE_RESULTS_FOR_MATERIALS = '/choose-course-materials';
export const CHOOSE_RESULTS_FOR_SESSIONS = '/choose-course-sessions';

export const PRIVACY_POLICY =
  'https://docs.google.com/document/d/1Pi3jFzs3eH4K7yNnkYqONrz_qqsun0e6/edit?usp=sharing&ouid=117859953410061859799&rtpof=true&sd=true';

export const TERMS_OF_USE =
  'https://docs.google.com/document/d/13Ghvo0u0KZS-GIwBOb9r0snIiV6NGAAU/edit?usp=sharing&ouid=117859953410061859799&rtpof=true&sd=true';

export const NHS = 'https://northeastnorthcumbria.nhs.uk/';
export const CNE = 'https://children-ne.org.uk';
export const CNE_POVERTY_PROOFING =
  'https://children-ne.org.uk/how-we-can-help/poverty-proofing-services';

export const TOBACCO_LEARN_MORE =
  'https://northeastnorthcumbria.nhs.uk/our-work/workstreams/health-and-prevention/tobacco-programme/';
export const TOBACCO_FOLLOW_X = 'https://twitter.com/SmokefreeNHS_NE';
